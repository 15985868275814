import React from 'react';
import Bem from 'react-better-bem';

import styles from './Tag.module.scss';

const VALID_COLORS = ['red', 'green', 'blue'];

const Tag = ({ children, color, large = false, ...props }) => {
    if (!children) {
        return null;
    }

    color = VALID_COLORS.includes(color) ? color : VALID_COLORS[0];

    return (
        <Bem style={styles}>
            <div el="tag" mod={{ color, large }}>
                {children}
            </div>
        </Bem>
    );
};

export default Tag;
