import React, { useRef } from 'react';
import Bem from 'react-better-bem';

import {
    AttendanceDisplay,
    Tooltip,
    Icon,
    ScoreBadge,
    ActionWheel,
    Button,
    Tag,
    AnimatedSvg,
    Input,
    Select,
    Toggle,
} from '../';

import { useAppStateContext } from '../../context';
import { getCopy, parseInput } from '../../utils';

import STATUS from '../../data/rating_statusses.json';
import RESULT_TYPE from "../../data/result_types.json";

import styles from './SmallRatings.module.scss';

const SmallRatings = ({
    expanded = false,
    criteria,
    result = '',
    resultType,
    feedback = '',
    status,
    block: { school_year: schoolYear } = {},

    // attendance props
    attendance,
    sessions,

    role,

    includePublicationStatus = false,
    includeAttendanceLink = true,

    // props needed for rating
    allowRating = false,
    studentId,
    ratingId,
    constrainRatings,
    constrainClosingRatings,
    updateScore,
    localFeedback,
    hasUnsavedFeedback,
    openFeedbackModal,
    openCloseCourseModal,
    updateStatus,
    updateResult,
    publish,
    completable,
    published,
    publishable,
}) => {
    const { modal: { open: openModal } } = useAppStateContext();

    const tagValue = getCopy(`rating.status.${status}.name`);
    const tagColor = getCopy(`rating.status.${status}.tagColor`);

    const copyEnv = role ? role : allowRating ? 'teacher' : 'student';

    const attendanceTitle = getCopy(`rating.tableHeaders.${copyEnv}.attendance.title`);
    const attendanceTooltip = getCopy(`rating.tableHeaders.${copyEnv}.attendance.tooltip`);
    const resultTitle = getCopy(`rating.tableHeaders.${copyEnv}.result.title`);
    const resultTooltip = getCopy(`rating.tableHeaders.${copyEnv}.result.tooltip`);
    const feedbackTitle = getCopy(`rating.tableHeaders.${copyEnv}.feedback.title`);
    const feedbackTooltip = getCopy(`rating.tableHeaders.${copyEnv}.feedback.tooltip`);
    const statusTitle = getCopy(`rating.tableHeaders.${copyEnv}.status.title`);
    const statusTooltip = getCopy(`rating.tableHeaders.${copyEnv}.status.tooltip`);
    const publishedTitle = getCopy(`rating.tableHeaders.${copyEnv}.published.title`);
    const publishedTooltip = getCopy(`rating.tableHeaders.${copyEnv}.published.tooltip`);
    const closedTitle = getCopy(`rating.tableHeaders.${copyEnv}.closed.title`);
    const closedTooltip = getCopy(`rating.tableHeaders.${copyEnv}.closed.tooltip`);

    const unsavedFeedbackButtonTitle = getCopy('rating.feedback.unsaved.buttonTitle');
    const emptyFeedbackButtonTitle = getCopy('rating.feedback.empty.buttonTitle');

    const publishedButtonTitle = getCopy('rating.published.published.buttonTitle');
    const publishableButtonTitle = getCopy('rating.published.publishable.buttonTitle');
    const unpublishedButtonTitle = getCopy('rating.published.unpublished.buttonTitle');

    const closedButtonTitle = getCopy('rating.closed.closed.buttonTitle');
    const closableButtonTitle = getCopy('rating.closed.closable.buttonTitle');

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="small-ratings" mod={{ expanded }}>
                {!expanded && <h6 el="title">Criteria</h6>}
                <ol el="competences">
                    {criteria.map((group, i) => (
                        <li key={`small-ratings-criteriagroup-${i}`} el="group">
                            <ol el="list">
                                {group.map((criterium) => {
                                    const {
                                        typeId: ctId,
                                        code,
                                        name,
                                        description,
                                        score,
                                        neededForClosing,
                                    } = criterium;

                                    // constrain action wheel if status = AFG && needed_for_closing
                                    // or when status = VL/NVL
                                    const constrain = constrainRatings || (constrainClosingRatings && neededForClosing);

                                    return (
                                        <li key={`small-ratings-criterium-${i}-${code}`} el="criterium">
                                            <SmallRatingsTooltip
                                                abbr={code}
                                                title={name}
                                            >
                                                <strong>{name}</strong><br />
                                                {description}
                                            </SmallRatingsTooltip>
                                            {allowRating ? (
                                                <ActionWheel
                                                    value={score}
                                                    setValue={(value) => {
                                                        updateScore([ratingId], ctId, value);
                                                    }}
                                                    constrain={constrain}
                                                    badgeSize="medium"
                                                    className={`${styles['score-badge']}`}
                                                />
                                            ) : (
                                                <ScoreBadge
                                                    value={score}
                                                    size={expanded ? 'large' : 'small'}
                                                    className={`${styles['score-badge']}`}
                                                />
                                            )}
                                        </li>
                                    );
                                })}
                            </ol>
                        </li>
                    ))}
                </ol>
                <hr el="line" />
                {!expanded && !allowRating && <h6 el="title" mod="progress">Studievoortgang</h6>}
                <div el="progress">
                    {(resultType) ? (<div el="stat">
                        <SmallRatingsTooltip
                            abbr={resultTitle}
                            title={resultTooltip}
                        />
                        {allowRating ?
                            resultType === RESULT_TYPE.OVG ?
                                (<Select
                                    small={true}
                                    options={[{ value: "O", label: "O" }, { value: "V", label: "V" }, { value: "G", label: "G" }]}
                                    value={result || ""}
                                    disabled={!completable}
                                    onChange={({ value }) => updateResult(ratingId, resultType, value, true)}
                                />) :
                                (<Input
                                    type="text"
                                    small={true}
                                    className={styles['student__result']}
                                    value={result || ""}
                                    noNotification={true}
                                    disabled={!completable}
                                    onChange={({ target }) => {
                                        parseInput(target);
                                        updateResult(ratingId, resultType, target.value);
                                    }}
                                />)
                         : (
                        <strong>{ result || '?' }</strong>
                        )}
                    </div>) : ''}
                    <div el="stat">
                        <SmallRatingsTooltip
                            abbr={attendanceTitle}
                            title={attendanceTooltip}
                        />
                        <AttendanceDisplay
                            attendance={attendance}
                            sessions={sessions}
                            addLink={includeAttendanceLink}
                            studentId={studentId}
                            year={schoolYear}
                            eduarteMode={allowRating ? 'enter' : 'view'}
                        />
                    </div>
                    <div el="stat">
                        <SmallRatingsTooltip
                            abbr={feedbackTitle}
                            title={feedbackTooltip}
                        />
                        {allowRating ? (
                            <Button
                                {...(
                                    hasUnsavedFeedback ? {
                                        children: <AnimatedSvg type="dots"/>,
                                        title: unsavedFeedbackButtonTitle
                                    } : {
                                        icon: 'edit',
                                        iconColor: 'blue',
                                        iconSize: 'large',
                                        title: !localFeedback.length ? emptyFeedbackButtonTitle : undefined
                                    }
                                )}
                                variant="mini"
                                onClick={openFeedbackModal}
                            />
                        ) : (
                            <Button
                                icon="edit"
                                variant="mini"
                                iconColor="blue"
                                iconSize="large"
                                onClick={() => openModal((feedback?.length) ? feedback : "Er is geen feedback gegeven.", 'Feedback')}
                            />
                        )}
                    </div>
                    <div el="stat">
                        <SmallRatingsTooltip
                            abbr={statusTitle}
                            title={statusTooltip}
                        />
                        {allowRating ? (
                            <div>
                                <Toggle
                                    type="radio"
                                    name="course-state-responsive"
                                    className={styles['state-toggle']}
                                    checked={status === STATUS.COMPLETED}
                                    disabled={!completable}
                                    color="green"
                                    change={(c) => {
                                        if (c) {
                                            updateStatus(ratingId, STATUS.COMPLETED);
                                        }
                                    }}
                                />
                                <Toggle
                                    type="radio"
                                    name="course-state-responsive"
                                    className={styles['state-toggle']}
                                    checked={status === STATUS.NOT_COMPLETED}
                                    disabled={!completable}
                                    color="red"
                                    change={(c) => {
                                        if (c) {
                                            updateStatus(ratingId, STATUS.NOT_COMPLETED);
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            tagValue ? (
                                <Tag color={tagColor} large={expanded}>{tagValue}</Tag>
                            ) : <small>?</small>
                        )}
                    </div>
                    {allowRating ? (
                        <div el="stat">
                            <SmallRatingsTooltip
                                abbr={publishedTitle}
                                title={publishedTooltip}
                            />
                            {published ? (
                                <Icon
                                    type="eye"
                                    valign="middle"
                                    color="green"
                                    title={publishedButtonTitle}
                                />
                            ) : publishable ? (
                                <Button
                                    icon="eye-closed"
                                    iconColor="blue"
                                    transparent
                                    variant="extra-small"
                                    onClick={() => {
                                        publish([ratingId]);
                                    }}
                                    title={publishableButtonTitle}
                                />
                            ) : (
                                <Icon
                                    type="eye-closed"
                                    valign="middle"
                                    color="gray"
                                    title={unpublishedButtonTitle}
                                />
                            )}
                        </div>
                    ) : includePublicationStatus ? (
                        <div el="stat">
                            <SmallRatingsTooltip
                                abbr={publishedTitle}
                                title={publishedTooltip}
                            />
                            <Icon
                                type={published ? 'eye' : 'eye-closed'}
                                color={published ? 'green' : 'gray'}
                                title={published ? publishedButtonTitle : unpublishedButtonTitle}
                                valign="middle"
                            />
                        </div>
                    ) : null}
                </div>
                {allowRating && <hr el="line"/>}
                {allowRating && (
                    <div el="progress">
                        <div el="stat">
                            <SmallRatingsTooltip
                                abbr={closedTitle}
                                title={closedTooltip}
                            />
                            {status === STATUS.CLOSED ? (
                                <Icon
                                    type="cross-circle"
                                    valign="middle"
                                    color="red"
                                    title={closedButtonTitle}
                                />
                            ) : (
                                <Button
                                    icon="cross-circle"
                                    iconColor="blue"
                                    transparent
                                    variant="extra-small"
                                    title={closableButtonTitle}
                                    onClick={openCloseCourseModal}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Bem>
    );
};

const SmallRatingsTooltip = ({ abbr, title, children, ...props }) => {
    const abbrRef = useRef();

    return (
        <>
            <abbr title={title} ref={abbrRef}>
                {abbr}
            </abbr>
            <Tooltip target={abbrRef} {...props}>
                {children}
            </Tooltip>
        </>
    );
};

export default SmallRatings;
