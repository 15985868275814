import React, { useCallback, useRef, useState } from 'react';

import { useLongPress } from 'use-long-press';

import { Button, Popover } from '../';

import styles from './SortButton.module.scss';

const VALID_SORT_DIRECTIONS = ['ASC', 'DESC'];

const SortButton = ({
    sortable = false,
    props = [],
    propNames = [],
    prop,
    direction = 'ASC',
    callback = () => void 0
}) => {
    const buttonRef = useRef();

    const sortCallback = useCallback(({ newDirection, newProperty }) => {
        const _direction = newDirection || direction;
        const _prop = newProperty || prop;
        callback(_direction, _prop);
    }, [callback, direction, prop]);

    // logic for handling popover (longpress)
    const [mountPopover, setMountPopover] = useState(false);
    const bindOpenPopover = useLongPress(() => { setMountPopover(mounted => !mounted); });

    // all this is bs if we're not sorting anyway of course
    if (!sortable) {
        return null;
    }

    direction = VALID_SORT_DIRECTIONS.includes(direction) ? direction : VALID_SORT_DIRECTIONS[0];
    const iconDirection = direction.toLowerCase();

    return (
        <>
            <Button
                icon={`sort-${iconDirection}`}
                variant="mini-circle"
                className={styles['sort-button']}
                onClick={() => {
                    const newDirection = direction === VALID_SORT_DIRECTIONS[0] ? VALID_SORT_DIRECTIONS[1] : VALID_SORT_DIRECTIONS[0];
                    sortCallback({ newDirection });
                }}
                title={props.length ? "Hou de knop ingeduwd om op andere eigenschappen te sorteren" : undefined}
                ref={buttonRef}
                { ...bindOpenPopover }
            />
            {props.length ? (
                <Popover
                    mounted={mountPopover}
                    attachedRef={buttonRef}
                    position="below"
                    onClose={() => { setMountPopover(false); }}
                    collapsed
                >
                    <ol className={styles['sort-props-list']}>
                        {props.map((_prop, i) => {
                            const propName = propNames[i] || _prop;

                            let cn = styles['sort-props-list__item'];
                            if (_prop === prop) {
                                cn += ` ${styles['sort-props-list__item--active']}`;
                            }

                            return (
                                <li
                                    key={`sort-prop-${_prop}`}
                                    className={cn}
                                    onClick={() => {
                                        sortCallback({ newProperty: _prop });
                                        setMountPopover(false);
                                    }}
                                >
                                    {propName}
                                </li>
                            );
                        })}
                    </ol>
                </Popover>
            ) : null}
        </>
    );
};

export default SortButton;



// <Button
//     icon={`sort-${iconDirection}`}
//     variant="mini-circle"
//     className={styles['sort-button']}
//     onClick={sortList}
// />
