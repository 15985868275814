import React, { useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import { NavLink } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import Bem from 'react-better-bem';

import { useAppStateContext } from '../../../../../context';
import { dateToDateString } from '../../../../../utils';

import styles from './WeekNav.module.scss';

const WEEKDAYS = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];

const WeekNav = ({ date, toTomorrow, toYesterday, roster = false }) => {

    const { screen: { isntSmallDesktop, isntTablet } } = useAppStateContext();

    const swipeHandlers = useSwipeable({
        onSwipedLeft: toTomorrow,
        onSwipedRight: toYesterday,
        preventDefaultTouchmoveEvent: true,
        delta: 50
    });

    const weekdays = useMemo(() => {
        const currentWeekday = moment(date).weekday();
        const weekDays = roster ? WEEKDAYS.slice(0, 5) : WEEKDAYS;

        return weekDays.map((day, index) => {
            const diff = index - currentWeekday;
            const dayDate = moment(date).add(diff, 'days');

            if (isntSmallDesktop) {
                day = day.substr(0, 2);
            }

            return {
                day,
                date: dayDate.format('D'),
                current: !diff,
                today: dayDate.isSame(moment(), 'date'),
                dateString: dateToDateString(dayDate)
            };
        })
    }, [date, isntSmallDesktop, roster]);

    return (
        <Bem style={styles}>
            <div el="weeknav" mod={{ choice: roster === true}}>
                <div el="gutter box">
                    { roster === true ? "Lessuur" : "Tijd"}
                </div>
                <div el="buttons box" { ...swipeHandlers }>
                    {weekdays.map(({ day, date, dateString, current, today }) =>
                        isntTablet ? (
                            <NavLink
                                key={`weeknav-${day}-${dateString}-mobile`}
                                to={`/agenda/${dateString}`}
                                className={`${styles['weeknav__buttons__mobile-button']} ${current ? styles['weeknav__buttons__mobile-button--current'] : ''} ${today ? styles['weeknav__buttons__mobile-button--today'] : ''}`}
                            >
                                <span className={styles['weeknav__buttons__mobile-button__weekday']}>
                                    {day}
                                </span>
                                <span
                                    className={`${styles['weeknav__buttons__mobile-button__date']} ${current ? styles['weeknav__buttons__mobile-button__date--current'] : ''} ${today ? styles['weeknav__buttons__mobile-button__date--today'] : ''}`}
                                >
                                    {date}
                                </span>
                            </NavLink>
                        ) : (
                            <NavLink
                                key={`weeknav-${day}-${dateString}`}
                                to={`/agenda/${dateString}`}
                                className={`${styles['weeknav__buttons__button']} ${current ? styles['weeknav__buttons__button--current'] : ''} ${today ? styles['weeknav__buttons__button--today'] : ''}`}
                            >
                                {day}
                            </NavLink>
                        )
                    )}
                </div>
            </div>
        </Bem>
    );
};

export default WeekNav;
