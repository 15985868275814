import React from 'react';
import Bem from 'react-better-bem';

import styles from './StickyFooter.module.scss';

const StickyFooter = ({ children, white = false, rounded = false, stretch = true, inset = false }) => {

    return (
        <Bem style={styles}>
            <div el="footer" mod={{ white, stretch, rounded, inset }}>
                <div el="content">
                    {children}
                </div>
            </div>
        </Bem>
    );
};

export default StickyFooter;
