import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStudentApi, useTraineePlanningApi } from './api';


function useCoachingTrainee () {
    const { student_id: studentId } = useParams();
    const {
        student,
        get: getStudent,
        loading: studentLoading,
        fetched: studentFetched
    } = useStudentApi(studentId);

    const { planning, loading: planningLoading } = useTraineePlanningApi(studentId, true);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (studentId) {
            getStudent(studentId);
        }
    }, [studentId, getStudent]);

    return {
        student: studentId && studentFetched ? student : {},
        studentId: studentId,
        studentLoading,
        setLoading,
        planning,
        planningLoading,
        loading,
    };
}

export default useCoachingTrainee;
