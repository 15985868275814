import { memo, useEffect, useState } from 'react';
import Bem from 'react-better-bem'

import { Icon } from "../../../components";

import styles from "./FileToUpload.module.scss";

const FileToUpload = ({ file, uploaded = false, uploadError }) => {
    const [doneIcon, setDoneIcon] = useState(false);
    const { name } = file;
    const cleanName = name.length > 20 ? `${name.slice(0, 20)}...` : name;

    useEffect(() => {
        let timmy;

        if (uploaded) {
            timmy = setTimeout(() => {
                setDoneIcon(true)
            }   , !uploadError ? 1500 : 500)
        }

        return () => {
            clearTimeout(timmy)
        }
    }, [uploadError, uploaded])

    return (
        <Bem style={styles}>
            <li el="file">
                <span el="text">{cleanName}</span>
                {doneIcon ? (<Icon
                    type={uploadError && !uploaded ? "info-circle" : "checkmark"}
                    color={uploadError && !uploaded ? "red" : "green"}
                    size="medium"
                    className={styles["body-card__body--button-upload"]}
                />) : null}
            </li>
        </Bem>
    );
};

export default memo(FileToUpload);
