import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { LinkList, Popover, SmallRatings, AnimatedSvg, Icon } from '../../../components';
import { useAppStateContext } from '../../../context';

import { FeedbackModal, CloseCourseModal } from './Modals';

import { getCopy } from '../../../utils';

import STATUS from '../../../data/rating_statusses.json';

import responsiveStyles from './GroupCourse.responsive.module.scss';
import globalStyles from './GroupCourse.module.scss';
const styles = { ...responsiveStyles, ...globalStyles };

const GroupCourseResponsive = ({ api, groupCode }) => {
    const { modal: { open: openModal } } = useAppStateContext();

    const {
        students = [],
        criteria = [],
        sessions,
        block,
        setCurrentStudent,
        unsetCurrentStudent,
        updateScore,
        updateResult,
        updateStatus,
        updateFeedback,
        updateLocalFeedback,
        publish
    } = api;

    const linksList = useLinkListsMemo(students, setCurrentStudent);

    const currentStudent = students.find(({ current }) => current);

    // remap criteria to include score to be used in <SmallRatings/>
    const remappedCriteria = useMemo(() => {
        if (!currentStudent) {
            return criteria;
        }

        const { ratings: { criteriaRatings } } = currentStudent;

        return criteria.map((group) => (
            group.map(({ typeId, ...criterium }) => {
                const rating = criteriaRatings.find(({ typeId: ctId }) => ctId === typeId);

                return {
                    typeId,
                    ...criterium,
                    score: rating?.score,
                };
            })
        ));
    }, [criteria, currentStudent]);

    /* * * * * * *
     * CALLBACKS *
    ** * * * * * */
    const openFeedbackModal = useCallback(() => {
        const {
            ratings: {
                id,
                localFeedback,
                feedback: remoteFeedback,
            },
            allowEmptyFeedback,
            full_name,
        } = currentStudent || {};

        openModal((
            <FeedbackModal
                feedback={localFeedback}
                remoteFeedback={remoteFeedback}
                updateLocalFeedback={(newFeedback) => { updateLocalFeedback(id, newFeedback); }}
                updateRemoteFeedback={(newFeedback) => { updateFeedback(id, newFeedback); }}
                allowEmptyFeedback={allowEmptyFeedback}
            />
        ), <>Feedback <small className={styles['feedback-modal__student-name']}>aan {full_name}</small></>);
    }, [openModal, updateLocalFeedback, updateFeedback, currentStudent]);

    const openCloseCourseModal = useCallback(() => {
        openModal(
            (
                <CloseCourseModal
                    closeCourse={() => {
                        updateStatus(currentStudent.ratingId, STATUS.CLOSED);
                    }}
                />
            ),
            (
                <>
                    <Icon type="info-circle" inline valign="middle" size="large" />
                    {getCopy('rating.closed.closable.buttonTitle')}
                </>
            )
        );
    }, [openModal, currentStudent, updateStatus]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    // link to progress overview
    const popoverSubTitle = useMemo(() => {
        const progressOverviewHref = getCopy(
            'progress.teacher.overview.path',
            { studentId: currentStudent?.flexx_id }
        );

        const progressOverviewTitle = getCopy(
            'progress.teacher.overview.title',
            { student: currentStudent?.full_name }
        );

        const progressOverviewLinkContent = getCopy(
            'progress.teacher.overview.linkTitle'
        );

        return (
            <Link
                to={progressOverviewHref}
                title={progressOverviewTitle}
                className={styles['progress-overview-link']}
            >
                <small className={styles['progress-overview-link__content']}>
                    {progressOverviewLinkContent}
                </small>
                <Icon
                    type="chevron-right"
                    size="small"
                    valign="middle"
                    color="blue-darker"
                />
            </Link>
        );
    }, [currentStudent]);

    return (
        <>
            {linksList}
            <Popover
                mounted={currentStudent?.flexx_id !== undefined}
                title={currentStudent?.full_name}
                subtitle={popoverSubTitle}
                onClose={unsetCurrentStudent}
            >
                {currentStudent?.flexx_id !== undefined && (
                    <SmallRatings
                        expanded
                        allowRating
                        includeAttendanceLink
                        result={currentStudent.ratings.result}
                        resultType={currentStudent.ratings.resultType}
                        attendance={currentStudent.ratings.attendance}
                        sessions={sessions}
                        block={block}
                        criteria={remappedCriteria}
                        status={currentStudent.ratings.status}
                        feedback={currentStudent.ratings.feedback}
                        studentId={currentStudent.flexx_id}
                        ratingId={currentStudent.ratingId}
                        constrainRatings={currentStudent.constrainRatings}
                        constrainClosingRatings={currentStudent.constrainClosingRatings}
                        hasUnsavedFeedback={currentStudent.hasUnsavedFeedback}
                        localFeedback={currentStudent.ratings.localFeedback}
                        updateScore={updateScore}
                        updateStatus={updateStatus}
                        updateResult={updateResult}
                        publish={publish}
                        openFeedbackModal={openFeedbackModal}
                        openCloseCourseModal={openCloseCourseModal}
                        completable={currentStudent.completable}
                        publishable={currentStudent.publishable}
                        published={currentStudent.ratings.published}
                    />
                )}
            </Popover>
        </>
    );
};

export default GroupCourseResponsive;


/* * * * * * * * * * * * * * * * *
 * MEMO'S FOR LINKLISTS (MOBILE) *
** * * * * * * * * * * * * * * * */
const useLinkListsMemo = (students, setCurrentStudent) => {
    const studentsMemo = useMemo(() => {
        return students.map(({ full_name, base_group, flexx_id, hasUnsavedFeedback }, ii) => ({
            children: (
                <strong className={styles['student']}>
                    {full_name}
                    {hasUnsavedFeedback && <AnimatedSvg type="dots" className={styles['inline-feedback-dots']} />}
                    <span className={styles['student__class']}>{base_group}</span>
                </strong>
            ),
            onClick: () => {
                setCurrentStudent(flexx_id);
            }
        }))
    }, [students, setCurrentStudent]);

    return (
        <LinkList
            links={studentsMemo}
        />
    );
};
