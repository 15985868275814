import React, { useMemo } from 'react';
import Bem from 'react-better-bem';

import styles from './Label.module.scss';

const Label = ({ children, htmlFor, forRef, selectLabel = false, ...props }) => {

    const forProp = useMemo(() => {
        if (htmlFor) {
            return htmlFor;
        }

        if (forRef?.current?.id) {
            return forRef.current.id;
        }

        return undefined;
    }, [htmlFor, forRef]);

    return children && (
        <Bem style={styles}>
            <label
                el="label"
                mod={{ select: selectLabel }}
                htmlFor={forProp}
                {...props}
            >
                {children}
            </label>
        </Bem>
    );
};

export default Label;
