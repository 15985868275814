import React, { useMemo } from 'react';
import Bem from 'react-better-bem';
import { Link } from 'react-router-dom';

import { Icon } from '../';

import { useEnvironmentContext } from '../../context';
import { getColor } from '../../utils';

import graphics from '../../assets/graphics';

import styles from './Button.module.scss';

const VALID_VARIANTS = ['default', 'inverted', 'small', 'extra-small', 'mini', 'circle', 'mini-circle', 'rounded', 'rounded-empty'];
const VARIANT_COLOR_MAP = {
    'default': 'default',
    'inverted': 'inverted',
    'small': 'default',
    'extra-small': 'default',
    'mini': 'mini',
    'circle': 'mini-circle',
    'mini-circle': 'mini-circle',
    'rounded': 'default',
    'rounded-empty': 'default',
};

const VALID_GRAPHICS = Object.keys(graphics);
const VALID_GRAPHIC_SIZES = ['default', 'extra-small', 'small', 'medium', 'large', 'extra-large'];
const VALID_ICON_POSITIONS = ['end', 'start'];

const Button = React.forwardRef(({
    color,
    iconColor,
    iconSize,
    children = false,
    block = false,
    variant = 'default',
    transparent = false,
    icon = false,
    iconPosition = 'end',
    graphic = false,
    graphicSize = 'default',
    upload = false,
    href,
    to,
    ...props
}, ref) => {
    const { environment } = useEnvironmentContext();

    const buttonVariant = VALID_VARIANTS.includes(variant) ? variant : VALID_VARIANTS[0];
    const buttonGraphic = graphic && VALID_GRAPHICS.includes(graphic) ? graphics[graphic] : null;
    const buttonGraphicSize = VALID_GRAPHIC_SIZES.includes(graphicSize) ? graphicSize : VALID_GRAPHIC_SIZES[0];

    iconPosition = VALID_ICON_POSITIONS.includes(iconPosition) ? iconPosition : VALID_ICON_POSITIONS[0];

    const buttonContent = useMemo(() => {
        const iconElement = icon ? (
            <>
                {['default', 'active', 'focus', 'hover', 'disabled'].map((mod) => {
                    const _iconColor = iconColor || color || `button-${VARIANT_COLOR_MAP[buttonVariant]}${mod !== 'default' ? `-${mod}` : ''}-text`;

                    return (
                        <Icon
                            key={`button-icon-${mod}`}
                            type={icon}
                            size={iconSize || (buttonVariant === 'mini-circle' ? 'small' : 'default')}
                            className={`${styles.button__icon} ${styles[`button__icon--${mod}`]}`}
                            color={_iconColor}
                        />
                    );
                })}
            </>
        ) : buttonGraphic ? (
            <img
                src={buttonGraphic}
                alt=""
                className={`${styles.button__graphic} ${styles[`button__graphic--size-${buttonGraphicSize}`]}`}
            />
        ) : null;

        let content = children;

        if (['mini', 'mini-circle'].includes(buttonVariant)) {
            if (iconElement) {
                content = null;
            } else {
                const child = React.Children.toArray(children).shift();
                content = typeof child === 'string' ? child.trim().substr(0, 1) : child;
            }
        }

        const cssProps = {};

        if (color) {
            cssProps.color = getColor(color, environment);
        }

        return (
            <Bem block="button" style={styles}>
                {iconPosition === 'start' && iconElement}
                {content ? (
                    <span el="content" mod={{ variant: buttonVariant, upload }} style={cssProps}>
                        {content}
                    </span>
                ) : null}
                {iconPosition === 'end' && iconElement}
            </Bem>
        );
    }, [children, icon, color, iconColor, environment, buttonVariant, iconSize, buttonGraphic, buttonGraphicSize, iconPosition, upload]);

    if (!buttonGraphic && !icon && !children) {
        return null;
    }

    if (to !== undefined) {
        let linkClassNames = [styles.button, styles[`button--variant-${variant}`], styles['button--link']];

        if (transparent) {
            linkClassNames.push(styles['button--transparent']);
        }

        if (block) {
            linkClassNames.push(styles['button--block']);
        }

        if (props.className) {
            linkClassNames = linkClassNames.concat(props.className);
        }

        return (
            <Link
                to={to}
                ref={ref}
                { ...props }
                className={linkClassNames.join(' ')}
            >
                {buttonContent}
            </Link>
        );
    }

    if (href !== undefined) {
        return (
            <Bem style={styles}>
                <a
                    el="button"
                    mod={{
                        variant: buttonVariant,
                        transparent,
                        block,
                        link: true
                    }}
                    href={href}
                    ref={ref}
                    { ...props }
                >
                    {buttonContent}
                </a>
            </Bem>
        );
    }

    return (
        <Bem style={styles}>
            <button
                el="button"
                mod={{
                    variant: buttonVariant,
                    transparent,
                    block,
                    link: true,
                    upload
                }}
                ref={ref}
                { ...props }
            >
                {buttonContent}
            </button>
        </Bem>
    );
});

export default Button;
