import { useEffect, useMemo, useReducer } from 'react';

import { useApi } from '../'

const INIT_VALUE = { available: [], requests: [] };

const useNonRosteredCoursesApi = () => {
	const { get, loading, value, fetched, error } = useApi('non-rostered courses', '/choices/moment2/non-rostered/', { initialValue: INIT_VALUE });

	const fetchWhen = !fetched && !loading;
	useEffect(() => {
		if (fetchWhen) {
			get();
		}
	}
	, [get, fetchWhen]);

	const [nonRostered, dispatchNonRostered] = useReducer(nonRosteredReducer, []);
	useEffect(() => { dispatchNonRostered({ type: 'INIT', value  }); }, [value]);

	const { results } = useMemo(() => {
		const results = nonRostered.filter(({ study_load: studyLoad }) => studyLoad > 0);

		// TODO: Determine which courses were not honored, but subsequently not scheduled.

		return { results };

	}, [nonRostered]);


	/* * * * * *
	 * OUTPUT  *
	** * * * * */
	return {
		actualAvailable: results,

		loading,
		fetched,
		error
	};
}

function nonRosteredReducer(state, action) {
	const { type, value: { available } } = action;

	switch (type) {
		case 'INIT': {
			const _available =  available
			return _available;
		}
		default: {
			return state;
		}
	}
}


export default useNonRosteredCoursesApi;