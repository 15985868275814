import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useEnvironmentContext } from '../../context';

import styles from './FooterLegal.module.scss';

const SIGNOUT_URL = process.env.REACT_APP_SIGNOUT_URL;
const FLEXX_ENV = process.env.REACT_APP_FLEXX_ENV;

const FooterLegal = () => {
    const { navItems: { footerLegal }, user: { fullName: userFullName, flexxId: userStudentNumber, baseGroup: userGroup }, environment } = useEnvironmentContext();

    const [addRemoveWaterMarkLink, setAddRemoveWaterMarkLink] = useState(false);

    useEffect(() => {
        const svg = document.querySelector('body > div[style*="data:image/svg"]');
        if (svg) {
            svg.style.position = 'fixed';
            if (FLEXX_ENV === 'development') {
                svg.parentNode.removeChild(svg);
            } else {
                setAddRemoveWaterMarkLink(true);
            }
        }
    }, []);

    const year = new Date().getFullYear();

    const userInfo = environment === 'STUDENT' && userGroup ?
        `${userFullName} - ${userStudentNumber} - ${userGroup}` :
        (environment === 'STUDENT' ? `${userFullName} - ${userStudentNumber}` : `${userFullName}`);

    return (
        <>
            <span>
                &copy; {year} <a href="https://rocva.nl" target="_blank" rel="noreferrer" className={`${styles.link} ${styles['link--no-line']}`}>ROC van Amsterdam</a>
            </span>
            {footerLegal.map(({ href, name, external }) => (
                <React.Fragment key={`footer-nav-link-${name}`}>
                    <span className={styles.divider}>|</span>
                    {external ? (
                        <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
                            {name}
                        </a>
                    ) : (
                        <Link to={href} className={styles.link}>
                            {name}
                        </Link>
                    )}
                </React.Fragment>
            ))}
            {addRemoveWaterMarkLink && (
                <>
                    <span className={styles.divider}>|</span>
                    <Link
                        to="#"
                        className={styles.link}
                        onClick={() => {
                            const svg = document.querySelector('body > div[style*="data:image/svg"]');
                            if (svg) {
                                svg.parentNode.removeChild(svg);
                            }
                        }}
                    >
                        Verwijder watermerk
                    </Link>
                </>
            )}
            <span className={styles.divider}>|</span>
            {/*
            <span className={styles.link} data-role={environment} id="start-flexx-guide">
                Start Flexx instructies
            </span>
            <span className={styles.divider}>|</span>
            */}
            <span className={`${styles.link} ${styles['link--no-hover']}`}>
                {userInfo}
            </span>
            <span className={styles.divider}>|</span>
            <a href={SIGNOUT_URL} className={styles.link}>
                Uitloggen
            </a>
        </>
    );
};

export default FooterLegal;
