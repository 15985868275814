import React, { useCallback } from 'react';
import { Navigate } from 'react-big-calendar';
import Bem from 'react-better-bem';

import { useAppStateContext, useEnvironmentContext } from '../../../../../context/';
import { Button, LoadingDots, BlockBadge } from '../../../../';

import WeekNav from '../WeekNav/WeekNav';

import styles from './Toolbar.module.scss';

const Toolbar = ({ date, onNavigate, label, loading, openMobileDatePicker, rosterBlock, showButtons = true, roster = false }) => {

    const { screen: { isTablet } } = useAppStateContext();
    const { environment, planning } = useEnvironmentContext();
    const block = !roster ? planning.getBlockByDate(date) : rosterBlock;

    const toToday = useCallback(() => {
        onNavigate(Navigate.TODAY);
    }, [onNavigate]);

    const toYesterday = useCallback(() => {
        onNavigate(Navigate.PREVIOUS);
    }, [onNavigate]);

    const toTomorrow = useCallback(() => {
        onNavigate(Navigate.NEXT);
    }, [onNavigate]);

    return (
        <>
            <Bem style={styles}>
                <div el="wrapper">
                    <Bem style={styles}>
                        <div el="toolbar" mod={{ desktop: isTablet }}>
                            {isTablet && showButtons ? (
                                <div el="box" mod="left">
                                    <Button
                                        variant="small"
                                        onClick={toToday}
                                        className={`${styles['toolbar__box__button']} ${styles['toolbar__box__button--today']}`}
                                    >
                                        Vandaag
                                    </Button>
                                    <Button
                                        variant="mini-circle"
                                        icon="chevron-left"
                                        onClick={toYesterday}
                                        className={`${styles['toolbar__box__button']} ${styles['toolbar__box__button--prev']}`}
                                    />
                                    <Button
                                        variant="mini-circle"
                                        icon="chevron-right"
                                        onClick={toTomorrow}
                                        className={`${styles['toolbar__box__button']} ${styles['toolbar__box__button--next']}`}
                                    />
                                    <h4 el="label">
                                        {label}
                                        <BlockBadge
                                            className={styles['block-badge']}
                                            block={block}
                                            size="large"
                                            period={environment === 'TEACHER'}
                                        />
                                        {loading ? <LoadingDots className={styles['loading-dots']} /> : null}
                                    </h4>
                                </div>
                            ) : !isTablet && showButtons ? (
                                <div el="box" mod="left">
                                    <Button
                                        variant="mini"
                                        icon="calendar-view-month"
                                        className={`${styles['toolbar__box__button']} ${styles['toolbar__box__button--calendar']}`}
                                        onClick={openMobileDatePicker}
                                    />
                                    <h4 el="label">
                                        {label}
                                        {loading ?
                                            <LoadingDots className={styles['loading-dots']} /> :
                                            (
                                                <BlockBadge
                                                    className={styles['block-badge']}
                                                    block={block}
                                                    period={environment === 'TEACHER'}
                                                />
                                            )
                                        }
                                    </h4>
                                </div>
                            ) : (
                                <div el="box" mod="left">
                                    <h4 el="label">
                                        {label}
                                        {loading ?
                                            <LoadingDots className={styles['loading-dots']} /> :
                                            (
                                                <BlockBadge
                                                    className={styles['block-badge']}
                                                    block={ block }
                                                    size="extra-large"
                                                    period={environment === 'TEACHER'}
                                                />
                                            )
                                        }
                                    </h4>
                                </div>
                            )}
                            {isTablet ? null : (
                                <div el="box" mod="right">
                                    <Button
                                        variant="mini"
                                        icon="calendar-today"
                                        onClick={toToday}
                                        className={`${styles['toolbar__box__button']} ${styles['toolbar__box__button--today']}`}
                                    />
                                </div>
                            )}
                        </div>
                    </Bem>

                    <WeekNav
                        date={date}
                        toTomorrow={toTomorrow}
                        roster={roster}
                        toYesterday={toYesterday}
                    />
                </div>
            </Bem>
        </>
    );
};

export default Toolbar;
