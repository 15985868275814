import { useEffect, useMemo } from 'react';

import useApi from '../useApi';

function useRecentReportsApi (apiPathPrefix = 'coach') {
    const { get, loading, value, fetched } = useApi('ophalen recente gespresksverslagen', `/coaching/${apiPathPrefix}/reports/recent/`, { initialValue: [] });

    // fetch initially
    const fetchWhen = !fetched && !loading;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    // sort by newest first
    const recentReports = useMemo(() => {
        return [...value].sort(({ report: { created_at: a } = {} }, { report: { created_at: b } = {} }) => {
            const da = new Date(a);
            const db = new Date(b);

            return da < db ? 1 : da < db ? -1 : 0;
        });
    }, [value]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        recentReports,
        loading,
        fetched
    };
}

export default useRecentReportsApi;
