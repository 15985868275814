import React from 'react';
import Bem from 'react-better-bem';

import { PageHeader, ReportsLinkList, Icon, Person, Button } from '../../../components';
import { useLayout, usePageTitle } from '../../../hooks';
import { useReportsApi, useMyCoachApi } from '../../../hooks/api';

import styles from './StudentCoaching.module.scss';

const StudentCoaching = () => {
    useLayout({ collapse: 'top' });
    usePageTitle('coaching.overview.student');

    const { fetched: fetchedReports, reports } = useReportsApi();
    const { fetched: fetchedCoach, error: errorCoach, coaches } = useMyCoachApi(true);

    return (
        <Bem style={styles}>
            <PageHeader title="Studieloopbaancoaching" />

            <section el="begeleiding-grid">

                <section el="column" mod="reports">
                    <section el="new-report">
                        <div el="content">
                            <h2>Nieuw gespreksverslag</h2>
                            <Button
                                target="_blank"
                                variant="small"
                                icon="text-add"
                                to="/coaching/verslagen/nieuw"
                            >
                                Nieuw verslag
                            </Button>
                        </div>
                    </section>

                    {fetchedReports && (
                        <section el="list">
                            <h2>
                                <Icon type="mail" color="red" valign="middle" inline />
                                Jouw gespreksverslagen
                            </h2>
                            <ReportsLinkList
                                data={reports}
                                hrefTemplate="/coaching/verslagen/[id]"
                            />
                        </section>
                    )}
                </section>

                {fetchedCoach && !errorCoach && (
                    <section el="column" mod="additional">
                        <section el="coaches">
                            <h2>Dit is jouw {(coaches?.length > 1) ? 'coaches' : 'coach'}</h2>
                            <div el="list">
                                {
                                coaches.map((coach, index) => (
                                    <div el="coach" key={index}>
                                        <Person
                                            {...coach}
                                            subtext={<a href={`mailto:${coach.email}`}>{coach.email}</a>}
                                            color="blue"
                                        />
                                    </div>
                                ))
                            }
                            </div>
                        </section>

                        <section el="feedback">
                            <h2>Feedback bij jouw beoordelingen</h2>
                            <Button el="feedback" variant="small" to={"/coaching/feedback"}>Feedback</Button>
                        </section>
                    </section>
                )}

            </section>
        </Bem>
    );
};

export default StudentCoaching;
