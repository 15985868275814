import afgesloten from './status_icons/afgesloten.svg';
import behaald from './status_icons/behaald.svg';
import niet_behaald from './status_icons/niet_behaald.svg';
import afstuderen from './status_icons/afstuderen.svg';
import voltooid_chart from './status_icons/voltooid_chart.svg';

const statusIcons = {
    afgesloten,
    behaald,
    niet_behaald,
    afstuderen,
    voltooid_chart,
};

export default statusIcons;
