import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { getCopy } from '../../../utils';
import { useLayout, usePageTitle } from '../../../hooks';
import { useProgressCompetencesApi } from '../../../hooks/api';
import {
    useAppStateContext,
    useCoachingTraineeContext,
    useBreadcrumbTrailContext,
} from '../../../context';

import { CompetenceGraph, CompetenceGraphLegend, Icon } from '../../../components/';

import * as gfx from '../../../assets/graphics';

import styles from './Competences.module.scss';

const ProgressCompetences = ({ api, role, student, studentId }) => {
    useLayout({ stretch: false, collapse: 'top', flexContent: false, margin: false });
    usePageTitle(`progress.competences.${role}`, { student: student?.full_name || '' });

    const { screen: { isSmallDesktop } } = useAppStateContext();

    const { competences, loading } = api;

    const titleRef = useRef();

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        const pageTitle = getCopy(`progress.${role}.competences.title`);
        const path = getCopy(`progress.${role}.competences.path`, { studentId });

        put({
            name: pageTitle,
            href: path,
            loading,
        }, role === 'student' ? 1 : 2);

        return () => {
            remove(pageTitle);
        };
    }, [remove, put, role, studentId, loading]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="competences container">
                {!isSmallDesktop && (
                    <h2 el="responsive-title" ref={titleRef} title="sup fool">
                        <Icon type="award" inline size="large" color="blue" />
                        Alle competenties
                    </h2>
                )}
                {!isSmallDesktop && (
                    <div el="responsive-legend">
                        <div el="content">
                            <CompetenceGraphLegend />
                        </div>
                    </div>
                )}
                <ul el="list">
                    {isSmallDesktop && (
                        <li el="item" mod="header">
                            <div el="content">
                                <h2 el="title">
                                    <Icon type="award" inline size="large" color="blue" />
                                    Alle competenties
                                </h2>
                            </div>
                                <div el="graph" mod="legend">
                                    <CompetenceGraphLegend />
                                </div>
                        </li>
                    )}
                    {competences.map(({ description, choiceId, id, scores, total }, i) => (
                        <li
                            el="item"
                            mod={{ competence: true, shaded: !!(i%2) }}
                            key={`competence-${id}-details`}
                        >
                            <div el="content">
                                <h4 el="title">
                                    <Link
                                        to={getCopy(
                                            `progress.${role}.competenceDetails.path`,
                                            { choiceId, studentId }
                                        )}
                                    >
                                        {description}
                                    </Link>
                                </h4>
                                <div el="stats">
                                    <div el="stat">
                                        <img
                                            alt=""
                                            src={gfx.competenceBadge}
                                            el="badge"
                                        />
                                        <strong el="value">{total} keer beoordeeld</strong>
                                    </div>
                                    {/*
                                    <div el="stat">
                                        <img alt="" src={gfx.statusBL} el="badge" />
                                        <strong el="value">10</strong>
                                    </div>
                                    */}
                                </div>
                            </div>
                            <div el="graph">
                                <CompetenceGraph
                                    scores={scores}
                                    invertLineColor={!!(i%2)}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </Bem>
    );
};

/* * * * * * * * *
 * COACH/MENTOR  *
** * * * * * * * */
const CoachProgressCompetences = ({ role = 'coach' }) => {
    const { studentId, student } = useCoachingTraineeContext();
    const api = useProgressCompetencesApi(studentId);

    return (
        <ProgressCompetences
            api={api}
            student={student}
            studentId={studentId}
            role={role}
        />
    );
};


/* * * * * * *
 * STUDENTS  *
** * * * * * */
const StudentProgressCompetences = () => {
    const api = useProgressCompetencesApi();

    return (
        <ProgressCompetences
            api={api}
            role="student"
        />
    );
};

/* * * * * *
 * EXPORT  *
** * * * * */
export {
    StudentProgressCompetences,
    CoachProgressCompetences,
};
