import React, { memo, useMemo } from 'react';
import moment from 'moment';
import Bem from 'react-better-bem';

import {
    BlockBadge,
    Icon,
    ProgressBar,
    StickyFooter,
    RouteStatusImage,
    IconButton,
} from '../../';

import { useAppStateContext } from '../../../context';
import { getCopy } from '../../../utils';

import styles from './Km2Roster.module.scss';

const WEEKDAYS = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag'];
const CSS_GRID_HALF_HOUR_HEIGHT = [1.875, 2.625]; // ~30px, ~42px
// const CSS_GRID_HALF_HOUR_HEIGHT = [0.9, 1.8]; // temp fix for smaller screens

const SLOT_STARTS = {
    1: '09:00:00',
    2: '10:30:00',
    3: '12:00:00',
    4: '12:30:00',
    5: '14:00:00',
    6: '15:30:00',
    7: '17:00:00',
}

const Km2Roster = ({
    block,
    roster,
    periods,
    activePeriod: _activePeriod,
    hilightedSlots,
    available,
    showAvailableCourses,
    showChoiceModal,
    chooseCourse,
    role,
    showAllAvailableCoursesMobile,
    hideFooter = false,
    bigPopover = false,
}) => {
    const { screen: { isTablet } } = useAppStateContext();

    const {
        student: {
            km2: {
                readable: timeLeftReadable,
                fraction: timeLeftFraction,
            },
        },
    } = block;

    // copy
    const rosterTitle = getCopy(`coursePlanner.secondMoment.${role}.roster.title`);
    const periodColumnTitle = getCopy(`coursePlanner.secondMoment.${role}.roster.periodColumnTitle`);
    const warningBarText = getCopy(
        `coursePlanner.secondMoment.${role}.roster.${!isTablet ? 'warningBarMobile' : 'warningBarDesktop'}`,
        { timeLeft: timeLeftReadable }
    );

    // weekdays => small screen format if needed
    const weekdays = useMemo(() => {
        return WEEKDAYS.map((day) => {
            if (!isTablet) {
                return day.substr(0, 2);
            }

            return day;
        });
    }, [isTablet]);

    // get array with all available slots (periods)
    const [activeWeekday, activePeriod] = _activePeriod;
    const availableSlots = useMemo(() => {
        if (hilightedSlots.length) {
            return [];
        }

        const slots = WEEKDAYS.reduce((acc, _, i) => {
            // add weekday to periods
            const periodsWithWeekday = periods.map((period) => ({
                ...period,
                weekday: i + 1,
            }));

            const emptyPeriods = periodsWithWeekday.filter(({ weekday, break: isBreak }, index) => {
                if (isBreak) {
                    return false;
                }

                const actualPeriod = index + 1;
                const hasRosterCourse = roster.some(({
                    weekday: [_day],
                    startPeriod: [_start],
                    endPeriod: [_end],
                }) => {
                    if (_day !== weekday) {
                        return false;
                    }

                    return actualPeriod >= _start && actualPeriod <= _end;
                });

                if (hasRosterCourse) {
                    return false;
                }

                const hasAvailableCourse = available.some(({
                    weekday: [_day],
                    startPeriod: [_start],
                    endPeriod: [_end],
                }) => {
                    if (_day !== weekday) {
                        return false;
                    }

                    return actualPeriod >= _start && actualPeriod <= _end;
                });

                return hasAvailableCourse;
            });

            return [ ...acc, ...emptyPeriods ];
        }, []);

        return slots;
    }, [periods, roster, available, hilightedSlots]);

    // get array to map for both grid lines and breaks
    // and get grid-template-rows css
    const [gridLines, breaks, gridCss] = useMemo(() => {
        // first get start and end of day
        // !! ASSUMING THAT THERE ARE NO GAPS IN PERIOD PLANNING !!
        const [startOfDay, endOfDay] = periods.reduce((acc, { startMoment, endMoment, break: isBreak }, i) => {
            if (!i) {
                return [startMoment, endMoment];
            }

            let [_sm, _em] = acc;

            if (startMoment.isBefore(_sm)) {
                _sm = startMoment;
            }

            if (endMoment.isAfter(_em)) {
                _em = endMoment;
            }

            return [_sm, _em];
        }, []);

        // get height of 1 half hour in grid
        const height = isTablet ? CSS_GRID_HALF_HOUR_HEIGHT[1] : CSS_GRID_HALF_HOUR_HEIGHT[0];

        // get the breaks (could be multiple)
        const breakPeriods = periods.filter(({ break: isBreak }) => isBreak);

        // get list of breaks used to map in output
        // positioned absolutely: we need to now at what height (start)
        // and the height of the break (duration)
        const breaks = breakPeriods.map(({ start, end, startMoment, endMoment }) => {
            const _start = getDurationInHalfHours(startOfDay, startMoment);
            const duration = getDurationInHalfHours(startMoment, endMoment);
            return {
                id: `${start}-${end}`,
                start: (_start * height).toFixed(3),
                duration: (duration * height).toFixed(3),
            };
        });

        // total duration of the day in half hours
        // (duration in minutes / 30 + 1) +1 because we need to include begin _and_ end time
        const nHalfHours = Math.floor(getDurationInHalfHours(startOfDay, endOfDay)) + 1;

        // array of 'lines' to loop over in output
        const lines = new Array(nHalfHours).fill().map((_, i) => {
            const time = moment(startOfDay).add(i * 30, 'minutes');

            // if during a break: don't draw a line
            const isInBreak = breakPeriods.some(({ startMoment, endMoment }) => (
                time.isBetween(startMoment, endMoment)
            ));

            // draw a solid line when it fits the begin/end of a period
            const solid = periods.some(({ startMoment, endMoment }) => (
                (time.isSame(startMoment, 'hour') && time.isSame(startMoment, 'minute')) ||
                (time.isSame(endMoment, 'hour') && time.isSame(endMoment, 'minute'))
            ));

            return {
                time: time.format('HH:mm'),
                solid,
                isInBreak,
            };
        });

        // css for grid template rows
        const gridCssString = periods.reduce((acc, { startMoment, endMoment }) => {
            const durationInHalfHours = getDurationInHalfHours(startMoment, endMoment);
            const rowHeight = (durationInHalfHours * height).toFixed(3);
            return `${acc} ${rowHeight}rem`;
        }, '');

        const gridTemplateCss = { gridTemplateRows: gridCssString.trim() };

        return [lines, breaks, gridTemplateCss];
    }, [periods, isTablet]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="page" mod={{ "big-popover": bigPopover }}>
                <div el="header">
                    <div el="content">
                        <h4 el="title">{rosterTitle}</h4>
                        <BlockBadge block={block} size="large" />
                    </div>
                    <div el="weeknav">
                        <div el="gutter">
                            <span>{periodColumnTitle}</span>
                        </div>
                        <ol el="schedule">
                            {weekdays.map((day) => (
                                <li
                                    key={`weeknav-${day}`}
                                    className={styles['page__header__weeknav__schedule__day']}
                                >
                                    {day}
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
                <div el="content">
                    <div el="lines">
                        {breaks.map(({ id, start, duration }) => (
                            <div
                                key={`break-${id}`}
                                el="break"
                                style={{
                                    top: `${start}rem`,
                                    height: `${duration}rem`,
                                }}
                            />
                        ))}
                        {gridLines.map(({ solid, isInBreak, time }) => (
                            <div
                                key={`time-line-${time}`}
                                el="line" mod={{ solid, break: isInBreak }}
                            />
                        ))}
                    </div>
                    <div el="gutter grid" style={gridCss}>
                        {periods.map(({ start, end, break: isBreak }, index, allPeriods) => {
                            const periodNumber = allPeriods
                                .filter(({ break: isBreak }) => !isBreak)
                                .findIndex(({ start: _start, end: _end }) => _start === start && _end === end) + 1;

                            return (
                                <div
                                    key={`schedules-row-${start}-${end}`}
                                    el="row period"
                                    mod={{ break: isBreak }}
                                >
                                    <span el="time child">{start}</span>
                                    {isBreak ? (
                                        <Icon
                                            type="break"
                                            size="large"
                                            color="blue"
                                            className={`${styles['page__content__gutter__period__child']} ${styles['page__content__gutter__period__icon']}`}
                                        />
                                    ) : (
                                        <span el="number child">{periodNumber}</span>
                                    )}
                                    {index === allPeriods.length - 1 ? (
                                        <span el="time child" mod="end">{end}</span>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                    <div el="schedule grid" style={gridCss}>
                        {roster.map(({
                            id: [id],
                            scheduled_course: { course, routeStatus },
                            availableSeats: [availableSeats],
                            startPeriod: [startPeriod],
                            endPeriod: [endPeriod],
                            start: startTime,
                            end: endTime,
                            weekday: [weekday],
                            request: { isChoice },
                        }) => (
                            <Slot
                                key={`roster-course-${id}`}
                                type={isChoice ? 'was-chosen' : 'rostered-course'}
                                weekday={weekday}
                                start={startPeriod}
                                end={endPeriod}
                                startTime={startTime}
                                endTime={endTime}
                            >
                                <Course
                                    course={course}
                                    routeStatus={routeStatus}
                                    isChoice={isChoice}
                                    availableSeats={availableSeats}
                                    showChoiceModal={showChoiceModal}
                                    id={id}
                                    startTime={startTime}
                                    endTime={endTime}
                                />
                            </Slot>
                        ))}

                        {availableSlots.map(({
                            weekday,
                            period,
                        }) => (
                            <Slot
                                key={`empty-slot-${weekday}-${period}`}
                                type="available"
                                weekday={weekday}
                                start={period}
                                end={period}
                                isActive={weekday === activeWeekday && period === activePeriod}
                            >
                                <AvailableSlot
                                    weekday={weekday}
                                    period={period}
                                    showAvailableCourses={showAvailableCourses}
                                    isActive={weekday === activeWeekday && period === activePeriod}
                                />
                            </Slot>
                        ))}

                        {hilightedSlots.map(({
                            id,
                            courseName,
                            weekday,
                            startPeriod: start,
                            endPeriod: end,
                            showButton,
                        }) => (
                            <Slot
                                key={`hilighted-slot-${weekday}-${start}-${end}`}
                                type="hilighted"
                                weekday={weekday}
                                start={start}
                                end={end}
                            >
                                <HilightedSlot
                                    button={showButton}
                                    chooseCourse={chooseCourse}
                                    id={id}
                                    courseName={courseName}
                                />
                            </Slot>
                        ))}
                    </div>
                </div>
            </div>
          {!bigPopover ? (
            <StickyFooter white rounded inset="right" stretch={false}>
                    {!isTablet ? <button el="float-button" onClick={showAllAvailableCoursesMobile}>
                        <Icon  type="plus-sign" color="white" size="large" float/>
                        <span el="text">A-Z</span>
                    </button> : null }
                    <div className={styles.footer}>
                        <div className={styles.footer__content}>
                            <Icon
                                type="clock-deadline"
                                size="large"
                                color="blue"
                                className={styles.footer__content__icon}
                            />
                            <span className={styles.footer__content__text}>
                                {warningBarText}
                            </span>
                        </div>
                        <div className={styles['footer__progress-bar']}>
                            <ProgressBar
                                color="red"
                                progress={timeLeftFraction}
                                round
                            />
                        </div>
                    </div>
                </StickyFooter>) : null
            }
        </Bem>
    );
}

export default memo(Km2Roster);


const Slot = ({ start, end, startTime, endTime, weekday, type, children, isActive }) => {

    const startOffsetInMinutes = calculateDurationInMinutes(SLOT_STARTS?.[start], startTime);
    const durationInMinutes = calculateDurationInMinutes(startTime, endTime);

    const slotRowAmount = (end - start) + 1;
    const slotRowMinutes = [start, end].includes(3) // Lunch slot is no. 3
        ? (slotRowAmount * 90) - 30 // Lunch slot is only 60 minutes, so subtract 30
        : slotRowAmount * 90;

    const heightPercentage = (durationInMinutes / slotRowMinutes) * 100;
    const offsetPercentage = (startOffsetInMinutes / slotRowMinutes) * 100;

    return (
        <Bem style={styles}>
            <div
                el="slot"
                mod={{ weekday, type, active: isActive }}
                style={{ gridRow: `${start} / ${end + 1}`}}
            >
                <div el="content" style={{
                    top: `calc(${offsetPercentage || 0}% + var(--km2roster-course-padding))`,
                    height: `calc(${heightPercentage || 100}% - (var(--km2roster-course-padding) * 2))`,
                }}>
                    {!isActive ? children : null }
                </div>
            </div>
        </Bem>
    );
};

const Course = ({ course, isChoice, showChoiceModal, id, routeStatus, startTime, endTime }) => {
    const { screen: { isTablet } } = useAppStateContext();
    const title = course.cleanName;

    return (
        <div className={styles.course}>
            <div className={styles['course__icons-container']}>
                {isTablet ? (<RouteStatusImage
                    status={routeStatus}
                    color="blue"
                    className={styles['course__icons-container--icon']}
                />) : null }
                {isChoice && isTablet ? (
                    <IconButton
                        icon="edit"
                        color="transparent"
                        className={styles['course__edit-button']}
                        onClick={() => {
                            showChoiceModal(id);
                        }}
                    />
                ) : isChoice ? (
                    <button
                        className={styles['course__mobile-edit-button']}
                        onClick={() => {
                            showChoiceModal(id);
                        }}
                    />
                ) : null}
            </div>
            <span className={styles.course__name}>{title}</span>
            <span className={styles.course__time}>
                {startTime.slice(0, 5)} &ndash; {endTime.slice(0, 5)}
            </span>
        </div>
    );
};


const AvailableSlot = ({ weekday, period, showAvailableCourses, isActive }) => {
    return (
        <div className={styles['available-slot']}>
            {isActive ? null : (
                <IconButton
                    icon="plus-sign"
                    iconSize="medium"
                    className={styles['available-slot--icon']}
                    onClick={() => {
                        showAvailableCourses(weekday, period);
                    }}
                />
            )}
        </div>
    );
};

const HilightedSlot = ({ chooseCourse, id, courseName, button }) => {
    return (
        <div className={styles.course}>
            <span className={`${styles.course__name} ${styles['course__name--hilighted']}`}>
                {courseName}
            </span>
            {button ? (
                <IconButton
                    icon="plus-sign"
                    color="transparent"
                    iconSize="medium"
                    iconColor="purple"
                    className={styles['course__add-button']}
                    onClick={() => { chooseCourse(id); }}
                />
            ) : null}
        </div>
    );
};

/* * * * *
 * UTILS *
** * * * */
const getDurationInHalfHours = (start, end) => {
    return moment.duration(end.diff(start)).asMinutes() / 30;
};

const calculateDurationInMinutes = (start, end) => {
    const startDate = new Date("1970-01-01T" + start + "Z");
    const endDate = new Date("1970-01-01T" + end + "Z");
    return (endDate - startDate) / 1000 / 60;
};
