import React, { useMemo } from 'react';
import Bem from 'react-better-bem';

import { dateToRelativeDateString } from '../../utils';

import { Icon, Person } from '../';

import LinkList from './LinkList';

import styles from './Reports.LinkList.module.scss';

const ReportsLinkList = ({ data, hrefTemplate = '/[id]', includeStudent = false, ...props }) => {
    const reportLinks = useMemo(() => {

        const reports = data.map(({
            id,
            // topic,
            has_read_at,
            has_read,
            report: {title, created_at, updated_at },
            messages: [response] = [],
            student: { flexx_id, ...student } = {},
        }) => ({
            id,
            flexx_id,
            student,
            topic: title,
            hasRead: has_read,
            hasResponse: response !== undefined,
            createdAt: dateToRelativeDateString(created_at),
            readAt: has_read ? dateToRelativeDateString(has_read_at) : null,
            respondedAt: response !== undefined ? dateToRelativeDateString(response && response.updated_at) : null,
        }));

        return reports.map(({ id, flexx_id, student, topic, hasRead, hasResponse, createdAt, readAt, respondedAt }) => ({
            to: hrefTemplate.replace('[id]', id).replace('[student_number]', flexx_id),
            children: (
                <Bem style={styles}>
                    <div el="link">
                        <h3 el="title">{topic}</h3>
                        <div el="status">
                            {includeStudent && (
                                <div el="student">
                                    <Icon
                                        type={hasRead ? 'text-checkmark' : hasResponse ? 'reply' : 'text-submit'}
                                        color={hasRead || hasResponse ? 'blue' : 'red'}
                                        inline
                                    />
                                    <Person
                                        {...student}
                                        flexx_id={flexx_id}
                                        subtext={createdAt}
                                        color="blue"
                                    />
                                </div>
                            )}
                            {!includeStudent && (
                                <span el="content" mod="sent">
                                    <Icon
                                        type="text-submit"
                                        color="red"
                                        inline
                                        valign="middle"
                                    />
                                    <span el="text">
                                        <small el="small">{createdAt}</small>
                                    </span>
                                </span>
                            )}
                            {(hasRead || hasResponse) && !includeStudent && (
                                <span el="content" mod="read">
                                    <Icon
                                        type={hasResponse ? 'reply' : 'text-checkmark'}
                                        color="blue"
                                        inline
                                        valign="middle"
                                    />
                                    <span el="text" mod="small">
                                        <small el="small">{hasResponse ? respondedAt : readAt}</small>
                                    </span>
                                    <span el="text" mod="large">
                                        <small el="small">{hasResponse ? `Gereageerd (${respondedAt})` : `Gelezen (${readAt})`}</small>
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>
                </Bem>
            )
        }))
    }, [data, hrefTemplate, includeStudent]);

    return (
        <LinkList
            {...props}
            links={reportLinks}
            large
        />
    )
};

export default ReportsLinkList;
