import React, { useCallback } from 'react';

import {
    Input,
    FilterButton,
    ChoicesFilterCompetencesModal,
} from '../../';

import { useAppStateContext } from '../../../context';

import styles from './FilterBar.module.scss';

const FilterBar = ({ setSearchValue, searchValue, competences, selectedCompetences, setSelectedCompetences, selectedProfile, setSelectedProfile, teams, diplomaProfiles, selectedTeams, setSelectedTeams }) => {

    const { modal: { open: openModal } } = useAppStateContext();

    const openFilterModal = useCallback(() => {
        openModal(
            <ChoicesFilterCompetencesModal
                competences={competences}
                selectedCompetences={selectedCompetences}
                teams={teams}
                diplomaProfiles={diplomaProfiles}
                selectedTeams={selectedTeams}
                selectedProfile={selectedProfile}
                setSelectedCompetences={setSelectedCompetences}
                setSelectedProfile={setSelectedProfile}
                setSelectedTeams={setSelectedTeams}
            />,
            "Filteren op competenties en profielen",
            { small: false },
        );
    }, [openModal,
        competences,
        selectedCompetences,
        setSelectedCompetences,
        selectedProfile,
        setSelectedProfile,
        teams,
        diplomaProfiles,
        selectedTeams,
        setSelectedTeams,
    ]);

    return (
        <div className={styles.bar}>
            <Input
                small
                noNotification
                placeholder="Zoeken"
                icon="spyglass"
                onChange={(e) => { setSearchValue(e.target.value); }}
                defaultValue={searchValue}
            />
            <FilterButton
                onClick={openFilterModal}
                className={styles.bar__button}
            />
        </div>
    );
};

export default FilterBar;
