import React from 'react';
import { Icon } from '../';

import ROUTE_STATUS from '../../data/route_statusses.json';

const VALID_ROUTE_STATUSSES = Object.keys(ROUTE_STATUS);


const RouteStatusImage = ({ status: _status = '', className = '', valign, color = 'blue' }) => {
    const status = _status.toUpperCase();

    if (!VALID_ROUTE_STATUSSES.includes(status)) {
        return null;
    }

    return (
        <Icon
            type={ROUTE_STATUS[status].icon}
            alt={ROUTE_STATUS[status].name}
            title={ROUTE_STATUS[status].name}
            color={color}
            className={className}
            valign={valign}
        />
    );
};

export default RouteStatusImage;
