import React, { useState, useEffect, useCallback } from 'react';
import Bem from 'react-better-bem';
import { useParams } from 'react-router-dom';

import { PageHeader, Icon, Person, Button, TextArea } from '../../../components';
import { useLayout, usePageTitle } from '../../../hooks';

import {
    useReportDetailsApi,
    useMarkReportAsRead,
    useUpdateReportResponse,
} from '../../../hooks/api';

import {
    useCoachingTraineeContext,
    useBreadcrumbTrailContext,
} from '../../../context';

import { getCopy } from '../../../utils';

import styles from './ReportDetails.module.scss';


const ReportDetails = ({ role = 'student' }) => {
    useLayout({ collapse: 'vertical', margin: false });
    const { setLoading } = useCoachingTraineeContext();

    /* * * * *
     * DATA  *
    ** * * * */
    const { report_id } = useParams();
    const { loading, fetched, report, response } = useReportDetailsApi(role, report_id);

    const {
        topic,
        text: reportText,
        createdAt,
        readAt,
        hasRead,
        hasResponse,
        author: student
    } = report;

    usePageTitle(
        loading ? 'loading' : `coaching.report.${role}`,
        { topic: (topic || '') }
    );

    useEffect(() => {
        if (role !== 'student') {
            setLoading(loading);
        }
    }, [loading, setLoading, role]);

    const { set, put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        if (role !== 'student') {
            put({
                name: `Gespreksverslag ${topic}`,
                loading,
            }, 2);
        } else {
            set([
                {
                    name: 'Overzicht',
                    href: '/coaching',
                },
                {
                    name: `Gespreksverslag ${topic}`,
                    loading,
                }
            ]);
        }
        return () => {
            if (role !== 'student') {
                remove(`Gespreksverslag ${topic}`);
            } else {
                set([]);
            }
        }
    }, [set, put, remove, topic, loading, role]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            {role === 'student' && (
                <PageHeader title="Studieloopbaancoaching" collapsed />
            )}
            {fetched && (
                <section el="report-grid">
                    <article el="report">
                        <header el="header">
                            <h2 el="title">{topic}</h2>
                            <p el="sent">
                                <Icon
                                    type="text-submit"
                                    color="red"
                                    inline
                                    valign="middle"
                                />
                                {role === 'student' ? (
                                    <small>Ingestuurd {createdAt}</small>
                                ) : (
                                    <small>Ingestuurd door student</small>
                                )}
                                {role === 'student' && hasRead && (
                                    <>
                                        <br/>
                                        <Icon type="text-checkmark" color="blue" inline valign="middle" />
                                        <small>Gelezen {readAt}</small>
                                    </>
                                )}
                            </p>
                            <Person
                                { ...student }
                                color="blue"
                                subtext={<small>{createdAt}</small>}
                                className={styles['report-grid__report__header__person']}
                            />
                        </header>
                        <main el="body">
                            {reportText}
                        </main>
                    </article>

                    <section el="actions">
                        <div el="content">
                            <ReportDetailsSideBar
                                role={role}
                                studentId={student?.flexx_id}
                                reportId={report_id}
                                response={response}
                                readAt={readAt}
                                hasRead={hasRead}
                                hasResponse={hasResponse}
                            />

                        </div>
                    </section>
                </section>
            )}
        </Bem>
    );
};

const ReportDetailsSideBar = ({ role, studentId, reportId, response, readAt, hasRead, hasResponse }) => {
    const { markAsRead, markedAsRead, markingAsRead } = useMarkReportAsRead(reportId);
    const { respond, responded, responding } = useUpdateReportResponse(reportId);

    const {
        text: responseText,
        updatedAt: responseUpdatedAt
    } = response;

    // if coach sends a response, automatically mark report as read
    const hasActuallyRead = hasRead || responded;

    /* * * * *
     * COPY  *
    ** * * * */
    const hasReadCopy = getCopy(`coaching.${role}.reportDetails.hasRead`);
    const hasNotReadCopy = getCopy(`coaching.${role}.reportDetails.hasNotRead`);
    const markAsReadCopy = getCopy(`coaching.${role}.reportDetails.mark`);
    const hasResponseCopy = getCopy(`coaching.${role}.reportDetails.hasResponse`);
    const hasNoResponseCopy = getCopy(`coaching.${role}.reportDetails.hasNoResponse`);
    const responsePlaceholderCopy = getCopy(`coaching.${role}.reportDetails.responsePlaceholder`);
    const newResponseCopy = getCopy(`coaching.${role}.reportDetails.newResponse`);
    const updatedResponseCopy = getCopy(`coaching.${role}.reportDetails.updatedResponse`);
    const submitResponseCopy = getCopy(`coaching.${role}.reportDetails.submitResponse`);
    const updateResponseCopy = getCopy(`coaching.${role}.reportDetails.updateResponse`);

    /* * * * * * * * * * * * *
     * LOCAL RESPONSE STATE  *
    ** * * * * * * * * * * * */
    const [localResponseText, setLocalResponseText] = useState(responseText);
    const exposedSetLocalResponseText = useCallback(({ target: { value } }) => {
        setLocalResponseText(value);
    }, []);

    useEffect(() => {
        setLocalResponseText(responseText);
    }, [responseText]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles} block="report-grid__actions__content">
            {role !== 'student' && <h3>Acties</h3>}
            {role !== 'student' && (
                <div el="mark-as-read">
                    <h4>
                        <Icon type="bell" inline color="blue" />
                        {hasActuallyRead ? hasReadCopy : hasNotReadCopy }
                    </h4>
                    {hasRead ? (
                        <>
                            <Icon type="text-checkmark" color="blue" inline valign="middle" />
                            {readAt}
                        </>
                    ) : (markedAsRead || hasActuallyRead) ? (
                        <>
                            <Icon type="text-checkmark" color="blue" inline valign="middle" />
                            {hasReadCopy}
                        </>
                    ) : role === 'coach' ? (
                        <Button icon="text-checkmark" onClick={markAsRead} disabled={markingAsRead} >
                            {markAsReadCopy}
                        </Button>
                    ) : null}
                </div>
            )}
            {role !== 'student' && <hr el="line" />}
            {role !== 'student' ? (
                <div el="respond">
                    <h4>
                        <Icon type="reply" inline color="blue" />
                        {hasResponse ? hasResponseCopy : hasNoResponseCopy}
                    </h4>
                    {role === 'coach' ? (
                        <>
                            <TextArea
                                value={localResponseText}
                                onChange={exposedSetLocalResponseText}
                                placeholder={responsePlaceholderCopy}
                                height={8}
                                notification={responding ? '' : responded && hasResponse ? updatedResponseCopy : responded ? newResponseCopy : hasResponse ? responseUpdatedAt : ''}
                            />
                            <div el="buttons">
                                <Button
                                    icon="upload"
                                    variant="small"
                                    disabled={!localResponseText?.length || responding}
                                    onClick={() => {
                                        respond(localResponseText);
                                    }}
                                >
                                    {hasResponse ? updateResponseCopy : submitResponseCopy}
                                </Button>
                            </div>
                        </>
                    ) : role === 'mentor' && hasResponse ? (
                        <p>
                            {localResponseText}
                            <br /><br />
                            <small>{responseUpdatedAt}</small>
                        </p>
                    ) : null}
                </div>
            ) : (
                <div el="response">
                    <h4>
                        <Icon type="reply" inline color="blue" />
                        Reactie van je coach
                    </h4>
                    <p el="text">
                        {hasResponse && responseText?.length ? responseText : <em>{hasNoResponseCopy}</em>}
                    </p>
                </div>
            )}
        </Bem>
    );
};

const StudentReportDetails = () => <ReportDetails role="student" />;

export { StudentReportDetails };
export default ReportDetails;
