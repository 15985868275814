import React from 'react';

import animatedSvgs from '../../assets/animatedSvgs';

const VALID_SVGS = Object.keys(animatedSvgs);

const AnimatedSvg = ({ type, ...props }) => {
    if (!VALID_SVGS.includes(type)) {
        return null;
    }

    const SvgComponent = animatedSvgs[type];

    return (
        <SvgComponent {...props} />
    );
};

export default AnimatedSvg;
