import React from 'react';
import Bem from 'react-better-bem';

import { useLayout, usePageTitle } from '../../hooks';

import { Button, Logo } from '../../components';

import styles from './Error_403.module.scss';

const SIGNIN_URL = process.env.REACT_APP_SIGNIN_URL;

const ERROR_403 = () => {
    usePageTitle();
    useLayout({ stretch: true, collapse: 'both', stretchContent: true, margin: false });

    return (
        <Bem style={styles}>
            <div el="page">
                <div el="background-image" />
                <div el="content">
                    <Logo color="white" size="large" className={styles.page__content__logo} />
                    <h1 el="title">Inloggen</h1>
                    <p el="text">
                        Klik op onderstaande knop om in te loggen met je ROC account.
                    </p>
                    <Button className={styles.page__content__button} href={SIGNIN_URL}>
                        Ga naar de login pagina
                    </Button>
                </div>
            </div>
        </Bem>
    );
};

export default ERROR_403;
