import React from 'react'
import Bem from 'react-better-bem';

import { useEnvironmentContext } from '../../context';

import styles from './ImpersonateBar.module.scss';

const STOP_IMPERSONATION_URL = process.env.REACT_APP_STOP_IMPERSONATION_URL;

const ImpersonateBar = () => {
	const { user } = useEnvironmentContext();

	const { impersonated, fullName } = user;

	if (!impersonated) {
		return null;
	}

	const location = encodeURIComponent(window.location.href);
	const href = `${STOP_IMPERSONATION_URL}?ref=${location}`;

	return (
		<Bem style={styles}>
			<div el="notification">
				<strong>Let op:</strong> Je bent op dit moment gebruiker <em>{fullName}</em> aan het simuleren! <a el="link" href={href}>Klik hier om te stoppen.</a>
			</div>
		</Bem>
	);
};

export default ImpersonateBar
