import React, { useContext } from 'react';
import useFilters from "../hooks/useFilters";

const FiltersContext = React.createContext();

const FiltersContextProvider = ({ children }) => {
    const filters = useFilters({});

    return (
        <FiltersContext.Provider value={filters}>
            {children}
        </FiltersContext.Provider>
    );
};

const useFiltersContext = () => useContext(FiltersContext);

export { FiltersContextProvider };
export default useFiltersContext;
