import { useEffect, useReducer, useCallback, useMemo } from 'react';
import useApi from '../useApi';

function useCoachingTraineesApi (apiPathPrefix = 'coach') {
    const {
        get: getTrainees,
        loading: loadingTrainees,
        fetched: fetchedTrainees,
        value: _trainees = [],
    } = useApi(`ophalen ${apiPathPrefix} studenten`, `/coaching/${apiPathPrefix}/trainees`, { initialValue: [], persist: true });

    // fetch initially
    const fetchWhen = !fetchedTrainees && !loadingTrainees;
    useEffect(() => {
        if (fetchWhen) {
            getTrainees();
        }
    }, [getTrainees, fetchWhen]);

    // hopefully just a temp fix
    const trainees = useMemo(() => {
        return _trainees.map((trainee) => {
            if (trainee.km1_status) {
                return trainee;
            }
            return { student: trainee };
        })
    }, [_trainees]);

    const groups = useMemo(() => {
        const groups = trainees
            .reduce((acc, { student: { base_group: groupName, flexx_id: id } }) => {
                if (!groupName) {
                    groupName = 'Overige studenten';
                    // return acc;
                }

                const existingGroupIndex = acc.findIndex(({ groupName: _gn }) => _gn === groupName);
                if (existingGroupIndex > -1) {
                    acc[existingGroupIndex].students.push(id);
                    return acc;
                }

                return [ ...acc, { groupName, students: [id] } ];
            }, [])
            .map(({ groupName, students }) => {
                return {
                    groupName,
                    students,
                };
            });

        groups.sort(({ groupName: a }, { groupName: b }) => {
            // always put 'Overige studenten' last
            if (a === 'Overige studenten') {
                return 1;
            }

            // try to split groupnames into relevant components
            const groupId_a = a.substr(3, 3);
            const cohortId_a = parseInt(a.substr(6), 10);
            const cohortSubGroup_a = a.substr(7);

            const groupId_b = b.substr(3, 3);
            const cohortId_b = parseInt(b.substr(6), 10);
            const cohortSubGroup_b = b.substr(7);

            // 'higher' cohort first
            if (cohortId_a !== cohortId_b) {
                return cohortId_a < cohortId_b ? 1 : -1;
            }

            if (groupId_a !== groupId_b) {
                return groupId_a < groupId_b ? -1 : 1;
            }

            if (cohortSubGroup_a !== cohortSubGroup_b) {
                return cohortSubGroup_a < cohortSubGroup_b ? -1 : 1;
            }

            return a < b ? -1 : 1;
        });

        return groups;
    }, [trainees]);

    const [reducedStudents, dispatchReducedStudents] = useReducer(studentsReducer, []);

    useEffect(() => {
        dispatchReducedStudents({ type: 'INIT', value: trainees });
    }, [trainees]);

    const filter = useCallback((search) => {
        dispatchReducedStudents({ type: 'FILTER', search });
    }, []);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        students: reducedStudents,
        groups,
        filter,
        loading: loadingTrainees,
        fetched: fetchedTrainees,
    };
}

export default useCoachingTraineesApi;

/* * * * * *
 * REDUCER *
** * * * * */
function studentsReducer (state, action) {
    const { type, value } = action;

    switch (type) {
        case 'INIT': {
            return value.map(({ km1_status = 'closed', student }) => ({
                ...student,
                km1_status,
                visible: true,
            }));
        }
        case 'FILTER': {
            const search = action.search.trim().toLowerCase();

            if (!search.length) {
                return state.map((student) => ({ ...student, visible: true }));
            }

            return state.map((student) => {
                const { full_name: name, base_group: group } = student;
                const visible = name.toLowerCase().indexOf(search) > -1 || group.toLowerCase().indexOf(search) > -1;
                return { ...student, visible };
            });
        }
        default: {
            return state;
        }
    }
}
