import React, { useMemo, useEffect } from 'react';
import Bem from 'react-better-bem';
import { Link } from 'react-router-dom';

import {
    ReportsLinkList,
    Icon,
    Person,
    IconButton,
    ProgressCourseList,
    CoachProgressLinks,
} from '../../../components';

import { useReportsApi } from '../../../hooks/api';
import { useCoachingTraineeContext } from '../../../context';
import { useLayout, usePageTitle } from '../../../hooks';
import { getCopy } from '../../../utils';

import styles from './TraineeDetails.module.scss';

const TraineeDetails = ({ role = 'coach' }) => {
    useLayout({ stretch: false, collapse: 'top' });

    /* * * * * * * * *
     * STUDENT INFO  *
    ** * * * * * * * */
    const {
        student,
        studentId,
        studentLoading,
        setLoading,
    } = useCoachingTraineeContext();

    usePageTitle(
        studentLoading ? 'loading' : `coaching.studentDetails.${role}`,
        { student: student?.full_name || '' }
    );

    const rootPath = getCopy(`coaching.${role}.path`);

    /* * * * * *
     * REPORTS *
    ** * * * * */
    const { loading: loadingReports, reports } = useReportsApi(role, studentId);
    const firstReports = useMemo(() => reports.slice(0, 3), [reports]);

    /* * * * * * * * * * * * * *
     * SET LOADING IN CONTEXT  *
    ** * * * * * * * * * * * * */
    useEffect(() => {
        setLoading(loadingReports);
    }, [setLoading, loadingReports])

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * BLOCKSPLANNING TO DEFINE IF WE SHOULD SHOW COURSEPLANNER  *
    ** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    // TODO: PUT BACK

    /* * * * * *
     * RENDER  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <section el="student" mod="shaded">
                <div el="content">
                    <Person
                        loading={studentLoading}
                        {...student}
                        subtext={<strong el="id">{studentId}</strong>}
                        large
                    />
                </div>
            </section>
            <section el="reports">
                <div el="content">
                    <h2>
                        <Icon type="mail" color="blue" valign="middle" inline />
                        Gespreksverslagen
                    </h2>
                    {!reports.length && (<strong>Nog geen gespreksverslagen</strong>)}
                    <ReportsLinkList
                        hrefTemplate={`${rootPath}/${studentId}/gespreksverslagen/[id]`}
                        data={firstReports}
                    />
                    <Link className={styles['reports__more-link']} to={`${rootPath}/${studentId}/gespreksverslagen`}>
                        <span className={styles['reports__more-link__text']}>
                            {reports.length > 3 ?
                                "Meer gespreksverslagen" :
                                "Alle gespreksverslagen"
                            }
                        </span>
                        <IconButton icon="chevron-right" className={styles['reports__more-link__chevron']} />
                    </Link>
                </div>
            </section>

            <CoachProgressLinks role={role} />

            <section el="courses">
                <ProgressCourseList
                    role={role}
                    icon="owl"
                />
            </section>
        </Bem>
    );
};

export default TraineeDetails;
