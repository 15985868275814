import { useEffect } from 'react';
import useApi from '../useApi';

import { useEnvironmentContext } from '../../context';
import { useUserPlanningApi } from '../api';

function useUserApi () {
    // get user info
    const { get, loading, value: userInfo, fetched, error, isAuthenticated } = useApi('user', `/user`, { noGlobalLoading: true, initialValue: {}, noWarning: true });

    // get planning
    const { fetch: fetchPlanning, planning } = useUserPlanningApi();

    // environment context
    const { setUserInformation, setUserPlanning } = useEnvironmentContext();

    // trigger fetch user
    const fetchUserWhen = !loading && !fetched && error === undefined && isAuthenticated !== true;
    useEffect(() => {
        if (fetchUserWhen) {
            get();
        }
    }, [fetchUserWhen, get]);

    // trigger fetching user on focus (handydandy for impersonation)
    useEffect(() => {
        window.onfocus = get;
        return () => {
            window.onfocus = null;
        }
    }, [get]);

    // store stuff in environment after authentication
    // + trigger get blocks planning
    const authenticatedUser = !loading && fetched && error === undefined && isAuthenticated === true;
    useEffect(() => {
        if (authenticatedUser) {
            // set user in environment context
            setUserInformation(userInfo);
            fetchPlanning();
        }
    }, [authenticatedUser, userInfo, setUserInformation, fetchPlanning]);

    // store planning in userEnvironment
    useEffect(() => {
        setUserPlanning(planning);
    }, [planning, setUserPlanning])

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        loadingUser: loading,
        fetchedUser: fetched,
        isAuthenticated
    };
}

export default useUserApi;
