import { useCallback, useEffect, useReducer, useMemo } from "react";

import { useApi } from "..";

const ACCEPTED_FILE_TYPES = ['.pdf','.docx','doc','application/msword','application/pdf','.xml', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // PDF AND WORD
const MAX_UPLOAD_SIZE = 10000000; // 10MB

const useInternshipApi = (role = 'student', studentId) => {
	const path = role === 'student' ?
		'/internships/' :
		`/students/${studentId}/internships/`;

	const { get, loading, value, fetched } = useApi('bpv voor student', path, { initialValue: { internships: [] } });

	const fetchWhen = !fetched && !loading && (role === 'student' || studentId !== undefined);

	useEffect(() => {
		if (fetchWhen) {
		    get();
		}
	}, [get, fetchWhen]);

	const [catalog, dispatchCatalog] = useReducer(catalogReducer, []);

	useEffect(() => {
		dispatchCatalog({type: 'INIT', payload: value});
	}, [value, role]);

	/* * * * * * * *
    * UPLOAD FILE  *
    ** * * * * * * */
	const { post: postFile } = useApi(
		'upload file',
		'/internships/file/',
	);

	const uploadFile = useCallback(async(file, internship_id, setUploaded, setProgress, setTimeStamp, setUploadError) => {
        if (!file || !internship_id ) return;

		const formData = new FormData();
		formData.append('Files', file);
		formData.append('internship_id', internship_id)

        if (!ACCEPTED_FILE_TYPES.includes(file.type) || file.size > MAX_UPLOAD_SIZE) {
            setUploadError(true);
            return;
        };

		await postFile(formData).then((response) => {
            if (!response.internships) return;

            const reader = new FileReader();
            reader.onloadend = () => {
                setUploaded(true);
            }

            reader.onprogress = (e) => {
                setProgress(Math.round((100 * e.loaded) / e.total))
                setTimeStamp(Math.floor((e.timeStamp)))
            }

            reader.readAsDataURL(file);

			dispatchCatalog({type: 'INIT', payload: response});

		}).catch((_error) => {
			//TODO: handle error here
		});

	}, [postFile]);

    /* * * * * * * *
    * DELETE FILE  *
    ** * * * * * * */
    const { del: deleteFile } = useApi(
        'delete file',
        '',
       { initialValue: { internships: [] }}
    );

    const deleteFileById = useCallback((id) => {
        if (!id) return;

        deleteFile(`/internships/file/${id}`).then((response) => {
            dispatchCatalog({type: 'INIT', payload: response});

        }).catch((error) => {
            //TODO: handle error here
        });
    }, [deleteFile]);

    const bpvLinks = useMemo(() => {
        const info = value?.bpv_info || {};
        const vacanciesLinks = Object.keys(info || {}).filter((key) => key.match(/url_vacancies_\d/g)).map((key) => info[key]);

        return {
            learningGoalsLink: info['url_learning_goals'],
            requestFormLink: info['url_request_form'],
            vacanciesLinks,
        };
    }, [value]);

	const { internships, summary, progressPercentage } = useMemo(() => {
		const internships = catalog && catalog.map(({
			id,
            address,
            postcode,
            location,
			company_name,
			completed_hours,
            entered_hours,
            bpv_total_hours,
			contact_person_email,
            contact_person_phone,
			contact_person_name,
			end_date,
            expected_end_date,
			start_date,
			student_id,
			total_hours,
            bpv_phase,
			files,
		}) => {
			return {
				id,
                address,
                postalCode: postcode,
                city: location,
				companyName: company_name,
				completedHours: completed_hours,
                enteredHours: entered_hours,
                bpvTotalHours: bpv_total_hours,
				contactPersonEmail: contact_person_email,
                contactPersonPhone: contact_person_phone,
				contactPersonName: contact_person_name,
				endDate: formatDate(end_date.includes('1900') ? expected_end_date : end_date, 'long', true),
				startDate: formatDate(start_date, 'long', true),
				totalHours: total_hours,
                bpvPhase: bpv_phase,
				studentId: student_id,
				filesLength: files.length,
				files: files.map(({ id, download_link, filename, created_at}) => {
					const shortFilename = filename.substring(0, 12);
					const filenameParts = filename.split(/[_,.]/g);
					const filenameWithoutPath =
					`${shortFilename.toLowerCase()}...${filenameParts[filenameParts.length - 1]}`;

					return {
						id,
						downloadLink: download_link,
						fileName: filenameWithoutPath,
						dateUploaded: formatDate(created_at, 'numeric', true),
					};
				})
			};
		});

		const totalHours = catalog && catalog.reduce( (acc, { total_hours }) => acc + total_hours, 0);
		const total = {
			hours: totalHours,
			title: "Totaal te halen uren",
			iconName: "afstuderen",
			tag: "total"
		}
		const accumulatedHours = catalog && catalog.reduce( (acc, { completed_hours }) => acc + completed_hours, 0);
		const accumulated = {
			hours: accumulatedHours,
			title: "Goedgekeurde uren",
			iconName: "behaald",
			tag: "accumulated"
		};

		const remainingHours = totalHours - accumulatedHours;
		const remaining = {
			hours: remainingHours,
			title: "Resterende uren",
			iconName: "voltooid_chart",
			tag: "remaining"
		};

		const summary = [accumulated, remaining, total];
		const progressPercentage = Math.round((accumulatedHours / totalHours) * 100);

		return { internships, summary, progressPercentage };

	}, [catalog]);

	return {
		value,
		loading,
		fetched,

		internships,
		summary,
		progressPercentage,

        _bpv: value?.bpv,
        bpvLinks,

		uploadFile,
        deleteFileById,
	};
};

function catalogReducer(state, action) {
	const { type, payload: { internships } } = action;

	switch (type) {
		case 'INIT':
			return internships;
		case 'UPLOAD_FILE': // not using this functionality yet but will be needed
			return [...state, internships];
		case 'DELETE_FILE': // not really using this yet
			return state.filter(({id}) => id !== internships);
		default:
			return state;
	}
}

function formatDate(date, type = 'long', year = false) {
    return new Date(date).toLocaleDateString('nl-NL', {
        day: 'numeric',
        month: type,
		year: year ? 'numeric' : undefined,
    });
}

export default useInternshipApi;
