import React from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { useEnvironmentContext } from '../../context';

import styles from './FooterNavigation.module.scss';

const FooterNavigation = () => {
    const { navItems: { footer } } = useEnvironmentContext();

    return (
        <Bem style={styles}>
            <nav el="footer-nav">
                <ol el="list">
                    {footer.map(({ name, href }) => (
                        <li key={`footer-nav-item-${name}`} el="item">
                            <Link to={href} className={styles['footer-nav__list__item__link']}>
                                {name}
                            </Link>
                        </li>
                    ))}
                </ol>
            </nav>
        </Bem>
    );
};

export default FooterNavigation;
