import React, { useEffect, useRef, useMemo } from 'react';
import Bem from 'react-better-bem';
import { useParams } from 'react-router-dom';

import {
    useCoachingTraineeContext,
    useBreadcrumbTrailContext,
    useAppStateContext,
} from '../../../context';

import { useLayout, usePageTitle } from '../../../hooks';
import { useProgressCourseApi, useProgressScheduledCourseApi } from '../../../hooks/api';

import {
    Button,
    Icon,
    CourseImage,
    RouteStatusImage,
    Table,
    Tooltip,
    ScoreBadge,
    Tag,
    ScoreLegend,
    SmallRatings,
    BlockBadge,
    AttendanceDisplay,
} from '../../../components';

import { getCopy } from '../../../utils';

import ROUTE_STATUS from '../../../data/route_statusses.json';

import styles from './Course.module.scss';


const VALID_ROUTE_STATUSSES = Object.keys(ROUTE_STATUS);

const ProgressCourse = ({ role = 'student', studentId, student, api, isScheduledCourse = false }) => {
    useLayout({ stretch: false, collapse: 'top' });
    const { screen: { isSmallDesktop, isTablet, isntTablet } } = useAppStateContext();

    /* * * * * * * *
     * API + PROPS *
    ** * * * * * * */
    const { course: apiCourse, loading, fetched } = api;

    const {
        scheduled_course: {
            criteria = [],
            canvas_url: canvasUrl,
            block = {},
            route_status: _routeStatus,
            course: {
                cleanName,
                category,
                description
            } = {},
        } = {},
        sessions = 0,
        ratings: [ rating = {} ] = [],
        teachers: teacherName= [],
    } = apiCourse;

    const rated_by = rating?.rated_by || 'Niet beoordeeld';

    const { status } = rating || {};
    const routeStatus = _routeStatus?.toUpperCase();

    const showRatings = status !== undefined;
    // const showRatings = true;
    const showRouteStatus = !isScheduledCourse && VALID_ROUTE_STATUSSES.includes(routeStatus);

    const nCriteria = criteria.flat().length;
    const completedCriteria = criteria.flat().reduce((acc, { score }) => {
        if (score >= 3) {
            acc++;
        }
        return acc;
    }, 0);

    // const copyRole = role === 'student' ? 'student' : 'teacher';
    // const attendanceLink = getAttendanceEduarteUrl(copyRole, studentId);

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    usePageTitle(
        loading ? 'loading' : isScheduledCourse ? `progress.scheduledCourse.${role}` : `progress.course.${role}`,
        {
            course: cleanName || '',
            student: student?.full_name || ''
        }
    );

    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        put({
            name: cleanName,
            loading,
        }, role === 'student' ? 1 : 2);

        return () => {
            remove(cleanName);
        };
    }, [remove, put, role, cleanName, loading]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    const noDescriptionMessage = getCopy(
        `progress.${role}.courses.noCourseInfoWarning`
    );

    // Attendance information
    // const isRatingAvailable = _ratings && _ratings.length;
    // const attendance = isRatingAvailable && _ratings[0].attendance

    return loading || !fetched ? null : (
        <Bem style={styles}>
            <section el="course-info">
                <div el="title">
                    <h2>
                        {cleanName}
                        {showRouteStatus ? (
                            <RouteStatusImage
                                status={routeStatus}
                                className={styles['course-info__title__route-status-icon']}
                                valign="sub"
                            />
                        ) : null}
                        <BlockBadge
                            className={styles['course-info__title__block-badge']}
                            block={block}
                            period={role !== 'student' && isScheduledCourse}
                        />
                    </h2>
                    {isTablet && showRatings && <ScoreLegend />}
                </div>
                <div el="meta">
                    {teacherName.map((teacher, index) => (
                        <p el="teacher" key={index}>
                            <Icon type="teacher" valign="middle" />
                            <span el="name">{teacher.full_name}</span>
                            {teacher.full_name.includes(rated_by) ? <span el="rated_by"> (beoordelaar)</span> : ''}
                        </p>
                    ))}
                    <div el="icon">
                        <div el="image">
                            <CourseImage
                                category={category}
                                ratingStatus={status}
                            />
                        </div>
                        {showRatings && (
                            <div el="text">
                                <span el="line" mod="done">{completedCriteria} criteria</span>
                                <span el="line" mod="total">van de {nCriteria} behaald</span>
                            </div>
                        )}
                    </div>
                </div>
                <div el="description">
                    <h3 el="title">Beschrijving</h3>
                    <p el="description">
                        {description?.length ?
                            description :
                            noDescriptionMessage
                        }
                    </p>
                </div>
            </section>

            {showRatings && (
                <section el="rating">
                    <h3 el="title">
                        <Icon
                            type="award"
                            size="large"
                            color="black"
                            valign="middle"
                            className={styles.rating__title__icon}
                        />
                        Beoordeling
                    </h3>
                    {isntTablet && (
                        <p el="legend">
                            <ScoreLegend />
                        </p>
                    )}
                    {isSmallDesktop ? (
                        <DesktopRatings
                            role={role}
                            rating={rating}
                            criteria={criteria}
                            sessions={sessions}
                            schoolYear={block?.school_year}
                            studentId={studentId}
                        />
                    ) : (
                        <ResponsiveRatings
                            role={role}
                            rating={rating}
                            criteria={criteria}
                            sessions={sessions}
                            block={block}
                        />
                    )}
                </section>
            )}

            {canvasUrl || !isScheduledCourse ? (
                <section el="links" mod={{ shaded: !isSmallDesktop }}>
                    <div el="content">
                        {/* {isScheduledCourse ? null : (
                            <div>
                            //     <h3 el="title">Presentie</h3>
                            //     <Button
                            //         href={attendanceLink}
                            //         icon="external-link"
                            //         variant="small"
                            //         target="_blank"
                            //         rel="noreferrer"
                            //     >
                            //         Bekijk presentie in Eduarte
                            //     </Button>
                            </div>
                        )} */}
                        {canvasUrl ? (
                            <div>
                                <h3 el="title">Canvas</h3>
                                <Button
                                    href={canvasUrl}
                                    icon="external-link"
                                    variant="small"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Bekijk cursus in Canvas
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </section>
            ) : null}

            {criteria?.length ? (
                <section el="criteria">
                    <div
                        el="group"
                        mod={[{ shaded: isSmallDesktop }, 'title']}
                    >
                        <div el="content">
                            <h4 el="title">Toelichting beoordelingscriteria</h4>
                        </div>
                    </div>
                    {criteria.map((group, i) => (
                        <div
                            key={`criteria-group-${i}`}
                            el="group"
                            mod={{ shaded: isSmallDesktop ? !(i%2) : !!(i%2) }}
                            // You might be asking yourself "wtf is happening here?"
                            // Well, on large screens we shade even divs, and on
                            // smaller screens we shade the odd divs. Of course we do.
                        >
                            <ol el="content">
                                {group.map((crit) => {
                                    const {
                                        typeId: ctId,
                                        name,
                                        description,
                                        score,
                                    } = crit;

                                    const showCriteriumScore = showRatings && (score !== undefined && score >= 0);
                                    const scoreName = (score !== undefined && score >= 0) ?
                                        getCopy(`rating.competenceScores[${score}].name`) :
                                        '';

                                    return (
                                        <li key={`criterium-group-${i}-${ctId}`} el="item">
                                            <h6 el="title">{name}</h6>
                                            <div el="content">
                                                {showCriteriumScore && (
                                                    <ScoreBadge
                                                        value={score}
                                                        size="medium"
                                                        className={styles.criteria__group__content__item__badge}
                                                    />
                                                )}
                                                <p el="description">
                                                    {showCriteriumScore && (
                                                        <span el="rate">{scoreName}</span>
                                                    )}
                                                    {showCriteriumScore && <br/>}
                                                    {description}
                                                </p>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ol>
                        </div>
                    ))}
                </section>
            ) : null}
        </Bem>
    );
};

const StudentProgressCourse = ({ scheduled = false }) => {
    const { group_course_id: gcId, course_id: scId, block_id: bId } = useParams();
    const gcApi = useProgressCourseApi(gcId);
    const scApi = useProgressScheduledCourseApi(scId, bId);

    const api = scheduled ? scApi : gcApi;

    return (
        <ProgressCourse
            role="student"
            api={api}
            isScheduledCourse={scheduled}
        />
    );
};

const CoachProgressCourse = ({ role, scheduled = false }) => {
    const { group_course_id: gcId, course_id: scId, block_id: bId } = useParams();
    const { studentId, student } = useCoachingTraineeContext();
    const gcApi = useProgressCourseApi(gcId, studentId);
    const scApi = useProgressScheduledCourseApi(scId, bId);

    const api = scheduled ? scApi : gcApi;

    return (
        <ProgressCourse
            role={role}
            api={api}
            studentId={studentId}
            student={student}
            isScheduledCourse={scheduled}
        />
    );
};

export {
    StudentProgressCourse,
    CoachProgressCourse,
};

/* * * * * * * *
 * COMPONENTS  *
** * * * * * * */
const DesktopRatings = ({ rating, criteria, sessions, role, schoolYear, studentId }) => {
    // TODO: Move this later. OR Find a better workaround for this
    const emptyCriteria = [
       [ {
            code: 'K1',
            name: 'Kennis 1',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },
        {
            code: 'K2',
            name: 'Kennis 2',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },
        {
            code: 'K3',
            name: 'Kennis 3',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },],
      [  {
            code: 'T1',
            name: 'Toepassen 1',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },
        {
            code: 'T2',
            name: 'Toepassen 2',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },
        {
            code: 'T3',
            name: 'Toepassen 3',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },],
       [ {
            code: 'C1',
            name: 'Beroepscompetentie 1',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },
        {
            code: 'C2',
            name: 'Beroepscompetentie 2',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },
        {
            code: 'C3',
            name: 'Beroepscompetentie 3',
            description: 'Geen criteria beschikbaar',
            neededForClosing: false,
            score: 1,
            typeId: 0,
        },]
    ]

    const showResultsColumn = !!rating.result_type;

    const _criteria = criteria.length > 0 ? criteria : emptyCriteria;
    const headerRow = useRatingHeaderRowMemo(_criteria, role, showResultsColumn);
    const bodyRow = useRatingBodyRowMemo(rating, _criteria, sessions, role, schoolYear, studentId, showResultsColumn);

    return (
        <Table
            rows={[headerRow, bodyRow]}
            className={styles.rating__table}
        />
    );
};

const ResponsiveRatings = ({ rating, criteria, sessions, role, block }) => {
    const {
        feedback = '',
        status,
        attendance,
        published,
        result,
        result_type: resultType
    } = rating;

    return (
        <SmallRatings
            criteria={criteria}
            feedback={feedback}
            status={status}
            attendance={attendance}
            sessions={sessions}
            published={published}
            result={result}
            resultType={resultType}
            block={block}
            includePublicationStatus={role !== 'student'}
        />
    );
};

const CriteriumHeaderTooltip = ({ abbr, title, children, ...props }) => {
    const abbrRef = useRef();

    return (
        <>
            <abbr title={title} ref={abbrRef}>
                {abbr}
            </abbr>
            <Tooltip target={abbrRef}>
                {children}
            </Tooltip>
        </>
    )
};

/* * * * *
 * MEMOS *
** * * * */
const useRatingBodyRowMemo = (rating, criteria, sessions, role, schoolYear, studentId, showResultsColumn) => {
    const { modal: { open: openModal } } = useAppStateContext();

    const {
        feedback = '',
        result,
        status,
        attendance,
        published,
    } = rating;

    return useMemo(() => {
        const criteriaCells = criteria.reduce((acc, group) => ([
            ...acc,
            ...group.map((criterium, ii, allCriteriaInGroup) => {
                const { score } = criterium;

                const paddingRight = ii === allCriteriaInGroup.length - 1 ? '1rem' : 0;
                const paddingLeft = !ii ? '1rem' : 0;

                return {
                    align: 'center',
                    border: true,
                    collapse: true,
                    spaceAfter: ii === allCriteriaInGroup.length - 1,
                    style: {
                        width: '3.5rem',
                        padding: `0 ${paddingRight} 0 ${paddingLeft}`,
                    },
                    content: (
                        <ScoreBadge
                            value={score}
                            size="medium"
                            className={styles['score-badge']}
                        />
                    ),
                };
            })
        ]), []);

        const tagValue = getCopy(`rating.status.${status}.name`);
        const tagColor = getCopy(`rating.status.${status}.tagColor`);

        const cells = [
            ...criteriaCells,
            showResultsColumn && {
                border: true,
                align: 'center',
                spaceAfter: true,
                content: (
                    <strong>{result}</strong>
                )
            },
            {
                border: true,
                align: 'center',
                spaceAfter: true,
                content: (
                    <Button
                        icon="edit"
                        variant="mini"
                        iconColor="blue"
                        iconSize="large"
                        onClick={() => openModal((feedback?.length) ? feedback : "Er is geen feedback gegeven.", 'Feedback')}
                    />
                )
            },
            {
                border: true,
                align: 'center',
                spaceAfter: true,
                content: (
                    <AttendanceDisplay
                        attendance={attendance}
                        sessions={sessions}
                        year={schoolYear}
                        studentId={studentId}
                        mode="view"
                    />
                )
            },
        ].filter(Boolean);

        // publication status cell
        if (role !== 'student') {
            const publishedTitle = getCopy('rating.published.published.buttonTitle');
            const unpublishedTitle = getCopy('rating.published.unpublished.buttonTitle');

            cells.push({
                align: 'center',
                border: true,
                content: (
                    <Icon
                        type={published ? 'eye' : 'eye-closed'}
                        color={published ? 'green' : 'gray'}
                        title={published ? publishedTitle : unpublishedTitle}
                        valign="middle"
                    />
                )
            });
        }

        // status cell
        cells.push({
            align: 'center',
            border: true,
            content: <Tag color={tagColor}>{tagValue}</Tag>
        });

        return cells;
    }, [criteria, status, showResultsColumn, result, feedback, attendance, sessions, schoolYear, studentId, role, openModal, published]);
};

const useRatingHeaderRowMemo = (criteria, role, showResultsColumn) => {
    const resultAbbrRef = useRef();
    const feedbackAbbrRef = useRef();
    const attendanceAbbrRef = useRef();
    const progressAbbrRef = useRef();
    const publishedAbbrRef = useRef();

    return useMemo(() => {
        const criteriaHeaderCells = criteria.reduce((acc, group) => ([
            ...acc,
            ...group.map(({ code, name, description }, ii, allCriteriaInGroup) => {
                const paddingRight = ii === allCriteriaInGroup.length - 1 ? '1rem' : 0;
                const paddingLeft = !ii ? '1rem' : 0;

                return {
                    align: 'center',
                    collapse: true,
                    style: {
                        width: '3.5rem',
                        padding: `0 ${paddingRight} 0 ${paddingLeft}`,
                    },
                    spaceAfter: ii === allCriteriaInGroup.length - 1,
                    bg: false,
                    content: (
                        <CriteriumHeaderTooltip abbr={code} title={name}>
                            <strong>{name}</strong><br />
                            {description}
                        </CriteriumHeaderTooltip>
                    ),
                };
            })
        ]), []);

        const copyRole = role === 'student' ? 'student' : 'teacher';

        const resultTitle = getCopy(`rating.tableHeaders.${copyRole}.result.title`);
        const resultTooltip = getCopy(`rating.tableHeaders.${copyRole}.result.tooltip`);
        const statusTitle = getCopy(`rating.tableHeaders.${copyRole}.status.title`);
        const statusTooltip = getCopy(`rating.tableHeaders.${copyRole}.status.tooltip`);
        const attendanceTitle = getCopy(`rating.tableHeaders.${copyRole}.attendance.title`);
        const attendanceTooltip = getCopy(`rating.tableHeaders.${copyRole}.attendance.tooltip`);
        const feedbackTitle = getCopy(`rating.tableHeaders.${copyRole}.feedback.title`);
        const feedbackTooltip = getCopy(`rating.tableHeaders.${copyRole}.feedback.tooltip`);

        const cells = [
            ...criteriaHeaderCells,
            showResultsColumn && {
                content: (
                    <>
                        <abbr
                            title={resultTooltip}
                            ref={resultAbbrRef}
                        >
                            {resultTitle}
                        </abbr>
                        <Tooltip target={resultAbbrRef} />
                    </>
                ),
                spaceAfter: true,
                align: 'center',
                bg: false
            },
            {
                content: (
                    <>
                        <abbr
                            title={feedbackTooltip}
                            ref={feedbackAbbrRef}
                        >
                            {feedbackTitle}
                        </abbr>
                        <Tooltip target={feedbackAbbrRef} />
                    </>
                ),
                spaceAfter: true,
                align: 'center',
                bg: false
            },
            {
                content: (
                    <>
                        <abbr
                            title={attendanceTooltip}
                            ref={attendanceAbbrRef}
                        >
                            {attendanceTitle}
                        </abbr>
                        <Tooltip target={attendanceAbbrRef} />
                    </>
                ),
                spaceAfter: true,
                align: 'center',
                bg: false
            },
        ].filter(Boolean);

        // publication status cell
        if (role !== 'student') {
            const publishedTitle = getCopy(`rating.tableHeaders.${copyRole}.published.title`);
            const publishedTooltip = getCopy(`rating.tableHeaders.${copyRole}.published.tooltip`);

            cells.push({
                align: 'center',
                bg: false,
                content: (
                    <>
                        <abbr
                            title={publishedTooltip}
                            ref={publishedAbbrRef}
                        >
                            {publishedTitle}
                        </abbr>
                        <Tooltip target={publishedAbbrRef} />
                    </>
                )
            });
        }

        // status cell
        cells.push({
            content: (
                <>
                    <abbr
                        title={statusTooltip}
                        ref={progressAbbrRef}
                    >
                        {statusTitle}
                    </abbr>
                    <Tooltip target={progressAbbrRef} />
                </>
            ),
            align: 'center',
            bg: false
        });

        return cells;
    }, [criteria, role, showResultsColumn]);
};
