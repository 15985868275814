import React, { useCallback, memo } from 'react';
import Bem from 'react-better-bem';

import { Icon } from '../';

import styles from './IconButton.module.scss';

const VALID_COLORS = ['default', 'transparent', 'blue', 'red', 'green'];

const IconButton = ({
    icon = 'chevron-right',
    border = false,
    color = 'default',
    iconColor: _iconColor = false,
    onClick,
    spanElement,
    iconSize,
    ...props
}) => {
    color = VALID_COLORS.includes(color) ? color : VALID_COLORS[0];

    const iconColor = _iconColor ?
        _iconColor :
        color === 'default' ?
            'icon-button-icon' :
            `icon-button-${color}-icon`;

    const isButton = typeof onClick === 'function';

    const wrappedOnClick = useCallback((e) => {
        if (typeof onClick === 'function') {
            e.stopPropagation();
            onClick(e);
        }
        return void 0;
    }, [onClick]);

    const iconElement = <Icon type={icon} size={iconSize ? `${iconSize}` : "small"} color={iconColor} valign="middle" />;

    if (isButton) {
        const innerProps = {
            el: 'button',
            mod: { button: true, color, border, disabled: props.disabled },
            onClick: wrappedOnClick,
            ...props
        };

        if (spanElement) {
            return (
                <Bem style={styles}>
                    <span tabIndex="0" role="button" {...innerProps}>
                        {iconElement}
                    </span>
                </Bem>
            );
        }

        return (
            <Bem style={styles}>
                <button {...innerProps}>
                    {iconElement}
                </button>
            </Bem>
        );
    }

    return (
        <Bem style={styles}>
            <span el="button" mod={{ color, border }} {...props}>
                {iconElement}
            </span>
        </Bem>
    );
};

export default memo(IconButton);
