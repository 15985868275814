import React, { useEffect } from 'react';
import Bem from 'react-better-bem';

import { ReportsLinkList, Icon, Person, Button } from '../../../components';
import {
    useCoachingTraineeContext,
    useBreadcrumbTrailContext,
} from '../../../context';
import { useLayout, usePageTitle } from '../../../hooks';
import { useReportsApi } from '../../../hooks/api';
import { getCopy } from '../../../utils';

import styles from './Reports.module.scss';

const Reports = ({ role = 'coach' }) => {
    useLayout({ stretch: false, collapse: 'top' });
    usePageTitle(`coaching.allReports.${role}`);

    /* * * * * * * * *
     * STUDENT INFO  *
    ** * * * * * * * */
    const { student, studentId, studentLoading, setLoading } = useCoachingTraineeContext();

    const rootPath = getCopy(`coaching.${role}.path`);
    const allReportsTitle = getCopy(`coaching.${role}.allReports.title`);

    const { loading, reports } = useReportsApi(role, studentId);
    useEffect(() => {
        setLoading(loading);
    }, [loading, setLoading]);

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        if (!studentLoading) {
            put({
                name: allReportsTitle,
                href: encodeURI(`${rootPath}/${studentId}/gespreksverslagen`),
            }, 2);
        }

        return () => {
            remove(allReportsTitle);
        };
    }, [remove, put, allReportsTitle, studentId, rootPath, studentLoading]);

    return (
        <Bem style={styles}>
            <section el="student" mod="shaded">
                <div el="content">
                    <Person
                        { ...student }
                        large
                        className={styles.student__title}
                    />
                </div>
            </section>
            <section el="reports">
                <div el="title">
                    <h2>
                        <Icon type="mail" color="blue" valign="middle" inline />
                        Gespreksverslagen
                    </h2>
                    <Button el="feedback" variant="small" to={`${rootPath}/${studentId}/feedback`}>Feedback</Button>
                </div>

                {
                    reports?.length ? (<ReportsLinkList
                        hrefTemplate={`${rootPath}/${studentId}/gespreksverslagen/[id]`}
                        data={reports}
                    />) : <strong>Nog geen gespreksverslagen</strong>
                }


            </section>
        </Bem>
    );
};

export default Reports;
