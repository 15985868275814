import { useCallback } from 'react';

import useApi from '../useApi';

function useAvatarApi () {
    const { get } = useApi('foto', '', { noGlobalLoading: true, noWarning: true, cachePolicy: 'cache-first' });

    const getAvatar = useCallback((id, type = 'students') => {
        const path = `/students/${id}/avatar/`;
        return get(path);
    }, [get]);

    return {
        getAvatar,
    };
}

export default useAvatarApi;
