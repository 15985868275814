import React, { useMemo, useEffect } from 'react';
import Bem from 'react-better-bem';

import {
    Icon,
    ReportsLinkList,
    Input,
    CoachingTraineesLinkList,
} from '../../../components';

import { useCoachingTraineesApi, useRecentReportsApi } from '../../../hooks/api';
import { usePageTitle, useLayout, useScrollRestoration } from '../../../hooks';
import {
    useCoachingTraineeContext,
    useBreadcrumbTrailContext,
} from '../../../context';
import { getCopy } from '../../../utils';

import styles from './CoachOverview.module.scss';

const CoachOverview = ({ role = 'coach' }) => {
    useLayout({ stretch: false, collapse: 'top' });
    usePageTitle(`coaching.overview.${role}`);
    const { pathname } = window.location;
    useScrollRestoration({ path: pathname });
    // const studentOverviewTitle = getCopy(`coaching.${role}.traineeList.title`);
    // const studentOverviewSubtitle = getCopy(`coaching.${role}.traineeList.subtitle`);
    const recentReportsTitle = getCopy(`coaching.${role}.recentReports.title`);
    const apiPrefix = getCopy(`coaching.${role}.apiPrefix`);
    const parentPagePath = getCopy(`coaching.${role}.path`);
    const urlPrefix = parentPagePath + '/';

    const { students, groups, filter, loading: loadingTrainees } = useCoachingTraineesApi(apiPrefix);
    const { recentReports, loading: loadingRecentReports } = useRecentReportsApi(apiPrefix);

    const { setLoading } = useCoachingTraineeContext();
    useEffect(() => {
        setLoading(loadingTrainees || loadingRecentReports);
    }, [loadingTrainees, loadingRecentReports, setLoading]);

    // reset breadcrumbtrail on overview page
    const { reset } = useBreadcrumbTrailContext();
    useEffect(() => { reset(); }, [reset]);

    const studentGroups = useMemo(() => {
        return groups
            .map(({ groupName, students: _studentIds }) => ({
                groupName,
                students: _studentIds
                    .map((id) => students.length && students.find(({ flexx_id: _id }) => id === _id))
                    .filter(({ last_name, visible }) => visible && last_name.length)
                    .sort(({ last_name: a }, { last_name: b }) => a < b ? -1 : b < a ? 1 : 0)
            }))
            .filter(({ students }) => students.length);
    }, [students, groups]);

    /**
     *  This effect will run 2sec after the component is mounted.
     * The custom Hook that is making this possible is in the Coaching.js
     */
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const pos = Number(sessionStorage.getItem(`scrollPosition-1-${pathname}`));
        studentGroups.length && window.scrollTo({
            top: pos,
            behavior: 'auto'
        });

    }, [pathname, studentGroups?.length]);

    return (
        <Bem style={styles}>
            <section el="coaching-grid">
                <section el="column" mod="students">
                    <div el="title-bar">
                        <Input
                            small
                            placeholder="Zoeken"
                            icon="spyglass"
                            onChange={({ target: { value } }) => { filter(value); }}
                        />
                    </div>
                    {studentGroups.map(({ groupName, students }, i, allGroups) => (
                        <CoachingTraineesLinkList
                            key={`coach-students-group-${groupName}`}
                            students={students}
                            groupName={groupName}
                            collapseTop={allGroups.length > 1}
                            role={role}
                            showAvatars={students.length < 50}
                        />
                    ))}
                </section>

                <section el="column" mod="reports">
                    <h2>
                        <Icon type="mail" color="blue" valign="middle" inline />
                        {recentReportsTitle}
                    </h2>
                    <p>De meest recent ingestuurde gespreksverslagen.</p>
                    <ReportsLinkList
                        hrefTemplate={`${urlPrefix}[student_number]/gespreksverslagen/[id]`}
                        data={recentReports}
                        includeStudent
                    />
                </section>

            </section>
        </Bem>
    );
};

export default CoachOverview;
