import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import useOnClickOutside from 'use-onclickoutside';
import useKey from '@rooks/use-key';
import Bem from 'react-better-bem';

import { useHiddenUIComponent } from '../../hooks';
import { useAppStateContext } from '../../context';

import styles from './Tooltip.module.scss';

/* * * * * * *
 * CONSTANTS *
** * * * * * */
const VALID_LINE_COLORS = ['default', 'blue', 'blue-semi-light'];
const VALID_DIRECTIONS = ['bottom', 'top'];
const VALID_TEXT_ALIGN = ['center', 'left', 'right'];

const APP_ROOT = document.getElementById('flexx-root');

/* * * * * * *
 * COMPONENT *
** * * * * * */
const Tooltip = ({ target, direction = 'bottom', noLine, lineColor, large = false, hover = false, align = 'center', children, ...props }) => {
    direction = VALID_DIRECTIONS.includes(direction) ? direction : VALID_DIRECTIONS[0];
    lineColor = VALID_LINE_COLORS.includes(lineColor) ? lineColor : VALID_LINE_COLORS[0];
    align = VALID_TEXT_ALIGN.includes(align) ? align : VALID_TEXT_ALIGN[0];

    const { screen: { isLargePhone } } = useAppStateContext();

    /* * * * * *
     * STATES  *
    ** * * * * */

    // store target element title
    const [abbrTitle, setAbbrTitle] = useState();

    // store css position for tooltip
    const [cssPosition, setCssPosition] = useState({});

    // store left offset for tooltip content
    // if too close to viewport edge
    const [cssOffset, setCssOffset] = useState(0);

    /* * * * *
     * LOGIC *
    ** * * * */
    // logic for opening/closing tooltip
    const {
        visible,
        hidden,
        open: _openTooltip,
        close: closeTooltip,
        hide: hideTooltip
    } = useHiddenUIComponent();

    const openTooltip = useCallback(() => {
        const bcr = target.current.getBoundingClientRect();
        const { top: abbrTop, left: abbrLeft, width: abbrWidth, height: abbrHeight } = bcr;

        let top = parseInt(direction === 'bottom' ? abbrTop + abbrHeight : abbrTop, 10);
        top += document.documentElement.scrollTop;
        const left = parseInt(abbrLeft + 0.5 * abbrWidth, 10);



        setCssPosition({ top: `${top}px`, left: `${left}px` });

        // half tooltip width + 10px margin
        const treshold = large && isLargePhone ? 220 :
                            !large && isLargePhone ? 130 :
                                large ? 160 : 110;
        let offset = 0;
        if (left < treshold) {
            offset = treshold - left;
        } else if (left + treshold > window.innerWidth) {
            offset = -1 * (left - window.innerWidth + treshold);
        }
        setCssOffset(offset);

        _openTooltip(true);
    }, [_openTooltip, direction, target, isLargePhone, large]);

    const tooltipRef = useRef();
    useOnClickOutside(tooltipRef, closeTooltip);
    useKey(['Escape'], closeTooltip);

    // logic for unsetting abbr title
    // to prevent browser default behaviour
    const removeTitle = useCallback(() => {
        const abbrElement = target?.current;
        if (abbrElement && abbrElement.title) {
            abbrElement.title = '';
        }
    }, [target]);

    const resetTitle = useCallback(() => {
        const abbrElement = target?.current;
        if (abbrElement && abbrTitle) {
            abbrElement.title = abbrTitle;
        }
    }, [target, abbrTitle]);

    // add classnames for styling to target component
    // add events to target component
    useEffect(() => {
        const abbrElement = target?.current;

        if (abbrElement) {
            setAbbrTitle((currentAbbrTitle) => abbrElement.title || currentAbbrTitle);

            // abbrElement.classList.add(styles.abbr);

            if (noLine) {
                abbrElement.classList.add(styles['abbr--no-line']);
            }

            if (lineColor !== 'default') {
                abbrElement.classList.add(styles[`abbr--color-${lineColor}`]);
            }

            if (hover) {
                abbrElement.onmouseover = () => {
                    removeTitle();
                    openTooltip();
                };

                abbrElement.onmouseout = () => {
                    resetTitle()
                    closeTooltip();
                };
            } else {
                abbrElement.onmouseover = removeTitle;
                abbrElement.onmouseout = resetTitle;
                abbrElement.onclick = openTooltip;
            }
        }

        return () => {
            if (abbrElement) {
                abbrElement.classList.remove(
                    // styles.abbr,
                    styles['abbr--no-line'],
                    styles['abbr--color-blue'],
                    styles['abbr--color-blue-semi-light']
                );

                abbrElement.onmouseover = null;
                abbrElement.onmouseout = null;
                abbrElement.onclick = null;
            }
        }
    }, [children, target, noLine, lineColor, removeTitle, resetTitle, openTooltip, closeTooltip, hover]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    const tooltipContent = children || abbrTitle || null;

    const tooltipElement = tooltipContent ? (
        <Bem style={styles}>
            <div
                el="wrapper"
                style={cssPosition}
                ref={tooltipRef}
                onTransitionEnd={hideTooltip}
                mod={{ hidden, visible, dir: direction, align, large }}
            >
                <div
                    el="tooltip"
                    style={{ left: `${cssOffset}px` }}
                >
                    <div el="content">
                        {tooltipContent}
                    </div>
                </div>
            </div>
        </Bem>
    ) : null;

    // portal that shit
    // https://reactjs.org/docs/portals.html
    return ReactDOM.createPortal(tooltipElement, APP_ROOT);
};

export default Tooltip;
