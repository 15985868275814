import React from 'react';

import { useLayout, usePageTitle } from '../../hooks';
import { PageHeader } from '../../components';

// import styles from './404.module.scss';

const ERROR_404 = () => {
    useLayout({ collapse: 'top' });
    usePageTitle("pageNotFound");

    return (
        <PageHeader
            title="404 Pagina niet gevonden"
        />
    );
};

export default ERROR_404;
