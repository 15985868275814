import React, { useMemo, useEffect, useState, useRef } from 'react';
import Bem from 'react-better-bem';

import { useAvatarsContext } from '../../context';

import styles from './Avatar.module.scss';

const FAILED_AVATAR = process.env.REACT_APP_FLEXX_FAILED_AVATAR_ID;

const VALID_SIZES = ['m', 'l', 'xl', 'xxl'];

const Avatar = ({
    first_name: first = '',
    last_name: last = '',
    full_name: name = '',
    flexx_id: id = '',

    noRemote = false,
    noPhoto = false,
    loading = false,

    // unused user props
    email, base_group, specialization, cohort, roles, impersonated, visible,

    fallback, // overwrite fallback text
    src, // overwrite fallback src

    alt = '',
    size = 'm',
    inverted = false,

    className = '',
}) => {
    const { getAvatar, abortAvatar } = useAvatarsContext();

    const [blobUrl, setBlobUrl] = useState();

    // 'hacky' way to make sure we don't try to update this component when unmounted
    const aborted = useRef(false);

    // only show (and fetch) avatar when:
    // - AND flexx_id is set
    // - AND noRemote is falsy
    // - AND when noPhoto is falsy
    const showAvatar = id && !noRemote && !noPhoto;
    useEffect(() => {
        async function _getAvatar () {
            const avatarBlobUrl = await getAvatar(id);
            if (!aborted.current) {
                setBlobUrl(avatarBlobUrl);
            }
        }

        if (showAvatar) {
            aborted.current = false;
            _getAvatar();
        }

        return () => {
            aborted.current = true;
        }
    }, [getAvatar, abortAvatar, id, showAvatar]);

    const imageSrc = useMemo(() =>
        (!aborted.current && blobUrl !== FAILED_AVATAR && blobUrl) || src,
    [blobUrl, src]);

    const imageAlt = alt.length ? alt : name;
    const textFallback = loading ? " " : fallback?.length ? fallback : `${first.substr(0, 1)}${last.substr(0, 1)}`;

    size = VALID_SIZES.includes(size) ? size : VALID_SIZES[0];

    return (
        <Bem style={styles}>
            {!loading && !noPhoto && imageSrc ? (
                <img
                    className={className}
                    el="avatar"
                    mod={{ size, inverted }}
                    alt={imageAlt}
                    title={imageAlt}
                    src={imageSrc}
                />
            ) : textFallback ? (
                <span
                    className={className}
                    el="avatar"
                    title={imageAlt}
                    mod={{ size, fallback: true, inverted }}
                >
                    {textFallback}
                </span>
            ) : null}
        </Bem>
    );
};

export default Avatar;
