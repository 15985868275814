import { useMemo, useEffect, useState } from 'react';
import useApi from '../useApi';

import { cleanupCourseName } from '../../utils';
import { useEnvironmentContext } from '../../context';

function useRatingOverviewApi () {
    // get planning parameters for filtering
    const { planning: { currentYear, upcomingYear, latestYear, blocks } } = useEnvironmentContext();
    const defaultYear = currentYear || upcomingYear || latestYear;

    const [isPublished, setIsPublished] = useState(false);

    const [selectedYear, setSelectedYear] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState({});

    useEffect(() => {
        const filters = JSON.parse(localStorage.getItem('filters')) ?? {};
        const { selectedYear: storedYear, selectedPeriod: storedPeriod } = filters || {};
        // Set the state from local storage
        if (storedYear && storedYear?.yearId) {
            setSelectedYear(storedYear);
        }

        if (storedPeriod && storedPeriod?.yearId && storedPeriod?.yearId === storedYear?.yearId) {
            setSelectedPeriod(storedPeriod);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('filters', JSON.stringify({ selectedYear, selectedPeriod }));
    }, [selectedYear, selectedPeriod]);

    // Selected year is of the format 2023/2024 and only the first year is valid.
    const url = (selectedPeriod?.blockId) ? `/ratings/block/${selectedPeriod.blockId}` : `/ratings/year/${selectedYear?.yearId}`;

    // api
    const { get, loading, value, fetched, clearCache } = useApi('cursusgroepen overzicht', url, { persist: true, initialValue: [] });

    // Only make the request when the selected year and selected period are defined
    const fetchWhen = !fetched && !loading && !!(selectedYear?.yearId && selectedPeriod?.blockId);
    useEffect(() => {
        if (fetchWhen) {
            get();
        } else if (isPublished) {
            clearCache();
            get();
            setIsPublished(false);
        }
    }, [get, fetchWhen, isPublished, clearCache]);

    useEffect(() => {
        if (selectedYear?.yearId || selectedPeriod?.blockId) {
            get();
        }
    }, [selectedYear?.yearId, selectedPeriod?.blockId, get]);

    const courses = useMemo(() => {
        if (!fetched || !value.length) {
            return [];
        }

        return value
            .sort(( // sort by block: latest first
                { start_date: a },
                { start_date: b }
            ) => {
                const da = new Date(a);
                const db = new Date(b);
                return da < db ? 1 : db < da ? -1 : 0;
            })
            .reduce((acc, courseGroup) => {
                const { students } = courseGroup;
                if (!students.length) {
                    return acc;
                }

                const { id, courseName, cleanName, period, group_name: groupName, course_name, group_code, start_date } = cleanupCourseName(courseGroup);

                const currentScheduledCourseIndex = acc.findIndex(({ key }) => key === `${course_name}-${period}`);
                if (currentScheduledCourseIndex > -1) {
                    acc[currentScheduledCourseIndex].groups.push(courseGroup);
                    acc[currentScheduledCourseIndex].groups.sort(({ group_code: a }, { group_code: b }) => a < b ? -1 : b < a ? 1 : 0);
                } else {
                    acc.push({
                        id,
                        courseName,
                        cleanName,
                        key: `${course_name}-${period}`,
                        block: {
                            period
                        },
                        groupName,
                        course_name,
                        group_code,
                        start_date,
                        groups: [courseGroup],
                    });
                }

                return acc;
            }, []);
    }, [value, fetched]);

    // get unique schoolyears from the blocks data
    const allYears = useMemo(() => {
        if (!blocks.length) {
            return [];
        }

        return blocks.reduce((ys, { start_date, end_date, school_year: _y, school_year_id: _yId, period: _p, id: _pId }) => {
            if (!start_date || !end_date) {
                return ys;
            }

            const yearIndex = ys.findIndex(({ yearId }) => yearId === _yId);

            if (!selectedYear?.yearId && _y === defaultYear) {
                setSelectedYear({ yearLabel: _y, yearId: _yId });
            }

            if (yearIndex > -1) {
                ys[yearIndex].periods.push({
                    period: _p,
                    blockId: _pId,
                    yearId: _yId
                })
            } else {
                ys.push({
                    yearLabel: _y,
                    yearId: _yId,
                    periods: [{
                        period: _p,
                        blockId: _pId,
                        yearId: _yId
                    }]
                })
            }

            return ys;
        }, []);
    }, [blocks, defaultYear, selectedYear?.yearId]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        courses,
        allYears,
        defaultYear,
        selectedYear,
        setSelectedYear,
        selectedPeriod,
        setSelectedPeriod,
        loading,
        fetched,
        setIsPublished
    };
}

export default useRatingOverviewApi;
