import React from 'react';
import Bem from 'react-better-bem';

import styles from './FormElementNotification.module.scss';

const VALID_TYPES = ['notification', 'warning'];

const FormElementNotification = ({ message = '', type = 'notification', transparent = false }) => {

    type = VALID_TYPES.includes(type) ? type : VALID_TYPES[0];

    return (
        <Bem style={styles}>
            <span el="notification" mod={[type, { transparent }]}>
                {message}
            </span>
        </Bem>
    );
};

export default FormElementNotification;
