import React, { memo } from "react";

import { getCopy } from "../../../utils";

import { PageHeader, BlockBadge, StudyLoadMeter, ScoreLegend } from "../../";

import styles from "./PageHeader.module.scss";

const ChoicesPageHeader = ({ role, block, studyLoad, onIntroductieClick, km2 = false, bigPopover = false, km1 = false }) => {
    const { number: blockNumber } = block;

    const titleText = getCopy(`coursePlanner.firstMoment.${role}.pageTitle`);
    const loadMeterLabel = getCopy(
        `coursePlanner.firstMoment.${role}.loadMeterLabel`,
        { blockNumber }
    );

    const title = (
        <>
            {titleText}
            {!km2 ? (
                <BlockBadge
                    size="extra-large"
                    block={block}
                    className={styles["block-badge"]}
                />
            ) : null}
        </>
    );

    const subtitle = km2 ? (
        <>
            Keuzemoment <strong>2</strong>
        </>
    ) : null;

    return (
        <PageHeader
            title={title}
            subtitle={subtitle}
            collapsed
            bigPopover={bigPopover}
        >
            <div className={styles["right-content"]}>
                <StudyLoadMeter
                    load={studyLoad}
                    label={loadMeterLabel}
                />
                {!km1 ? (<ScoreLegend
                    choiceMoment
                    onChoiceMomentClick={onIntroductieClick}
                />) : null }
            </div>
        </PageHeader>
    );
};

export default memo(ChoicesPageHeader);
