import React from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { useAppStateContext, useAuthenticationContext } from '../../context';
import { Logo, NavButton, Navigation, ImpersonateBar } from '../';

import styles from './Header.module.scss';


const Header = () => {
    const {
        header: { ref: headerRef },
        nav: { opened: navOpen }
    } = useAppStateContext();
    const { isAuthenticated } = useAuthenticationContext();

    if (!isAuthenticated) {
        return <header ref={headerRef} />;
    };

    return (
        <Bem style={styles}>
            <header
                el="header"
                mod="stretched"
                ref={headerRef}
            >
                {isAuthenticated && <ImpersonateBar />}
                {isAuthenticated && (
                    <div
                        el="content"
                        mod={{ 'nav-open': navOpen, padded: true }}
                    >
                        <Link to="/" title="Flexx Homepage" className={styles.header__content__logo}>
                            <Logo />
                        </Link>
                        <Navigation desktop />
                        <NavButton />
                    </div>
                )}
            </header>
        </Bem>
    );
};

export default Header;
