import React, { useRef, useEffect, useState, useCallback } from 'react';
import useResizeObserver from 'use-resize-observer';
import Bem from 'react-better-bem';

import { Icon } from '../';

import styles from './WarningBar.module.scss';

const VALID_VARIANTS = ['info', 'error'];
const VARIANT_ICON_COLORS = {
    info: 'warning-bar-info-text',
    error: 'warning-bar-error-text'
};

const WarningBar = ({ children, icon, variant = 'info', collapsed = false, attach, ...props }) => {

    variant = VALID_VARIANTS.includes(variant) ? variant : VALID_VARIANTS[0];

    const [attachArrowLeft, setAttachArrowLeft] = useState(false);
    const barRef = useRef();
    const checkAttachTo = useCallback(() => {
        if (attach?.current && barRef?.current) {
            const { left: aleft, width: awidth } = attach.current.getBoundingClientRect();
            const { left: bleft } = barRef.current.getBoundingClientRect();

            const left = parseInt(aleft + 0.5 * awidth - bleft, 10);
            setAttachArrowLeft(left);
        }
    }, [attach]);

    useEffect(checkAttachTo, [checkAttachTo]);
    useResizeObserver({ ref: attach, onResize: checkAttachTo });

    if (!children) {
        return null;
    }

    return (
        <Bem style={styles}>
            <div el="bar" mod={[variant, { collapsed }]} ref={barRef} {...props}>
                {icon && (
                    <Icon
                        type={icon}
                        className={styles.bar__icon}
                        color={VARIANT_ICON_COLORS[variant]}
                        size="large"
                    />
                )}
                <div el="content">
                    {children}
                </div>
                {attachArrowLeft !== false ? (
                    <span el="caret" style={{ left: `${attachArrowLeft}px` }} />
                ) : null}
            </div>
        </Bem>
    );
};

export default WarningBar;
