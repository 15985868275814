import React, { useEffect } from 'react';
import Bem from 'react-better-bem';

import { useHiddenUIComponent } from '../../hooks';

import styles from './StickyFormFooter.module.scss';

const StickyFormFooter = ({ mounted = false, top = false, children }) => {

    const { visible, hidden, open, close, hide } = useHiddenUIComponent();

    useEffect(() => {
        if (mounted) {
            open();
        } else {
            close();
        }
    }, [mounted, open, close]);

    return (
        <Bem style={styles}>
            <div
                el="footer"
                onAnimationEnd={hide}
                mod={{ visible, hidden, top }}
            >
                <div el="content">
                    {children}
                </div>
            </div>
        </Bem>
    );
};

export default StickyFormFooter;
