import { withRouter } from 'react-router-dom';

import {
    AuthenticationContextProvider,
    AppStateContextProvider,
    EnvironmentContextProvider,
    BreadcrumbTrailContextProvider,
    CoachingTraineeContextProvider,
    RatingsContextProvider,
    AvatarsContextProvider,
    FiltersContextProvider
} from '../context/providers';

/* * * * * * *
 * MAIN HOC  *
** * * * * * */
function withFlexxHocs (ChildComponent) {
    return (props) => (
        <AppStateContextProvider>
            <EnvironmentContextProvider>
                <AuthenticationContextProvider>
                    <AvatarsContextProvider>
                        <FiltersContextProvider>
                            <BreadcrumbTrailContextProvider>
                                <ChildComponent {...props} />
                            </BreadcrumbTrailContextProvider>
                        </FiltersContextProvider>
                    </AvatarsContextProvider>
                </AuthenticationContextProvider>
            </EnvironmentContextProvider>
        </AppStateContextProvider>
    );
}

export default function withHocs (ChildComponent) {
    return withRouter(withFlexxHocs(ChildComponent));
}

/* * * * * * * *
 * OTHER HOCS  *
** * * * * * * */
function withCoachingTrainee (ChildComponent) {
    return (props) => (
        <CoachingTraineeContextProvider>
            <ChildComponent {...props} />
        </CoachingTraineeContextProvider>
    );
}

function withTeacherRatings (ChildComponent) {
    return (props) => (
        <RatingsContextProvider>
            <ChildComponent {...props} />
        </RatingsContextProvider>
    );
}

export {
    withCoachingTrainee,
    withTeacherRatings
};
