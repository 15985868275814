import React, { useMemo, useRef } from 'react';

import { Icon, Tooltip } from '../';
import { useEnvironmentContext } from '../../context';
import { getAttendanceEduarteUrl, getCopy } from '../../utils';

import styles from './AttendanceDisplay.module.scss';

const LEGACY_YEARS = (process.env.REACT_APP_FLEXX_LEGACY_YEARS || '').split(',');


function AttendanceDisplay ({ attendance, sessions, studentId, addLink = false, year, eduarteMode = 'view' }) {

    const attDisplay = useMemo(() => {
        const display = (<small><strong>{attendance} / {sessions}</strong></small>);

        if (addLink) {
            const href = getAttendanceEduarteUrl('teacher', studentId, eduarteMode);
            return (
                <a className={styles['attendance-link']} href={href} target="_blank" rel="noreferrer">
                    {display}
                    <Icon
                        type="external-link"
                        size="extra-small"
                        className={styles['attendance-link__icon']}
                    />
                </a>
            );
        }

        return display;
    }, [attendance, sessions, addLink, studentId, eduarteMode]);

    if (LEGACY_YEARS.includes(year)) {
        return <AttendanceTooltip mode={eduarteMode} studentId={studentId} />;
    }

    return attDisplay;
}

export default AttendanceDisplay;

function AttendanceTooltip ({ mode, studentId }) {
    const { environment } = useEnvironmentContext();
    const role = environment?.toLowerCase();

    const iconColor = role === 'teacher' ? 'blue-darker' : 'red-darkest';
    const abbrTitle = getCopy(`eduarteAttendance.${role}.abbrTitle`);
    const tooltipContent = getCopy(`eduarteAttendance.${role}.tooltip`);
    const attendanceLink = getAttendanceEduarteUrl(role, studentId, mode)

    const iconRef = useRef();

    return (
        <>
            <Icon
                ref={iconRef}
                type="question-mark-circle"
                color={iconColor}
                valign="middle"
                size="medium"
                title={abbrTitle}
            />
            <Tooltip
                target={iconRef}
                title={abbrTitle}
                direction="top"
                noLine
            >
                {tooltipContent}<br />
                Klik <a href={attendanceLink} target="_blank" rel="noreferrer" title="Eduarte">hier</a> om presentie in te zien.
            </Tooltip>
        </>
    );
};
