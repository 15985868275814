import { useEffect, useState, useRef } from 'react';

import { getCopy } from '../utils';

import TITLES from '../data/page_titles.json';
const BASE_TITLE = process.env.REACT_APP_FLEXX_PAGE_TITLE;

const LOADING_CHARS = ['↑','↗︎','➞','↘︎','↓', '↙︎', '←', '↖︎'];

// page title
function usePageTitle (identifier, replacements) {
    const [title, setTitle] = useState('');

    useEffect(() => {
        setDocTitle(title);
    }, [title]);

    const intervalRef = useRef();
    useEffect(() => {
        if (identifier !== 'loading') {
            const title = identifier ? getCopy(identifier, replacements, TITLES) : '';
            setTitle(title);
        } else {
            intervalRef.current = setInterval(setLoadingPageTitle, 500);
        }
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [identifier, replacements]);

    return null;
}

export default usePageTitle;

function setDocTitle (title) {
    document.title = title.length ? `${title} | ${BASE_TITLE}` : BASE_TITLE;
}

let loadingCharsIndex = 0;
function setLoadingPageTitle () {
    const char = LOADING_CHARS[loadingCharsIndex % LOADING_CHARS.length]
    loadingCharsIndex = (loadingCharsIndex + 1) % LOADING_CHARS.length;
    setDocTitle(char);
}
