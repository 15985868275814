import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";

import { getCopy } from "../../../utils";

import { useLayout, usePageTitle } from "../../../hooks";
import { useCoachingTraineeContext, useEnvironmentContext, useAppStateContext } from "../../../context";
import { useFirstMomentApi } from "../../../hooks/api";

import {
    ChoicesPageHeader,
    ChoicesBag,
    ChoicesBasket,
    ChoicesFilterBar,
    ChoicesSection,
    ChoicesInfoModal,
    CourseImage,
    WarningBar,
    Icon,
    LoadingDots,
} from "../../../components";

import styles from "./FirstMoment.module.scss";

const MODE_AWAITING_CHOICES = "__AWAITING_CHOICES__"; // coach is waiting for student to make choices
const MODE_APPROVE = "__APPROVE__"; // coach is approving/declining

const MODE_MAKE_CHOICES = "__MAKE_CHOICES__"; // student is making choices
const MODE_AWAITING_RESULTS = "__AWAITING__"; // student is waiting for coach to approve/decline

const FirstMoment = ({ role, block, api, student, mode = MODE_MAKE_CHOICES }) => {
    useLayout({ collapse: "vertical", margin: false });
    // const [isModalOpen, toggleModal] = useState(true);

    const {
        modal: { open: openModal },
    } = useAppStateContext();

    /* * * *
     * API *
     ** * * */
    const {
        loading,
        offer,
        studyLoad,
        competences,
        teams,
        diplomaProfiles,
        studentChoices,
        coachChoices,
        systemChoices,
        choose,
        deleteChoice,
        approve,
        decline,
        submitChoices,
    } = api;
    const { number: blockNumber } = block;

    /* * * * * * * *
     * INFO MODAL  *
     ** * * * * * * */
    const openInfoModal = useCallback(
        (choice, allowChoosing = true) => {
            const {
                id,
                scheduled_course: {
                    course: { cleanName, category },
                },
            } = choice;

            const modalTitle = (
                <>
                    <CourseImage category={category} size="small" className={styles["info-modal__course-image"]} />
                    {cleanName}
                </>
            );

            openModal(
                <ChoicesInfoModal
                    choice={choice}
                    allowChoosing={allowChoosing}
                    choose={() => {
                        choose(id, role);
                    }}
                    role={role}
                    student={student}
                />,
                modalTitle
            );
        },
        [openModal, choose, role, student]
    );

    // state for storing "mode"
    const [makingChoices, setMakingChoices] = useState(false);
    // only when either coach or student is making choices
    // allow toggling mode
    const allowToggling = [MODE_APPROVE, MODE_MAKE_CHOICES].includes(mode);
    const toggleMode = useCallback(() => {
        if (allowToggling) {
            setMakingChoices((c) => !c);
        }
    }, [allowToggling]);

    /* * * * *
     * LISTS *
     ** * * * */
    // state to allow searching in lists of available choices
    const [searchValue, setSearchValue] = useState("");
    const [selectedCompetences, setSelectedCompetences] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState([]);

    const filterList = useCallback((list, _searchValue = "", _selectedCompetences = [], _selectedProfile = [], _selectedTeams = [],) => {
        if (!_searchValue.length && !_selectedCompetences.length && !_selectedProfile.length && !_selectedTeams.length) {
            return list;
        }

        const needle = _searchValue.toLowerCase();

        return list
            .filter((choice) => {
                if (!needle.length) {
                    return true;
                }

                const {
                    scheduled_course: {
                        course: { courseName, cleanName },
                        teams = [],
                    },
                } = choice;

                const hayStack = `${courseName} ${cleanName}`.toLowerCase();

                return hayStack.includes(needle) || teams.some((team) => team?.toLowerCase()?.includes(needle));
            })
            .filter((choice) => {
                if (!_selectedCompetences.length) {
                    return true;
                }

                const {
                    scheduled_course: { criteria = [] },
                } = choice;

                const critChoiceIds = criteria.map(({ choice_id }) => choice_id);

                return critChoiceIds.some((cId) => _selectedCompetences.includes(cId));
            })
            .filter((choice) => {
                if (!_selectedTeams.length) {
                    return true;
                }

                const {
                    scheduled_course: { teams = [] },
                } = choice;

                return teams.some((team) => _selectedTeams.includes(team));
            })
            .filter((choice) => {
                if (!_selectedProfile.length) {
                    return true;
                }

                const {
                    scheduled_course: { course: { profile_name: profileName } },
                } = choice;

                return _selectedProfile.includes(profileName);
            });
    }, []);

    // needed to position attachArrow on warningbar
    const geplandIconRef = useRef();

    const lists = useMemo(() => {
        // output for not choosing: only system choices
        if (!makingChoices) {
            const geplandListTitleText = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.gepland.title`, {
                blockNumber,
                student: student?.first_name,
            });

            const geplandListTitle = blockNumber ? (
                <>
                    {geplandListTitleText}
                    {mode === MODE_MAKE_CHOICES ? (
                        <Icon
                            ref={geplandIconRef}
                            type={role === "student" ? "lock" : "info"}
                            color="blue-darker"
                            valign={role === "student" ? "text-top" : undefined}
                            inline="after"
                            cursor="pointer"
                        />
                    ) : null}
                </>
            ) : (
                <LoadingDots />
            );

            const hasRedoCourses = systemChoices.some(
                ({ scheduled_course: { routeStatus } }) => routeStatus === "VERTRAGEND"
            );

            // hide bar when student is awaiting results
            const geplandSubTitle =
                blockNumber && systemChoices?.length ? (
                    <PlannedCoursesWarningBars
                        role={role}
                        attachRef={geplandIconRef}
                        hasRedoCourses={hasRedoCourses}
                        mode={mode}
                        student={student}
                    />
                ) : null;

            return [
                {
                    choices: systemChoices,
                    title: geplandListTitle,
                    subtitle: geplandSubTitle,
                    showAll: true,
                    allowChoosing: false,
                    allowApproving: mode === MODE_APPROVE,
                },
            ];
        }

        // output for choosing: offer of available courses
        const versnellendListTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.versnellend.title`, {
            student: student?.first_name,
        });
        const versnellendSubTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.versnellend.subtitle`);
        const versnellendIcon = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.versnellend.icon`);

        const verbredendListTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.verbredend.title`);
        const verbredendSubTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.verbredend.subtitle`);
        const verbredendIcon = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.verbredend.icon`);

        const masterClassesListTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.masterClasses.title`);

        const previousChoicesListTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.previous.title`);

        const bpvTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.bpv.title`);

        const electiveListTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.electives.title`, {
            student: student?.first_name,
        });
        const electiveSubTitle = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.electives.subtitle`);
        const electiveIcon = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.electives.icon`);

        const {
            versnellend: _versnellend,
            verbredend: _verbredend,
            masterClasses: _masterClasses,
            previous: _previousChoices,
            bpv: _bpv,
            electives: _electives,
        } = offer;

        const bpv = filterList(_bpv, searchValue, selectedCompetences, selectedProfile, selectedTeams);
        const electives = filterList(_electives, searchValue, selectedCompetences, selectedProfile, selectedTeams);
        const versnellend = filterList(_versnellend, searchValue, selectedCompetences, selectedProfile, selectedTeams);
        const verbredend = filterList(_verbredend, searchValue, selectedCompetences, selectedProfile, selectedTeams);
        const masterClasses = filterList(_masterClasses, searchValue, selectedCompetences, selectedProfile, selectedTeams);
        const previousChoices = filterList(_previousChoices, searchValue, selectedCompetences, selectedProfile, selectedTeams);

        return [
            {
                choices: versnellend,
                title: versnellendListTitle,
                subtitle: versnellendSubTitle,
                icon: versnellendIcon,
                showAll: false,
                allowChoosing: true,
                allowApproving: false,
            },
            {
                choices: verbredend,
                title: verbredendListTitle,
                subtitle: verbredendSubTitle,
                icon: verbredendIcon,
                showAll: false,
                allowChoosing: true,
                allowApproving: false,
            },
            {
                choices: electives,
                title: electiveListTitle,
                subtitle: electiveSubTitle,
                icon: electiveIcon,
                showAll: false,
                allowChoosing: true,
                allowApproving: false,
            },
            {
                choices: masterClasses,
                title: masterClassesListTitle,
                showAll: false,
                allowChoosing: true,
                allowApproving: false,
            },
            {
                choices: previousChoices,
                title: previousChoicesListTitle,
                showAll: false,
                allowChoosing: true,
                allowApproving: false,
            },
            {
                choices: bpv,
                title: bpvTitle,
                showAll: false,
                allowChoosing: true,
                allowApproving: false,
                sortField: "code"
            }
        ].filter(({ choices }) => !!choices.length);
    }, [
        offer,
        systemChoices,
        makingChoices,
        role,
        blockNumber,
        filterList,
        searchValue,
        selectedCompetences,
        selectedTeams,
        selectedProfile,
        student,
        mode,
    ]);

    /* * * * * *
     * OUTPUT  *
     ** * * * * */
    return (
        <>
            <ChoicesPageHeader
                role={role}
                block={block}
                student={student}
                studyLoad={studyLoad}
                km1
            />
            <section className={styles.section}>
                <div className={styles.section__inner}>
                    {makingChoices ? (
                        <ChoicesBasket
                            role={role}
                            student={student}
                            toggleMode={toggleMode}
                            myChoices={role === "coach" ? coachChoices : studentChoices}
                            theirChoices={role === "coach" ? studentChoices : coachChoices}
                            deleteChoice={deleteChoice}
                            submitChoices={submitChoices}
                            loading={loading}
                            openInfoModal={openInfoModal}
                        />
                    ) : (
                        <ChoicesBag
                            role={role}
                            block={block}
                            student={student}
                            awaitingChoices={mode === MODE_AWAITING_CHOICES}
                            awaitingResults={mode === MODE_AWAITING_RESULTS}
                            toggleMode={toggleMode}
                            studentChoices={studentChoices}
                            coachChoices={coachChoices}
                            approve={approve}
                            decline={decline}
                            loading={loading}
                            openInfoModal={openInfoModal}
                        />
                    )}
                </div>
            </section>
            {makingChoices ? (
                <ChoicesFilterBar
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    competences={competences}
                    selectedCompetences={selectedCompetences}
                    teams={teams}
                    diplomaProfiles={diplomaProfiles}
                    selectedTeams={selectedTeams}
                    selectedProfile={selectedProfile}
                    setSelectedCompetences={setSelectedCompetences}
                    setSelectedProfile={setSelectedProfile}
                    setSelectedTeams={setSelectedTeams}
                />
            ) : null}
            {lists.map(({ choices, title, subtitle, icon, allowChoosing, allowApproving, showAll, sortField }, i) => (
                <ChoicesSection
                    key={`choices-list-${title}`}
                    title={title}
                    subtitle={subtitle}
                    icon={icon}
                    choices={choices}
                    shaded={!!(i % 2)}
                    choose={(acId) => {
                        choose(acId, role);
                    }}
                    deleteChoice={deleteChoice}
                    approve={approve}
                    decline={decline}
                    openInfoModal={openInfoModal}
                    allowChoosing={allowChoosing}
                    allowApproving={allowApproving}
                    showAll={showAll}
                    role={role}
                    student={student}
                    sortField={sortField}
                />
            ))}
        </>
    );
};

const StudentFirstMoment = () => {
    const { planning: { km1Student: block = {} } = {} } = useEnvironmentContext();

    const { student: { km1: { current, awaiting } = {} } = {}, number: blockNumber } = block;

    const api = useFirstMomentApi();

    usePageTitle("coursePlanner.firstMoment.student", { blockNumber });

    if (!current && !awaiting) {
        return null;
    }

    const mode = awaiting ? MODE_AWAITING_RESULTS : MODE_MAKE_CHOICES;

    return <FirstMoment role="student" mode={mode} block={block} api={api} />;
};

const CoachFirstMoment = () => {
    /* * * * * * * * *
     * STUDENT INFO  *
     ** * * * * * * * */
    const {
        student = {},
        studentId,
        planning: { km1Coach: coachBlock, km1Student: studentBlock } = {},
    } = useCoachingTraineeContext();

    const targetBlock = coachBlock || studentBlock || {};
    const { number: blockNumber } = targetBlock;

    usePageTitle([student.full_name, blockNumber].includes(undefined) ? "loading" : "coursePlanner.firstMoment.coach", {
        student: student.full_name,
        blockNumber,
    });

    const api = useFirstMomentApi(studentId, "coach");

    const mode = coachBlock ? MODE_APPROVE : MODE_AWAITING_CHOICES;

    return <FirstMoment role="coach" student={student} block={targetBlock} mode={mode} api={api} />;
};

export { CoachFirstMoment, StudentFirstMoment };

const PlannedCoursesWarningBars = ({ role, mode, hasRedoCourses, attachRef, student }) => {
    const [showPlannedWarningBar, setShowPlannedWarningBar] = useState(!hasRedoCourses);

    useEffect(() => {
        setShowPlannedWarningBar(!hasRedoCourses);
    }, [hasRedoCourses]);

    useEffect(() => {
        const icon = attachRef.current;
        if (icon) {
            icon.onclick = () => {
                setShowPlannedWarningBar((c) => !c);
            };
        }

        return () => {
            if (icon) {
                icon.onclick = null;
            }
        };
    }, [attachRef]);

    const plannedWarningBarText = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.gepland.notification`);

    return (
        <>
            {showPlannedWarningBar && mode === MODE_MAKE_CHOICES ? (
                <WarningBar icon="info" attach={attachRef}>
                    {plannedWarningBarText}
                </WarningBar>
            ) : null}
            {showPlannedWarningBar && mode === MODE_MAKE_CHOICES && hasRedoCourses ? <br /> : null}
            {hasRedoCourses ? (
                <WarningBar variant="error">
                    <RedoWarningBarContent role={role} student={student} />
                </WarningBar>
            ) : null}
        </>
    );
};

const RedoWarningBarContent = ({ role, student }) => {
    const text = getCopy(`coursePlanner.firstMoment.${role}.choiceTypes.vertragend.notification`, {
        student: student?.first_name,
    });
    const [strong, normal] = text.split("||");

    return (
        <>
            <strong>{strong} </strong>
            <span>{normal}</span>
            <Icon type="redo" color="warning-bar-error-text" inline="after" />
        </>
    );
};
