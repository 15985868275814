import React from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { AnimatedSvg, ProgressBar, Icon, IconButton } from '../';

import { useEnvironmentContext } from '../../context';

import styles from './HomePageCoursePlannerNavTile.module.scss';

const HomePageCoursePlannerNavTile = ({
    block,
    mode,
    role,
    isKm1 = false,
    isKm2 = false,
    stretched = false
}) => {
    const { getRouteByName } = useEnvironmentContext();


    const {
        student: {
            km1: {
                awaiting: awaitingKm1,
                fraction: km1TimeLeftStudent,
            } = {},
            km2: {
                fraction: km2TimeLeftStudent,
            } = {},
        } = {},
        coach: {
            km1: {
                fraction: km1TimeLeftCoach,
            } = {},
        } = {},
        number: blockNumber,
        period: blockPeriod,
    } = block;

    const moment = isKm2 ? 2 : isKm1 ? 1 : 1;

    const kmText = role === 'student' ? `keuzemoment ${moment} - ` : '';
    const blockNr = role === 'student' ? blockNumber : blockPeriod;
    const blockText = role === 'student' ? 'blok' : 'periode';

    const timeLeft = isKm2 ? km2TimeLeftStudent :
        isKm1 ? km1TimeLeftStudent :
        awaitingKm1 ? km1TimeLeftCoach : 1;

    const routes = {
        student: `cursusplanner-moment${moment}`,
        coach: 'Mijn coachstudenten',
        mentor: 'Overige coachstudenten',
    };

    const href = getRouteByName(routes[role]);

    if (!href) {
        return null;
    }

    const owlClasses = !stretched ?
        styles["link__tile__owl"] :
        `${styles["link__tile__owl"]} ${styles["link__tile__owl--stretched"]}`;

    return (
        <Link to={href} className={styles.link}>
            <Bem style={styles} block="link">
                <div el="tile" mod={{ collapsed: true, stretched }}>
                    {awaitingKm1 ? (
                        <div el="awaiting-icon">
                            <Icon type="persons-linked" color="blue-darker" />
                        </div>
                    ) : null}
                    <AnimatedSvg type="readingOwl" className={owlClasses}/>
                    <span el="title">
                        <span el="header"><>Cursus&shy;</><>planner</></span><br/>
                        <small el="sub">{kmText}<strong>{blockText} {blockNr}</strong></small>
                    </span>
                    {stretched ? <IconButton /> : null}
                    <ProgressBar
                        color={awaitingKm1 ? 'blue' : 'red-darkest'}
                        progress={timeLeft}
                        className={styles.link__tile__progress}
                    />
                </div>
            </Bem>
        </Link>
    );
};

export default HomePageCoursePlannerNavTile;
