import React from 'react';

import { Badge } from '../';

const BlockBadge = ({ period = false, invert = false, block = {}, size, ...props }) => {

    const index = period ? block.period : block.number;

    if ([null, undefined].includes(index)) {
        return null;
    }

    return (
        <Badge
            {...props}
            invert={invert}
            size={size}
        >
            {period ? 'Periode' : 'Blok'} {index}
        </Badge>
    );
};

export default BlockBadge;
