import { useState, useCallback, useReducer, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import {
    useHiddenUIComponent,
    useNotifications,
    useContainerLayout,
    useScreenSize,
    useElementHeight,
} from '.';

function useAppState () {
    /* * * * * * * * * * * *
     * SCREEN SIZE STATES  *
    ** * * * * * * * * * * */
    const screen = useScreenSize();

    /* * * * * * * * *
     * NOTIFICATIONS *
    ** * * * * * * * */
    const notifications = useNotifications();

    /* * * * * * * *
     * NAV STATES  *
    ** * * * * * * */
    const [navOpen, setNavOpen] = useState(false);
    const openNav = useCallback(() => { setNavOpen(true); }, []);
    const closeNav = useCallback(() => { setNavOpen(false); }, []);
    const toggleNav = useCallback(() => { setNavOpen((_c) => !_c); }, []);

    // store global loading state
    const [{ loading }, dispatchLoading] = useReducer(loadingReducer, { apis: [], loading: false });
    const startLoading = useCallback((id) => { dispatchLoading({ type: 'START', id }); }, []);
    const stopLoading = useCallback((id) => { dispatchLoading({ type: 'STOP', id }); }, []);

    /* * * * * * * * *
     * AVATAR QUEUE  *
    ** * * * * * * * */
    const [avatarQueue, setAvatarQueue] = useState(0);
    const bumpAvatarQueue = useCallback(() => {
        setAvatarQueue((c) => c + 1);
    }, []);
    const popAvatarQueue = useCallback(() => {
        setAvatarQueue((c) => c - 1);
    }, []);

    /* * * * * * * * * *
     * OVERLAY STATES  *
    ** * * * * * * * * */
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [transparentOverlay, setTransparentOverlay] = useState(false);
    const [disableScrollOnOverlay, setDisableScrollOnOverlay] = useState(false);
    const showOverlay = useCallback(({ transparent = false, disableScroll = false } = {}) => {
        setTransparentOverlay(transparent);
        setDisableScrollOnOverlay(disableScroll);
        setOverlayVisible(true);
    }, []);
    const hideOverlay = useCallback(() => {
        setOverlayVisible(false);
    }, []);

    // hide overlay on navigation
    const location = useLocation();
    const locationRef = useRef(location.pathname);
    useEffect(() => {
        if (location.pathname !== locationRef.current) {
            hideOverlay();
        }
        locationRef.current = location.pathname;
    }, [location, hideOverlay])

    /* * * * * * * * *
     * MODAL STATES  *
    ** * * * * * * * */
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalSmall, setModalSmall] = useState(false);
    const [paddingBottom, setPaddingBottom] = useState(true);
    const [position, setPosition] = useState(null);
    const [altTitle, setAltTitle] = useState(false)

    const hideOverlayForModal = useCallback(() => {
        hideOverlay();
    }, [hideOverlay]);

    const {
        visible: modalVisible,
        hidden: modalHidden,
        open: openModal,
        close: closeModal,
        hide: hideModal
    } = useHiddenUIComponent(showOverlay, hideOverlayForModal);

    const exposedOpenModal = useCallback((content, title = null, { small } = {}, paddingBottom = null, position = null, altTitle = false) => {
        openModal();
        setModalContent(content);
        setModalTitle(title);
        setModalSmall(small || false);
        setPaddingBottom(paddingBottom);
        setPosition(position);
        setAltTitle(altTitle)
    }, [openModal]);

    const exposedHideModal = useCallback(() => {
        if (!modalVisible) {
            hideModal();
            setModalContent(null);
            setModalTitle(null);
            setModalSmall(false);
            setPaddingBottom(false);
            setPosition(null);
            setAltTitle(false)
        }
    }, [hideModal, modalVisible]);

    /* * * * * * * * * * *
     * SIDEPANEL STATES  *
    ** * * * * * * * * * */
    const sidePanel = useHiddenUIComponent();

    const [sidePanelTitle, setSidePanelTitle] = useState('');
    const [sidePanelContent, setSidePanelContent] = useState('');

    /* * * * * * * * * * * * * * * * *
     * DOM ELEMENT DIMENSION STATES  *
    ** * * * * * * * * * * * * * * * */
    const headerRef = useElementHeight('header');
    const footerRef = useElementHeight('footer');
    const pageTitleRef = useElementHeight('pagetitle');

    // container layout
    const container = useContainerLayout();

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        notifications,

        container,

        loading,
        startLoading,
        stopLoading,

        avatarQueue: {
            queue: avatarQueue,
            bump: bumpAvatarQueue,
            pop: popAvatarQueue,
        },

        header: { ref: headerRef },
        footer: { ref: footerRef },
        pageTitle: { ref: pageTitleRef },

        screen,

        nav: {
            opened: navOpen,
            open: openNav,
            close: closeNav,
            toggle: toggleNav,
        },

        overlay: {
            transparent: transparentOverlay,
            disableScroll: disableScrollOnOverlay,
            visible: overlayVisible,
            show: showOverlay,
            hide: hideOverlay,
        },

        modal: {
            visible: modalVisible,
            hidden: modalHidden,
            open: exposedOpenModal,
            close: closeModal,
            hide: exposedHideModal,
            content: modalContent,
            title: modalTitle,
            small: modalSmall,
            noPaddingBottom: paddingBottom,
            modalPosition: position,
            altTitle
        },

        sidePanel: {
            ...sidePanel,
            title: sidePanelTitle,
            setTitle: setSidePanelTitle,
            content: sidePanelContent,
            setContent: setSidePanelContent,
        },
    };
}

export default useAppState;

// global loading state reducer
function loadingReducer (state, action) {
    const { type, id } = action;
    const { apis } = state;

    switch (type) {
        case 'START': {
            if (!apis.includes(id)) {
                apis.push(id);
            }

            return { apis, loading: true };
        }
        case 'STOP': {
            const _apis = apis.filter((_id) => _id !== id);

            return {
                apis: _apis,
                loading: !!_apis.length,
            };
        }
        default: {
            return state;
        }
    }
}





