import React, { useState, useEffect, useRef } from 'react';
import Bem from 'react-better-bem';
import { useDebouncedCallback } from 'use-debounce';

import { ModalButtons, TextArea } from '../../../components';

import { useAppStateContext } from '../../../context';

import { getCopy } from '../../../utils';

import styles from './GroupCourse.module.scss';

const FeedbackModal = ({ feedback = '', remoteFeedback, updateRemoteFeedback, updateLocalFeedback, allowEmptyFeedback = true }) => {
    const { modal: { close } } = useAppStateContext();

    // store state (for textarea value)
    const [internalFeedback, setInternalFeedback] = useState(feedback);

    // debounced update of local feedback
    const debouncedUpdateLocalFeedback = useDebouncedCallback(() => { updateLocalFeedback(internalFeedback); }, 250);
    useEffect(() => { debouncedUpdateLocalFeedback(internalFeedback); }, [internalFeedback, debouncedUpdateLocalFeedback]);

    // save button
    const buttons = [{
        content: getCopy('rating.feedback.saveButton'),
        onClick: () => {
            if (allowEmptyFeedback || internalFeedback.length) {
                updateRemoteFeedback(internalFeedback);
            }
            close();
        },
        disabled: !allowEmptyFeedback && !internalFeedback.length
    }];

    // restore button
    if (internalFeedback !== remoteFeedback && remoteFeedback.length) {
        buttons.unshift({
            content: getCopy('rating.feedback.resetButton'),
            onClick: () => {
                updateLocalFeedback(remoteFeedback);
                setInternalFeedback(remoteFeedback);
            }
        });
    }

    const warning = !allowEmptyFeedback && !internalFeedback.length ?
        getCopy('rating.feedback.emptyWarning') :
        '';

    // autofocus
    const textAreaRef = useRef();
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.selectionStart = textAreaRef.current.value.length;
            textAreaRef.current.selectionEnd = textAreaRef.current.value.length;
            textAreaRef.current.focus();
        }
    }, []);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <TextArea
                value={internalFeedback}
                onChange={({ target: { value } }) => {
                    setInternalFeedback(value);
                }}
                ref={textAreaRef}
                warning={warning}
                height={5}
                noMaxWidth
                resize
            />
            <ModalButtons
                buttons={buttons}
            />
        </Bem>
    )
};

const CloseCourseModal = ({ closeCourse })  => {
    const { modal: { close } } = useAppStateContext();

    const modalContent = getCopy('rating.closed.modal.content');
    const closeButton = getCopy('rating.closed.modal.closeButton');
    const cancelButton = getCopy('rating.closed.modal.cancelButton');

    return (
        <>
            <p>
                {modalContent}
            </p>
            <ModalButtons
                buttons={[
                    {
                        content: closeButton,
                        onClick: () => {
                            closeCourse();
                            close();
                        }
                    },
                    {
                        content: cancelButton,
                        onClick: close
                    }
                ]}
            />
        </>
    )
}


export { FeedbackModal, CloseCourseModal };
