import _default from './course_icons/default.svg';
import exam from './course_icons/exam.svg';
import coaching from './course_icons/coaching.svg';
import coach from './course_icons/coach.svg';
import theoretical from './course_icons/theory.svg';
import software from './course_icons/software.svg';
import masterclass from './course_icons/masterclass.svg';
import study from './course_icons/study.svg';
import mentor from './course_icons/mentor.svg';
import cursus from './course_icons/cursus.svg';

import project from './course_icons/project.svg';
import project_1 from './course_icons/project_media.svg';
import project_2 from './course_icons/project_zorg.svg';

import internship from './course_icons/internship_media.svg';
import internship_1 from './course_icons/internship_media.svg';
import internship_2 from './course_icons/internship_zorg.svg';

import practical from './course_icons/practical_media.svg';
import practical_1 from './course_icons/practical_media.svg';
import practical_2 from './course_icons/practical_zorg.svg';

const courseIcons = {
    default: _default,
    exam,
    coaching,
    coach,
    project,
    project_1,
    project_2,
    theoretical,
    software,
    practical,
    practical_1,
    practical_2,
    internship,
    internship_1,
    internship_2,
    masterclass,
    study,
    mentor,
    cursus
};

export default courseIcons;
