import React, { useEffect, useRef, useMemo, useState } from 'react';
import Bem from 'react-better-bem';
import moment from 'moment';
import 'moment/locale/nl';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';

import { useAppStateContext } from '../../context';
import { Toolbar, Event, EventDetailsPopover, DatePickerPopover } from './lib/components';

import styles from './Calendar.module.scss';
import './lib/styles.scss';

// settings for ReactBigCalendar
const localizer = momentLocalizer(moment);
const dateTimeFormats = {
    dayHeaderFormat: 'D MMMM yyyy'
};

// component
function Calendar (props) {
    const {
        events,
        date,
        urlDate,
        defaultView = 'day',
        views = ['day'],
        onNavigate,
        selectedEventId,
        onSelectEvent,
        onCloseDetailsPopover,
        viewDate,
        setViewDate,
        loading,
    } = props;

    /* * * * * * * * * * * * * * *
     * SELECTED EVENT + DETAILS  *
    ** * * * * * * * * * * * * * */
    const selectedEventDetails = useMemo(() => {
        const selectedEvent = events.find(({ id }) => `${id}` === selectedEventId);
        if (!selectedEvent) {
            return undefined;
        }

        const {
            id,
            title,
            type,
            start,
            end,
            teacher,
            canvasUrl,
            room,
            building,
            groupCourses,
         } = selectedEvent;

        const isToday = moment().isSame(moment(start), 'date');

        return {
            id,
            title,
            type,
            isToday,
            date: moment(start).format('L'),
            start: moment(start).format('LT'),
            end: moment(end).format('LT'),
            teacher,
            canvasUrl,
            building,
            room,
            groupCourses,
        };
    }, [events, selectedEventId]);

    /* * * * * * * * * * * * * * * * * * * * * *
     * SCROLL TO ACTIVE EVENT OR CURRENT TIME  *
    ** * * * * * * * * * * * * * * * * * * * * */
    const scrolledOnDateRef = useRef();
    useEffect(() => {
        if (scrolledOnDateRef.current !== urlDate) {
            let scrollTarget = document.querySelector('.rbc-current-time-indicator');

            if (!scrollTarget) {
                const time = moment().startOf('hour');
                const labels = document.querySelectorAll('.rbc-label');
                const labelElement = [...labels].find((label) => label.innerText === time.format('LT'));
                scrollTarget = labelElement;
            }

            if (scrollTarget) {
                const { y, height } = scrollTarget.getBoundingClientRect();
                const offset = parseInt(y + height / 2 - window.innerHeight / 2 + document.documentElement.scrollTop, 10);
                window.scrollTo(0, offset);
            }
        }
        scrolledOnDateRef.current = urlDate;
    }, [urlDate]);

    /* * * * * * * * * * * * * * * * *
     * STATES FOR MOBILE DATEPICKER  *
    ** * * * * * * * * * * * * * * * */
    const { screen: { isntTablet } } = useAppStateContext();
    const [openMobileDatePicker, setOpenMobileDatePicker] = useState(false);

    /* * * * * *
     * RENDER  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="calendar">
                <BigCalendar
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    formats={dateTimeFormats}
                    culture="nl-NL"
                    selectable={false}
                    onSelectEvent={onSelectEvent}
                    components={{
                        toolbar: (props) => (
                            <Toolbar
                                loading={loading}
                                openMobileDatePicker={() => { setOpenMobileDatePicker(true); }}
                                {...props}
                            />
                        ),
                        event: Event
                    }}

                    views={views}
                    defaultView={defaultView}

                    {...(typeof onNavigate === 'function' ?
                        { onNavigate, date } :
                        { defaultDate: date }
                    )}

                    events={events}
                />
            </div>
            <EventDetailsPopover
                event={selectedEventDetails}
                onClose={onCloseDetailsPopover}
            />
            <DatePickerPopover
                events={events}
                isMounted={isntTablet && openMobileDatePicker}
                date={date}
                onNavigate={onNavigate}
                viewDate={viewDate}
                setViewDate={setViewDate}
                onClose={() => { setOpenMobileDatePicker(false); }}
            />
        </Bem>
    );
}

export default Calendar;
