import useApi from '../useApi';

function useStudentApi () {
    const { get, loading, value, fetched } = useApi('student gegevens', '/students');

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        get,
        student: value,
        loading,
        fetched,
    };
}

export default useStudentApi;
