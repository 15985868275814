import { useEffect, useMemo } from 'react';
import useApi from '../useApi';

import { useEnvironmentContext } from '../../context';
import { useTraineePlanningApi } from "./usePlanningApi";
import { cleanupCourseName } from '../../utils';

function useProgressCoursesApi (studentId) {
    const {
        environment,
        planning,
    } = useEnvironmentContext();

    const { planning: studentPlanning }  = useTraineePlanningApi(studentId, true);
    const { current, upcoming, latest } = (studentId) ? studentPlanning : planning;

    const apiPath = environment === 'TEACHER' ?
        `/students/${studentId}/courses/progress` :
        `/courses/progress`;

    // get planning parameters for filtering
    const currentBlock = current || upcoming;
    const previousBlock = latest;

    const { get, loading, value, fetched } = useApi('cursusoverzicht', apiPath, { initialValue: [], persist: true });

    // fetch initially
    const fetchWhen = !fetched && !loading;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    const [currentCourses, previousCourses] = useMemo(() => {
        const current = value
            .filter(({ block_id }) =>
                block_id === currentBlock?.id
            )
            .map((course) => cleanupCourseName(course));

        const previous = value
            .filter(({ block_id }) =>
                block_id === previousBlock?.id
            )
            .map((course) => cleanupCourseName(course));

        current.sort(sortByCourseName);
        previous.sort(sortByCourseName);

        return [current, previous]
    }, [value, currentBlock, previousBlock]);

    return {
        currentCourses,
        previousCourses,

        currentBlock,
        previousBlock,

        loading,
        fetched,
    };
}

export default useProgressCoursesApi;

function sortByCourseName (courseA, courseB) {
    const { courseName: a } = courseA;
    const { courseName: b } = courseB;

    return a < b ? -1 : b < a ? 1 : 0;
}
