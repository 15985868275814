import React, {useEffect, useMemo} from 'react';
import Bem from 'react-better-bem';

import {
    useAppStateContext,
    useBreadcrumbTrailContext,
    useCoachingTraineeContext,
    useEnvironmentContext,
    useFiltersContext
} from "../../../context";

import { useLayout, usePageTitle } from '../../../hooks';
import { useFeedbackApi, useMyCoachApi } from '../../../hooks/api';

import {
    PageHeader,
    Icon,
    Person,
    Button,
    FilterButton,
    CoachingFeedbackCard,
    CoachingFeedbackFilter,
} from '../../../components';

import styles from './Feedback.module.scss';
import { getCopy } from "../../../utils";

const Feedback = ({ role = 'coach', planning }) => {
    useLayout({ collapse: 'top' });
    usePageTitle(`coaching.feedback.${role}`);

    const { studentId } = useCoachingTraineeContext();
    const { selectedYear, setSelectedYear, selectedPeriod, setSelectedPeriod } = useFiltersContext();

    const { currentYear, upcomingYear, latestYear, blocks } = planning;
    const defaultYear = currentYear || upcomingYear || latestYear;

    const isStudent = role === 'student';

    const { fetched: fetchedCoach, error: errorCoach, coaches } = useMyCoachApi(isStudent);

    const [allYears, allBlocks] = useMemo(() => {
        if (!blocks.length) {
            return [[], []];
        }

        return blocks.reduce(([ys, ps], { has_ratings, school_year: _y, school_year_id: _yId, number, period: _p, id: _pId }) => {
            if (!has_ratings) {
                return [ys, ps];
            }

            const yearIndex = ys.findIndex(({ yearId }) => yearId === _yId);
            const blockIndex = ps.findIndex(({ blockId }) => blockId === _pId);

            if (yearIndex <= -1) {
                ys.push({
                    yearLabel: _y,
                    yearId: _yId
                })
            }

            if (blockIndex <= -1) {
                ps.push({
                    number,
                    period: _p,
                    blockId: _pId,
                    year: _y
                })
            }

            return [ys, ps];
        }, [[], []]);
    }, [blocks]);

    const {
        fetched: fetchedFeedback = true,
        feedback
    } = useFeedbackApi(role !== 'student' ? { role, studentId } : { role });

    /* * * * * * * * * * *
    * FILTER SIDE PANEL  *
    ** * * * * * * * * * */
    const {
        sidePanel: {
            hidden: isSidePanelHidden,
            open: openSidePanel,
            close: closeSidePanel,
            setContent: setSidePanelContent,
        }
    } = useAppStateContext();

    useEffect(() => {
        setSidePanelContent(
            <CoachingFeedbackFilter
                allYears={allYears}
                allBlocks={allBlocks}
                defaultYear={selectedYear}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
            />
        );
    }, [
        allYears,
        allBlocks,
        defaultYear,
        selectedYear,
        selectedPeriod,
        setSidePanelContent,
        closeSidePanel,
        setSelectedYear,
        setSelectedPeriod
    ]);

    let filterButtonContent = selectedYear?.yearLabel || '';
    if (selectedPeriod?.period) {
        const periodStr = `Blok ${selectedPeriod.number}`;
        filterButtonContent = periodStr;
    }

    return (
        <Bem style={styles}>
            <section el="feedback-grid" mod={role}>
                <section el="column" mod="feedback">
                    { isStudent && <section el="new-report">
                        <div el="content">
                            <h2>Nieuw gespreksverslag</h2>
                            <Button
                                target="_blank"
                                variant="small"
                                icon="text-add"
                                to="/coaching/verslagen/nieuw"
                            >
                                Nieuw verslag
                            </Button>
                        </div>
                    </section> }

                    {fetchedFeedback && (
                        <section el="list">
                            <div el="title-bar">
                                <h2>
                                    <Icon type="mail" color={isStudent ? "red" : "blue"} valign="middle" inline />
                                    Feedback
                                </h2>
                                <FilterButton onClick={(isSidePanelHidden) ? openSidePanel : closeSidePanel}>
                                    {filterButtonContent || 'Filter overzicht'}
                                </FilterButton>
                            </div>
                            <ol el="card-lists">
                                {
                                    feedback?.length ? feedback.map((item, index) => (
                                        <CoachingFeedbackCard {...item} key={index} />
                                    )) :
                                    <strong>Nog geen feedback</strong>
                                }
                            </ol>
                        </section>
                    )}
                </section>

                {fetchedCoach && !errorCoach && (
                    <section el="column" mod="coaches">
                        <h2>Dit is jouw {(coaches?.length > 1) ? 'coaches' : 'coach'}</h2>
                        <div el="list">
                            {
                                coaches.map((coach, index) => (
                                    <div el="coach" key={index}>
                                        <Person
                                            {...coach}
                                            subtext={<a href={`mailto:${coach.email}`}>{coach.email}</a>}
                                            color="blue"
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                )}
            </section>
        </Bem>
    );
};

const FeedbackPageHeader = ({ printPath }) => {
    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        put({
            name: 'Studieloopbaancoaching',
            href: encodeURI(`/coaching`),
        }, 0);

        put({
            name: "Feedback",
            href: encodeURI(`/coaching/feedback`)
        }, 1);

        return () => {
            remove('Alle gespreksverslagen');
            remove("Feedback");
        };
    }, [remove, put]);

    return (
        <PageHeader title={"Studieloopbaancoaching"} />
    );
}

const StudentFeedbackOverview = () => {
    const { planning } = useEnvironmentContext();
    const { selectedYear, selectedPeriod } = useFiltersContext();

    const filterUrl = selectedPeriod?.blockId !== undefined ? `block/${selectedPeriod.blockId}/` : selectedYear?.yearId !== undefined ? `year/${selectedYear.yearId}/` :  ''
    const printPath = `feedback/excel/${filterUrl}`;

    return (
        <>
            <FeedbackPageHeader printPath={printPath} />
            <Feedback role={"student"} planning={planning} />
        </>
    );
}

const TeacherFeedbackOverview = ({ role }) => {
    /* * * * * * * * *
     * STUDENT INFO  *
    ** * * * * * * * */
    const { student, studentId, studentLoading, planning} = useCoachingTraineeContext();

    const rootPath = getCopy(`coaching.${role}.path`);
    const allReportsTitle = getCopy(`coaching.${role}.allReports.title`);

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        if (!studentLoading) {
            put({
                name: "Feedback",
                href: encodeURI(`${rootPath}/${studentId}/gespreksverslagen/feedback`),
            }, 3);
        }

        return () => {
            remove("Feedback");
        };
    }, [remove, put, allReportsTitle, studentId, rootPath, studentLoading]);

    return (
        <Bem style={styles}>
            <section el="student" mod="shaded">
                <div el="content">
                    <Person
                        { ...student }
                        large
                        className={styles.student__title}
                    />
                </div>
            </section>
            <Feedback role={role} planning={planning} />
        </Bem>
    );
}

export { StudentFeedbackOverview, TeacherFeedbackOverview };
