import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useBreadcrumbTrailContext } from '../../context';
import { getCopy, ENABLE_STUDYROUTE } from '../../utils';

import {
    StudentProgress,
    StudentProgressOverview,
    StudentProgressRoute,
    StudentProgressCourse,
    StudentProgressCompetences,
    StudentProgressCompetenceDetails,
    StudentProgressBPV,
} from '.';

import { PageHeader, ProgressOverviewHeaderContent } from '../../components';


const Progress = () => {
    const landingMatch = useRouteMatch('/voortgang');
    const overviewMatch = useRouteMatch('/voortgang/overzicht');
    const studieRouteMatch = useRouteMatch('/voortgang/studieroute');


    const scheduledCourseMatch = useRouteMatch('/voortgang/cursus/:course_id/:block_id');

    const onProgressLandingPage = landingMatch !== null && landingMatch.isExact;
    const onProgressOverviewPage = overviewMatch !== null;
    const onStudieRoutePage = studieRouteMatch !== null;
    const onScheduledCoursePage = scheduledCourseMatch !== null;

    const showLegend = onProgressOverviewPage || onStudieRoutePage;

    const headerPageTitle = onScheduledCoursePage ?
        getCopy('progress.student.pageTitle.scheduledCourse') :
        getCopy('progress.student.pageTitle.default');

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        const name = getCopy('progress.student.progress.title');
        if (!onProgressLandingPage) {
            const href = getCopy('progress.student.progress.path');
            put({ name, href }, 0);
        }

        return () => {
            remove(name);
        };
    }, [remove, put, onProgressLandingPage]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <>
            <PageHeader title={headerPageTitle} collapsed>
                {showLegend ? <ProgressOverviewHeaderContent studieRoute={onStudieRoutePage}/> : null}
            </PageHeader>
            <Switch>
                <Route path="/voortgang/overzicht" exact={true}>
                    <StudentProgressOverview />
                </Route>
                {ENABLE_STUDYROUTE ? (
                    <Route path="/voortgang/studieroute" exact={true}>
                        <StudentProgressRoute />
                    </Route>
                ) : null}
                <Route path="/voortgang/competenties" exact={true}>
                    <StudentProgressCompetences />
                </Route>
                <Route path="/voortgang/mijn-bpv" exact={true}>
                    <StudentProgressBPV />
                </Route>
                <Route path="/voortgang/competenties/:choice_id" exact={true}>
                    <StudentProgressCompetenceDetails />
                </Route>
                <Route path="/voortgang/cursus/:course_id/:block_id" exact={true}>
                    <StudentProgressCourse scheduled />
                </Route>
                <Route path="/voortgang/cursus/:group_course_id" exact={true}>
                    <StudentProgressCourse />
                </Route>
                <Route path="/voortgang" exact={true}>
                    <StudentProgress />
                </Route>
            </Switch>
        </>
    )
};

export default Progress;
