import * as animatedSvgs from './animated_svgs';

// import dots from './animated_svgs/dots.animated.svg';

const renamedAnimatedSvgs = Object.keys(animatedSvgs).reduce((acc, svgName) => {
    // const name = (svgName.substr(0, 1).toUpperCase() + svgName.substr(1)).replace(/animated$/i, '');
    const name = svgName.replace(/animated$/i, '');
    return {
        ...acc,
        [name]: animatedSvgs[svgName]
    }
}, {});

export default renamedAnimatedSvgs;
