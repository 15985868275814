import React, { useState, useCallback, useEffect } from 'react';
import Bem from 'react-better-bem';

import { useAppStateContext } from '../../context';

import styles from './Overlay.module.scss';

const Overlay = () => {

    const { overlay: { visible, transparent, disableScroll } } = useAppStateContext();

    const [hidden, setHidden] = useState(true);

    const hideOverlay = useCallback(() => {
        if (!visible) {
            setHidden(true);
        }
    }, [visible]);

    useEffect(() => {
        if (visible) {
            setHidden(false);
            if (disableScroll) {
                document.body.classList.add(styles['no-scroll']);
            }
        }
        return () => {
            document.body.classList.remove(styles['no-scroll']);
        };
    }, [visible, disableScroll]);

    return (
        <Bem style={styles}>
            <div
                el="overlay"
                mod={{ hidden, visible, transparent }}
                onAnimationEnd={hideOverlay}
            />
        </Bem>
    );
};

export default Overlay;
