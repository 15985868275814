import React, { useCallback } from 'react';
import Bem from 'react-better-bem';

import { useAppStateContext } from '../../context';
import { Icon, ScoreBadge } from '../';
import { getCopy } from '../../utils';

import styles from './ScoreLegend.module.scss';

const RATINGS  = [
    getCopy('rating.competenceScores[1].name'),
    getCopy('rating.competenceScores[2].name'),
    getCopy('rating.competenceScores[3].name'),
    getCopy('rating.competenceScores[4].name'),
    getCopy('rating.competenceScores.invalid.name'),
];

const STUDIE_ROUTES  = [
    {
        name: getCopy('progress.studieRoute.behaald.name'),
        title: getCopy('progress.studieRoute.behaald.title'),
    },
    {
        name: getCopy('progress.studieRoute.niet_behaald.name'),
        title: getCopy('progress.studieRoute.niet_behaald.title'),
    },
    {
        name: getCopy('progress.studieRoute.lock.name'),
        title: getCopy('progress.studieRoute.lock.title'),
    },
    {
        name: getCopy('progress.studieRoute.redo.name'),
        title: getCopy('progress.studieRoute.redo.title'),
    },
    {
        name: getCopy('progress.studieRoute.speed.name'),
        title: getCopy('progress.studieRoute.speed.title'),
    },
    {
        name: getCopy('progress.studieRoute.bpv.name'),
        title: getCopy('progress.studieRoute.bpv.title'),
    },
];


// const ScoreLegend = ({ expanded, includeRatingManual = false, bpv = false }) => {
const ScoreLegend = ({
    expanded,
    onChoiceMomentClick,
    includeRatingManual = false,
    bpv = false,
    choiceMoment = false,
    studieRoute = false
}) => {
    const LIST = !studieRoute ? RATINGS : STUDIE_ROUTES;

    const { modal: { open: openModal } } = useAppStateContext();
    const legendTitle =
        !bpv && !choiceMoment && !studieRoute ? 'Betekenis beoordelingsiconen' :
        (bpv && !choiceMoment && !studieRoute) || (!bpv && !choiceMoment && studieRoute) ? 'Legenda' :
        'Cursussen die niet ingeroosterd zijn'

    const legendModalTitle = !bpv && !studieRoute ? 'Betekenis beoordelingsiconen' : !bpv && studieRoute ? "Legenda" : 'BPV'
    const legendModalTitleIcon = !bpv ? 'question-mark-circle' : '';
    const legendIcon = choiceMoment ? "info-bubble" : "question-mark-circle";

    const openLegendModal = useCallback(() => {
        openModal(
            <>
                <LegendModal bpv={bpv} studieRoute={studieRoute} list={LIST}/>
                {includeRatingManual && <RatingManual />}
            </>,
            <>
                <Icon
                    type={legendModalTitleIcon}
                    inline
                    valign="middle"
                    className={styles['modal-title-icon']}
                />
                {legendModalTitle}
            </>,
            { small: !bpv ? true : false }
        )
    }, [openModal, bpv, studieRoute, LIST, includeRatingManual, legendModalTitleIcon, legendModalTitle]);

    if (expanded) {
        return <LegendModal expanded />;
    }

    return (
        <Bem style={styles}>
            <button el="button" onClick={() => {
                if (choiceMoment ) {
                    onChoiceMomentClick();
                }
                openLegendModal()
            }} mod={{ choiceMoment }}>
                <Icon
                    type={legendIcon}
                    valign="middle"
                    inline
                />
                <span el="text" mod={{ bpv }}>
                    {legendTitle}
                </span>
            </button>
        </Bem>
    );
};

export default ScoreLegend;

const LegendModal = ({ expanded = false, bpv, studieRoute, list }) => {

    return (
        <Bem style={styles}>
           { !bpv ? (
           <div el="legend" mod={{ expanded }}>
                {!expanded && !studieRoute ? (<h5 el="title">Legenda</h5>) : null }
                <ol el="list">
                    {list.map((name, index) => {
                        const value = !name.name && name === getCopy('rating.competenceScores.invalid.name') ? -1 :
                        index + 1;
                        return (
                            <li key={`legend-item-${name.name ? name.name : name}`} el="item">
                                <ScoreBadge
                                    value={value}
                                    size={studieRoute ? "small" : "large"}
                                    className={styles.legend__list__item__badge}
                                    studieRoute={studieRoute}
                                    name={name.name}
                                />
                                <span el="text" mod={{ "route" : studieRoute }}>
                                    {name.title ? name.title : name}
                                </span>
                            </li>
                        );
                    })}
                </ol>
            </div>) : (
                <div el="legend">
                    <span>Content nog niet beschikbaar</span>
                </div>
            )}
        </Bem>
    );
};

const RatingManual = () => {

    return (
        <Bem style={styles}>
            <div el="manual">
                <p el="text">
                    Je kan je toetsenbord gebruiken om snel te beoordelen. Navigeer met <kbd el="key">←</kbd>, <kbd el="key">→</kbd>, <kbd el="key">↑</kbd> en <kbd el="key">↓</kbd> (of <kbd el="key">w</kbd>, <kbd el="key">a</kbd>, <kbd el="key">s</kbd> en <kbd el="key">d</kbd>) en gebruik de cijfers om te beoordelen:
                </p>
                <ol el="list">
                    <li el="item"><kbd el="key">1</kbd> grijs ({getCopy('rating.competenceScores[1].name')})</li>
                    <li el="item"><kbd el="key">2</kbd> oranje ({getCopy('rating.competenceScores[2].name')})</li>
                    <li el="item"><kbd el="key">3</kbd> groen ({getCopy('rating.competenceScores[3].name')})</li>
                    <li el="item"><kbd el="key">4</kbd> blauw ({getCopy('rating.competenceScores[4].name')})</li>
                    <li el="item"><kbd el="key">0</kbd> {getCopy('rating.competenceScores[0].nameLong')}</li>
                    <li el="item"><kbd el="key">-</kbd> {getCopy('rating.competenceScores.invalid.name')}</li>
                </ol>
            </div>
        </Bem>
    );
};
