import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import Bem from 'react-better-bem';
import useOnClickOutside from 'use-onclickoutside';
import useKey from '@rooks/use-key';

import { useHiddenUIComponent } from '../../hooks';
import { useAppStateContext } from '../../context';

import { Button } from '../';

import styles from './Popover.module.scss';

const VALID_POSITIONS = ['center-right', 'below'];

const Popover = ({
    position = 'center-right',
    children,
    title,
    subtitle,
    onClose,
    attachedRef,
    buttons = [],
    bigPopover = false,
    mounted = false,
    choiceMoment = false,
    collapsed = false,
}) => {
    position = VALID_POSITIONS.includes(position) ? position : VALID_POSITIONS[0];

    const {
        overlay: { show: showOverlay, hide: hideOverlay },
        screen: { isSmallDesktop, isntSmallDesktop }
    } = useAppStateContext();

    const openPopover = useCallback(() => {
        showOverlay({
            transparent: isSmallDesktop,
            disableScroll: isntSmallDesktop,
        });
    }, [showOverlay, isntSmallDesktop, isSmallDesktop]);

    const closePopover = useCallback(() => {
        hideOverlay();
        if (typeof onClose === 'function') {
            onClose();
        }
    }, [onClose, hideOverlay]);

    const {
        visible,
        hidden,
        open,
        close,
        hide: hidePopover
    } = useHiddenUIComponent(openPopover, closePopover);

    useEffect(() => {
        if (choiceMoment || bigPopover) {
            hideOverlay();
        }
    }, [choiceMoment, hideOverlay, bigPopover]);

    const cssProps = useMemo(() => {
        if (!hidden && attachedRef?.current && isSmallDesktop) {
            const { left, top, width, height } = attachedRef.current.getBoundingClientRect();

            let leftOffset = 0;
            let topOffset = 0;

            switch (position) {
                case 'below':
                    topOffset = parseInt(top + height, 10);
                    leftOffset = parseInt(left, 0);
                    break;
                // case 'center-right':
                default:
                    topOffset = parseInt(top + height / 2, 10);
                    leftOffset = parseInt(left + width - 345, 10);
            }

            return {
                top: `${topOffset}px`,
                left: `${leftOffset}px`
            };
        }

        return {};
    }, [isSmallDesktop, attachedRef, position, hidden]);

    // fix transition delay
    const [actuallyVisible, setActuallyVisible] = useState(visible);
    useEffect(() => {
        setActuallyVisible(!hidden && visible);
    }, [visible, hidden]);

    const wasMounted = useRef();
    useEffect(() => {
        if (wasMounted.current !== mounted) {
            if (mounted) {
                open();
            } else {
                close();
            }
        }
        wasMounted.current = mounted;
    }, [mounted, open, close]);

    const popoverRef = useRef();
    useOnClickOutside(popoverRef, close);
    useKey(['Escape'], close);

    return (
        <Bem style={styles}>
            <div
                el="popover"
                mod={{
                    visible: actuallyVisible,
                    hidden,
                    attached: !!Object.keys(cssProps)?.length,
                    position,
                    collapsed,
                    'choice-moment': choiceMoment,
                    'big-popover': bigPopover
                }}
                ref={popoverRef}
                onTransitionEnd={hidePopover}
                style={cssProps}
            >
                <div el="content header" mod={{ 'no-color': !title }}>
                    <div el="text">
                        <span el="span"></span>
                        {title ? (<h3 el="title">{title}</h3>) : null}
                        {subtitle ? (<h4 el="subtitle">{subtitle}</h4>) : null }
                    </div>
                    <div el="buttons">
                        {buttons.map(({ icon, onClick }) => (
                            <Button
                                key={`popover-button-icon`}
                                icon={icon}
                                variant="mini"
                                color={title && !bigPopover ? 'black' : title && bigPopover ? "white" : undefined}
                                transparent
                                onClick={onClick}
                            />
                        ))}
                        <Button
                            icon="cross"
                            variant="mini"
                            color={title && !bigPopover ? 'black' : title && bigPopover ? "white" : undefined}
                            transparent
                            onClick={close}
                        />
                    </div>
                </div>
                <div el="content">
                    {children}
                </div>
            </div>
        </Bem>
    );
};

export default Popover;
