export const customSelectStyles  = {
    container: (base, state) => ({
        ...base,
        maxWidth: 'fit-content',
        maxHeight: 'fit-content'
    }),
    control: (base, state) => ({
        ...base,
        margin: '0',
        padding: '0 0.125rem 0 0.5rem',
        borderRadius: '0.375rem',
        gap: '0.5rem',
        minWidth: '3rem',
        border: (state.isDisabled) ? '2px solid var(--c-button-default-disabled-border)' : ((state.isFocused) ? '2px solid var(--c-input-focus-border)' : '2px solid var(--c-input-default-border)'),
        backgroundColor: (state.isDisabled) ? 'var(--c-input-disabled-background)' : (state.isFocused) ? 'var(--c-input-focus-background)' : 'var(--c-input-default-background)',
        outline: (state.isFocused) ? '0' : '',
        color: (state.isDisabled) ? 'var(--c-input-disabled-text)' : (state.isFocused) ? 'var(--c-input-focus-text)' : 'var(--c-input-default-text)',
        boxShadow: (state.isFocused) ? 'var(--c-input-focus-border)' : 'var(--c-input-default-border)',
        fontSize: '0.875rem',
        minHeight: 'fit-content',
        '&:hover': {
            cursor: 'pointer',
            border: '2px solid var(--c-input-focus-border)',
        },
    }),
    singleValue: (base) => ({
        ...base,
        padding: '0',
        margin: '0',
        minWidth: '1rem',
        lineHeight: '1rem',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0',
        margin: '0'
    }),
    indicatorsContainer: (base) => ({
        ...base
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        padding: '0',
        margin: '0',
        color: (state.isDisabled) ? '2px solid var(--c-input-disabled-border)' : 'var(--c-input-focus-border)',
        '&:hover': {
            color: 'var(--c-input-focus-border)',
        }
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    placeholder: (base) => ({
      ...base,
        display: 'none'
    }),
    menu: (base) => ({
        ...base,
        borderRadius: '0.375rem',
        zIndex: '1201',
    }),
    menuList: (base) => ({
        ...base,
        padding: '0',
        margin: '0',
        border: 'none',
        "& :first-of-type": {
            borderTopLeftRadius: '0.375rem',
            borderTopRightRadius: '0.375rem',
        },
        "& :last-of-type": {
            borderBottomLeftRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
            borderBottom: 'none',
        }
    }),
    option: (base, state) => ({
        ...base,
        borderBottom: '1px solid var(--c-input-default-border)',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        backgroundColor: (state.isSelected ) ? 'var(--c-input-focus-border)' : 'var(--c-input-default-background)',
        '&:hover': {
            backgroundColor: 'var(--c-input-focus-border)',
            color: 'var(--c-button-default-active-background)',
            cursor: 'pointer'
        }
    }),
}
