import { useState, useEffect, useCallback } from 'react';

const STORE_NAME_PREFIX = process.env.REACT_APP_STORAGE_STORE_NAME;

function getLocalStorageOrDefault(key, defaultValue, noDefault) {
    const stored = localStorage.getItem(key);
    if (!stored && !noDefault) {
        return defaultValue;
    }
    return JSON.parse(stored);
}

function useLocalStorage(key, defaultValue, options = {}) {
    const  {
        // set ls value to defaultValue,
        // even if ls already has value
        reset = false,
        // don't use STORE_NAME_PREFIX in storage key
        noPrefix = false,
        // don't set a default value
        // (defaultValue is completely ignored)
        noDefault = false,
    } = options;

    const storageKey = noPrefix ? key : STORE_NAME_PREFIX + key;

    const [value, _setValue] = useState(
        reset && !noDefault ?
            defaultValue :
            getLocalStorageOrDefault(storageKey, defaultValue, noDefault)
    );

    const setLocalStorageValue = useCallback((value) => {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }, [storageKey]);

    const setValue = useCallback((value, direct = false) => {
        _setValue(value);
        if (direct) {
            setLocalStorageValue(value);
        }
    }, [setLocalStorageValue]);

    useEffect(() => {
        setLocalStorageValue(value);
    }, [setLocalStorageValue, value]);

    const clearValue = useCallback(() => {
        localStorage.removeItem(storageKey);
    }, [storageKey]);

    return [value, setValue, clearValue];
}

export default useLocalStorage;
