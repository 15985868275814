import React, { useRef, useEffect, useState } from 'react';
import Bem from 'react-better-bem';
import moment from 'moment';
import 'moment/locale/nl';
import { useParams } from 'react-router-dom';

import { useEnvironmentContext } from '../../../../../context';
import { Badge, Icon, GroupName } from '../../../../';

import { getCopy } from '../../../../../utils';

import styles from './Event.module.scss';

const Event = ({ event }) => {
    const {
        id,
        title,
        type,
        start,
        end,
        previewCourses,
        groupCourses,
        teacher,
        building,
        room,
    } = event;

    const { environment } = useEnvironmentContext();

    const from = moment(start).format('LT');
    const to = moment(end).format('LT');

    // scroll event into view, if it's the active event on page load
    const eventRef = useRef();
    const { eventId: selectedEventId } = useParams();

    const [shouldScrollIntoView, setShouldScrollIntoView] = useState(id === selectedEventId);
    useEffect(() => {
        if (shouldScrollIntoView) {
            eventRef.current.scrollIntoView(false);
            setShouldScrollIntoView(false);
        }
    }, [shouldScrollIntoView]);

    // render options
    const durationHours = moment(end).diff(moment(start), 'hours', true);

    const showTeacher = environment === 'STUDENT' && teacher?.length;
    const showGroupBadges = environment === 'TEACHER' && groupCourses.length;
    const showLocationRoom = room !== null;
    const showLocationBuilding = building !== null;
    const showLocation = showLocationRoom || showLocationBuilding;

    const eventTypeIcon = getCopy(`calendar.type.${type}.icon`);

    // resize lib parent event to have a minimum width (for overlapping events)
    useEffect(() => {
        const libEvent = eventRef.current?.parentNode?.parentNode;
        if (libEvent) {
            const left = libEvent.style.left;

            libEvent.style.width = !['0%', 'auto'].includes(left) ? '85%' : '100%';
            libEvent.style.left = 'auto';
            libEvent.style.right = !['0%', 'auto'].includes(left) ? 0 : 'auto';
        }
    }, [start, end]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div
                el="event"
                mod={{
                    'over-30': durationHours >= 0.5,
                    'over-45': durationHours >= 0.75,
                    'over-60': durationHours >= 1,
                    'over-75': durationHours >= 1.25,
                    'over-90': durationHours >= 1.5,
                    'over-120': durationHours >= 2,
                    env: environment.toLowerCase(),
                }}
                data-event-id={id}
                ref={eventRef}
            >
                <div el="content">
                    <div el="line" mod="first">
                        <span el="entry title">
                            <Icon
                                type={eventTypeIcon}
                                size="small"
                                color="calendar-event-text"
                                inline
                                valign="middle"
                            />
                            <span el="text">
                                {title}
                            </span>
                        </span>
                        <span el="entry time">
                            {from} - {to}
                        </span>
                    </div>
                    <div el="line" mod="second">
                        {showLocation ? (
                            <span el="entry location">
                                {showLocationRoom ? (
                                    <Badge
                                        background="calendar-event-badge-background"
                                        text="calendar-event-badge-text"
                                        size="small"
                                        className={`${styles.event__content__location__badge} ${styles['event__content__location__badge--small']}`}
                                    >
                                        {room}
                                    </Badge>
                                ) : null}
                                {showLocationBuilding ? (
                                    <span el="building">{building}</span>
                                ) : null}
                            </span>
                        ) : null}

                        {showTeacher ? (
                            <span el="entry teacher">
                                <Icon
                                    type="teacher"
                                    size="extra-small"
                                    color="calendar-event-text"
                                    valign="middle"
                                    inline
                                />
                                <span el="name">
                                    {teacher}
                                </span>
                            </span>
                        ) : null}

                        {showGroupBadges ? (
                            <span el="entry groups">
                                {previewCourses.map(({ id, group_code }) => (
                                    <Badge
                                        key={`group-${id}`}
                                        className={styles.event__content__entry__badge}
                                    >
                                        <GroupName name={group_code} inheritcolor />
                                    </Badge>
                                ))}
                                {(groupCourses?.length > previewCourses?.length) ? (
                                    <Badge key="group-courses-additional" className={styles.event__content__entry__badge}>
                                        <GroupName name={`+${groupCourses?.length - previewCourses?.length}`} inheritcolor />
                                    </Badge>
                                ) : ``}
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>
        </Bem>
    );
}

export default Event;
