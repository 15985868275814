import { useState, useCallback, useReducer, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";

import useApi from "../useApi";
import { useEnvironmentContext } from "../../context";

function useProgressCoachGroupApi() {
    const { planning: { currentYear, upcomingYear, latestYear } } = useEnvironmentContext();
    const defaultYear = currentYear || upcomingYear || latestYear;

    const [selectedYear, setSelectedYear] = useState({});
    const [selectedBlock, setSelectedBlock] = useState({});

    /* * * * * * * * * * * * * * * * * * * * * * * * * *
     * GET COACH GROUP PROGRESS OVERVIEW               *
    ** * * * * * * * * * * * * * * * * * * * * * * * * */
    const { group_code: groupCode } = useParams();

    let apiPath = `/coaching/progress/${groupCode}`;
    apiPath = (selectedBlock?.blockId) ? `${apiPath }/block/${selectedBlock.blockId}` : `${apiPath }/year/${selectedYear?.yearId}`

    const { get, loading, value: _students, fetched } = useApi('Voortgangsoverzicht groep', apiPath, { initialValue: [] });
    const { get: getBlocks, loading: blocksLoading, value: blocks, fetched: blocksFetched } = useApi('Groep blokken', `/coaching/group/${groupCode}/blocks`, { initialValue: [] });

    const [students, dispatchStudents] = useReducer(studentReducer, []);

    /* * * * * * * * * * * *
     * FETCH AND DISPATCH  *
    ** * * * * * * * * * * */
    const fetchWhen = !fetched && !loading && !!(selectedYear?.yearId && selectedBlock?.blockId);
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [fetchWhen, get]);

    const fetchBlocksWhen = !blocksFetched && !blocksLoading;
    useEffect(() => {
        if (fetchBlocksWhen) {
            getBlocks();
        }
    }, [fetchBlocksWhen, getBlocks]);

    useEffect(() => {
        const year = blocks.find(({ school_year }) => {
            return school_year === defaultYear;
        });

        const { school_year: yearLabel, school_year_id: yearId } = year || {};

        if (yearLabel && yearId) {
            setSelectedYear({ yearLabel, yearId });
        }
    }, [defaultYear, blocks])

    useEffect(() => {
        if (selectedYear?.yearId || selectedBlock?.blockId) {
            get();
        }
    }, [selectedYear?.yearId, selectedBlock?.blockId, get]);

    const [allYears, allBlocks] = useMemo(() => {
        if (!blocks.length) {
            return [[], []];
        }

        const [_allYears, _allBlocks] = blocks.reduce(([ys, ps], { school_year: _y, school_year_id: _yId, number, period: _p, id: _pId }) => {
            const yearIndex = ys.findIndex(({ yearId }) => yearId === _yId);
            const blockIndex = ps.findIndex(({ blockId }) => blockId === _pId);

            if (yearIndex <= -1) {
                ys.push({
                    yearLabel: _y,
                    yearId: _yId
                })
            }

            if (blockIndex <= -1) {
                ps.push({
                    number,
                    period: _p,
                    blockId: _pId,
                    year: _y
                })
            }

            return [ys, ps];
        }, [[], []]);

        if (!selectedYear?.yearId && _allYears?.length) {
            setSelectedYear(_allYears?.[_allYears?.length - 1])
        }

        return [_allYears, _allBlocks];
    }, [blocks, selectedYear?.yearId]);

    const dispatchWhen = fetched && !loading;
    useEffect(() => {
        if (dispatchWhen) {
            dispatchStudents({
                type: 'INIT',
                students: _students,
            });
        }
    }, [dispatchWhen, _students?.length, _students]);

    /* * * * * * * * * *
     * EXPOSED METHODS *
    ** * * * * * * * * */
    // update current student (responsive panel)
    const setCurrentStudent = useCallback((studentId) => {
        dispatchStudents({
            type: 'SET_CURRENT_STUDENT',
            studentId
        });
    }, [])

    const unsetCurrentStudent = useCallback(() => {
        dispatchStudents({
            type: 'UNSET_CURRENT_STUDENT'
        });
    }, []);

    // sort list
    const [sortDirection, setSortDirection] = useState('ASC');
    const [sortProperty, setSortProperty] = useState('last_name')

    const sortStudents = useCallback((direction = 'ASC', property = 'last_name') => {
        setSortDirection(direction);
        setSortProperty(property);
        dispatchStudents({ type: 'SORT', direction, property });
    }, []);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        groupCode,

        students,
        sortStudents,
        sortDirection,
        sortProperty,

        setCurrentStudent,
        unsetCurrentStudent,

        allYears,
        allBlocks,

        selectedYear,
        setSelectedYear,
        selectedBlock,
        setSelectedBlock,

        loading,
        fetched
    };
}

function studentReducer (state, action) {
    const { type } = action;

    switch (type) {
        case 'INIT': {
            const { students } = action;

            const remappedStudents = students.map((student) => {
                return {
                    ...student,
                    current: false, // <- state for showing student in panel in responsive designs
                    visible: true, // <- state for search/filter
                };
            }).sort(({ last_name: a }, { last_name: b }) => a < b ? -1 : b < a ? 1 : 0);
            return remappedStudents;
        }
        case 'SORT': {
            const { direction = 'ASC', property = 'last_name' } = action;

            state.sort(({ [property]: a }, { [property]: b }) => {
                if (direction === 'ASC') {
                    return a < b ? -1 : b < a ? 1 : 0;
                }
                return a < b ? 1 : b < a ? -1 : 0;
            });

            return [ ...state ];
        }
        case 'SET_CURRENT_STUDENT': {
            const { studentId } = action;

            return state.map((student) => {
                const { flexx_id } = student;
                return {
                    ...student,
                    current: flexx_id === studentId,
                };
            });
        }
        case 'UNSET_CURRENT_STUDENT': {
            return state.map((student) => ({
                ...student,
                current: false,
            }));
        }
        default: {
            return state;
        }
    }
}

export default useProgressCoachGroupApi;
