import React, { useMemo, memo } from "react";
import Bem from "react-better-bem";

import {
    BPVProgress,
    BPVPageHeader,
    InternshipOverview as Overview,
    NoInternship,
} from "../";

import { LoadingDots } from "../../";

import { useEnvironmentContext } from "../../../context";

import styles from "./OverviewPage.module.scss";

const ROLE = "TEACHER";
const DEFAULT_TOTAL_HOURS = 0;

const OverviewPage = memo(({ showHeader = true, api, studentName }) => {
    const { environment } = useEnvironmentContext();
    const teacher = environment === ROLE;

    const {
        internships,
        uploadFile,
        _bpv,
        bpvLinks,
        deleteFileById,
        fetched,
    } = api;

    const { summary, progress } =
        useMemo(() => {
            const _internship = internships?.[0];

            const totalHours = (_internship && _internship.bpvTotalHours) || DEFAULT_TOTAL_HOURS;

            const accumulatedHours =
                internships &&
                internships.reduce((acc, { completedHours, bpvPhase }) => {
                    return acc + completedHours;
                }, 0);

            const remainingHours = totalHours - accumulatedHours;

            const total = {
                hours: totalHours,
                title: "Totaal te halen uren",
                iconName: "afstuderen",
                tag: "total",
            };

            const accumulated = {
                hours: accumulatedHours,
                title: "Goedgekeurde uren",
                iconName: "behaald",
                tag: "accumulated",
            };

            const remaining = {
                hours: remainingHours <= 0 ? 0 : remainingHours,
                title: "Resterende uren",
                iconName: "voltooid_chart",
                tag: "remaining",
            };

            const _summary = [accumulated, remaining, total];

            const _progress = Math.round(
                (accumulatedHours / totalHours) * 100
            );

            return {
                summary: _summary,
                progress: _progress > 100 ? 100 : _progress,
                _totalHours: totalHours,
                _accumulatedHours: accumulatedHours,
            };
        }, [internships]);

    if (!fetched) {
        return (
            <Bem style={styles}>
                <section el="empty">
                    <LoadingDots amount={4} />
                </section>
            </Bem>
        );
    }

    return (
        <Bem style={styles}>
            <section el="container">
                {showHeader ? <BPVPageHeader /> : null}
                {(internships?.length > 0) ? <BPVProgress progress={progress} /> : null}
                {(internships?.length > 0) ? (
                    <Overview
                        internships={internships}
                        uploadFile={uploadFile}
                        summary={summary}
                        deleteFile={deleteFileById}
                        studentRole={!teacher}
                        bpvLinks={bpvLinks}
                    />
                ) : (
                    <NoInternship
                        completed={_bpv}
                        studentName={studentName}
                        isStudent={!teacher}
                        bpvLinks={bpvLinks}
                    />
                )}
            </section>
        </Bem>
    );
});

export default OverviewPage;
