import React from 'react';
import Bem from 'react-better-bem';


import { Label, FormElementNotification, Icon } from '../';

import styles from './Input.module.scss';

const Input = React.forwardRef(({
    label,
    id,
    warning,
    notification,
    options = [],
    small = false,
    upload = false,
    transparent = false,
    icon = false,
    noNotification = false,
    type = 'text',
    select = false,
    ...props
}, ref) => {

    return (
        <Bem style={styles}>
            <div el="wrapper" mod={{ small, upload }}>
                {label && <Label htmlFor={id} >{label}</Label>}
                    <input
                        {...props}
                        el="input"
                        mod={{ small, transparent }}
                        type={type}
                        ref={ref}
                        id={id}
                    />
                {icon && <Icon size={small ? 'small' : 'default'} type={icon} className={styles.wrapper__icon} />}
            </div>
            {noNotification ? null : (
                <FormElementNotification
                    message={warning || notification}
                    type={warning?.length ? 'warning' : 'notification'}
                    transparent={transparent}
                />
            )}
        </Bem>
    );
});

export default Input;
