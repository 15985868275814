import React, { useCallback } from 'react'
import Bem from 'react-better-bem';
import { Icon, IconButton, Button } from "../..";

import { useEnvironmentContext } from '../../../context';

import styles from './File.module.scss'

const File = ({ file, deleteFile }) => {
    const { fileName, id, downloadLink, dateUploaded } = file;

    const { environment } = useEnvironmentContext();
    const teacher = environment === 'TEACHER';

    const handleFileDeletion = useCallback((id) => {
        deleteFile(id);

    }, [deleteFile]);

    return (
        <Bem style={styles}>
            <li
                el="list-item"
                key={`list-item-${fileName}-${id}`}
                mod="files"
            >
                <div el="left">
                    <Icon
                        type="paperclip"
                        color="blue"
                    />
                    <div el="available-files">
                        <a
                            href={downloadLink}
                            el="text"
                            download={fileName}
                        >
                            {fileName}
                        </a>
                        <span el="date">{dateUploaded}</span>
                    </div>
                </div>
                <div el="button-group">
                    {!teacher ? (<IconButton
                        icon="trash"
                        color="transparent"
                        onClick={() => handleFileDeletion(id)}
                        disabled={teacher}
                    /> ) : null }
                    <Button
                        href={downloadLink}
                        icon="upload-2"
                        color={teacher ? "blue" : "red"}
                        className={styles["list-item--button-download"]}
                        download={fileName}
                    />
                </div>
            </li>
        </Bem>
    )
}

export default File
