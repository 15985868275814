import React, { useMemo, useCallback } from 'react';
import isHexcolor from 'is-hexcolor';
import Bem from 'react-better-bem';

import { useEnvironmentContext } from '../../context';
import { getColor } from '../../utils';

import styles from './Badge.module.scss';

const Badge = ({ children, background = false, text = false, invert = false, size = 'default', ...props }) => {

    const { environment } = useEnvironmentContext();

    const getBadgeColor = useCallback((color) => (
        !color ? false : isHexcolor(color) ? color : getColor(color, environment)
    ), [environment]);

    const cssProps = useMemo(() => {
        const backgroundColor = getBadgeColor(background);
        const color = getBadgeColor(text);

        return {
            ...(backgroundColor && { backgroundColor }),
            ...(color && { color }),
        };
    }, [background, text, getBadgeColor]);

    return (
        <Bem style={styles}>
            <span
                {...props}
                el="badge"
                mod={{ size, invert }}
                style={cssProps}
            >
                {children}
            </span>
        </Bem>
    );
};

export default Badge;
