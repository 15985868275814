import React from 'react';
import Bem from 'react-better-bem';

import { useLayout, usePageTitle } from '../../hooks';
import { LoadingDots, Logo } from '../../components'

import styles from './Loading.module.scss';

const Loading = () => {
    usePageTitle();
    useLayout({ stretch: true, collapse: 'both', stretchContent: true, margin: false });

    return (
        <Bem style={styles}>
            <div el="page">
                <div el="background-image" />
                <div el="content">
                    <Logo color="white" size="large" className={styles.page__content__logo} />
                    <h1 el="title"><LoadingDots/></h1>
                </div>
            </div>
        </Bem>
    );
};

export default Loading;
