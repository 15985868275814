import React, { useCallback, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import useKey from '@rooks/use-key';
import Bem from 'react-better-bem';

import { useHiddenUIComponent } from '../../hooks';
import { ScoreBadge } from '../../components';
import { getCopy } from '../../utils';

import styles from './ActionWheel.module.scss';

const WHEEL_VALUES = [
    [-1, getCopy('rating.competenceScores.invalid.name')],
    [1, getCopy('rating.competenceScores[1].name')],
    [2, getCopy('rating.competenceScores[2].name')],
    [3, getCopy('rating.competenceScores[3].name')],
    [4, getCopy('rating.competenceScores[4].name')],
];


const ActionWheel = ({
    title = 'Selecteer',
    constrain = false,
    value = 0,
    setValue = () => void 0,
    badgeSize = 'small',
    ...props
}) => {
    const {
        visible: actionWheelIsVisible,
        hidden,
        open: openActionWheel,
        close: closeActionWheel,
        hide: hideActionWheel
    } = useHiddenUIComponent();

    const buttonRef = useRef();
    const wheelRef = useRef();
    useOnClickOutside(wheelRef, closeActionWheel);

    const handleKbScore = useCallback(({ key }) => {
        const hasFocus = document.activeElement === buttonRef?.current;
        if ((!constrain || parseInt(key, 10) > 0) && (hasFocus || actionWheelIsVisible)) {
            setValue(key === '-' ? -1 : parseInt(key, 10));
            buttonRef.current.focus();
            closeActionWheel();
        }
    }, [actionWheelIsVisible, constrain, setValue, closeActionWheel]);

    const handleKbClose = useCallback(() => {
        if (actionWheelIsVisible) {
            buttonRef.current.focus();
            closeActionWheel();
        }
    }, [actionWheelIsVisible, closeActionWheel]);

    useKey(['0', '1', '2', '3', '4', '-'], handleKbScore);
    useKey(['Escape'], handleKbClose);

    // render
    return (
        <Bem style={styles}>
            <div el="wrapper">
                <button
                    {...props}
                    ref={buttonRef}
                    el="button"
                    mod={{ size: badgeSize }}
                    onClick={openActionWheel}
                >
                    <ScoreBadge
                        value={value}
                        size={badgeSize}
                        noAlt
                    />
                </button>
                <div
                    el="wheel"
                    mod={{ hidden, visible: actionWheelIsVisible }}
                    ref={wheelRef}
                    onAnimationEnd={hideActionWheel}
                >
                    <span el="title">{title}</span>
                    <button
                        el="button"
                        mod="middle"
                        disabled={constrain}
                        onClick={() => {
                            setValue(0);
                            closeActionWheel();
                        }}
                        title={getCopy('rating.competenceScores[0].name')}
                        tabIndex={-1}
                    >
                        <ScoreBadge value={0} noAlt size="medium" />
                    </button>
                    {WHEEL_VALUES.map(([val, name], i) => {
                        // they want to be able to assign this so I just comment it out for now
                        // const disabled = val === -1 && !constrain;

                        return (
                            <button
                                key={`actionwheel-button-${val}`}
                                el="button"
                                mod={{ 'badge-pos': i }}
                                // disabled={disabled}
                                onClick={() => {
                                    setValue(val);
                                    closeActionWheel();
                                }}
                                title={name}
                                tabIndex={-1}
                            >
                                <ScoreBadge value={val} noAlt size="medium" />
                            </button>
                        );
                    })}
                </div>
            </div>
        </Bem>
    );
};

export default ActionWheel;
