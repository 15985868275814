import React from 'react';

import { ScoreLegend, PrintButton } from '../../';

import styles from './OverviewHeaderContent.module.scss';

const OverviewHeaderContent = ({ studentId, studieRoute, showLegend = true }) => {

    return (
        <div className={styles.content}>
            { showLegend ? (<ScoreLegend
                studieRoute={studieRoute}
            />) : null }
           { !studieRoute ? (<PrintButton
                path={(studentId) ? `results/${studentId}/pdf` : `results/pdf`}
                className={styles.content__printbutton}
            />) : null }
        </div>
    );
};

export default OverviewHeaderContent;
