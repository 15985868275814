import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../../';
import { getCopy } from '../../../utils';

import styles from './ChoicesButton.module.scss';

const ChoicesButton = ({ to = '/', km1Status }) => {

    const disabled = km1Status === 'closed';

    if (disabled) {
        return (
            <button
                className={`${styles.button} ${styles['button--disabled']}`}
                disabled
            >
                <Icon
                    type="reading-owl"
                    color="white"
                    className={styles.button__icon}
                />
            </button>
        );
    }

    const buttonClassName = [styles.button];
    const textClassName = [styles.button__text];

    if (km1Status === 'open_with_action') {
        buttonClassName.push(styles['button--requires-actions']);
        textClassName.push(styles['button__text--requires-actions']);
    }

    const iconColor = km1Status === 'open' ? 'blue-darkest' : 'white';

    const text = getCopy('coursePlanner.firstMoment.coach.pageTitle');

    return (
        <Link
            to={to}
            className={buttonClassName.join(' ')}
        >
            <Icon
                type="reading-owl"
                color={iconColor}
                className={styles.button__icon}
            />
            <span className={textClassName.join(' ')}>
                {text}
            </span>
            <Icon
                type="chevron-right"
                color={iconColor}
                className={styles.button__arrow}
            />
        </Link>
    );
};

export default ChoicesButton;
