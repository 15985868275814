import React, { useRef, useCallback, memo } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import useKey from '@rooks/use-key';
import Bem from 'react-better-bem';

import { useAppStateContext } from '../../context';
import { Button, Icon } from '../';

import styles from './Modal.module.scss';

const Modal = () => {
    const {
        modal: {
             content,
             title,
             visible,
             hidden,
             close,
             hide,
             small,
             onClose,
             noPaddingBottom,
             modalPosition,
             altTitle,
        } } = useAppStateContext();

    const actualClose = useCallback(() => {
        if (typeof onClose === 'function') {
            onClose();
        }
        close();
    }, [onClose, close]);

    const modalRef = useRef();
    useOnClickOutside(modalRef, actualClose);
    useKey(['Escape'], actualClose);

    return (
        <Bem style={styles}>
            <div
                el="modal"
                mod={{ visible, hidden, small, noPadBot: noPaddingBottom, modalPosition }}
                ref={modalRef}
                onAnimationEnd={hide}
            >
                <div el="content">
                    <header el="header">
                        {altTitle ? (<div el="upload">
                            <Icon type="upload-2" color="black" size="medium"/>
                            <h3 el="title">{title || <span>&nbsp;</span>}</h3>
                        </div>) : null }
                        {!altTitle ? (<h3 el="title">{title || <span>&nbsp;</span>}</h3>) : null}
                        <Button
                            variant="mini"
                            transparent
                            icon="cross"
                            color="modal-title"
                            iconSize="small"
                            tabIndex={-1}
                            onClick={actualClose}
                            className={styles['modal__content__header__close-button']}
                        />
                    </header>
                    <main el="main">
                        {content}
                    </main>
                </div>
            </div>
        </Bem>
    );
};

const ModalButtons = ({ buttons = [] }) => {
    const { screen: { isTablet } } = useAppStateContext();

    if (!buttons || !buttons.length) {
        return null;
    }

    return (
        <Bem style={styles}>
            <div el="modal-buttons">
                {buttons.map(({ content, ...props }, index) => (
                    <Button
                        key={`modal-button-${index}`}
                        variant={isTablet ? 'default' : 'small'}
                        className={styles['modal-buttons__button']}
                        {...props}
                    >
                        {content}
                    </Button>
                ))}
            </div>
        </Bem>
    );
}

export { ModalButtons };
export default memo(Modal);
