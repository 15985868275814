import React from 'react';
import Bem from 'react-better-bem';

import { useAppStateContext } from '../../context';

import styles from './NavButton.module.scss';

const NavButton = () => {
    const { nav: { opened, toggle } } = useAppStateContext();

    return (
        <Bem style={styles}>
            <button
                el="button"
                mod={{ open: opened }}
                onClick={toggle}
            >
                <span el="line" mod={['top', { 'open': opened, 'top--open': opened }]} />
                <span el="line" mod={['middle', { 'open': opened, 'middle--open': opened }]} />
                <span el="line" mod={['bottom', { 'open': opened, 'bottom--open': opened }]} />
            </button>
        </Bem>
    );
};

export default NavButton;
