import { useState, useCallback } from 'react';

import { NotLoggedInRedirect } from '../components';

import { useAppStateContext, useEnvironmentContext } from '../context';

function useAuthentication () {

    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const { notifications: { error } } = useAppStateContext();
    const { clearUserInformation } = useEnvironmentContext();

    const authenticate = useCallback(() => {
        setIsAuthenticated(true);
    }, []);

    const revoke = useCallback((redirect) => {
        if (redirect) {
            error(<NotLoggedInRedirect/>, { title: 'Niet ingelogd!', noDismiss: true });
        }
        clearUserInformation();
        setIsAuthenticated(false);
    }, [error, clearUserInformation]);

    return {
        isAuthenticated,
        authenticate,
        revoke
    };
}

export default useAuthentication;
