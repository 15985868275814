import React, { useMemo } from 'react';
import Bem from 'react-better-bem';

import { useEnvironmentContext } from '../../context';

import styles from './Logo.module.scss';

import logos from '../../assets/logo';

const defaultColors = {
    header: {
        'TEACHER': 'white',
        'STUDENT': 'red',
        'ADMIN': 'white',
        'NON_AUTH': 'white'
    }
};

const VALID_COLORS = Object.keys(logos);
const VALID_TYPES = Object.keys(defaultColors);
const VALID_SIZES = ['default', 'small', 'large'];

const Logo = ({ color, type = 'header', size, ...props }) => {
    const { environment } = useEnvironmentContext();

    size = VALID_SIZES.includes(size) ? size : VALID_SIZES[0];

    const logoSrc = useMemo(() => {
        if (color !== undefined && VALID_COLORS.includes(color)) {
            return logos[color];
        }

        if (VALID_TYPES.includes(type)) {
            return logos[defaultColors[type][environment]];
        }

        return logos[VALID_COLORS[0]];
    }, [color, type, environment]);

    return (
        <Bem style={styles}>
            <img el="logo" mod={{ size }} alt="Flexx" src={logoSrc} {...props} />
        </Bem>
    );
};

export default Logo;
