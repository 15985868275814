import React from 'react';
import Bem from 'react-better-bem';

import {
    Button,
    TileContainer,
    WarningBar,
    ProgressBar,
    CourseTile,
    ChoicesEmptyBox,
    LoadingDots,
    ProgressImage,
} from '../../';
import { getCopy } from '../../../utils';

import graphics from '../../../assets/graphics';
import styles from './Bag.module.scss';

const Bag = ({
    role,
    block = {},
    student = {},
    awaitingChoices = false,
    awaitingResults = false,
    studentChoices = [],
    coachChoices = [],
    loading,
    toggleMode,
    approve,
    decline,
    openInfoModal,
}) => {

    // get right timing, depending on role
    const {
        student: {
            km1: {
                readable: timeRemainingReadableStudent,
                fraction: timeRemainingFractionStudent,
            } = {}
        } = {},
        coach: {
            km1: {
                readable: timeRemainingReadableCoach,
                fraction: timeRemainingFractionCoach,
            } = {}
        } = {}
    } = block;

    const timeRemainingReadable = (role === 'student' && awaitingResults) || (role === 'coach' && !awaitingChoices) ?
        timeRemainingReadableCoach :
        timeRemainingReadableStudent;

    const timeRemainingFraction = (role === 'student' && awaitingResults) || (role === 'coach' && !awaitingChoices) ?
        timeRemainingFractionCoach :
        timeRemainingFractionStudent;

    const hasChoices = studentChoices.length || coachChoices.length;
    const hasOwnChoices = (role === 'student' && studentChoices.length > 0) || (role === 'coach' && coachChoices.length > 0);

    /* * * * *
     * COPY  *
    ** * * * */
    const copyBagKey = (role === 'student' && awaitingResults) || (role === 'coach' && awaitingChoices) ? 'bagAwaiting' : 'bag';

    const buttonText = getCopy(hasOwnChoices ?
        `coursePlanner.firstMoment.${role}.bag.button.default` :
        `coursePlanner.firstMoment.${role}.bag.button.empty`
    );

    const title = timeRemainingReadable ? getCopy(
        `coursePlanner.firstMoment.${role}.${copyBagKey}.title`,
        { timeRemaining: timeRemainingReadable }
    ) : <LoadingDots />;

    const warningBarText = getCopy(
        `coursePlanner.firstMoment.${role}.${copyBagKey}.warning`,
        { timeRemaining: timeRemainingReadable }
    );

    const studentChoicesListTitle = getCopy(
        `coursePlanner.firstMoment.${role}.${copyBagKey}.list.studentTitle`,
        { student: student?.first_name }
    );

    const coachChoicesListTitle = getCopy(
        `coursePlanner.firstMoment.${role}.${copyBagKey}.list.coachTitle`,
        { student: student?.first_name }
    );

    const emptyBoxText = getCopy(
        `coursePlanner.firstMoment.${role}.${copyBagKey}.list.empty`,
        { student: student?.first_name }
    );


    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="bag" mod={{ role }}>
                <div el="content" mod={{ collapse: hasChoices }}>
                    <div el="header">
                        <div el="graphic">
                            {awaitingResults ? (
                                <ProgressImage
                                    status="Voldaan"
                                    className={`${styles['bag__content__header__graphic__img']} ${styles['bag__content__header__graphic__img--awaiting']}`}
                                />
                            ) : (
                                <img
                                    src={graphics.deadlineclock}
                                    el="img"
                                    alt=""
                                />
                            )}
                        </div>
                        <div el="text">
                            <strong el="remaining">{title}</strong>
                        </div>
                    </div>
                    <div el="button">
                        {(awaitingResults || awaitingChoices) ? null : (
                            <Button
                                block
                                variant="inverted"
                                className={styles.bag__content__button__button}
                                onClick={toggleMode}
                            >
                                {buttonText}
                            </Button>
                        )}
                    </div>
                    <div el="courses">
                        {loading || hasChoices ? null : (
                            <ChoicesEmptyBox>
                                {emptyBoxText}
                            </ChoicesEmptyBox>
                        )}
                        {!loading && studentChoices.length ? (
                            <TileContainer title={studentChoicesListTitle} scroll>
                                {studentChoices.map((choice) => {
                                    const {
                                        id,
                                        is_bpv,
                                        is_special: isMasterClass,
                                        scheduled_course: {
                                            course,
                                            routeStatus,
                                            _bpvPhase,
                                        },
                                        request: {
                                            is_approved,
                                        },
                                    } = choice;

                                    return (
                                        <CourseTile
                                            altLayout
                                            altIcon="info-circle"
                                            onClick={() => { openInfoModal(choice, false); }}
                                            key={`chosen_course-${choice.id}`}
                                            role={role}
                                            routeStatus={isMasterClass ? 'masterclass' : routeStatus}
                                            toggle={role === 'coach' && !awaitingChoices}
                                            bpvPhase={_bpvPhase === 1 || _bpvPhase === 2 || is_bpv}
                                            toggled={is_approved}
                                            onToggle={is_approved ?
                                                () => { decline(id); } :
                                                () => { approve(id); }
                                            }
                                            {...course}
                                        />
                                    );
                                })}
                            </TileContainer>
                        ) : null}
                        {!loading && coachChoices.length && studentChoices.length ? <br /> : null}
                        {!loading && coachChoices.length ? (
                            <TileContainer title={coachChoicesListTitle} scroll>
                                {coachChoices.map((choice) => {
                                    const {
                                        is_special: isMasterClass,
                                        is_bpv,
                                        scheduled_course: {
                                            course,
                                            routeStatus,
                                            _bpvPhase,
                                        },
                                    } = choice;

                                    return (
                                        <CourseTile
                                            altLayout
                                            altIcon="info-circle"
                                            onClick={() => { openInfoModal(choice, false); }}
                                            key={`chosen_course-${choice.id}`}
                                            role={role}
                                            bpvPhase={_bpvPhase === 1 || _bpvPhase === 2 || is_bpv}
                                            routeStatus={isMasterClass ? 'masterclass' : routeStatus}
                                            {...course}
                                        />
                                    );
                                })}
                            </TileContainer>
                        ) : null}
                    </div>
                </div>
                {hasChoices ? (
                    <WarningBar
                        icon="info"
                        variant="error"
                        className={styles.basket__warning}
                        collapsed
                    >
                        {warningBarText}
                    </WarningBar>
                ) : null}
                <ProgressBar color="red" progress={timeRemainingFraction} />
            </div>
        </Bem>
    );
};

export default Bag;
