import { useEffect, useMemo } from 'react';
import useApi from '../useApi';

import { useEnvironmentContext } from '../../context';
import { cleanupCourseName } from '../../utils';

const DEFAULT_SESSIONS = parseInt(process.env.REACT_APP_FLEXX_DEFAULT_SESSIONS, 10);

function useProgressCourseApi (courseGroupId, studentId) {
    const { environment } = useEnvironmentContext();
    const apiPath = environment === 'TEACHER' ?
        `/students/${studentId}/courses/progress/${courseGroupId}` :
        `/courses/progress/${courseGroupId}`;

    const { get, loading, value, fetched } = useApi('cursusvoortgang', apiPath, { initialValue: {} });

    const fetchWhen = courseGroupId?.length && !loading && !fetched;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [fetchWhen, get]);

    const groupCourse = useCourseMemo(value);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        course: groupCourse,
        loading,
        fetched,
    };
}

export default useProgressCourseApi;

function useCourseMemo (value) {
    return useMemo(() => {
        const course = cleanupCourseName(value);
        const {
            cleanName,
            category,
            course_description: description,
            cohort_block,
            block_year,
            block_period,
            course_criteria = [],
            course_canvas_url: canvas_url,
            route_status,
            rating = {},
            teachers = [],
            sessions = DEFAULT_SESSIONS
        } = course;

        const { criteria: _criteria = [] } = rating || {};

        // remap criteria for easier use within <SmallRatings/>
        const criteria = course_criteria
            .reduce((acc, crit, index) => {
                const {
                    id: ctId,
                    group_order: gOrder,
                    code,
                    name,
                    description,
                    needed_for_closing: neededForClosing,
                } = crit;

                if (!acc[gOrder]) {
                    acc[gOrder] = [];
                }

                acc[gOrder][index] = {
                    typeId: ctId,
                    code,
                    name,
                    description,
                    score: _criteria?.find(obj => obj?.code === code)?.score,
                    neededForClosing,
                };

                return acc;
            }, [])
            .map((group) => group.filter(Boolean))
            .filter(Boolean);

        const teacherList = teachers.map((value) => ({ full_name: value }));

        return {
            scheduled_course: {
                criteria,
                canvas_url,
                block: {
                    number: cohort_block,
                    school_year: (block_year) ? `${block_year}/${parseInt(block_year) + 1}` : block_year,
                    period: block_period,
                },
                route_status,
                course: {
                    cleanName,
                    category,
                    description
                },
            },
            sessions,
            ratings: [rating],
            teachers: teacherList, //== Maintain the structure so that the course page does not break
        };
    }, [value]);
}
