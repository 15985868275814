import React from 'react'
import Bem from 'react-better-bem'

import { Icon, Button } from "../../../components"

import { extractUrlName, getCopy } from '../../../utils'

import styles from "./BPVFooter.module.scss"

const BPVFooter = ({ isStudent, bpvLinks }) => {
	const footerTitle = getCopy(`bpv.footer.title`)
	const footerContent = getCopy(`bpv.footer.content`)
    const { vacanciesLinks } = bpvLinks;

	return (
		<Bem style={styles}>
			<article el="footer">
				<div el="col" mod="icon">
				    <span el="circle"></span>
					<Icon
						color="red"
						type="badge"
						size="extra-large"
						className={styles["footer--img"]}
					/>
				</div>
				<div el="col" mod="text-content">
					<h1 el="title">{footerTitle}</h1>
					<p el="text">{footerContent}</p>
					<div el="button-group">
                        {
                            vacanciesLinks.map((vacancy, index) => (
                            <Button
                                key={`vacancy-${index}`}
                                variant="default"
                                href={vacancy}
                                target="_blank"
                                disabled={!isStudent}
                            >
                                Naar {extractUrlName(vacancy)}
                            </Button>
                            ))
                        }
                    </div>
				</div>
			</article>
		</Bem>
	)
}

export default BPVFooter
