import React from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { CourseImage, RouteStatusImage, IconButton, ToggleSlider } from '../';

import styles from './CourseTile.module.scss';

const VALID_LAYOUTS = ['default', 'line'];

const CourseTile = ({
    // course props
    name,           // Engels-01
    cleanName,      // Engels 1
    courseName,     // Engels
    courseIndex,    // 1

    category = 'default', // course category (icon)
    image, // image src (overwrites category)
    profile = 'geen_profiel', // study profile (colored line)

    status, // course completion status (rating status)
    routeStatus, // status in study route (verbredend etc.)
    routeStatusIconColor, // color for the route status icon

    to, href, onClick, // turn tile into link/button

    layout = 'default', // tile shape: default (square) or line

    altLayout = false, // alternative layout (layout = 'default' only)
    altIcon = 'chevron-right', // icon to use in alt layout
    altOnClick,

    toggle = false, // add toggle switch (layout = 'default' only)
    toggled, // state for toggle
    onToggle, // callback for toggle (should update toggled)

    // variants
    noShadow = false, // enforce no shadow
    empty = false, // empty (striped) design with no content
    faded = false, // 'grayed-out' design
    disabled = false, // faded + no pointer events
    bpvPhase,
}) => {
    layout = VALID_LAYOUTS.includes(layout) ? layout : VALID_LAYOUTS[0];

    if (empty) {
        return (
            <div
                className={`${styles.tile} ${styles['tile--empty']} ${styles[`tile--layout-${layout}`]}`}
            />
        );
    }

    const hasLink = !disabled && !!(to || href);
    const isButton = !disabled && !hasLink && typeof onClick === 'function';
    const hasAltOnClick = typeof altOnClick === 'function';

    /* * * * * *
     * CONTENT *
    ** * * * * */
    const addIndexBadge = !altLayout && layout !== 'line' && ![0, '0', null, undefined].includes(courseIndex);
    const addAltButton = altLayout && layout === 'default' && (hasAltOnClick || hasLink || isButton);
    const addToggleSlider = toggle && layout === 'default';

    const courseTileName = layout === 'line' || altLayout ? cleanName : courseName;

    const courseImageClassName = [styles.tile__icon__image];
    if (layout === 'line') {
        courseImageClassName.push(styles['tile__icon__image--inline']);
    }

    const tileContent = (
        <Bem
            style={styles}
            block="tile"
            mod={{
                link: (hasLink || isButton),
                button: isButton,
                layout,
                disabled,
                faded,
                bpvPhase,
                'no-shadow': noShadow,
            }}
        >
            {addToggleSlider ? (
                <ToggleSlider
                    toggled={toggled}
                    onToggle={onToggle}
                    className={styles['tile__toggle-slider']}
                    spanElement={hasLink || isButton}
                />
            ) : null}
            {layout === 'default' ? (
                <RouteStatusImage
                    status={routeStatus}
                    className={styles['tile__route-status']}
                    color={routeStatusIconColor}
                />
            ) : null}
            <div el="icon">
                {layout === 'line' ? (
                    <RouteStatusImage
                        status={routeStatus}
                        color={routeStatusIconColor}
                    />
                ) : null}
                <CourseImage
                    src={image}
                    category={category}
                    ratingStatus={status}
                    className={courseImageClassName.join(' ')}
                    inline={layout === 'line'}
                />
            </div>
            <div el="title">
                {courseTileName ? <span el="text">{courseTileName}</span> : null}
                {addIndexBadge ? <span el="index">{courseIndex}</span> : null}
                {addAltButton ? (
                    <IconButton
                        spanElement={hasLink || isButton}
                        className={styles.tile__title__button}
                        icon={altIcon}
                        onClick={altOnClick}
                    />
                ) : null}
            </div>
        </Bem>
    );

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    const classNames = [
        styles.tile,
        styles[`tile--layout-${layout}`],
        styles[`tile--profile-${profile}`],
    ];

    if (noShadow) {
        classNames.push(styles['tile--no-shadow']);
    }

    if (faded) {
        classNames.push(styles['tile--faded']);
    }

    if (disabled) {
        classNames.push(styles['tile--disabled']);
    }

    if (hasLink || isButton) {
        classNames.push(styles['tile--link']);
    }

    if (isButton) {
        classNames.push(styles['tile--button']);
    }

    if (bpvPhase) {
        classNames.push(styles['tile--bpvPhase']);
    }

    const className = classNames.join(' ');

    if (hasLink && to) {
        return (
            <Link to={to} className={className} title={cleanName}>
                {tileContent}
            </Link>
        );
    }

    if (hasLink && href) {
        return (
            <a href={href} className={className} title={cleanName}>
                {tileContent}
            </a>
        );
    }

    if (isButton) {
        return (
            <button onClick={onClick} className={className} title={cleanName}>
                {tileContent}
            </button>
        );
    }

    return (
        <div className={className} title={cleanName}>
            {tileContent}
        </div>
    );
};

export default CourseTile;
