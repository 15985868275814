import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Bem from 'react-better-bem';

import { useEnvironmentContext } from '../../context';

import { customSelectStyles } from './customStyles';

import styles from './CustomSelect.module.scss';

const CustomSelect = ({ options, onChange }) => {
    const [allOptions, setAllOptions] = useState(options);
    const [ placeHolder, setPlaceHolder ] = useState('Alle cursussen');

    const handleNewOption = (e) => {
        const newOption = {
            value: e,
            label: e ? e.slice(0, 1).toUpperCase() + e.slice(1) : ''
        }

        setAllOptions([...allOptions]);
        setPlaceHolder(newOption.label);
        onChange(newOption);
    }

    const handleKeyDown = (e) => {
        const { value } = e.target;

        if (!value) return;

        handleNewOption(value);
    }

return (
	<Bem style={styles}>
		<label el="label">Filter cursus op:</label>
		<CreatableSelect
			options={allOptions}
			styles={customSelectStyles}
			width="100%"
			placeholder={placeHolder}
			onChange={onChange}
			// blurInputOnSelect
            formatCreateLabel={(value) => `Zoeken: ${value}`}
            onKeyDown={handleKeyDown}
            onCreateOption={handleNewOption}
			components={
                {
                    Container: CustomContainer,
                    Menu: CustomMenu,
                    Option: CustomOption,
                    Control: CustomControl,
			    }
            }
		/>
	</Bem>
	)
}

export default CustomSelect;

const CustomMenu = ({ children, innerProps }) => {
	const { environment } = useEnvironmentContext();
	const accent = environment === 'STUDENT';

	return (
		<Bem style={styles}>
			<div
				{...innerProps}
				el="custom-menu"
				mod={{ accent }}
			>
				{children}
			</div>
		</Bem>
	)
}

const CustomOption = ({ children, innerProps, isFocused }) => {
	const { environment } = useEnvironmentContext();
	const accent = environment === 'STUDENT';

	return (
		<Bem style={styles}>
			<div
				{...innerProps}
				el="custom-option"
				mod={{
					accent,
					selectedS: isFocused && accent,
					selectedT: isFocused && !accent
				}}
			>
				{children}
			</div>
		</Bem>
	)
}

const CustomControl = ({ children, innerProps }) => {
	const { environment } = useEnvironmentContext();
	const accent = environment === 'STUDENT';

	return (
		<Bem style={styles}>
			<div
				{...innerProps}
				el="custom-control"
				mod={{
					accent,
				}}
			>
				{children}
			</div>
		</Bem>
	)
}

const CustomContainer = ({ children, innerProps }) => {
	const { environment } = useEnvironmentContext();
	const accent = environment === 'STUDENT';

	return (
		<Bem style={styles}>
			<div
				{...innerProps}
				el="custom-container"
				mod={{
					accent,
				}}
			>
				{children}
			</div>
		</Bem>
	)
}
