import { useState, useCallback } from 'react';

const VALID_COLLAPSE = [false, 'both', 'vertical', 'horizontal', 'left', 'right', 'top', 'bottom'];
const VALID_BOOLS = [true, false];
const VALID_BACKGROUNDS = [false, 'gray', 'blue'];

function useContainerLayout () {
    const [collapsed, setCollapsed] = useState(false);
    const [stretched, setStretched] = useState(false);
    const [margin, setMargin] = useState(true);
    const [padContent, setPadContent] = useState(false);
    const [stretchContent, setStretchContent] = useState(false);
    const [background, setBackground] = useState(false);
    const [flexContent, setFlexContent] = useState(false);

    const setLayout = useCallback(({ stretch = false, collapse = false, padContent = false, stretchContent = false, margin = true, background = false, flexContent = false }) => {
        collapse = VALID_COLLAPSE.includes(collapse) ? collapse : false;
        stretch = VALID_BOOLS.includes(stretch) ? stretch : false;
        padContent = VALID_BOOLS.includes(padContent) ? padContent : false;
        stretchContent = VALID_BOOLS.includes(stretchContent) ? stretchContent : false;
        margin = VALID_BOOLS.includes(margin) ? margin : true;
        background = VALID_BACKGROUNDS.includes(background) ? background : false;
        flexContent = VALID_BOOLS.includes(flexContent) ? flexContent : false;

        setCollapsed(collapse);
        setStretched(stretch);
        setMargin(margin);
        setPadContent(padContent);
        setStretchContent(stretchContent);
        setBackground(background);
        setFlexContent(flexContent);
    }, []);

    return {
        collapsed,
        stretched,
        padContent,
        margin,
        stretchContent,
        flexContent,
        background,
        setLayout
    };
}

export default useContainerLayout;
