import React, { useState, useCallback } from 'react';
import Bem from 'react-better-bem';

import { Toggle } from '../../';

import styles from './FilterCompetencesModal.module.scss';

const FilterCompetencesModal = ({
    competences,
    selectedCompetences,
    teams,
    selectedTeams,
    selectedProfile,
    setSelectedCompetences,
    setSelectedProfile,
    setSelectedTeams,
    diplomaProfiles = [],
 }) => {

    const [innerSelectedCompetences, setInnerSelectedCompetences] = useState(selectedCompetences);
    const [innerSelectedTeams, setInnerSelectedTeams] = useState(selectedTeams);
    const [innerSelectedProfiles, setInnerSelectedProfiles] = useState(selectedProfile);

    const uniqueCompetences = competences.reduce((acc, _comp) => {
        const { description } = _comp;
        const isDescriptionInAcc = acc.find((accComp) => accComp.description === description);

        if (!isDescriptionInAcc) {
            acc.push(_comp);
        }

        return acc.filter(({ description }) => !description.includes("*"));
    }, []);

    // divide the uniqueCompetences into two arrays
    const competencesLeft = uniqueCompetences.filter((_, i) => i % 2 === 0);
    const competencesRight = uniqueCompetences.filter((_, i) => i % 2 === 1);

    const selectCompetences = useCallback((ids = []) => {
        setInnerSelectedCompetences(ids);
        setSelectedCompetences(ids);
    }, [setSelectedCompetences]);

    const selectTeams = useCallback((teams = []) => {
        setInnerSelectedTeams(teams);
        setSelectedTeams(teams);
    }, [setSelectedTeams]);

    const selectProfiles = useCallback((_profiles = []) => {
        setInnerSelectedProfiles(_profiles);
        setSelectedProfile(_profiles);
    }, [setSelectedProfile]);

    return (
        <Bem style={styles}>
            <div el="control" mod="all">
                {teams.length ? (
                <div el="profiles">
                <h3 el="title">Teams</h3>
                    <div el="team-group">
                        <div>
                            <Toggle
                                type="checkbox"
                                label="Alle teams"
                                checked={innerSelectedTeams.length === teams.length}
                                change={(checked) => { selectTeams(checked ? teams : []) }}
                            />
                            <ul>
                                {teams.map((team) => {
                                    const randId = Math.random().toString(36).substring(7);
                                    const isChecked = innerSelectedTeams.includes(team);

                                    return (
                                        <li
                                            key={`team-profile-${team}-${randId}`}
                                            el="filter-item"
                                        >
                                            <Toggle
                                                type="checkbox"
                                                label={team}
                                                checked={isChecked}
                                                change={(checked) => {
                                                    const selectedTeams = checked ?
                                                        [ ...innerSelectedTeams, team ] :
                                                        innerSelectedTeams.filter((_team) => _team !== team);

                                                        selectTeams(selectedTeams);
                                                }}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>) : null }
                {uniqueCompetences.length ?  (
                    <div el="profiles">
                    <h3 el="title">Competenties</h3>
                        <div el="competence-group">
                            <div>
                                <Toggle
                                    type="checkbox"
                                    label="Alle competenties"
                                    checked={innerSelectedCompetences.length === uniqueCompetences.length}
                                    change={(checked) => {
                                        const ids = checked ?
                                        uniqueCompetences.map(({ choice_id }) => choice_id) :
                                            [];

                                            selectCompetences(ids);
                                    }}
                                />
                                <ul>
                                    {competencesLeft.map((competence) => {
                                        const { choice_id, description } = competence
                                        const randId = Math.random().toString(36).substring(7);
                                        const isChecked = innerSelectedCompetences.includes(choice_id);

                                        const cleanDescription = description.replace(/[*]/g, '');

                                        return (
                                            <li
                                                key={`diploma-profile-${description}-${randId}`}
                                                el="filter-item"
                                            >
                                                <Toggle
                                                    type="checkbox"
                                                    label={cleanDescription}
                                                    checked={isChecked}
                                                    change={(checked) => {
                                                        const ids = checked ?
                                                            [ ...innerSelectedCompetences, choice_id ] :
                                                            innerSelectedCompetences.filter((_cId) => _cId !== choice_id);

                                                            selectCompetences(ids);
                                                    }}
                                                />
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <ul>
                                {competencesRight.map((competence) => {
                                    const { choice_id, description } = competence
                                    const randId = Math.random().toString(36).substring(7);
                                    const isChecked = innerSelectedCompetences.includes(choice_id);

                                    const cleanDescription = description.replace(/[*]/g, '');

                                    return (
                                        <li
                                            key={`diploma-profile-${description}-${randId}`}
                                            el="filter-item"
                                        >
                                            <Toggle
                                                type="checkbox"
                                                label={cleanDescription}
                                                checked={isChecked}
                                                change={(checked) => {
                                                    const ids = checked ?
                                                        [ ...innerSelectedCompetences, choice_id ] :
                                                        innerSelectedCompetences.filter((_cId) => _cId !== choice_id);

                                                        selectCompetences(ids);
                                                }}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>) : null }
                <ul el="profiles">
                    <h3 el="title">Diplomaprofiel</h3>
                    <Toggle
                        type="checkbox"
                        label="Alle profielen"
                        checked={innerSelectedProfiles.length === diplomaProfiles.length}
                        change={(checked) => {
                            const profiles = checked ? diplomaProfiles.map(({ name }) => name) : [];
                            selectProfiles(profiles);
                        }}
                    />
                    {diplomaProfiles.map(({ name: profileName = "", colour: profileColour = "" }) => {
                        const randId = Math.random().toString(36).substring(7);
                        const isChecked = innerSelectedProfiles.includes(profileName);

                        return (
                            <li
                                key={`diploma-profile-${profileName}-${randId}`}
                                el="filter-item"
                            >
                                <Toggle
                                    type="checkbox"
                                    label={profileName}
                                    color={profileColour}
                                    borderColor={profileColour}
                                    checked={isChecked}
                                    change={(checked) => {
                                        const selectedProfiles = checked ?
                                            [ ...innerSelectedProfiles, profileName ] :
                                            innerSelectedProfiles.filter((_profile) => _profile !== profileName);

                                            selectProfiles(selectedProfiles);
                                    }}
                                />
                            </li>
                        )
                    })}
                </ul>
            </div>

        </Bem>
    );

};

export default FilterCompetencesModal;
