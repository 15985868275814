import { useEffect, useMemo } from 'react';

import useApi from '../useApi';
import { getCopy } from '../../utils';

function useReportsApi (role = 'student', studentId) {

    const path = role === 'student' ?
        '/coaching/reports/' :
        `/coaching/${getCopy(`coaching.${role}.apiPrefix`)}/reports/students/${studentId}/`;

    const { get, loading, value, fetched } = useApi('gespreksverslagen voor student', path, { initialValue: [] });

    // fetch initially
    const fetchWhen = !fetched && !loading && (role === 'student' || studentId !== undefined);
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    // sort by newest first
    const reports = useMemo(() => {
        return [...value].sort(({ report: { created_at: a } = {} }, { report: { created_at: b } = {} }) => {
            const da = new Date(a);
            const db = new Date(b);

            return da < db ? 1 : db < da ? -1 : 0;
        });
    }, [value]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        reports,
        loading,
        fetched
    };
}

export default useReportsApi;
