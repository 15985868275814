import React, { useMemo, useEffect, useState } from 'react';
import Bem from 'react-better-bem';

import { TileContainer, CourseGroupTile, PageHeader, Input, BlockBadge, ToggleSlider2, Icon, LoadingDots, FilterButton } from '../../../components';

import { useAppStateContext, useBreadcrumbTrailContext, useRatingsContext } from '../../../context';
import { useLayout, usePageTitle, useLocalStorage, useScrollRestoration } from '../../../hooks';
import { getCopy } from '../../../utils';

import styles from './Overview.module.scss';

const RatingOverview = () => {
    useLayout({ stretch: false, collapse: 'top' });
    usePageTitle('rating');
    const { pathname } = window.location;
    useScrollRestoration({ path: pathname });

    const { reset: resetBreadCrumbTrail } = useBreadcrumbTrailContext();
    useEffect(() => {
        resetBreadCrumbTrail();
    }, [resetBreadCrumbTrail]);

    const { courses, allYears, defaultYear, selectedYear, selectedPeriod, loading, setSelectedYear, setSelectedPeriod } = useRatingsContext();
    const [scrollPosition, setScrollPosition] = useLocalStorage('rating-overview-scroll-position', -1);

    /* * * * * * * * * * *
     * FILTER SIDE PANEL  *
    ** * * * * * * * * * */
    const {
        sidePanel: {
            open: openSidePanel,
            close: closeSidePanel,
            setContent: setSidePanelContent,
        }
    } = useAppStateContext();

    const [filterRated, setFilterRated] = useState(false);

    useEffect(() => {
        setSidePanelContent(
            <FilterPanel
                allYears={allYears}
                defaultYear={defaultYear}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                filterRated={filterRated}
                setFilterRated={setFilterRated}
                closePanel={closeSidePanel}
            />
        );
    }, [allYears, selectedYear, selectedPeriod, filterRated, setSidePanelContent, closeSidePanel, defaultYear, setSelectedYear, setSelectedPeriod]);

    /* * * * * *
     * SEARCH  *
    ** * * * * */
    const [search, setSearch] = useState('');

    /* * * * * * * * * * *
     * REMAP AND FILTER  *
    ** * * * * * * * * * */
    const courseSections = useMemo(() => {
        const _courses = courses
            .map((course) => {
                const { courseName, cleanName, groupName, group_code: groupCode, groups: _groups } = course;
                const groups = _groups.filter(({
                    students,
                    published: nPublishedRatings,
                }) => {
                    // filter out fully rated groups
                    if (filterRated) {
                        const nStudents = students.length;
                        if (nPublishedRatings === nStudents) {
                            return false;
                        }
                    }

                    // filter by search
                    if (search.length) {
                        const needle = search.trim().toLowerCase();

                        const groupMatch = groupName.toLowerCase().includes(needle) ||
                            groupCode.toLowerCase().includes(needle);

                        const courseMatch = [courseName, cleanName].some((_n) =>
                            _n.toLowerCase().includes(needle)
                        );

                        const studentMatch = students.some(({ student_full_name: full_name, student_number: flexx_id }) => (
                            full_name.toLowerCase().includes(needle) || flexx_id.includes(needle)
                        ));

                        const visible = groupMatch || courseMatch || studentMatch;

                        if (!visible) {
                            return false;
                        }
                    }

                    return true;
                });

                return { ...course, groups };
            })
            .filter(({ groups }) => {
                if (!groups.length) {
                    return false;
                }

                return true;
            });

        if (_courses.length) {
            return _courses.map((course, i) => {
                const {
                    id: scheduledCourseId,
                    block,
                    groups,
                    cleanName
                } = course;

                const sectionTitle = (
                    <>
                        {cleanName} <BlockBadge block={block} className={styles['block-badge']} period />
                    </>
                );

                return (
                    <section
                        key={`course-groups-overview-${scheduledCourseId}`}
                        el="section"
                        mod={{ shaded: !!(i%2) }}
                    >
                        <div el="content">
                            <TileContainer title={sectionTitle}>
                                {groups
                                    .map(({ group_code: groupCode, id: courseGroupId, students, published: nPublishedRatings }) => (
                                        <CourseGroupTile
                                            key={`course-group-${courseGroupId}`}
                                            code={groupCode}
                                            students={students}
                                            nPublishedRatings={nPublishedRatings}
                                            link={`/beoordelen/${courseGroupId}`}
                                            onClick={() => { setScrollPosition(window.scrollY, true); }}
                                        />
                                    ))
                                }
                            </TileContainer>
                        </div>
                    </section>
                )
            });
        }

        if (loading) {
            return (
                <section el="section">
                    <div el="content">
                        <h3><LoadingDots/></h3>
                    </div>
                </section>
            );
        }

        // empty list
        const title = getCopy('rating.overview.emptyList.title');
        const tagLine = getCopy('rating.overview.emptyList.tagLine');

        return (
            <section el="section">
                <div el="content">
                    <h3>{title}</h3>
                    <p>{tagLine}</p>
                </div>
            </section>
        );
    }, [courses, loading, filterRated, search, setScrollPosition]);

    /* * * * * * * * * *
     * SCROLL POSITION *
    ** * * * * * * * * */
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        courses.length && window.scrollTo({
            top: scrollPosition,
            behavior: 'auto'
        });

    }, [pathname, courses, scrollPosition]);

    /* * * * * *
     * RENDER  *
    ** * * * * */
    let filterButtonContent = selectedYear?.yearLabel || '';
    if (selectedPeriod?.period) {
        const periodStr = `Periode ${selectedPeriod.period}`;
        if (filterButtonContent.length) {
            filterButtonContent += ` - ${periodStr}`;
        } else {
            filterButtonContent = periodStr;
        }
    }

    return (
        <Bem style={styles}>
            <PageHeader title="Beoordelen" />
            <section el="beoordelen">
                <div el="search-bar">
                    <FilterButton onClick={openSidePanel}>
                        {filterButtonContent || 'Filter overzicht'}
                    </FilterButton>
                    <div>
                        <Input
                            small
                            placeholder="Zoeken"
                            icon="spyglass"
                            onChange={(e) => { setSearch(e.target.value); }}
                            defaultValue={search}
                        />
                    </div>
                </div>
                {courseSections}
            </section>
        </Bem>
    );
};

export default RatingOverview;


function FilterPanel ({
    allYears,
    defaultYear,
    selectedYear, setSelectedYear,
    selectedPeriod, setSelectedPeriod,
    filterRated, setFilterRated,
}) {

    const currentPeriods = allYears?.find(({ yearId }) => selectedYear?.yearId === yearId)?.periods || [];

    return (
        <>
            {allYears?.length ? (
                <>
                    <h2>Schooljaar</h2>
                    <ul className={styles['filter-list']}>
                        {allYears.map(({ yearId, yearLabel }) => (
                            <li
                                key={`filter-year-${yearId}`}
                                className={styles['filter-list__item']}
                            >
                                <button
                                    className={`${styles['filter-list__item__button']}${selectedYear?.yearId === yearId ? ' ' + styles['filter-list__item__button--current'] : ''}`}
                                    onClick={() => {
                                        setSelectedPeriod(null);
                                        setSelectedYear({ yearLabel, yearId })
                                    }}
                                >
                                    {yearLabel}
                                    {defaultYear === yearLabel ? (
                                        <Icon
                                            type="calendar-today"
                                            size="small"
                                            color={selectedYear?.yearId === yearId ? 'black' : 'blue-darker'}
                                            className={styles['filter-list__item__button__icon']}
                                        />
                                    ) : null}
                                </button>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}
            {currentPeriods?.length ? (
                <>
                    <h2>Periode</h2>
                    <ul className={styles['filter-list']}>
                        {currentPeriods.map(({ period, blockId, yearId }) => (
                            <li
                                key={`filter-period-${blockId}`}
                                className={styles['filter-list__item']}
                            >
                                <button
                                    className={`${styles['filter-list__item__button']}${selectedPeriod?.blockId === blockId ? ' ' + styles['filter-list__item__button--current'] : ''}`}
                                    onClick={() => setSelectedPeriod((c) => c?.blockId === blockId ? null : { period, blockId, yearId })}
                                >
                                    Periode {period}
                                </button>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}
            <h2>Status</h2>
            <div className={`${styles['filter-list']} ${styles['filter-list--status']}`}>
                <label className={styles['filter-list__toggle']}>
                    Verberg beoordeeld
                    <ToggleSlider2
                        checked={filterRated}
                        setChecked={setFilterRated}
                        // className={styles['filter-list__toggle__slider']}
                    />
                </label>
            </div>
        </>
    );
}
