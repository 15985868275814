import { useEffect, useState } from 'react';

export default function useFilters ({ filterLocalName = 'feedback-filters' }) {
    const [selectedYear, setSelectedYear] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState({});

    useEffect(() => {
        const filters = JSON.parse(localStorage.getItem(filterLocalName)) ?? {};
        const { selectedYear: storedYear = {}, selectedPeriod: storedPeriod = {} } = filters || {};
        const storedYearExists = Object.keys(storedYear || {})?.length;
        // Set the state from local storage
        if (storedYearExists) {
            setSelectedYear(storedYear);
        }

        const storedPeriodExists = Object.keys(storedPeriod || {})?.length;
        if (storedPeriodExists && storedPeriod?.yearId === storedYear?.yearId) {
            setSelectedPeriod(storedPeriod);
        }
    }, [filterLocalName]);

    useEffect(() => {
        const hasFilters = Object.keys(selectedYear || {})?.length || Object.keys(selectedPeriod || {})?.length
        if (hasFilters) {
            localStorage.setItem(filterLocalName, JSON.stringify({ selectedYear, selectedPeriod }));
        }
    }, [selectedYear, selectedPeriod, filterLocalName]);

    return {
        selectedYear,
        setSelectedYear,
        selectedPeriod,
        setSelectedPeriod
    };
}
