import React, { useContext } from 'react';

import { useBreadcrumbTrail } from '../hooks';

const BreadcrumbTrailContext = React.createContext({});

const BreadcrumbTrailContextProvider = ({ children }) => {
    const bcTrail = useBreadcrumbTrail();

    return (
        <BreadcrumbTrailContext.Provider value={bcTrail}>
            {children}
        </BreadcrumbTrailContext.Provider>
    );
};

const useBreadcrumbTrailContext = () => useContext(BreadcrumbTrailContext);

export { BreadcrumbTrailContextProvider };
export default useBreadcrumbTrailContext;
