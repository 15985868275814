import React, { useEffect, useState, useCallback } from 'react';
import Bem from 'react-better-bem';
import { useHistory } from 'react-router-dom';

import { useAppStateContext, useBreadcrumbTrailContext } from '../../../context';
import { usePageTitle, useLayout } from '../../../hooks';
import { useNewReportApi } from '../../../hooks/api';
import { PageHeader, Button, Input, TextArea, StickyFormFooter, Icon } from '../../../components';

import styles from './NewReport.module.scss';

const NewReport = () => {
    useLayout({ collapse: 'both', background: 'blue', stretch: true, stretchContent: false, padContent: true, margin: false, flexContent: true });
    usePageTitle('coaching.newReport.student');

    const { screen: { isTablet, isSmallDesktop, isntSmallDesktop } } = useAppStateContext();

    const history = useHistory();

    const { submit, submitting, submitted, newReportId } = useNewReportApi();

    /* * * * * * * *
     * FORM STATES *
    ** * * * * * * */
    const [topic, setTopic] = useState('');
    const [topicWarning, setTopicWarning] = useState(null);

    const [text, setText] = useState('');
    const [textWarning, setTextWarning] = useState(null);

    const exposedSetTopic = useCallback(({ target: { value } }) => {
        setTopicWarning(null);
        setTopic(value);
    }, []);

    const exposedSetText = useCallback(({ target: { value } }) => {
        setTextWarning(null);
        setText(value);
    }, []);

    /* * * * * *
     * SUBMIT  *
    ** * * * * */
    const submitNewReport = useCallback(() => {
        let shouldSubmit = true;

        if (!topic.length) {
            setTopicWarning('De titel is verplicht.');
            shouldSubmit = false;
        } else if (topic.length > 280) {
            setTopicWarning('De titel mag maximaal 280 karakters lang zijn.');
            shouldSubmit = false;
        } else {
            setTopicWarning(null);
        }

        if (!text.length) {
            setTextWarning('Je kan geen leeg verslag insturen.');
            shouldSubmit = false;
        } else {
            setTextWarning(null);
        }

        if (shouldSubmit) {
            submit(topic, text);
        }
    }, [topic, text, submit]);

    // redirect to new report after submission
    useEffect(() => {
        if (submitted && newReportId !== null) {
            history.push(`/coaching/verslagen/${newReportId}`);
        }
    }, [submitted, newReportId, history]);

    // breadcrumb trail
    const { set } = useBreadcrumbTrailContext();
    useEffect(() => {
        set([
            {
                name: 'Overzicht',
                href: '/coaching',
            },
            {
                name: 'Nieuw gespreksverslag',
                href: '/coaching/verslagen/nieuw'
            }
        ]);
        return () => {
            set([]);
        };
    }, [set]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            {isTablet && (
                <PageHeader
                    title="Studieloopbaancoaching"
                />
            )}
            <form el="form" onSubmit={submitNewReport}>
                <div el="header">
                    <Button
                        to="/coaching"
                        variant="mini"
                        transparent
                        icon="cross"
                        color={isSmallDesktop ? 'red' : 'black'}
                        tabIndex={-1}
                        className={styles['form__header__close-button']}
                    />
                    {isSmallDesktop ? (
                        <h2 el="title">Nieuw gespreksverslag</h2>
                    ) : (
                        <h3 el="title">Nieuw gespreksverslag</h3>
                    )}
                    {isntSmallDesktop && (
                        <Button
                            icon="upload"
                            className={styles['form__header__submit-button']}
                            disabled={submitting || submitted}
                            onClick={submitNewReport}
                        >
                            Insturen
                        </Button>
                    )}
                </div>
                <div el="body">
                    <Input
                        placeholder="Titel van het verslag"
                        transparent
                        className={styles['form__body__input']}
                        value={topic}
                        onChange={exposedSetTopic}
                        required
                        warning={topicWarning}
                        maxLength={280}
                    />
                    <TextArea
                        placeholder="Type hier je gespreksverslag..."
                        transparent
                        className={styles['form__body__textarea']}
                        value={text}
                        onChange={exposedSetText}
                        required
                        warning={textWarning}
                    />
                </div>
            </form>
            {isSmallDesktop && (
                <StickyFormFooter mounted={true}>
                    <div className={styles.footer}>
                        <p className={styles.footer__text}>
                            <Icon
                                type="exclamation-mark-circle"
                                color="white"
                                inline
                                valign="middle"
                                className={styles.footer__text__icon}
                            />
                            <small>
                                <strong>
                                    Let op! Deze tekst wordt niet automatisch tussentijds opgeslagen. Wanneer je de pagina verlaat zonder het verslag in te sturen wordt de tekst verwijderd!
                                </strong>
                            </small>
                        </p>
                        <Button
                            icon="upload"
                            className={styles['footer__submit-button']}
                            disabled={submitting || submitted}
                            onClick={submitNewReport}
                        >
                            Insturen
                        </Button>
                    </div>
                </StickyFormFooter>
            )}
        </Bem>
    );
};

export default NewReport;
