import React from 'react';
import Bem from 'react-better-bem';

import { CourseImage } from '../../';
import { dateToRelativeDateString } from "../../../utils";

import styles from './FeedbackCard.module.scss';

const FeedbackCard = ({ course_name, block, status, teacher, date_published, feedback }) => {
    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <li el="card">
                <div el="header">
                    <div el="course-title">
                        <CourseImage
                            ratingStatus={status}
                            inline={true}
                            className={styles.card__header__status}
                        />
                        <h2 el="title">{ course_name }</h2>
                    </div>
                    <h6>Blok { block }</h6>
                </div>
                <div el="body">
                    <small>{ dateToRelativeDateString(date_published) } van { teacher }</small>
                    <p el="feedback">{ feedback }</p>
                </div>
            </li>
        </Bem>
    );
};

export default FeedbackCard;
