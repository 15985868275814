import React from 'react';
import Bem from 'react-better-bem';

import { Label, FormElementNotification } from '../';

import styles from './TextArea.module.scss';


const TextArea = React.forwardRef(({
    label,
    height = false,
    resize = false,
    id,
    warning,
    notification,
    transparent = false,
    noMaxWidth = false,
    ...props
}, ref) => {

    return (
        <Bem style={styles}>
            {label && <Label htmlFor={id}>{label}</Label>}

            <textarea
                {...props}
                el="textarea"
                mod={{ height, transparent, 'no-max-width': noMaxWidth, resize }}
                id={id}
                ref={ref}
            />

            <FormElementNotification
                message={warning || notification}
                type={warning?.length ? 'warning' : 'notification'}
                transparent={transparent}
            />
        </Bem>
    );
});

export default TextArea;
