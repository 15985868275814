import { useEffect, useMemo } from 'react';
import useApi from '../useApi';

import { useEnvironmentContext } from '../../context';
import { refineCourseName } from '../../utils';

function useProgressCompetenceDetailsApi (choiceId, studentId) {
    const { environment } = useEnvironmentContext();
    const apiPath = environment === 'TEACHER' ?
        `/students/${studentId}/progress/criteria/${choiceId}` :
        `/progress/criteria/${choiceId}`;

    const { get, loading, value, fetched } = useApi('competentie details', apiPath, { initialValue: [], persist: true });

    // fetch initially
    const fetchWhen = !fetched && !loading && choiceId !== undefined;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    // restructure data
    const { criterium, groups, scores, _pastGroups } = useMemo(() => {
        const groups = value
            .filter((groupCourse) => {
                const {
                    ratings: [{ criteria_ratings: _cRatings = [] } = {}] = [{}],
                    criteria: _criteria = [],
                    scheduled_course: {
                        criteria = [],
                    },
                } = groupCourse;

                const cRatings = _cRatings.filter(({ score }) => score && score !== -1);

                const _past = cRatings?.length && (criteria.length || _criteria.length);
                const _future = _criteria.length;
                return _past || _future;

                // return cRatings?.length && (criteria.length || _criteria.length);
            })
            .map((groupCourse) => ({
                ...groupCourse,
                scheduled_course: {
                    ...groupCourse.scheduled_course,
                    course: refineCourseName(groupCourse.scheduled_course.course),
                },
            }));

        const _pastGroups = groups.filter((_group) => {
            return _group.criteria?.length && _group?.ratings?.length && _group?.ratings[0]?.criteria_ratings?.length;
        })

        const criterium = groups[0]?.criteria[0]?.description;

        const scores = _pastGroups.reduce((acc, groupCourse) => {

            const {
                // ratings: [ { criteria_ratings: [ { score } ] } ],
                ratings,
                scheduled_course: { block },
            } = groupCourse;

            const score = ratings[0].criteria_ratings[0]?.score;

            if (!score) {
                return acc;
            }

            const { id: blockId } = block;

            const cIndex = acc.findIndex(({ block: { id: _blockId } }) => _blockId === blockId);

            if (cIndex > -1) {
                acc[cIndex].scores.push(score);
            } else {
                acc.push({ block, scores: [score] });
            }

            return acc;
        }, []);

        scores.sort(({ block: { number: a } }, { block: { number: b } }) => a < b ? -1 : b < a ? 1 : 0);

        return { criterium, groups, scores, _pastGroups };
    }, [value]);

    return {
        loading,
        fetched,
        criterium,
        groups,
        scores,
        _pastGroups,
    };

}

export default useProgressCompetenceDetailsApi;
