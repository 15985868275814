import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from "react-router-dom";
import Bem from "react-better-bem";

import { useAppStateContext } from "../../../context";

import {
    Avatar, CourseProgressFilter, FilterButton, GroupName,
    Table, Tooltip
} from "../../../components";
import { getCopy } from "../../../utils";

import tableLayout from "./table_layout.json";

import globalStyles from "./CoachGroup.desktop.module.scss";
import desktopStyles from "./CoachGroup.desktop.module.scss";

const styles = { ...globalStyles, ...desktopStyles };

const CoachGroupDesktop = ({ api = {}, groupCode = "" }) => {
    const {
        sidePanel: {
            open: openSidePanel,
            close: closeSidePanel,
            setContent: setSidePanelContent,
        },
    } = useAppStateContext();

    const {
        students,
        sortStudents = () => {},
        sortDirection = "",
        sortProperty = "",
        loading = false,
        fetched = true,

        allYears = [],
        allBlocks = [],
        defaultYear = "",

        selectedYear,
        setSelectedYear,
        selectedBlock,
        setSelectedBlock,
    } = api;

    useEffect(() => {
        setSidePanelContent(
            <CourseProgressFilter
                allYears={allYears}
                allBlocks={allBlocks}
                defaultYear={selectedYear}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
            />
        );
    }, [
        allYears,
        allBlocks,
        defaultYear,
        selectedYear,
        selectedBlock,
        setSidePanelContent,
        closeSidePanel,
        setSelectedYear,
        setSelectedBlock
    ]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    const headerRow = useHeaderRowMemo(
        students,
        groupCode,
        {
            sort: sortStudents,
            direction: sortDirection,
            property: sortProperty
        }
    );

    /* * * * * * * *
     * TABLE ROWS  *
    ** * * * * * * */
    const tableRows = useTableRowsMemo(
        students
    );

    let filterButtonContent = selectedYear?.yearLabel || '';
    if (selectedBlock?.period) {
        const periodStr = `Blok ${selectedBlock.number}`;
        filterButtonContent = periodStr;
    }

    /* * * * * *
     * RENDER  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="page-content">
                <FilterButton
                    onClick={openSidePanel}
                    className={styles['filter-button']}
                >
                    {filterButtonContent}
                </FilterButton>
                {!loading && fetched && (
                    <Table
                        rows={[
                            headerRow,
                            ...tableRows
                        ]}
                        className={styles.table}
                    />
                )}
            </div>
        </Bem>
    )
};

const useHeaderRowMemo = (students, groupCode, { sort, direction, property }) => {
    const totalAbbrRef = useRef();
    const completedAbbrRef = useRef();
    const notCompletedAbbrRef = useRef();
    const closedAbbrRef = useRef();
    const availableAbbrRef = useRef();
    const resultsAbbrRef = useRef();

    return useMemo(() => {
        const totalHeader = getCopy('rating.tableHeaders.progress.total');
        const completedHeader = getCopy('rating.tableHeaders.progress.completed');
        const notCompletedHeader = getCopy('rating.tableHeaders.progress.notCompleted');
        const closedHeader = getCopy('rating.tableHeaders.progress.closed');
        const availableHeader = getCopy('rating.tableHeaders.progress.available');
        const resultsHeader = getCopy('rating.tableHeaders.progress.results');

        return [
            {
                content: <GroupName code={groupCode} inheritcolor />,
                sort: {
                    sortable: true,
                    props: ['first_name', 'last_name'],
                    propNames: ['Sorteer op voornaam', 'Sorteer op achternaam'],
                    prop: property,
                    direction: direction,
                    callback: (dir, prop) => sort(dir, prop)
                },
                bg: 'single',
                ...tableLayout.cells[0]
            },
            {
                content: (<>
                    <abbr title={totalHeader?.tooltip} ref={totalAbbrRef}>
                        { totalHeader?.title }
                    </abbr>
                    <Tooltip target={totalAbbrRef} />
                </>),
                bg: 'first',
                ...tableLayout.cells[1]
            },
            {
                content: (<>
                    <abbr title={completedHeader?.tooltip} ref={completedAbbrRef}>
                        { completedHeader?.title }
                    </abbr>
                    <Tooltip target={completedAbbrRef} />
                </>),
                ...tableLayout.cells[2]
            },
            {
                content: (<>
                    <abbr title={notCompletedHeader?.tooltip} ref={notCompletedAbbrRef}>
                        { notCompletedHeader?.title }
                    </abbr>
                    <Tooltip target={notCompletedAbbrRef} />
                </>),
                ...tableLayout.cells[3]
            },
            {
                content: (<>
                    <abbr title={closedHeader?.tooltip} ref={closedAbbrRef}>
                        { closedHeader?.title }
                    </abbr>
                    <Tooltip target={closedAbbrRef} />
                </>),
                ...tableLayout.cells[4]
            },
            {
                content: (<>
                    <abbr title={availableHeader?.tooltip} ref={availableAbbrRef}>
                        { availableHeader?.title }
                    </abbr>
                    <Tooltip target={availableAbbrRef} />
                </>),
                bg: 'last',
                ...tableLayout.cells[5]
            },
            {
                content: (<>
                    <abbr title={resultsHeader?.tooltip} ref={resultsAbbrRef}>
                        { resultsHeader?.title }
                    </abbr>
                    <Tooltip target={resultsAbbrRef} />
                </>),
                bg: true,
                ...tableLayout.cells[6]
            }
        ];
    }, [sort, direction, property, groupCode]);
};

const useTableRowsMemo = (students) => {
    return useMemo(() => {
        return students
            .filter(({ visible }) => visible)
            .map((student, i) => {
                const {
                    flexx_id,
                    first_name,
                    last_name,
                    full_name,
                    ratings: {
                        total,
                        completed,
                        not_completed,
                        closed,
                        available,
                        results
                    }

                } = student;

                const progressOverviewHref = getCopy(
                    'progress.teacher.overview.path',
                    { studentId: flexx_id }
                );

                const progressOverviewTitle = getCopy(
                    'progress.teacher.overview.title',
                    { student: full_name }
                );

                return [
                    {
                        content: (
                            <>
                                <Link
                                    className={styles['student']}
                                    title={progressOverviewTitle}
                                    to={progressOverviewHref}
                                >
                                    <Avatar
                                        flexx_id={flexx_id}
                                        first_name={first_name}
                                        last_name={last_name}
                                        full_name={full_name}
                                        className={styles['student__avatar']}
                                    />
                                    <span className={styles['student__name']}>
                                        { full_name }
                                    </span>
                                </Link>
                            </>
                        ),
                        border: "single",
                        ...tableLayout.cells[0]
                    },
                    {
                        content: (<>{total}</>),
                        border: "first",
                        ...tableLayout.cells[1]
                    },
                    {
                        content: (<>{completed}</>),
                        border: true,
                        ...tableLayout.cells[2]
                    },
                    {
                        content: (<>{not_completed}</>),
                        border: true,
                        ...tableLayout.cells[3]
                    },
                    {
                        content: (<>{closed}</>),
                        border: true,
                        ...tableLayout.cells[4]
                    },
                    {
                        content: (<>{available}</>),
                        border: 'last',
                        ...tableLayout.cells[5]
                    },
                    {
                        content: (<>{results}</>),
                        border: true,
                        ...tableLayout.cells[6]
                    },
                ]
            })
    }, [students]);
};

/* * * * * *
 * EXPORT  *
** * * * * */
export default CoachGroupDesktop;
