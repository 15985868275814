import React from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { Icon } from '../';

import styles from './HomePageNavTile.module.scss';

const HomePageNavTile = ({ href, icon, name, inverted = false }) => {
    const title = Array.isArray(name) ? (
        name.map((word, i, allWords) => (
            i < allWords.length - 1 && word[word.length - 1] !== ' ' ? (
                <React.Fragment key={`word-${word}-${i}`}>
                    {word}&shy;
                </React.Fragment>
            ) : (
                <React.Fragment key={`word-${word}-${i}`}>
                    {word}
                </React.Fragment>
            )
        ))
    ) : name;

    const iconColor = inverted ? 'homepage-navtile-inverted-text' : 'homepage-navtile-text';

    return (
        <Link to={href} className={styles.link}>
            <Bem style={styles} block="link" mod={{ inverted }}>
                <div el="tile">
                    <Icon
                        type={icon}
                        color={iconColor}
                        size="extra-large"
                    />
                    <span el="title">
                        {title}
                    </span>
                </div>
            </Bem>
        </Link>
    );
};

export default HomePageNavTile;
