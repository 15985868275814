import { useEffect, useMemo } from 'react';

import useApi from '../useApi';

import { getCopy } from '../../utils';
import useFiltersContext from "../../context/FiltersContext";

function useFeedbackApi ({ role = 'student', studentId }) {
    const { selectedYear, selectedPeriod } = useFiltersContext();

    const filterUrl = selectedPeriod?.blockId !== undefined ? `block/${selectedPeriod.blockId}/` : selectedYear?.yearId !== undefined ? `year/${selectedYear.yearId}/` :  ''

    const path = role === 'student' ?
        `/coaching/feedback/${filterUrl}` :
        `/coaching/${getCopy(`coaching.${role}.apiPrefix`)}/feedback/students/${studentId}/${filterUrl}`;

    const { get, loading, value, fetched } = useApi('feedback voor student', path, { initialValue: [] });

    const fetchWhen = !fetched && !loading;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    useEffect(() => {
        if (selectedYear?.yearId || selectedPeriod?.blockId) {
            get();
        }
    }, [selectedYear?.yearId, selectedPeriod?.blockId, get]);

    // sort by newest first
    const feedback = useMemo(() => {
        return [...value].sort(({ feedback: { created_at: a } = {} }, { feedback: { created_at: b } = {} }) => {
            const da = new Date(a);
            const db = new Date(b);

            return da < db ? 1 : db < da ? -1 : 0;
        });
    }, [value]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        feedback,
        loading,
        fetched
    };
}

export default useFeedbackApi;
