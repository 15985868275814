import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
import { useApi } from '../';

import { FULL_CHOICES, refineCourseName } from '../../utils';

const INIT_VALUE = { available: [], requests: [] };

function useFirstMomentApi (studentId = false, role = 'student') {
    const [categories, setCategories] = useState({});

    const getPath = studentId === false ?
        `/choices/moment1/` :
        `/students/${studentId}/choices/moment1/`;

    const { get, loading, value, fetched, error } = useApi('beschikbare cursussen', getPath, { initialValue: INIT_VALUE });

    // fetch initially
    const fetchWhen = !fetched && !loading && (role === 'student' || studentId !== false);
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    useEffect(() => {
        if (role === 'student') {
            setCategories(value?.categories || {})
        } else {
            setCategories({})
        }
    }, [value, role]);

    const [catalog, dispatchCatalog] = useReducer(catalogReducer, []);
    useEffect(() => { dispatchCatalog({ type: 'INIT', data: value, role }); }, [value, role]);

    // group available choices into groups as used on page
    const {
        offer,
        _bpvRequiredCourses,
        studyLoad,
        studentChoices,
        coachChoices,
        systemChoices,
        remoteChoices,
        competences,
        teams,
        diplomaProfiles,
    } = useMemo(() => {
        const studentChoices = catalog.filter(({ request: { studentChoice } }) => studentChoice);
        const coachChoices = catalog.filter(({ request: { coachChoice } }) => coachChoice);
        const systemChoices = catalog.filter(({ request: { systemChoice } }) => systemChoice);

        // calculate studyload
        // for students we don't take into account if a course has been approved or not
        const studyLoad = [...studentChoices, ...coachChoices, ...systemChoices]
            .filter(({ request: { is_approved } }) => role === 'student' || is_approved)
            .reduce((acc, { study_load }) => acc + study_load, 0);

        // remoteChoice is _only_ set in reducer in INIT, which is used
        // to reduce data from the backend
        const remoteChoices = catalog.filter(({ request: { remoteChoice } }) => remoteChoice);

        const _bpvRequiredCourses = catalog.filter(({ scheduled_course: { bpv_required } }) => bpv_required > 0)

        const {
            hide_bpv: hideBpv,
            hide_electives: hideElectives,
            hide_external_courses: hideExternal,
            hide_internal_courses: hideInternal,
            hide_masterclasses: hideMasterclasses
        } = categories;

        const offer = catalog.reduce(
            (acc, av) => {
                const {
                    is_masterclass,
                    is_bpv,
                    is_previous,
                    is_elective,
                    scheduled_course: { route_status: _choiceType },
                    request: { systemChoice },
                } = av;

                // map masterclasses
                const choiceType = is_masterclass ? 'masterClasses' : _choiceType;

                const excludeCourse = (choiceType === 'versnellend' && hideInternal) ||
                                      (choiceType === 'verbredend' && hideExternal) ||
                                      (is_masterclass && hideMasterclasses) ||
                                      (is_bpv && hideBpv) ||
                                      (is_elective && hideElectives);

                if (systemChoice || excludeCourse) {
                    return acc;
                }

                if (!FULL_CHOICES && (choiceType === 'verbredend' || choiceType === 'versnellend')) {
                    return acc;
                }

                if (acc[choiceType] && !is_bpv) {
                    acc[choiceType].push(av);
                } else if (!is_bpv) {
                    acc.gepland.push(av);
                }

                // if previous, _also_ add to that list
                if (is_previous) {
                    acc.previous.push(av);
                }

                // for BPV courses
                if (is_bpv) {
                    acc.bpv.push(av)
                }

                // for electives
                if (is_elective) {
                    acc.electives.push(av)
                }

                return acc;
            },
            { versnellend: [], verbredend: [], vertragend: [], gepland: [], previous: [], masterClasses: [], bpv: [], electives: [] }
        );

        const competences = [ ...offer.versnellend, ...offer.verbredend, ...offer.masterClasses, ...offer.bpv, ...offer.previous, ...offer.electives ]
            .reduce((acc, { scheduled_course: { criteria: _crits = [] } }) => {
                const _competences = _crits
                    .filter(({ choice_id: cId, type: { is_choice_based } }) => {
                        if (!is_choice_based) {
                            return false;
                        }

                        return !acc.some(({ choice_id: _cId }) => _cId === cId);
                    });

                return [ ...acc, ..._competences ];
            }, []);

        competences.sort(({ description: a }, { description: b }) => (
            a < b ? -1 : b < a ? 1 : 0
        ));

        const teams = [ ...offer.versnellend, ...offer.verbredend, ...offer.masterClasses, ...offer.bpv, ...offer.previous, ...offer.electives ]
            .reduce((acc, { scheduled_course: { teams: _courseTeams = [] } }) => {
                const _teams = _courseTeams
                    .filter((team) => !acc.some((_team) => _team === team));

                return [ ...acc, ..._teams ];
            }, []);

        teams.sort((a, b) => (
            a < b ? -1 : b < a ? 1 : 0
        ));

        const diplomaProfiles = [ ...offer.versnellend, ...offer.verbredend, ...offer.masterClasses, ...offer.bpv, ...offer.previous, ...offer.electives ]
            .reduce((acc, { scheduled_course: { course: { profile, profile_name: profileName, profile_color: profileColour  } } }) => {
                const match = acc.some((_profile) => _profile.name === profileName)

                if (!match && profileName) {
                    acc.push({ profile, name: profileName, colour: profileColour  })
                }

                return acc;
            }, []);

        diplomaProfiles.sort(({ name: profileNameA }, { name: profileNameB }) => (
            profileNameA < profileNameB ? -1 : profileNameB < profileNameA ? 1 : 0
        ));

        return {
            offer,
            _bpvRequiredCourses,
            studyLoad,
            studentChoices,
            coachChoices,
            systemChoices,
            remoteChoices,
            competences,
            teams,
            diplomaProfiles,
        };
    }, [catalog, role, categories]);


    /* * * * * * * * *
     * LOCAL METHODS *
    ** * * * * * * * */
    const choose = useCallback((availableCourseId, role) => {
        dispatchCatalog({ type: 'CHOOSE', availableCourseId, role });
    }, []);

    const deleteChoice = useCallback((availableCourseId) => {
        dispatchCatalog({ type: 'DELETE_CHOICE', availableCourseId });
    }, []);


    /* * * * * * * * * * * * *
     * DECLINING / APPROVING *
    ** * * * * * * * * * * * */
    const { post: approveChoicePost } = useApi('keuze goedkeuren/afkeuren', '');
    const approveOrDecline = useCallback((availableCourseId, approve = true) => {
        dispatchCatalog({ type: approve ? 'APPROVE' : 'DECLINE', availableCourseId });

        const path = `${getPath}approve/${availableCourseId}/`;
        const payload = { is_approved: approve };

        approveChoicePost(path, payload).then((response) => {
            dispatchCatalog({ type: 'INIT', data: response });
        });
    }, [approveChoicePost, getPath]);

    const approve = useCallback((availableCourseId) => {
        approveOrDecline(availableCourseId);
    }, [approveOrDecline]);

    const decline = useCallback((availableCourseId) => {
        approveOrDecline(availableCourseId, false);
    }, [approveOrDecline]);

    /* * * * * * * * * *
     * REMOTE METHODS  *
    ** * * * * * * * * */
    // make choices
    const { post: makeChoicesPost } = useApi('keuzes maken', `${getPath}request/`);

    const submitChoices = useCallback(() => {
        const requested = [ ...studentChoices, ...coachChoices, ...systemChoices ].map(({ id }) => id);
        const deleted = remoteChoices.map(({ id }) => id).filter((id) => !requested.includes(id));

        const payload = { requested, deleted };

        makeChoicesPost(payload).then((response) => {
            dispatchCatalog({ type: 'INIT', data: response });
        });

    }, [remoteChoices, studentChoices, coachChoices, systemChoices, makeChoicesPost]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */

    return {
        offer,
        _bpvRequiredCourses,
        studyLoad,
        competences,
        teams,
        diplomaProfiles,

        studentChoices,
        coachChoices,
        systemChoices,

        choose,
        deleteChoice,
        approve,
        decline,

        submitChoices,

        loading,
        fetched,
        error,
    };
}

function catalogReducer (state, action) {
    const { type } = action;

    switch (type) {
        case 'INIT': {
            const { data: { available, requests }, role } = action;

            return available
                .filter(({ meets_dependencies }) => role !== 'student' || meets_dependencies)
                .map((av) => {
                    const { id: aId, scheduled_course: sc } = av;

                    const request = requests.find(({ available_course: ac }) => ac === aId);

                    // when we find a request AND the request isn't default => user choice
                    // otherwise it's a system choice, and we don't display it as a choice
                    const studentChoice = !request?.is_default && request?.requested_by?.toLowerCase() === 'student';
                    const coachChoice = !request?.is_default && request?.requested_by?.toLowerCase() === 'coach';
                    const systemChoice = request?.is_default || request?.requested_by?.toLowerCase() === 'system';

                    const remoteChoice = studentChoice || coachChoice || systemChoice;

                    return {
                        ...av,
                        scheduled_course: {
                            ...sc,
                            routeStatus: sc.route_status?.toUpperCase(),
                            course: refineCourseName(sc.course),
                        },
                        request: {
                            studentChoice,
                            coachChoice,
                            systemChoice,
                            remoteChoice,
                            ...request,
                        },
                    };
                });
        }
        case 'CHOOSE': {
            const { availableCourseId, role } = action;

            const studentChoice = role === 'student';
            const coachChoice = role === 'coach';

            return state.map((av) => {
                const { id, request } = av;

                if (id !== availableCourseId) {
                    return av;
                }

                return {
                    ...av,
                    request: {
                        ...request,
                        studentChoice,
                        coachChoice,
                        systemChoice: false,
                        is_approved: coachChoice,
                    },
                };
            });
        }
        case 'DELETE_CHOICE': {
            const { availableCourseId } = action;

            return state.map((av) => {
                const { id, request } = av;

                // you can't delete default choices ↓
                if (id !== availableCourseId || request.systemChoice) {
                    return av;
                }

                return {
                    ...av,
                    request: {
                        ...request,
                        studentChoice: false,
                        coachChoice: false,
                        systemChoice: false,
                    },
                };
            });
        }
        case 'APPROVE': {
            const { availableCourseId } = action;

            return state.map((av) => {
                const { id, request } = av;

                if (id !== availableCourseId) {
                    return av;
                }

                return {
                    ...av,
                    request: {
                        ...request,
                        is_approved: true,
                    },
                };
            });
        }
        case 'DECLINE': {
            const { availableCourseId } = action;

            return state.map((av) => {
                const { id, request } = av;

                // you can't delete coach choices ↓
                if (id !== availableCourseId || request.coachChoice) {
                    return av;
                }

                return {
                    ...av,
                    request: {
                        ...request,
                        is_approved: false,
                    },
                };
            });
        }
        default: {
            return state;
        }
    }
}

export default useFirstMomentApi;
