import React from 'react';
import Bem from 'react-better-bem';

import styles from './GroupName.module.scss';

const GroupName = ({ code, name, inheritcolor = false, noHilight = false, ...props }) => {

    const groupName = code || name || "";
    const splittedName = [groupName.slice(0, -2), groupName.slice(-2)];

    return (
        <Bem style={styles}>
            <span el="group-name" {...props}>
                {noHilight ? groupName : splittedName[0]}
                {noHilight ? null : <strong el="id" mod={{ color: !inheritcolor }}>{splittedName[1]}</strong>}
            </span>
        </Bem>
    );
};

export default GroupName;
