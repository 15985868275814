import { useState, useCallback, memo, useEffect } from 'react';
import Bem from 'react-better-bem';

import { FileToUpload } from  '../';
import { Icon } from '../../../components'

import { useEnvironmentContext } from '../../../context';

import styles from "./DragDropFile.module.scss";


const DragDropFile = ({ internshipId, uploadFile, closeModal }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState([]);
    const [progress, setProgress] = useState(0);
    const [timeStamp, setTimeStamp] = useState(0);

    const { environment } = useEnvironmentContext();
    const teacher = environment === 'TEACHER';
    const student = environment === 'STUDENT';

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
            setIsUploading(true);
            setUploadError(false);

        } else if (e.type === "dragleave") {
            setDragActive(false);
            setIsUploading(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        setIsUploading(true);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();

        setDragActive(false);
        setIsUploading(true);
        setUploadError(false);

        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
    };

    const handleFiles = useCallback((theFiles) => {

        for (let i = 0, numFiles = theFiles.length; i < numFiles; i++) {
            const _file = theFiles[i];
            setFile(() => [_file]);
            uploadFile(_file, internshipId, setUploaded, setProgress, setTimeStamp, setUploadError);

        }
    }, [internshipId, uploadFile]);

    useEffect(() => {
        let timer = null;
        let delay = !uploadError && progress ? 1000 : !uploadError && !progress ? 500 : 100;


        if (uploaded) {
           timer = setTimeout(() => {
                setIsUploading(false);
                closeModal();
            }, delay);
        }

        return () => {
            clearTimeout(timer);
            delay = undefined;
        }
    }, [uploaded, closeModal, progress, timeStamp, uploadError, isUploading]);

    useEffect(() => {
        if (uploadError) {
            setUploaded(false);
            setIsUploading(false);
        }
    }, [uploadError]);


    return (
        <Bem
            style={styles}
            mod={{
                "active-teacher": dragActive && teacher,
                "active-student": dragActive && student
            }}
        >
            <div
				id="form-file-upload"
				el="file-upload"
				onDragEnter={handleDrag}
			>
                <input
                    type="file"
                    name='myfile'
                    id="input-file-upload"
                    el="input"
                    accept=".pdf,.docx,doc,application/msword"
                    onChange={handleChange}
                />
                <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    el="label"
                    mod={{
                        teacher,
                        student,
                    }}
                >
                    <Icon
                        type={isUploading || uploadError ? "upload" : "upload-2"}
                        color={uploadError ? "red" : "blue"}
                        className={`${styles["file-upload__label--icon"]} ${
                            isUploading ? `${styles["file-upload__label--icon-active"]}` : ""
                        }`}
                    />
                    {!dragActive && !isUploading && !uploadError ? (
                        <span el="text">"Sleep hier een .PDF of een .DOCX om te uploaden"</span> ) : (
                        <div el="progress-bar-wrapper" >
                            <div
                                el="progress-bar__progress" style={{
                                    width: `${!uploadError && progress ? progress : !uploadError && !progress ? 10 : 100}%`,
                                    transition: `all ${timeStamp && !uploadError ? timeStamp : "0.5s"} ease-in-out`
                                }}
                                mod={{
                                    active: (!dragActive && isUploading) || (!isUploading),
                                    completed: uploaded && isUploading,
                                }}
                            ></div>
                            {uploadError && !isUploading ? (<span el="error">Controleer het bestandstype of de grootte van het bestand</span>) : (<span el="uploading">Uploaden...</span>) }
                        </div>)
                    }
                </label>
                <div el="upload-local">
                    <input
                        type="file"
                        id="upload-local"
                        el="input"
                        onChange={handleChange}
                        accept=".pdf,.docx,doc,application/msword"
                    />
                    <label htmlFor="upload-local" el="label">
                        Of selecteer een bestand van dit apparaat
                    </label>
                </div>
                <ul el="files-to-upload">
                    {file.map((file, index) => (
                        <FileToUpload
                            key={`file-${index}`}
                            file={file}
                            uploaded
                            uploadError
                        />
                    ))}
                </ul>
                {dragActive ? (
                    <div
                        id="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                        el="file-element"
                    ></div>
                ): null}
            </div>
        </Bem>
    );
}

export default memo(DragDropFile);
