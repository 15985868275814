import React from "react";

import { OverviewPage } from "../../components";
import { useLayout } from '../../hooks';

import { useInternshipApi } from '../../hooks/api';

const MijnBPV = () => {
    useLayout({ collapse: 'both' });

    const api = useInternshipApi();

    return (
        <OverviewPage
            api={api}
        />
    )
};

export default MijnBPV;
