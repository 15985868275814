import React from 'react';
import Bem from 'react-better-bem';

import { useLayout, usePageTitle } from '../../hooks';
import { useAppStateContext, useEnvironmentContext } from '../../context';
import { HomePageNavigation, Avatar } from '../../components';

import styles from './Home.module.scss';


const Home = () => {
    const { screen: { isntTablet } } = useAppStateContext();
    useLayout();
    usePageTitle();

    const { user, environment } = useEnvironmentContext();
    const { firstName, lastName, fullName, baseGroup, flexxId, roles } = user;

    const isStudent = environment === 'STUDENT';
    const showBaseGroup = !!(isStudent && baseGroup);

    return (
        <Bem style={styles}>
            <div el="tagline">
                <Avatar
                    className={styles.tagline__avatar}
                    size="xxl"
                    flexx_id={flexxId}
                    first_name={firstName}
                    last_name={lastName}
                    full_name={fullName}
                />
                <div el="text">
                    <h2 el="title">
                        Hoi {firstName}, {isntTablet ? <br /> : null}
                        welkom bij <strong>Flexx</strong>.
                    </h2>
                    {isStudent ? (<h3>{flexxId}</h3>) : null}
                    {showBaseGroup ? (<h3>Je zit in {baseGroup}.</h3>) : null}
                </div>
            </div>
            <HomePageNavigation roles={roles} />
        </Bem>
    );
};

export default Home;
