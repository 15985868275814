import React from 'react';
import Bem from 'react-better-bem';

import {
    ProgressCourseList,
    StudentProgressLinks,
} from '../../../components';

import { useLayout, usePageTitle } from '../../../hooks';

import styles from './StudentProgress.module.scss';

const StudentProgress = () => {
    useLayout({ stretch: false, collapse: 'top' });
    usePageTitle('progress.progress.student');

    /* * * * * *
     * RENDER  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <StudentProgressLinks />

            <section el="courses">
                <ProgressCourseList
                    role="student"
                />
            </section>
        </Bem>
    );
};

export default StudentProgress;
