import React from 'react';
import Bem from 'react-better-bem';

import styles from './Select.module.scss';
import { Label } from "../index";

import Select from 'react-select';

import { customSelectStyles } from './customStyles';

const CustomSelect = React.forwardRef(({
    id,
    name,
    label,
    value,
    placeholder = "",
    options = [],
    small = false,
    disabled = false,
    ...props
}, ref) => {
    return (
        <Bem style={styles}>
            <div el="wrapper" mod={{small}}>
                {label && <Label htmlFor={id} el="label">{label}</Label>}
                <Select
                    id={id}
                    name={name}
                    isSearchable={false}
                    options={options}
                    placeholder={"test"}
                    defaultValue={{ value: value || "\u00A0", label: value || "\u00A0" }} // Simulate an empty character
                    styles={customSelectStyles}
                    isDisabled={disabled}
                    ref={ref}
                    {...props}
                />
            </div>
        </Bem>
    );
});

export default CustomSelect;
