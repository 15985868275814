import { useEffect, useRef } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useLocalStorage } from '../hooks';

import { useAppStateContext } from '../context';

function usePageHistory (routes = []) {
    const { pathname } = useLocation();
    const { modal: { close: closeModal } } = useAppStateContext();

    const [history, setHistory] = useLocalStorage('page-history', [], { reset: true });

    // push to internal history on pathname change
    const prevPath = useRef();
    useEffect(() => {
        if (prevPath.current !== pathname) {
            prevPath.current = pathname;

            closeModal();

            const currentRouteName = routes.find(({ path, exactPath: exact }) => {
                return matchPath(pathname, { path, exact }) !== null;
            })?.name;

            if (currentRouteName) {
                setHistory((current) => {
                    if (current[0] !== currentRouteName.toUpperCase()) {
                        return [ currentRouteName.toUpperCase(), ...current ];
                    }

                    return [ ...current ];
                });
            }
        }
    }, [pathname, routes, setHistory, closeModal]);

    return {
        history,
        lastPage: history[0],
    };
}

export default usePageHistory;
