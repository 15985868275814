import { useCallback, useState } from 'react';
import useApi from '../useApi';

export default function useNewReportApi () {
    const { post, loading } = useApi('insturen verslag', '/coaching/reports/new/', { initialValue: {} });

    const [submitted, setSubmitted] = useState(false);
    const [newReportId, setNewReportId] = useState(null);

    const exposedPost = useCallback(async (title = '', content = '') => {
        post({ title, content })
            .then(({ error, data: { title, id } = {} } = {}) => {
                setSubmitted(id !== undefined && title !== undefined && !error);
                setNewReportId(id);
            })
    }, [post]);

    return {
        submit: exposedPost,
        submitting: loading,
        submitted,
        newReportId
    };
}
