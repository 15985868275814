import { useEffect, useMemo, useCallback, useState } from 'react';

import { dateToRelativeDateString, getCopy } from '../../utils';
import useApi from '../useApi';

export function useReportDetailsApi (role = 'student', reportId) {
    const apiUrl = role === 'student' ?
        `/coaching/reports/${reportId}` :
        `/coaching/${getCopy(`coaching.${role}.apiPrefix`)}/reports/${reportId}`;

    const { get, loading, value, fetched } = useApi('gespreksverslag', apiUrl, { initialValue: { report: {} } });

    // fetch initially
    const fetchWhen = !fetched && !loading && reportId !== undefined;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    const { report, response } = useMemo(() => {
        let responseText = '',
            responseUpdatedAt = '';

        const {
            has_read: hasRead,
            has_read_at: readAt,
            response,
            report: {
                title: topic,
                content: text,
                created_at,
            },
            student,
            coach,
        } = value;

        if (response !== null && response !== undefined) {
            responseText = response.content;
            responseUpdatedAt = response.updated_at;
        }

        return {
            report: {
                topic,
                text,
                hasRead,
                hasResponse: !!responseText?.length,
                readAt: hasRead ? dateToRelativeDateString(readAt) : null,
                createdAt: dateToRelativeDateString(created_at),
                author: student
            },
            response: {
                text: responseText,
                updatedAt: dateToRelativeDateString(responseUpdatedAt),
                author: coach,
            },
        };
    }, [value]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        report,
        response,
        loading,
        fetched
    };
}

export function useMarkReportAsRead (reportId) {
    const { post, loading } = useApi('markeer verslag als gelezen', `/coaching/reports/${reportId}/read/`, { initialValue: {} });

    const [markedAsRead, setMarkedAsRead] = useState(false);

    const exposedPost = useCallback(async () => {
        post({ has_read: true })
            .then(({ error, ...value }) => {
                if (!error) {
                    const { data : { has_read } } = value;
                    setMarkedAsRead(has_read);
                } else {
                    setMarkedAsRead(false);
                }
            });
    }, [post]);

    return {
        markAsRead: exposedPost,
        markedAsRead,
        markingAsRead: loading
    };
}

export function useUpdateReportResponse (reportId) {
    const { post, loading } = useApi('reageren op verslag', `/coaching/reports/${reportId}/respond/`, { initialValue: {} });

    const [responded, setResponded] = useState(false);

    const exposedPost = useCallback(async (message = '') => {
        post({ response: message })
            .then(({ error, ...value }) => {
                if (!error) {
                    const { data: { response } } = value;
                    setResponded(!!response?.length);
                } else {
                    setResponded(false);
                }
            });
    }, [post]);

    return {
        respond: exposedPost,
        responding: loading,
        responded
    };
}
