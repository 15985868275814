import React, { useMemo } from 'react';
import Bem from 'react-better-bem';

import { Button } from '../../';
import { useEnvironmentContext, useCoachingTraineeContext } from '../../../context';
import {
    getAttendanceEduarteUrl,
    getCalendarEduarteUrl,
    getCopy,
    ENABLE_STUDYROUTE,
} from '../../../utils';

import styles from './Links.module.scss';


const Links = ({ role = 'student', hasKm1, hasKm2, studentId, extraLinks = [], km1BlockNumber, km2BlockNumber, bpvEnabled = true, studyRouteEnabled = true }) => {
    const buttons = useMemo(() => {
        const copyRole = role === 'student' ? 'student' : 'teacher';
        const path = window.location.pathname

        const _buttons = [
            {
                title: getCopy(`coaching.${role}.attendanceOverview.title`),
                linkTitle: getCopy(`coaching.${role}.attendanceOverview.linkTitle`),
                href: getAttendanceEduarteUrl(copyRole, studentId),
                target: '_blank',
                rel: 'noreferrer',
                icon: 'external-link',
            },
            {
                title: getCopy(`progress.${role}.overview.title`),
                linkTitle: getCopy(`progress.${role}.overview.linkTitle`),
                to: getCopy(`progress.${role}.overview.path`, { studentId }),
            },
            {
                title: getCopy(`progress.${role}.competences.title`),
                linkTitle: getCopy(`progress.${role}.competences.linkTitle`),
                to: getCopy(`progress.${role}.competences.path`, { studentId }),
            },
        ];

        if (bpvEnabled) {
            _buttons.push({
                title: 'BPV',
                linkTitle: 'BPV',
                to: `${path}${role === 'student' ? '' : '/voortgang'}/mijn-bpv`,
            });
        }

        if (role === 'coach') {
            _buttons.push({
                title: getCopy(`progress.${role}.feedback.title`),
                linkTitle: getCopy(`progress.${role}.feedback.linkTitle`),
                to: getCopy(`progress.${role}.feedback.path`, { studentId }),
            })
        }

        // inject study route button
        if (ENABLE_STUDYROUTE && studyRouteEnabled) {
            _buttons.splice(2, 0, {
                title: getCopy(`progress.${role}.route.title`),
                linkTitle: getCopy(`progress.${role}.route.linkTitle`),
                to: getCopy(`progress.${role}.route.path`, { studentId }),
            });
        }

        if (hasKm2) {
            _buttons.unshift({
                title: getCopy(`progress.${role}.coursePlanner.km2Title`),
                linkTitle: getCopy(
                    `progress.${role}.coursePlanner.km2LinkTitle`,
                    { blockNumber: km2BlockNumber }
                ),
                to: getCopy(`progress.${role}.coursePlanner.km2Path`, { studentId }),
            });
        }

        if (hasKm1) {
            _buttons.unshift({
                title: getCopy(`progress.${role}.coursePlanner.km1Title`),
                linkTitle: getCopy(
                    `progress.${role}.coursePlanner.km1LinkTitle`,
                    { blockNumber: km1BlockNumber }
                ),
                to: getCopy(`progress.${role}.coursePlanner.km1Path`, { studentId }),
            });
        }

        extraLinks.forEach(([position, link]) => {
            _buttons.splice(position, 0, link);
        });

        return _buttons;
    }, [role, studentId, bpvEnabled, studyRouteEnabled, hasKm2, hasKm1, extraLinks, km2BlockNumber, km1BlockNumber]);

    return (
        <Bem style={styles}>
            <section el="links" mod="shaded">
                <div el="content">
                    {buttons.map(({ title, linkTitle, ...buttonProps }) =>
                        <div el="column" key={`link-${linkTitle}`}>
                            <h3>{title}</h3>
                            <Button
                                variant="small"
                                {...buttonProps}
                            >
                                Ga naar {linkTitle}
                            </Button>
                        </div>
                    )}
                </div>
            </section>
        </Bem>
    );
};

const StudentProgressLinks = () => {
    const { user: { bpvEnabled, studyRouteEnabled }, planning: { km1Student, km2Student } } = useEnvironmentContext();

    return (
        <Links
            role="student"
            hasKm1={km1Student !== undefined}
            hasKm2={km2Student !== undefined}
            km1BlockNumber={km1Student?.number}
            km2BlockNumber={km2Student?.number}
            bpvEnabled={bpvEnabled}
            studyRouteEnabled={studyRouteEnabled}
        />
    );
};

const CoachProgressLinks = ({ role }) => {
    const {
        planning: { km1Coach, km2Student },
        student: { flexx_id: studentId, bpv_active: bpvEnabled, study_route_active: studyRouteEnabled }
    } = useCoachingTraineeContext();

    const extraLinks = [
        [1, {
            title: getCopy('eduarteCalendar.title'),
            linkTitle: getCopy('eduarteCalendar.linkTitle'),
            href: getCalendarEduarteUrl(studentId),
            target: '_blank',
            rel: 'noreferrer',
            icon: 'external-link',
        }]
    ];

    return (
        <Links
            role={role}
            hasKm1={['coach', 'secondcoach'].includes(role) && km1Coach !== undefined}
            studentId={studentId}
            km1BlockNumber={km1Coach?.number}
            km2BlockNumber={km2Student?.number}
            bpvEnabled={bpvEnabled}
            studyRouteEnabled={studyRouteEnabled}
            extraLinks={extraLinks}
        />
    );
}

export { CoachProgressLinks, StudentProgressLinks };
