import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import { useApi } from '../';

import { refineCourseName } from '../../utils';

import PERIOD_PLANNING from '../../config/period_planning.json';

const INIT_VALUE = { available: [], requests: [] };

const PERIODS = PERIOD_PLANNING.map((period, index) => {
    const sm = moment(period.start, 'HH:mm');
    const em = moment(period.end, 'HH:mm');
    return {...period, period: index + 1, startMoment: sm, endMoment: em };
});


function useSecondMomentApi (studentId = false, role = 'student') {
    const getPath = studentId === false ?
        `/choices/moment2/` :
        `/students/${studentId}/choices/moment2/`;

    const { get, loading, value, fetched, error } = useApi('beschikbare cursussen km2', getPath, { initialValue: INIT_VALUE });

    // fetch initially
    const fetchWhen = !fetched && !loading && (role === 'student' || studentId !== false);
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    // catalog is a 'flat' list of all available and roster actual courses
    const [catalog, dispatchCatalog] = useReducer(catalogReducer, []);
    useEffect(() => { dispatchCatalog({ type: 'INIT', data: value }); }, [value]);

    // group available choices into groups as used on page
    const { roster, available, studyLoad } = useMemo(() => {
        const roster = catalog.filter(({ request: { isPlanned, isChoice } }) => isPlanned || isChoice);

        const available = catalog
            .filter(({
                meets_dependencies,
                availableSeats: [availableSeats],
                can_be_chosen,
                request: { isAvailable }
            }) => (
                isAvailable && can_be_chosen && meets_dependencies && availableSeats > 0
            ))
            .filter(({
                weekday: [weekday],
                startPeriod: [startPeriod],
                endPeriod: [endPeriod],
            }) => {
                // check if this course (available in period) overlaps
                // with another course already in the roster. If so: don't show as a choice
                const overlapsWithCourseInRoster = roster.some(({
                    weekday: [rDay],
                    startPeriod: [rStart],
                    endPeriod: [rEnd],
                }) => {
                    if (rDay !== weekday) {
                        return false;
                    }

                    const startOverlapsA = startPeriod >= rStart && startPeriod <= rEnd;
                    const endOverlapsA = endPeriod >= rStart && endPeriod <= rEnd;
                    const startOverlapsB = rStart >= startPeriod && rStart <= endPeriod;
                    const endOverlapsB = rEnd >= startPeriod && rEnd <= endPeriod;

                    return startOverlapsA || endOverlapsA || startOverlapsB || endOverlapsB;
                });

                return !overlapsWithCourseInRoster;
            });

        // by default sort available by time (weekday + periods)
        available.sort((a, b) => {
            const { weekday: [ad], startPeriod: [as], endPeriod: [ae] } = a;
            const { weekday: [bd], startPeriod: [bs], endPeriod: [be] } = b;

            if (ad !== bd) {
                return ad - bd;
            }

            if (as !== bs) {
                return as - bs;
            }

            return ae - be;
        });

        // calculate studyload
        const studyLoad = roster.reduce((acc, { study_load }) => acc + study_load, 0);

        return {
            roster,
            available,
            studyLoad,
        };
    }, [catalog]);

    /* * * * * * * * *
     * LOCAL METHODS *
    ** * * * * * * * */
    const choose = useCallback((actualCourseId) => {
        dispatchCatalog({ type: 'CHOOSE', actualCourseId });
    }, []);

    const deleteChoice = useCallback((actualCourseId) => {
        dispatchCatalog({ type: 'DELETE_CHOICE', actualCourseId });
    }, []);

    /* * * * * * * * * *
     * REMOTE METHODS  *
    ** * * * * * * * * */
    // make choices
    const { post: makeChoicesPost } = useApi('keuzes maken', `${getPath}request/`);

    const submitChoices = useCallback((catalog) => {
        const requested = catalog
            .filter(({ request: { isAvailable } }) => !isAvailable)
            .map(({ id: [id] }) => id);

        const deleted = catalog
            .filter(({ request: { isAvailable, isRemoteChoice } }) => isAvailable && isRemoteChoice)
            .map(({ id: [id] }) => id);

        const remoteChoices = catalog
            .filter(({ request: { isRemoteChoice } }) => isRemoteChoice)
            .map(({ id: [id] }) => id);

        // check if we should post:
        // either user has deleted a choice
        // or the list of remote choices is different from the requested list (user made choice)
        const madeChoice = requested.some((id) => !remoteChoices.includes(id)) ||
            remoteChoices.some((id) => !requested.includes(id));

        if (deleted.length || madeChoice) {
            const payload = { requested, deleted };

            makeChoicesPost(payload).then((response) => {
                if (typeof response === 'object' && ![response?.available, response?.requests].includes(undefined)) {
                    dispatchCatalog({ type: 'INIT', data: response });
                }
            });
        }
    }, [makeChoicesPost]);

    // automatically trigger submit after changing the catalog
    useEffect(() => {
        submitChoices(catalog);
        // const submit = () => { submitChoices(catalog); };
        // const submitTimer = setTimeout(submit, 1000);
        // return () => {
        //     clearTimeout(submitTimer);
        // }
    }, [catalog, submitChoices]);

    // get up-to-date available seats
    const { post: fetchAvailableSeats } = useApi(
        'beschikbare plekken ophalen',
        `${getPath}capacity/`,
        {
            noGlobalLoading: true,
            noWarning: true,
        },
    );
    const getAvailableSeats = useCallback((actualCourseIds = []) => {
        return fetchAvailableSeats(actualCourseIds)
            .then((response) => {
                if (typeof response === 'object') {
                    dispatchCatalog({
                        type: 'UPDATE_AVAILABLE_SEATS',
                        seats: response,
                    });
                    return response;
                }
                return undefined;
            });
    }, [fetchAvailableSeats]);

    /* * * * * *
     * SORTING *
    ** * * * * */
    const [sortMethod, setSortMethod] = useState('ALPHABET');

    const getSortMethod = useCallback((method) => {
        setSortMethod(method);
    }, []);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        roster,
        available,
        studyLoad,
        periods: PERIODS,

        sortMethod,
        setSortMethod,

        choose,
        deleteChoice,
        getAvailableSeats,
        getSortMethod,

        loading,
        fetched,
        error,
    };
}

function catalogReducer (state, action) {
    const { type } = action;

    switch (type) {
        case 'INIT': {
            const { data: { available, requests } } = action;

            return available
                .map((av) => {
                    const {
                        id: aId,
                        scheduled_course: sc,
                        available_seats,
                        weekday,
                        start,
                        end,
                    } = av;

                    const request = requests.find(({ actual_course: ac }) => ac === aId);

                    const isAvailable = !request;
                    const isPlanned = !isAvailable && !!request?.is_actual;
                    const isChoice = !isAvailable && !isPlanned;

                    // if we get a choice from the backend at this point
                    // we can mark it as such, so we can later use this
                    // to figure out which choices were deleted
                    const isRemoteChoice = isPlanned || isChoice;

                    const [
                        startPeriod, endPeriod,
                        actualStartPeriod, actualEndPeriod,
                    ] = getPeriods(start, end);

                    return {
                        ...av,
                        id: [aId],
                        startPeriod: [startPeriod],
                        endPeriod: [endPeriod],
                        actualStartPeriod: [actualStartPeriod],
                        actualEndPeriod: [actualEndPeriod],
                        availableSeats: [available_seats],
                        weekday: [weekday],
                        scheduled_course: {
                            ...sc,
                            routeStatus: sc.route_status?.toUpperCase(),
                            course: refineCourseName(sc.course),
                        },
                        request: {
                            isAvailable,
                            isChoice,
                            isPlanned,
                            isRemoteChoice,
                            ...request,
                        },
                    };
                });
        }
        case 'CHOOSE': {
            const { actualCourseId } = action;

            //! CHECK the difference between the response received from the backend and that of the local state

            return state.map((ac) => {
                const { id: [id], request } = ac;

                // only allow choosing of available requests ↓
                if (id !== actualCourseId || !request.isAvailable) {
                    return ac;
                }

                return {
                    ...ac,
                    request: {
                        ...request,
                        isAvailable: false,
                        isChoice: true,
                    },
                };
            });
        }
        case 'DELETE_CHOICE': {
            const { actualCourseId } = action;

            return state.map((ac) => {
                const { id: [id], request } = ac;

                // you can only delete choices  ↓
                if (id !== actualCourseId || !request.isChoice) {
                    return ac;
                }

                return {
                    ...ac,
                    request: {
                        ...request,
                        isAvailable: true,
                        isChoice: false,
                    },
                };
            });
        }
        case 'UPDATE_AVAILABLE_SEATS': {
            const { seats } = action;
            const targetIds = Object.keys(seats);

            return state.map((ac) => {
                const { id: [id] } = ac;
                if (!targetIds.includes(`${id}`)) {
                    return ac;
                }

                return {
                    ...ac,
                    availableSeats: [seats[id]],
                };
            });
        }
        default: {
            return state;
        }
    }
}

export default useSecondMomentApi;

/**
 *
 * @param { StartTime } | when the scheduled course starts
 * @param { endTime } | when the scheduled course ends
 * @returns { Array} | an array of the indexes for both the startTime and the endTime from the PERIODS list
 */
function getPeriods (startTime, endTime) {
    let startPeriod = PERIODS.findIndex(({ startMoment, endMoment }) => {
        const sm = moment(startTime, "HH:mm");
        return sm.isSameOrAfter(startMoment) && sm.isBefore(endMoment);
    });

    if (startPeriod === -1) {
        startPeriod = 0;
    }

    let endPeriod = PERIODS.findIndex(({ startMoment, endMoment }) => {
        const em = moment(endTime, "HH:mm");
        return em.isAfter(startMoment) && em.isSameOrBefore(endMoment);
    });

    if (endPeriod === -1) {
        endPeriod = PERIODS.length - 1;
    }

    // output "actual periods" (take into account any breaks)
    let actualStartPeriod = null;
    let actualEndPeriod = null;

    if (!PERIODS[startPeriod].break) {
        const amountOfBreaksBeforePeriod = PERIODS.slice(0, startPeriod)
            .filter(({ break: _break }) => _break).length;
        // +1 because we were working with (0-based) indexes
        actualStartPeriod = startPeriod - amountOfBreaksBeforePeriod + 1;
    }

    if (!PERIODS[endPeriod].break) {
        const amountOfBreaksBeforePeriod = PERIODS.slice(0, endPeriod)
            .filter(({ break: _break }) => _break).length;
        // +1 because we were working with (0-based) indexes
        actualEndPeriod = endPeriod - amountOfBreaksBeforePeriod + 1;
    }

    // add 1 to both start and end, because we were working with (0-based) indexes
    startPeriod++;
    endPeriod++;

    return [startPeriod, endPeriod, actualStartPeriod, actualEndPeriod];
}

