import React from 'react';
import Bem from 'react-better-bem';

import styles from './LoadingDots.module.scss';

const LoadingDots = ({ amount = 3, char = '•', ...props }) => {

    return (
        <Bem style={styles}>
            <div {...props} el="dots">
                {new Array(amount).fill().map((_, index) => (
                    <span
                        key={`loading-dot-${index}`}
                        el="dot"
                        style={{
                            animationDelay: `${index * 180}ms`
                        }}
                    >
                        {char}
                    </span>
                ))}
            </div>
        </Bem>
    );
};

export default LoadingDots;
