import React from "react";

import styles from "./ToggleSlider2.module.scss";

const ToggleSlider2 = ({ checked, setChecked }) => {
    const handleToggle = () => {
        setChecked(!checked);
    };

    return (
        <label className={styles.sliderToggle}>
            <input type="checkbox" checked={checked} onChange={handleToggle} />
            <span className={styles.slider}></span>
        </label>
    );
};

export default ToggleSlider2;
