import React, { useEffect } from "react";
import Bem from "react-better-bem";

import { PageHeader } from "../../";
import { useBreadcrumbTrailContext } from "../../../context";

import { getCopy } from "../../../utils";

import styles from "./PageHeader.module.scss";

const BPVPageHeader = () => {
    const pageTitle = getCopy(`bpv.student.pageTitle`);

    const { put, remove } = useBreadcrumbTrailContext();

    useEffect(() => {
        const studentPageTitle = getCopy(`bpv.student.pageTitle`);
        const routePath = getCopy(`bpv.student.path`);

        put(
            {
                name: studentPageTitle,
                href: routePath,
            },
            0
        );

        return () => {
            remove(studentPageTitle);
            remove(routePath);
        };
    }, [put, remove]);

    return (
        <Bem style={styles}>
            <PageHeader
                collapsed={true}
                title={pageTitle}
                alignChildren="end"
            />
        </Bem>
    );
};

export default BPVPageHeader;
