import React, { useEffect} from 'react'
import { useParams } from 'react-router-dom';

import { getCopy } from '../../../utils';
import { useStudentApi, useInternshipApi } from '../../../hooks/api';
import { useLayout } from '../../../hooks';

import { useBreadcrumbTrailContext } from '../../../context'

import { OverviewPage } from '../../../components'

const Overview = ({ role }) => {
    const { student_id: studentId } = useParams();
    useLayout({ collapse: 'both' });

	const {
        student,
        get: getStudent,
        loading: studentLoading,
        fetched: studentFetched,
    } = useStudentApi(studentId);

	const fetchStudentWhen = studentId && !studentLoading && !studentFetched;

    useEffect(() => {
        if (fetchStudentWhen) {
            getStudent(studentId);
        }
    }, [fetchStudentWhen, getStudent, studentId]);

    const api = useInternshipApi(`${role}`, studentId);

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();

    useEffect(() => {
        const pageTitle = getCopy(`progress.${role}.overview.bpv`,
            { student: student?.full_name }
        );
        const path = getCopy(`progress.${role}.overview.path`, { studentId });

        put({
            name: pageTitle,
            href: path,
            studentLoading,
        }, role === 'student' ? 1 : 2);

        return () => {
            remove(pageTitle);
        };
    }, [remove, put, role, studentId, student, studentLoading]);

    return (
        <OverviewPage
            showHeader={false}
            api={api}
            studentName={student?.first_name}
        />
    )
}

export default Overview

