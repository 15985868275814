import React from 'react';
import Bem from 'react-better-bem';

import { getCopy } from '../../utils';

import styles from './ScoreBadge.module.scss';

import badgeFiles from '../../assets/badges';

import studieRouteIcons from "../../assets/studieRouteIcons";

const BADGES = {
    'small': {
        '-1': badgeFiles.sInvalid,
        0: badgeFiles.s0,
        1: badgeFiles.s1,
        2: badgeFiles.s2,
        3: badgeFiles.s3,
        4: badgeFiles.s4,
        unknown: badgeFiles.sUnknown,
    },
    'medium': {
        '-1': badgeFiles.mInvalid,
        0: badgeFiles.m0,
        1: badgeFiles.m1,
        2: badgeFiles.m2,
        3: badgeFiles.m3,
        4: badgeFiles.m4,
        unknown: badgeFiles.mUnknown,
    },
    'large': {
        '-1': badgeFiles.lInvalid,
        0: badgeFiles.l0,
        1: badgeFiles.l1,
        2: badgeFiles.l2,
        3: badgeFiles.l3,
        4: badgeFiles.l4,
        unknown: badgeFiles.lUnknown,
    },
};

const RATINGS = {
    '-1': getCopy('rating.competenceScores.invalid.name'),
    0: getCopy('rating.competenceScores[0].name'),
    1: getCopy('rating.competenceScores[1].name'),
    2: getCopy('rating.competenceScores[2].name'),
    3: getCopy('rating.competenceScores[3].name'),
    4: getCopy('rating.competenceScores[4].name'),
    unknown: getCopy('rating.competenceScores.unknown.name'),
};


const ScoreBadge = ({ size = 'small', value = 0, noAlt = false, notHandled = false, name, studieRoute, ...props }) => {
    size = Object.keys(BADGES).includes(size) ? size : 'small';
    let actualValue = typeof value !== 'string' ?
        Math.max(-1, Math.min(4, parseInt(value, 10))) :
        value;

    if (isNaN(actualValue)) {
        actualValue = 'unknown';
    }
    const src = !studieRoute ? BADGES[size][!notHandled ? actualValue : -1] : studieRouteIcons[name]

    return (
        <Bem style={styles}>
            <img
                {...props}
                el="badge"
                mod={{ size, 'route': studieRoute }}
                alt={noAlt ? '' : RATINGS[actualValue]}
                title={RATINGS[actualValue]}
                src={src}
            />
        </Bem>
    );
};

export default ScoreBadge;
