import React from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { GroupName, Avatar, IconButton } from '../';

import styles from './CourseGroupTile.module.scss';

const CourseGroupTile = ({ link, code, nPublishedRatings, students = [], ...props }) => {
    const nStudents = students.length;
    const allStudentsPublished = nPublishedRatings === nStudents;

    const tileContent = (
        <Bem style={styles}>
            <div el="content" mod={{ 'all-published': allStudentsPublished }}>
                <strong el="rated-students-count">
                    {nPublishedRatings} / {nStudents}
                </strong>
                <div el="avatars">
                    {students.slice(0, 3).map((student) => {
                        const {student_number: studentId, student_first_name: firstName, student_last_name: lastName, student_full_name: fullName} = student;
                        return (
                            <Avatar
                                {...student}
                                flexx_id={studentId}
                                first_name={firstName}
                                last_name={lastName}
                                full_name={fullName}
                                key={`avatar-${studentId}`}
                                className={styles.content__avatars__avatar}
                                size="l"
                            />
                        );
                    })}
                    {students.length > 3 ? (
                        <Avatar
                            fallback={`+${students.length - 3}`}
                            size="l"
                            className={styles.content__avatars__avatar}
                            inverted
                            noPhoto
                        />
                    ) : null}
                </div>
                <div el="title">
                    <GroupName code={code} className={styles['content__title__group-name']} />
                    {link && (
                        <IconButton
                            className={styles.content__title__button}
                            {...allStudentsPublished && {
                                icon: 'checkmark',
                                color: 'green',
                                border: true
                            }}
                        />
                    )}
                </div>
            </div>
        </Bem>
    );

    let tileClassName = styles.tile;
    if (allStudentsPublished) {
        tileClassName += ` ${styles['tile--all-published']}`;
    }

    if (link) {
        tileClassName += ` ${styles['tile--link']}`;
        return (
            <Link to={link} className={tileClassName} { ...props }>
                {tileContent}
            </Link>
        );
    }

    return (
        <div className={tileClassName} { ...props }>
            {tileContent}
        </div>
    );
};

export default CourseGroupTile;
