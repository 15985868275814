import { useEffect, useState } from 'react';
import { useSessionStorage } from './';

const PATHS = ['/', '/coaching', '/tweede-coach', '/beoordelen', '/mentoraat']

export default function useScrollRestoration ({ path }) {
    const [scrollPosition, setScrollPosition] = useSessionStorage(`scrollPosition-${path}`, 0);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const { pathname } = window.location;

        if (path !== pathname || !PATHS.includes(pathname)) {
            return
        }

        const handleScroll = () => {
            const { scrollY } = window;
            setScrollY(scrollY);
            setScrollPosition(scrollY);
            window.sessionStorage.setItem(`scrollPosition-1-${path}`, scrollPosition);
        };

        const cleanUp = () => {
            window.removeEventListener('scroll', handleScroll);
        };

        window.addEventListener('scroll', handleScroll);
        return () => cleanUp();

    }, [path, scrollPosition, scrollY]);

    return { scroll: scrollPosition };
}
