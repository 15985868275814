import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import useApi from '../useApi';

import { useEnvironmentContext } from '../../context';

import { cleanupCourseName } from '../../utils';

const CRITERIA_CATEGORY_SIZE = 3;

function useProgressOverviewApi (studentId) {
    // get planning parameters for filtering
    const { environment, planning: { currentYear, upcomingYear, latestYear, blocks } } = useEnvironmentContext();
    const defaultYear = currentYear || upcomingYear || latestYear;

    const [selectedYear, setSelectedYear] = useState({});
    const [selectedBlock, setSelectedBlock] = useState({});

    const [criteriaStartIndex, setCriteriaStartIndex] = useState(0);

    let apiPath = environment === 'TEACHER' ? `/students/${studentId}/progress` : `/progress`;
    apiPath = (selectedBlock?.blockId) ? `${apiPath }/block/${selectedBlock.blockId}` : `${apiPath }/year/${selectedYear?.yearId}`

    /* * * * * * * * * * * * *
     * GET PROGRESS OVERVIEW *
    ** * * * * * * * * * * * */
    const { get, loading, value, fetched } = useApi('Ophalen beoordelingsoverzicht', apiPath, { persist: true, initialValue: [] });

    /* * * * * * * * * * * * * * * *
     * REDUCER FOR RESULTS *
    ** * * * * * * * * * * * * * * */
    const [results, dispatchResults] = useReducer(resultsReducer, []);

    // fetch initially
    const fetchWhen = !fetched && !loading && !!(selectedYear?.yearId && selectedBlock?.blockId);
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    const dispatchWhen = fetched && !loading;
    useEffect(() => {
        if (dispatchWhen) {
            dispatchResults({
                type: 'INIT',
                value
            });
        }
    }, [dispatchWhen, value, value?.length]);

    useEffect(() => {
        const year = blocks.find(({ start_date, end_date, school_year }) => {
            return start_date && end_date && school_year === defaultYear;
        });

        const { school_year: yearLabel, school_year_id: yearId } = year || {};

        if (yearLabel && yearId) {
            setSelectedYear({ yearLabel, yearId });
        }
    }, [blocks, defaultYear])

    useEffect(() => {
        if (selectedYear?.yearId || selectedBlock?.blockId) {
            get();
        }
    }, [selectedYear?.yearId, selectedBlock?.blockId, get]);

    const criteriaMap = useMemo(() => {
        return value.reduce((acc, { course_criteria: criteria = [] }) => {
            criteria.forEach(({ type_id: id, name, code, group_order: groupOrder }) => {
                const criteriaExists = (acc[groupOrder] || []).some((item) => item.id === id)
                const hidden = (groupOrder-1) < criteriaStartIndex || (groupOrder-1) >= (criteriaStartIndex + CRITERIA_CATEGORY_SIZE);

                if (!acc[groupOrder]) {
                    acc[groupOrder] = [];
                }

                if (!criteriaExists) {
                    acc[groupOrder].push({ code, name, id, hidden });
                    acc[groupOrder].sort(({ id: a }, { id: b }) => {
                        return a < b ? -1 : b < a ? 1 : 0;
                    });
                }
            });

            return acc;
        }, [])
        .filter(Boolean)
        .map((group) => group.filter(Boolean));
    }, [value, criteriaStartIndex]);

     // update selection
    const toggleCourses = useCallback((subject) => {
        dispatchResults({
            type: 'TOGGLE_COURSE',
            subject,
        });
    }, []);

    return {
        criteriaMap,
        results,
        selectedYear,
        setSelectedYear,
        selectedBlock,
        setSelectedBlock,
        toggleCourses,
        criteriaStartIndex,
        setCriteriaStartIndex,
        loading,
        fetched
    };
}

function resultsReducer (state, action) {
    const { type, value } = action;

    switch (type) {
        case 'INIT': {
            // Send an array of all the unique courses that can be found in the data received
            //  course should all be grouped by subjects
            const uniqueSubjects = new Set();
            value.forEach(({ course_subject }) => {
                uniqueSubjects.add(course_subject);
            });

            // Create an array of all the subjects
            const subjects = Array.from(uniqueSubjects);
            subjects.sort();

            return subjects
                .map((subject) => {
                    let hasResultsColumn = false;
                    const courses = value
                        .filter(({ rating, course_subject: _subject }) => {
                            if (!rating || _subject !== subject) {
                                return false;
                            }

                            return true;
                        })
                        .map((groupCourse, i, allGroupCourses) => {
                            const {
                                id: groupCourseId,
                                sessions: _sessions,
                                block_year,
                                cohort_block: number,
                                course_criteria: criteria,
                                rating: {
                                    attendance,
                                },
                                course_id: courseId,
                                rating: ratings,
                            } = groupCourse;

                            // 'live sessions' OR ELSE 'admin portal sessions' OR ELSE from .env
                            const sessions = _sessions

                            const { cleanName } = cleanupCourseName(groupCourse);

                            // check if course has been followed by student multiple times
                            const duplicates = courseId !== undefined ?
                                allGroupCourses.filter(({ course_id }) => (
                                    course_id === courseId
                                )) : [];

                            // sort duplicates from old to new in order to define which try it was
                            duplicates.sort((courseA, courseB) => {
                                const { cohort_block: bA } = courseA;
                                const { cohort_block: bB } = courseB;

                                const a = bA;
                                const b = bB;

                                if (a === b) {
                                    return 0;
                                }

                                if (a === undefined) {
                                    return 1;
                                }

                                if (b === undefined) {
                                    return -1;
                                }

                                return a - b;
                            });

                            const nTry = 1 + duplicates.findIndex(({ id: _gcId }) => _gcId === groupCourseId);

                            // wasRepeated: _not_ the last time course was done by student
                            const wasRepeated = nTry > 0 && nTry < duplicates.length;

                            const { result_type: resultType } = ratings || {};

                            if (resultType) {
                                hasResultsColumn = true;
                            }

                            return {
                                groupCourseId,
                                cleanName,
                                block: {
                                    school_year: (block_year) ? `${block_year}/${parseInt(block_year) + 1}` : block_year,
                                    number
                                },
                                criteria,
                                sessions,
                                attendance,
                                ratings,
                                wasRepeated,
                                nTry,
                            };
                    });

                    return ({
                        subject,
                        expanded: true,
                        courses,
                        hasResultsColumn,
                        showToggle: courses.length >= 3,
                        hasCourses: courses.length
                    })
                })
                .filter(({ hasCourses }) => hasCourses);
        }
        case 'TOGGLE_COURSE': {
            const { subject } = action;

            return state.map((course) => {
                if (course.subject === subject) {
                    return ({ ...course, expanded: !course.expanded })
                }

              return ({ ...course })
            });

        }
        default: {
            return state;
        }
    }
}

export default useProgressOverviewApi;
