import React, { useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import useKey from '@rooks/use-key';
import Bem from 'react-better-bem';

import {
    Tooltip,
    Avatar,
    Icon,
    Toggle,
    ActionWheel,
    Button,
    Table,
    Input,
    GroupName,
    AnimatedSvg,
    AttendanceDisplay,
    Select
} from '../../../components';

import { FeedbackModal, CloseCourseModal } from './Modals';

import { useAppStateContext } from '../../../context';
import { getCopy, parseInput } from '../../../utils';

import STATUS from '../../../data/rating_statusses.json';
import RESULT_TYPE from '../../../data/result_types.json';
import tableLayout from './table_layout.json';

import desktopStyles from './GroupCourse.desktop.module.scss';
import globalStyles from './GroupCourse.module.scss';
const styles = { ...desktopStyles, ...globalStyles };

const MAX_SELECTED_STUDENTS_AVATARS = 9;

const GroupCourseDesktop = ({ api, groupCode }) => {
    const {
        modal: { open: openModal, hidden: modalHidden },
        // notifications: { success }
    } = useAppStateContext();

    const {
        criteria = [],
        students = [],
        sessions,
        block: { school_year: schoolYear } = {},

        updateResult,
        updateScore,
        updateStatus,
        updateFeedback,
        updateLocalFeedback,
        publish,

        selectStudent,
        selectAllStudents,
        sortStudents,
        sortDirection,
        sortProperty,
        filterStudents,

        loading,
        fetched
    } = api;

    // keyboard nav
    const nCriteria = criteria.reduce((acc, criteriaGroup) => acc + criteriaGroup.length, 0);

    const handleKbNavigation = useCallback(({ key, ...event }) => {
        const focussedElement = document.activeElement;
        if (['INPUT', 'TEXTAREA'].includes(focussedElement?.tagName) || !modalHidden) {
            return;
        }

        const nStudents = students.filter(({ visible }) => visible).length;
        const totalCriteria = nStudents * nCriteria;

        const currentTabIndex = focussedElement?.tabIndex;
        let newTabIndex = currentTabIndex;
        if (!currentTabIndex || currentTabIndex < 1) {
            newTabIndex = 1;
        } else {
            newTabIndex = currentTabIndex - 1;
            switch (key) {
                case 'w':
                case 'ArrowUp': {
                    newTabIndex -= nCriteria;
                    break;
                }
                case 'd':
                case 'ArrowRight': {
                    newTabIndex += 1;
                    break;
                }
                case 's':
                case 'ArrowDown': {
                    newTabIndex += nCriteria
                    break;
                }
                case 'a':
                case 'ArrowLeft': {
                    newTabIndex -= 1;
                    break;
                }
                default: {
                    return;
                }
            }
            newTabIndex = (totalCriteria + newTabIndex) % totalCriteria;
            newTabIndex++;
        }

        const newActiveElement = document.querySelector(`[tabindex="${newTabIndex}"]`);

        if (newActiveElement) {
            newActiveElement.focus();
        }

    }, [modalHidden, nCriteria, students]);

    useKey(['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft' , 'w', 'd', 's', 'a'], handleKbNavigation);

    const showResultsColumn = students.some((student) => student?.ratings?.resultType);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    const headerRow = useHeaderRowMemo(
        criteria,
        groupCode,
        showResultsColumn,
        {
            sort: sortStudents,
            direction: sortDirection,
            property: sortProperty
        }
    );

    const tableRows = useTableRowsMemo(
        students,
        criteria,
        openModal,
        sessions,
        updateStatus,
        updateFeedback,
        updateLocalFeedback,
        updateScore,
        updateResult,
        publish,
        selectStudent,
        schoolYear,
        showResultsColumn,
    );

    const selectAllRow = useSelectAllRowMemo(students, selectAllStudents, filterStudents);

    const selectedStudentsRow = useSelectedStudentsRowMemo(students, criteria, updateScore, publish, showResultsColumn);

    /* * * * * *
     * RENDER  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="page-content">
                {!loading && fetched && (
                    <Table
                        rows={[
                            headerRow,
                            selectAllRow,
                            selectedStudentsRow,
                            ...tableRows
                        ]}
                        className={styles.table}
                    />
                )}
            </div>
        </Bem>
    );
};

export default GroupCourseDesktop;



const useTableRowsMemo = (students, criteria, openModal, sessions, updateStatus, updateFeedback, updateLocalFeedback, updateScore, updateResult, publish, selectStudent, schoolYear, showResultsColumn) => {

    return useMemo(() => {
        const unsavedFeedbackButtonTitle = getCopy('rating.feedback.unsaved.buttonTitle');
        const emptyFeedbackButtonTitle = getCopy('rating.feedback.empty.buttonTitle');

        const publishedButtonTitle = getCopy('rating.published.published.buttonTitle');
        const publishableButtonTitle = getCopy('rating.published.publishable.buttonTitle');
        const unpublishedButtonTitle = getCopy('rating.published.unpublished.buttonTitle');

        const closedButtonTitle = getCopy('rating.closed.closed.buttonTitle');
        const closableButtonTitle = getCopy('rating.closed.closable.buttonTitle');

        return students
            .filter(({ visible }) => visible)
            .map((student, i) => {
                const {
                    flexx_id,
                    first_name,
                    last_name,
                    full_name,
                    base_group,
                    hasUnsavedFeedback,

                    selected,

                    completable,
                    publishable,
                    allowEmptyFeedback,
                    constrainRatings,
                    constrainClosingRatings,

                    ratings: {
                        id: ratingId,
                        attendance,
                        result,
                        resultType,
                        feedback: remoteFeedback,
                        localFeedback,
                        published,
                        status,
                        criteriaRatings,
                    }
                } = student;

                let nCriteria = criteria.reduce((acc, criteriaGroup) => acc + criteriaGroup.length, 0);
                let tabIndex = i * nCriteria;

                const criteriaCells = criteria.reduce((acc, criteriaGroup, ii, allCriteriaGroups) => ([
                    ...acc,
                    ...criteriaGroup.map((criterium, iii, allCriteriaInGroup) => {
                        const { typeId, neededForClosing } = criterium;

                        const value = criteriaRatings.find(({ typeId: _tId }) => typeId === _tId)?.score || 0;

                        // constrain action wheel if status = AFG && needed_for_closing
                        // or when status = VL/NVL
                        const constrain = constrainRatings || (constrainClosingRatings && neededForClosing);

                        tabIndex++;

                        return {
                            content: (
                                <ActionWheel
                                    value={value}
                                    setValue={(value) => {
                                        updateScore([ratingId], typeId, value);
                                    }}
                                    constrain={constrain}
                                    tabIndex={tabIndex}
                                />
                            ),
                            spaceAfter: ii < allCriteriaGroups.length - 1 && iii === allCriteriaInGroup.length - 1,
                            border: true,
                            ...tableLayout.criteria
                        };
                    })
                ]), []);

                const progressOverviewHref = getCopy(
                    'progress.teacher.overview.path',
                    { studentId: flexx_id }
                );
                const progressOverviewTitle = getCopy(
                    'progress.teacher.overview.title',
                    { student: student.full_name }
                );

                return [
                    {
                        content: (
                            <>
                                <Toggle
                                    type="checkbox"
                                    size="small"
                                    className={styles['select-row-toggle']}
                                    checked={selected}
                                    change={(checked) => { selectStudent(flexx_id, checked); }}
                                />
                                <Link
                                    className={styles['student']}
                                    title={progressOverviewTitle}
                                    to={progressOverviewHref}
                                >
                                    <Avatar
                                        flexx_id={flexx_id}
                                        first_name={first_name}
                                        last_name={last_name}
                                        full_name={full_name}
                                        className={styles['student__avatar']}
                                    />
                                    <span className={styles['student__name']}>
                                        {full_name}
                                    </span>
                                    <span className={styles['student__class']}>{base_group}</span>
                                </Link>
                            </>
                        ),
                        border: true,
                        ...tableLayout.cells[0]
                    },
                    ...criteriaCells,
                    showResultsColumn && {
                        content: (
                            resultType === RESULT_TYPE.OVG ?
                                <Select
                                    small={true}
                                    options={[{ value: "O", label: "O" }, { value: "V", label: "V" }, { value: "G", label: "G" }]}
                                    value={result || ""}
                                    disabled={!completable}
                                    onChange={({ value }) => updateResult(ratingId, resultType, value, true)}
                                /> :
                                <Input
                                    type="text"
                                    small={true}
                                    className={styles['student__result']}
                                    value={result || ""}
                                    noNotification={true}
                                    disabled={!completable}
                                    onChange={({ target }) => {
                                        parseInput(target);
                                        updateResult(ratingId, resultType, target.value);
                                    }}
                                />
                        ),
                        border: true,
                        spaceBefore: !!criteriaCells.length,
                        ...tableLayout.cells[1],
                        style: {
                            paddingLeft: 0
                        }
                    },
                    {
                        content: (
                            <AttendanceDisplay
                                attendance={attendance}
                                sessions={sessions}
                                studentId={flexx_id}
                                year={schoolYear}
                                eduarteMode="enter"
                                addLink
                            />
                        ),
                        border: true,
                        spaceBefore: (showResultsColumn) ? false : !!criteriaCells.length,
                        ...tableLayout.cells[(showResultsColumn) ? 2 : 1],
                        style: {
                            paddingLeft: (showResultsColumn) ? "1rem" : 0
                        }
                    },
                    {
                        content: (
                            <Button
                                {...(
                                    hasUnsavedFeedback ? {
                                        children: <AnimatedSvg type="dots" />,
                                        title: unsavedFeedbackButtonTitle
                                    } : {
                                        icon: 'edit',
                                        iconColor: 'blue',
                                        iconSize: 'large',
                                        title: !localFeedback.length ? emptyFeedbackButtonTitle : undefined
                                    }
                                )}
                                variant="mini"
                                onClick={() => {
                                    openModal((
                                        <FeedbackModal
                                            feedback={localFeedback}
                                            remoteFeedback={remoteFeedback}
                                            updateLocalFeedback={(newFeedback) => { updateLocalFeedback(ratingId, newFeedback); }}
                                            updateRemoteFeedback={(newFeedback) => { updateFeedback(ratingId, newFeedback); }}
                                            allowEmptyFeedback={allowEmptyFeedback}
                                        />
                                    ), <>Feedback <small className={styles['feedback-modal__student-name']}>aan {full_name}</small></>);
                                }}
                            />
                        ),
                        border: true,
                        ...tableLayout.cells[3]
                    },
                    {
                        content: (
                            <>
                                <Toggle
                                    type="radio"
                                    name={`course-state-${flexx_id}`}
                                    className={styles['state-toggle']}
                                    checked={status === STATUS.COMPLETED}
                                    disabled={!completable}
                                    color="green"
                                    change={(c) => {
                                        if (c) {
                                            updateStatus(ratingId, STATUS.COMPLETED);
                                        }
                                    }}
                                />
                                <Toggle
                                    type="radio"
                                    name={`course-state-${flexx_id}`}
                                    className={styles['state-toggle']}
                                    checked={status === STATUS.NOT_COMPLETED}
                                    disabled={!completable}
                                    color="red"
                                    change={(c) => {
                                        if (c) {
                                            updateStatus(ratingId, STATUS.NOT_COMPLETED);
                                        }
                                    }}
                                />
                            </>
                        ),
                        border: true,
                        ...tableLayout.cells[4]
                    },
                    {
                        content: (
                            <Bem style={styles}>
                                {published ? (
                                    <Icon
                                        type="eye"
                                        valign="middle"
                                        color="green"
                                        title={publishedButtonTitle}
                                    />
                                ) : publishable ? (
                                    <Button
                                        icon="eye-closed"
                                        iconColor="blue"
                                        transparent
                                        variant="extra-small"
                                        onClick={() => { publish([ratingId]); }}
                                        title={publishableButtonTitle}
                                    />
                                ) : (
                                    <Icon
                                        type="eye-closed"
                                        valign="middle"
                                        color="gray"
                                        title={unpublishedButtonTitle}
                                    />
                                )}
                            </Bem>
                        ),
                        border: 'last',
                        ...tableLayout.cells[5],
                        style: {
                            paddingRight: 0
                        }
                    },
                    {
                        content: status === STATUS.CLOSED ? (
                            <Icon
                                type="cross-circle"
                                valign="middle"
                                color="red"
                                title={closedButtonTitle}
                            />
                        ) : (
                            <Button
                                icon="cross-circle"
                                iconColor="blue"
                                transparent
                                variant="extra-small"
                                title={closableButtonTitle}
                                onClick={() => {
                                    openModal(
                                        (
                                            <CloseCourseModal
                                                closeCourse={() => {
                                                    updateStatus(ratingId, STATUS.CLOSED);
                                                }}
                                            />
                                        ),
                                        (
                                            <>
                                                <Icon type="info-circle" inline valign="middle" size="large" />
                                                {closableButtonTitle}
                                            </>
                                        )
                                    );
                                }}
                            />
                        ),
                        border: true,
                        ...tableLayout.cells[6]
                    }
                ].filter(Boolean)
            })
    }, [students, criteria, openModal, updateStatus, sessions, updateFeedback, updateLocalFeedback, updateScore, updateResult, publish, selectStudent, schoolYear, showResultsColumn]);
};

const useHeaderRowMemo = (criteria, groupCode, showResultsColumn, { sort, direction, property }) => {
    const progressAbbrRef = useRef();
    const publishedAbbrRef = useRef();
    const closedAbbrRef = useRef();
    const feedbackAbbrRef = useRef();
    const attendanceAbbrRef = useRef();
    const resultAbbrRef = useRef();

    return useMemo(() => {
        const resultTitle = getCopy('rating.tableHeaders.teacher.result.title');
        const resultTooltip = getCopy('rating.tableHeaders.teacher.result.tooltip');
        const attendanceTitle = getCopy('rating.tableHeaders.teacher.attendance.title');
        const attendanceTooltip = getCopy('rating.tableHeaders.teacher.attendance.tooltip');
        const feedbackTitle = getCopy('rating.tableHeaders.teacher.feedback.title');
        const feedbackTooltip = getCopy('rating.tableHeaders.teacher.feedback.tooltip');
        const statusTitle = getCopy('rating.tableHeaders.teacher.status.title');
        const statusTooltip = getCopy('rating.tableHeaders.teacher.status.tooltip');
        const publishedTitle = getCopy('rating.tableHeaders.teacher.published.title');
        const publishedTooltip = getCopy('rating.tableHeaders.teacher.published.tooltip');
        const closedTitle = getCopy('rating.tableHeaders.teacher.closed.title');
        const closedTooltip = getCopy('rating.tableHeaders.teacher.closed.tooltip');

        const criteriaHeaderCells = criteria.reduce((acc, criteriaGroup, i, allCriteriaGroups) => ([
            ...acc,
            ...criteriaGroup.map(({ code, name, description }, ii, allCriteriaInGroup) => ({
                content: (
                    <CriteriumHeaderTooltip
                        abbr={code}
                        title={name}
                    >
                        <strong>{name}</strong><br />
                        {description}
                    </CriteriumHeaderTooltip>
                ),
                bg: allCriteriaInGroup.length === 1 ? 'single' : ii === allCriteriaInGroup.length - 1 ? 'last' : true,
                spaceAfter: i < allCriteriaGroups.length - 1 && ii === allCriteriaInGroup.length - 1,
                ...tableLayout.criteria
            }))
        ]), []);

        return [
            {
                content: <GroupName code={groupCode} inheritcolor />,
                sort: {
                    sortable: true,
                    props: ['first_name', 'last_name'],
                    propNames: ['Sorteer op voornaam', 'Sorteer op achternaam'],
                    prop: property,
                    direction: direction,
                    callback: (dir, prop) => sort(dir, prop)
                },
                bg: criteriaHeaderCells.length ? true : 'single',
                ...tableLayout.cells[0]
            },
            ...criteriaHeaderCells,
            showResultsColumn && {
                content: (
                    <>
                        <abbr title={resultTooltip} ref={resultAbbrRef}>
                            {resultTitle}
                        </abbr>
                        <Tooltip target={resultAbbrRef} />
                    </>
                ),
                bg: 'first',
                spaceBefore: !!criteriaHeaderCells.length,
                ...tableLayout.cells[1]
            },
            {
                content: (
                    <>
                        <abbr title={attendanceTooltip} ref={attendanceAbbrRef}>
                            {attendanceTitle}
                        </abbr>
                        <Tooltip target={attendanceAbbrRef} />
                    </>
                ),
                bg: (showResultsColumn) ? true : 'first',
                spaceBefore: (showResultsColumn) ? false : !!criteriaHeaderCells.length,
                ...tableLayout.cells[(showResultsColumn) ? 2 : 1]
            },
            {
                content: (
                    <>
                        <abbr title={feedbackTooltip} ref={feedbackAbbrRef}>
                            {feedbackTitle}
                        </abbr>
                        <Tooltip target={feedbackAbbrRef} />
                    </>
                ),
                ...tableLayout.cells[3]
            },
            {
                content: (
                    <>
                        <abbr title={statusTooltip} ref={progressAbbrRef}>
                            {statusTitle}
                        </abbr>
                        <Tooltip target={progressAbbrRef} />
                    </>
                ),
                ...tableLayout.cells[4]
            },
            {
                content: (
                    <>
                        <abbr title={publishedTooltip} ref={publishedAbbrRef}>
                            {publishedTitle}
                        </abbr>
                        <Tooltip target={publishedAbbrRef} />
                    </>
                ),
                bg: 'last',
                ...tableLayout.cells[5]
            },
            {
                content: (
                    <>
                        <abbr title={closedTooltip} ref={closedAbbrRef}>
                            {closedTitle}
                        </abbr>
                        <Tooltip target={closedAbbrRef} />
                    </>
                ),
                bg: true,
                ...tableLayout.cells[6]
            }
        ].filter(Boolean);
    }, [criteria, sort, direction, property, groupCode, showResultsColumn]);
};

const useSelectAllRowMemo = (students, selectAllStudents, filterStudents) => {
    const visibleStudents = useMemo(() => ( students.filter(({ visible }) => visible) ), [students]);
    const allSelected = useMemo(() => ( visibleStudents.every(({ selected }) => selected) ), [visibleStudents]);
    const nVisible = visibleStudents.length;

    return useMemo(() => {
        return {
            collapse: true,
            cells: [
                {
                    content: (
                        <div className={styles['filter-row']}>
                            <Toggle
                                type="checkbox"
                                size="small"
                                className={`${styles['select-row-toggle']} ${styles['select-row-toggle--all']}`}
                                checked={allSelected && nVisible > 0}
                                change={(checked) => { selectAllStudents(checked); }}
                                label={`Alle studenten (${nVisible})`}
                                labelColor="blue-darkest"
                                disabled={!nVisible}
                            />
                            <Input
                                small
                                onChange={({ target }) => { filterStudents(target.value); }}
                                placeholder="Zoeken"
                                icon="spyglass"
                            />
                        </div>
                    ),
                    border: false
                }
            ]
        };
    }, [nVisible, allSelected, selectAllStudents, filterStudents]);
};

const useSelectedStudentsRowMemo = (students, criteria, updateScore, publish, showResultsColumn) => {
    const visibleStudents = useMemo(() => ( students.filter(({ visible }) => visible) ), [students]);
    const selectedStudents = useMemo(() => ( visibleStudents.filter(({ selected }) => selected) ), [visibleStudents]);

    const selectedCriteria = useMemo(() => {
        const _criteria = criteria.flat();
        return _criteria.map(({ typeId: ctId, ...criterium }) => {
            const selectedScore = selectedStudents.reduce((acc, { ratings: { criteriaRatings } }, i) => {
                const score = criteriaRatings.find(({ typeId: _ctId }) => _ctId === ctId)?.score;

                if (!i) {
                    return score;
                }

                if (score === acc) {
                    return acc;
                }

                return undefined;
            }, undefined);

            return { ...criterium, typeId: ctId, score: selectedScore };
        });
    }, [selectedStudents, criteria]);

    return useMemo(() => {
        if (selectedStudents.length < 2) {
            return [];
        }

        const allPublished = selectedStudents.every(({ ratings: { published } }) => published);
        const nonePublishable = selectedStudents.every(({ publishable }) => !publishable);
        const publishableIds = selectedStudents.filter(({ publishable }) => publishable).map(({ ratingId }) => ratingId);

        const criteriaCells = criteria.reduce((acc, criteriaGroup, i, allCriteriaGroups) => ([
            ...acc,
            ...criteriaGroup.map((criterium, ii, allCriteriaInGroup) => {
                const { typeId: ctId } = criterium;

                const selectedCriterium = selectedCriteria.find(({ typeId: _ctId }) => ctId === _ctId);
                let value = selectedCriterium?.score;

                if (value === undefined) {
                    value = '?';
                }

                return {
                    content: (
                        <ActionWheel
                            value={value}
                            setValue={(newValue) => {
                                const ratingIds = selectedStudents
                                    .filter(({ constrainRatings, constrainClosingRatings }) => {
                                        if (newValue > 0) {
                                            return true;
                                        }

                                        if (constrainRatings) {
                                            return false;
                                        }

                                        return !(constrainClosingRatings && selectedCriterium?.needed_for_closing);
                                    })
                                    .map(({ ratings: { id } }) => id);

                                updateScore(ratingIds, ctId, newValue);
                            }}
                        />
                    ),
                    spaceAfter: i < allCriteriaGroups.length - 1 && ii === allCriteriaInGroup.length - 1,
                    border: true,
                    bg: true,
                    ...tableLayout.criteria
                };
            })
        ]), []);

        const allPublishedButtonTitle = getCopy('rating.published.published.multiButtonTitle');
        const nonePublishedButtonTitle = getCopy('rating.published.unpublished.multiButtonTitle');
        const multiPublishableButtonTitle = getCopy('rating.published.publishable.multiButtonTitle');

        return [
            {
                content: (
                    <>
                        {selectedStudents.slice(0, MAX_SELECTED_STUDENTS_AVATARS).map((student, i) => (
                            <Avatar
                                key={`select-all-avatar-${student.flexx_id}`}
                                className={`${styles.avatar} ${styles['avatar--selected']}`}
                                {...student}
                            />
                        ))}
                        {selectedStudents.length > MAX_SELECTED_STUDENTS_AVATARS ? (
                            <Avatar
                                fallback={`+${selectedStudents.length - MAX_SELECTED_STUDENTS_AVATARS}`}
                                className={`${styles.avatar} ${styles['avatar--selected']}`}
                                noPhoto
                                inverted
                            />
                        ) : null}
                    </>
                ),
                ...tableLayout.cells[0],
                bg: true
            },
            ...criteriaCells,
            showResultsColumn && {
                content: null, // result
                ...tableLayout.cells[1],
                spaceBefore: !!criteriaCells.length,
                bg: true,
                shaded: true
            },
            {
                content: null, // presentie
                ...tableLayout.cells[(showResultsColumn) ? 2: 1],
                spaceBefore: (showResultsColumn) ? false : !!criteriaCells.length,
                bg: true,
                shaded: true
            },
            {
                content: null, // feedback
                ...tableLayout.cells[3],
                bg: true,
                shaded: true
            },
            {
                content: null, // voldaan/niet voldaan
                ...tableLayout.cells[4],
                bg: true,
                shaded: true
            },
            {
                content: (
                    <Bem style={styles}>
                        {allPublished ? (
                            <Icon
                                type="eye"
                                valign="middle"
                                color="green"
                                title={allPublishedButtonTitle}
                            />
                        ) : nonePublishable ? (
                            <Icon
                                type="eye-closed"
                                valign="middle"
                                color="gray"
                                title={nonePublishedButtonTitle}
                            />
                        ) : (
                            <Button
                                icon="eye-closed"
                                iconColor="blue"
                                transparent
                                variant="extra-small"
                                onClick={() => { publish(publishableIds); }}
                                title={multiPublishableButtonTitle}
                            />
                        )}
                    </Bem>
                ),
                ...tableLayout.cells[5],
                style: {
                  paddingRight: 0
                },
                bg: 'last',
                border: 'last'
            },
            {
                content: null, // afgesloten
                ...tableLayout.cells[6],
                bg: true,
                shaded: true
            }
        ].filter(Boolean);
    }, [selectedStudents, selectedCriteria, criteria, publish, updateScore, showResultsColumn]);
};

const CriteriumHeaderTooltip = ({ abbr, title, children, ...props }) => {
    const abbrRef = useRef();

    return (
        <>
            <abbr title={title} ref={abbrRef}>
                {abbr}
            </abbr>
            <Tooltip
                target={abbrRef}
            >
                {children}
            </Tooltip>
        </>
    )
};
