import { useEffect, useMemo } from 'react';
import useApi from '../useApi';

import { refineCourseName } from '../../utils';


function useProgressScheduledCourseApi (courseId, blockId) {
    const apiPath = `/courses/${courseId}/blocks/${blockId}/?criteria=true`;

    const { get, loading, value, fetched } = useApi('cursusinformatie', apiPath, { initialValue: {} });

    const fetchWhen = courseId !== undefined && blockId !== undefined && !loading && !fetched;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [fetchWhen, get]);

    const scheduledCourse = useCourseMemo(value);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        course: { scheduled_course: scheduledCourse },
        loading,
        fetched,
    };
}

export default useProgressScheduledCourseApi;

function useCourseMemo (value) {
    return useMemo(() => {
        const {
            criteria: _criteria = [],
            course: _course = {},
        } = (value || {});

        // remap criteria for easier use within <SmallRatings/>
        const criteria = _criteria
            .reduce((acc, crit) => {
                const {
                    type: {
                        id: ctId,
                        group_order: gOrder,
                        order,
                        code,
                        name,
                    },
                    description,
                    needed_for_closing: neededForClosing,
                } = crit;

                if (!acc[gOrder]) {
                    acc[gOrder] = [];
                }

                acc[gOrder][order] = {
                    typeId: ctId,
                    code,
                    name,
                    description,
                    neededForClosing,
                };

                return acc;
            }, [])
            .map((group) => group.filter(Boolean))
            .filter(Boolean);

        return {
            ...value,
            course: refineCourseName(_course),
            criteria,
        };
    }, [value]);
}
