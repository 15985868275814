import React, { useEffect, useRef } from 'react';
import Bem from 'react-better-bem';
import { useLocation, matchPath } from 'react-router-dom';

import { NavLink } from '../';

import { useAppStateContext, useEnvironmentContext, useAuthenticationContext } from '../../context';

import styles from './Navigation.module.scss';

const Navigation = ({ desktop = false }) => {
    const { nav: { opened: navOpen } } = useAppStateContext();
    const { navItems: { navbar } } = useEnvironmentContext();
    const { pathname } = useLocation();
    const { isAuthenticated } = useAuthenticationContext();

    const baseDelay = 300;
    const delayPerItem = 100;
    const itemPadding = 16;

    const activeItemRef = useRef();
    const lineRef = useRef();

    useEffect(() => {
        const navItem = activeItemRef.current;
        const line = lineRef.current;

        if (navItem && line) {
            line.style.left = `${navItem.offsetLeft + itemPadding}px`;
            line.style.width = `${navItem.offsetWidth - 2 * itemPadding}px`;
        } else if (line) {
            line.style.left = 0;
            line.style.width = 0;
        }
    }, [pathname, navbar]);

    if (!isAuthenticated) {
        return null;
    }

    return (
        <Bem style={styles}>
            <nav
                el="nav"
                mod={{ open: !desktop && navOpen, desktop }}
            >
                <ol el="list" mod={{ desktop }}>
                    {navbar.map(({ name, icon, href, path }, _i) => {
                        const isActive = matchPath(pathname, { path, exact: false }) !== null;

                        return (
                            <li
                                key={`nav-item-${name}`}
                                el="item"
                                mod={{ open: !desktop && navOpen, desktop }}
                                style={ desktop ? {} : {
                                    transitionDelay: navOpen ? `${baseDelay + _i * delayPerItem}ms` : '0ms'
                                }}
                                {...desktop && isActive && { ref: activeItemRef }}
                            >
                                <NavLink
                                    to={href}
                                    name={name}
                                    desktop={desktop}
                                    icon={icon}
                                />
                            </li>
                        );
                    })}
                </ol>
                {desktop && (
                    <div
                        el="line"
                        ref={lineRef}
                    />
                )}
            </nav>
        </Bem>
    );
}

export default Navigation;
