import React, { useMemo } from 'react';
import Bem from 'react-better-bem';

import { Icon } from '../../';

import styles from './FeedbackFilter.module.scss';

const FeedbackFilter = ({ allYears, allBlocks, defaultYear, selectedYear, setSelectedYear, selectedPeriod, setSelectedPeriod, }) => {
    const filterLinks = useMemo(() => {

        return (
            <>
                {allYears?.length ? (
                    <>
                        <h2>Schooljaar</h2>
                        <ul className={styles['filter-list']}>
                            {allYears.map(({ yearId, yearLabel }) => (
                                <li
                                    key={`filter-year-${yearId}`}
                                    className={styles['filter-list__item']}
                                >
                                    <button
                                        className={`${styles['filter-list__item__button']}${selectedYear?.yearId === yearId ? ' ' + styles['filter-list__item__button--current'] : ''}`}
                                        onClick={() => {
                                            setSelectedPeriod(null);
                                            setSelectedYear({ yearLabel, yearId })
                                        }}
                                    >
                                        {yearLabel}
                                        {defaultYear === yearLabel ? (
                                            <Icon
                                                type="calendar-today"
                                                size="small"
                                                color={selectedYear?.yearId === yearId ? 'black' : 'blue-darker'}
                                                className={styles['filter-list__item__button__icon']}
                                            />
                                        ) : null}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}
                {allBlocks?.length ? (
                    <>
                        <h2>Blokken</h2>
                        <ul className={styles['filter-list']}>
                            {allBlocks.map(({ number, period, blockId, yearId }) => (
                                <li
                                    key={`filter-period-${blockId}`}
                                    className={styles['filter-list__item']}
                                >
                                    <button
                                        className={`${styles['filter-list__item__button']}${selectedPeriod?.blockId === blockId ? ' ' + styles['filter-list__item__button--current'] : ''}`}
                                        onClick={() => setSelectedPeriod((c) => c?.blockId === blockId ? null : { number, period, blockId, yearId })}
                                    >
                                        Blok {number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}
            </>
        );
    }, [allYears, allBlocks, selectedYear?.yearId, defaultYear, setSelectedPeriod, setSelectedYear, selectedPeriod?.blockId]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            {filterLinks}
        </Bem>
    );
};

export default FeedbackFilter;
