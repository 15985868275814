import React, { useEffect } from 'react';

import { useLayout, usePageTitle, useScrollRestoration } from '../../../hooks';
import { useAppStateContext, useBreadcrumbTrailContext } from "../../../context";
import { PageHeader, PrintButton } from "../../../components";
import { getCopy } from "../../../utils";

import CoachGroupDesktop from "./CoachGroup.desktop";
import CoachGroupResponsive from "./CoachGroup.responsive";

import styles from './CoachGroup.module.scss';
import { useProgressCoachGroupApi } from "../../../hooks/api";

const CoachProgressGroup = () => {
    const { screen: { isSmallDesktop } } = useAppStateContext();
    useLayout({ stretch: false, collapse: 'vertical', flexContent: true, margin: false });
    usePageTitle(`progress.coachGroup`);

    const api = useProgressCoachGroupApi();
    const { loading, groupCode, selectedYear, selectedBlock } = api;

    usePageTitle(
        loading ? 'loading' : 'progressGroup',
        {
            course: groupCode || '',
            group: groupCode || '',
        }
    );

    const { pathname } = window.location;
    useScrollRestoration({ path: pathname });

    /* * * * * *
     * RENDER  *
    ** * * * * */
    return (
        <>
            <CoachGroupPageHeader
                loading={loading}
                courseName={groupCode}
                groupCode={groupCode}
                selectedYear={selectedYear}
                selectedBlock={selectedBlock}
            />
            {isSmallDesktop ? (
                <CoachGroupDesktop
                    groupCode={groupCode}
                    api={api}
                />
            ) : (
                <CoachGroupResponsive
                    groupCode={groupCode}
                    api={api}
                />
            )}
        </>
    );
};

const CoachGroupPageHeader = ({ groupCode, loading, selectedYear, selectedBlock }) => {
    const pageTitle = getCopy('progress.coachGroup.pageTitle');

    const title = loading ? '' : (
        <>
            {pageTitle}
        </>
    );

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { set } = useBreadcrumbTrailContext();
    useEffect(() => {
        set([]);
    }, [set, loading]);

    let printPath = `progress/${groupCode}/pdf`;
    printPath = (selectedBlock?.blockId) ? `${printPath}/block/${selectedBlock.blockId}` : `${printPath}/year/${selectedYear?.yearId}`

    return (
        <PageHeader
            title={title}
            collapsed
        >
            <PrintButton
                path={printPath}
                className={styles.content__printbutton}
            />
        </PageHeader>
    );
}

/* * * * * *
 * EXPORT  *
** * * * * */
export {
    CoachProgressGroup
};
