import React from 'react';
import Bem from 'react-better-bem';

import { ProgressGraph } from '../../';

import styles from './StudyLoadMeter.module.scss';

// const MIN_LOAD = parseInt(process.env.REACT_APP_FLEXX_WEEKLY_STUDYLOAD_MIN, 10);
const MAX_LOAD = parseInt(process.env.REACT_APP_FLEXX_WEEKLY_STUDYLOAD_MAX, 10);

// factor: how much hours is 1 unit of studyload?
const FACTOR = parseFloat(process.env.REACT_APP_FLEXX_WEEKLY_STUDYLOAD_FACTOR || 0.5);

const StudyLoadMeter = ({ load = 0, max = MAX_LOAD, label = false, ...props }) => {
    const studyLoad = Math.max(load, 0);

    let readableLoad = studyLoad * FACTOR;
    readableLoad = parseInt(readableLoad, 10) === readableLoad ?
        `${readableLoad}` :
        `${readableLoad.toFixed(1)}`.replace('.', ',');

    const GraphLabel = () => (
        <Bem style={styles} block="meter__graph">
            <div el="label">
                <span el="top">{readableLoad}</span>
                <span el="bottom">uur</span>
            </div>
        </Bem>
    );

    return (
        <Bem style={styles}>
            <div el="meter">
                {label && <div el="label">{label}</div>}
                <div el="graph">
                    <ProgressGraph
                        done={studyLoad}
                        total={MAX_LOAD}
                        label={<GraphLabel />}
                    />
                </div>
            </div>
        </Bem>
    );
};

export default StudyLoadMeter;
