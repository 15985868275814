import React from 'react';
import { ProgressImage } from '../';

import courseIcons from '../../assets/courseIcons';

import STATUS from '../../data/rating_statusses.json';

import styles from './CourseImage.module.scss';

const VALID_STATUSSES = Object.keys(STATUS).map((key) => STATUS[key]);
const VALID_CATEGORIES = Object.keys(courseIcons);
const VALID_SIZES = ['default', 'medium', 'small'];


const CourseImage = ({ src, category, ratingStatus, alt = '', size = 'default', inline = false, className, ...props }) => {
    size = VALID_SIZES.includes(size) ? size : VALID_SIZES[0];
    category = VALID_CATEGORIES.includes(category) ? category : 'def';
    ratingStatus = VALID_STATUSSES.includes(ratingStatus) ? ratingStatus : undefined;

    // inline layout only shows ratingStatus
    // so don't return anything if we don't have a ratingStatus
    if (inline && ratingStatus === undefined) {
        return null;
    }

    let classname = `${styles.course} ${styles[`course--size-${size}`]}`;
    let statusClassname = styles.course__status;
    const imgClassName = `${styles.course__img} ${styles[`course__img--size-${size}`]}`;

    if (className) {
        classname += ` ${className}`;
    }

    if (inline) {
        statusClassname += ` ${styles['course__status--inline']}`;
    }

    const imgSrc = src || courseIcons[category] || courseIcons['default'];

    return (
        <div {...props} className={classname}>
            {!inline ? (
                <img
                    className={imgClassName}
                    src={imgSrc}
                    alt={alt}
                />
            ) : null}
            {size === 'default' && ratingStatus !== undefined ? (
                <ProgressImage
                    status={ratingStatus}
                    className={statusClassname}
                />
            ) : null}
        </div>
    );
};

export default CourseImage;
