import React, { useState, useEffect, useCallback } from 'react';
import Bem from 'react-better-bem';

import {
    CourseImage,
    RouteStatusImage,
    IconButton,
    Icon,
    Button,
} from '../../';

import { getCopy } from '../../../utils';

import ROUTE_STATUSSES from '../../../data/route_statusses.json';

import styles from './Km2AvailableCourse.module.scss';

const WEEKDAYS = ['Ma', 'Di', 'Wo', 'Do', 'Vr'];


const Km2AvailableCourse = ({
    availableChoice,
    expanded = false,
    expand,
    hidePopover,
    autoExpand = false,
    addButton = false,
    deleteButton = false,
    viewButton = false,
    choose,
    delete: _delete,
    getAvailableSeats: _getAvailableSeats,
    keepOwnAvailabilityState = false,
    hilightSlots: _hilightSlots = false,
}) => {

    const {
        id,
        availableSeats,
        actualStartPeriod,
        actualEndPeriod,
        weekday,
        scheduled_course: {
            routeStatus,
            course: {
                cleanName: courseName,
                category: courseCategory,
                description: courseDescription,
            },
        },
    } = availableChoice;

    /* * * * * * * * * * * * * * * * * * * * * *
     * UPDATE AVAILABLE SEATS AND HILIGHTSLOTS *
    ** * * * * * * * * * * * * * * * * * * * * */
    const isExpanded = expanded || autoExpand;
    const [ownAvailableSeats, setOwnAvailableSeats] = useState(availableSeats[0]);

    const flatIds = id.join(';');
    const getAvailableSeats = useCallback(() => {
        const _ids = flatIds.split(';');
        _getAvailableSeats(_ids)
            .then((response) => {
                if (response !== undefined && !isNaN(response[_ids[0]])) {
                    setOwnAvailableSeats(response[_ids[0]]);
                }
            });
    }, [_getAvailableSeats, flatIds]);

    const tileHasButton = addButton || deleteButton;
    const hilightSlots = useCallback(() => {
        if (_hilightSlots) {
            const slots = availableChoice.id.map((id, index) => ({
                    id,
                    courseName: availableChoice.scheduled_course.course.cleanName,
                    weekday: availableChoice.weekday[index],
                    startPeriod: availableChoice.startPeriod[index],
                    endPeriod: availableChoice.endPeriod[index],
                    showButton: !tileHasButton,
            }));

            _hilightSlots(slots);
        }
    }, [availableChoice, tileHasButton, _hilightSlots]);

    // update availability on expanding
    useEffect(() => {
        if (isExpanded) {
            getAvailableSeats();
        }
    }, [isExpanded, getAvailableSeats]);

    useEffect(() => {
        if (isExpanded) {
            hilightSlots();
        }
    }, [isExpanded, hilightSlots]);

    // depending on wether this component should update
    // available seats itself (in modal) use availableSeats (from main reducer)
    // or ownAvailableSeats (internal state)
    const actualAvailableSeats = keepOwnAvailabilityState ? [ownAvailableSeats] : availableSeats;

    /* * * * *
     * COPY  *
    ** * * * */
    const readableRouteStatus = ROUTE_STATUSSES[routeStatus]?.name;

    const duration = actualEndPeriod[0] - actualStartPeriod[0] + 1;
    const durationTarget = duration === 1 ? 'single' : 'multiple';
    const durationText = getCopy(
        `coursePlanner.secondMoment.student.available.course.duration.${durationTarget}`,
        { duration }
    );

    const seatsTarget = availableSeats === 1 ? 'single' : 'multiple';
    const seatsText = getCopy(
        `coursePlanner.secondMoment.student.available.course.seats.${seatsTarget}`,
        { seats: availableSeats }
    );

    const addButtonText = getCopy('coursePlanner.secondMoment.student.available.course.add');
    const deleteButtonText = getCopy('coursePlanner.secondMoment.student.roster.course.delete');
    const viewButtonText = getCopy('coursePlanner.secondMoment.student.roster.view');

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="course" mod={{ expanded: isExpanded }}>
                <header el="header">
                    <div el="route-status">
                        <RouteStatusImage
                            status={routeStatus}
                        />
                        {readableRouteStatus ? (
                            <span el="text">{readableRouteStatus}</span>
                        ) : null}
                    </div>
                    <div el="button">
                        {!autoExpand ? (
                            <IconButton
                                color="blue"
                                icon="cross"
                                onClick={() => { expand(id[0]); }}
                            />
                        ) : null}
                    </div>
                    <div el="course">
                        <CourseImage
                            category={courseCategory}
                            className={styles.course__header__course__image}
                        />
                        <span el="name">{courseName}</span>
                        <span el="seats" title={seatsText}>
                            <Icon type="chair" color="blue" />
                            {actualAvailableSeats.join(';')}
                        </span>
                    </div>
                </header>
                <main el="content">
                    <ul el="list">
                        {courseDescription ? (
                            <li el="item" mod="description">
                                <Icon
                                    type="text"
                                    color="blue-darker"
                                    className={styles.course__content__list__item__icon}
                                />
                                <span el="text">{courseDescription}</span>
                            </li>
                        ) : null}
                        <li el="item">
                            <Icon
                                type="chair"
                                color="gray-darkest"
                                className={styles.course__content__list__item__icon}
                            />
                            <div el="text">
                                {actualAvailableSeats.map((seats, i, allSeats) => {
                                    const copyWithPeriod = allSeats.length > 1 ? 'withPeriod' : 'withoutPeriod';
                                    const copyCount = seats === 1 ? 'single' : 'multiple';
                                    const _weekday = WEEKDAYS[weekday[i] - 1];

                                    const start = actualStartPeriod[i];

                                    const seatsText = getCopy(
                                        `coursePlanner.secondMoment.student.available.course.seats.${copyWithPeriod}.${copyCount}`,
                                        { seats, weekday: _weekday, start }
                                    );

                                    return (
                                        <span el="line" key={`available-seats-${weekday[i]}-${start}`}>
                                            {seatsText}
                                        </span>
                                    );
                                })}
                            </div>
                        </li>
                        <li el="item">
                            <Icon
                                type="clock"
                                color="gray-darkest"
                                className={styles.course__content__list__item__icon}
                            />
                            <span el="text">{durationText}</span>
                        </li>
                    </ul>
                    {addButton && availableSeats[0] > 0 ? (
                        <div el="button">
                            <Button
                                variant="small"
                                icon="plus-sign"
                                iconPosition="start"
                                onClick={() => { choose(id[0]); }}
                            >
                                {addButtonText}
                            </Button>
                        </div>
                    ) : null}
                    {deleteButton ? (
                        <div el="button">
                            <Button
                                variant="small"
                                icon="trash"
                                iconPosition="start"
                                onClick={() => { _delete(id[0]); }}
                            >
                                {deleteButtonText}
                            </Button>
                        </div>
                    ) : null}
                    {viewButton ? (
                        <div el="button">
                            <Button
                                variant="small"
                                icon="eye"
                                iconPosition="start"
                                onClick={hidePopover}
                            >
                                {viewButtonText}
                            </Button>
                        </div>
                    ) : null}
                </main>
            </div>
        </Bem>
    );
};

export default Km2AvailableCourse;
