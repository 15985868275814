import React, { useMemo, useRef, useEffect } from 'react';
import Bem from 'react-better-bem';

import { ScoreBadge, Tooltip, Icon } from '../../';

import { getCopy } from '../../../utils';

import styles from './Competences.module.scss';


const SCORE_NAMES = {
    1: getCopy('rating.competenceScores[1].name'),
    2: getCopy('rating.competenceScores[2].name'),
    3: getCopy('rating.competenceScores[3].name'),
    4: getCopy('rating.competenceScores[4].name'),
};

const SCORE_COLORS = {
    1: getCopy('rating.competenceScores[1].color'),
    2: getCopy('rating.competenceScores[2].color'),
    3: getCopy('rating.competenceScores[3].color'),
    4: getCopy('rating.competenceScores[4].color'),
};

/* * * * *
 * GRAPH *
** * * * */
const CompetenceGraph = ({ scores = {}, invertLineColor = false }) => {

    const { min, max } = getMinMax(scores);
    const { averageOffset, averageColor, averageScoreName } = useAverageValues(scores);

    const lineHidden = min === 4 || max === 1 || min === max;

    // refs
    const dotScoreOneRef = useRef();
    const dotScoreTwoRef = useRef();
    const dotScoreThreeRef = useRef();
    const dotScoreFourRef = useRef();
    const averageRef = useRef();

    const scoreRefs =[dotScoreOneRef, dotScoreTwoRef, dotScoreThreeRef, dotScoreFourRef];

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="graph">
                <div el="boxes container" mod={{ invert: invertLineColor }}>
                    <div el="box" />
                    <div el="box" />
                    <div el="box" />
                </div>
                <div el="track container">
                    <div el="line" mod={{ min, max, hidden: lineHidden }} />
                    <div el="dots">
                        {scoreRefs.map((ref, i) => (
                            <div
                                el="dot"
                                mod={{ value: i + 1, hidden: !scores[i + 1] }}
                                data-value={scores[i + 1]}
                                ref={ref}
                                key={`track-dot-${i + 1}`}
                            />
                        ))}
                    </div>
                    <div
                        el="average"
                        mod={{ color: averageColor }}
                        style={{ left: `${averageOffset}%` }}
                        data-value-name={averageScoreName}
                        ref={averageRef}
                    />
                </div>
            </div>
            {scoreRefs.map((ref, i) => (
                <Tooltip target={ref} hover direction="top" align="left" key={`tooltip-${i + 1}`}>
                    <span>{SCORE_NAMES[i + 1]}:</span>
                    <br/>
                    <strong>{scores[i + 1]} keer beoordeeld</strong>
                </Tooltip>
            ))}
            <Tooltip target={averageRef} hover direction="top" align="left" large>
                <h6 className={`${styles['tooltip-title']} ${styles[`tooltip-title--${averageColor}`]}`}>
                    {averageScoreName}
                </h6>
                <div className={styles['tooltip-content']}>
                    <span className={styles['tooltip-content__icon']}>
                        <Icon type="info-circle" color="white" />
                    </span>
                    <p className={styles['tooltip-content__text']}>
                        Dit is de gemiddelde score berekend over alle beoordelingen tot nu toe.
                    </p>
                </div>
            </Tooltip>
        </Bem>
    );
};

/* * * * * *
 * LEGEND  *
** * * * * */
const CompetenceGraphLegend = () => {
    return (
        <Bem style={styles}>
            <div el="graph" mod="legend">
                <div el="badges container">
                    {[1, 2, 3, 4].map((value) => (
                        <div el="badge" mod={{ value }} key={`badge-value-${value}`}>
                            <ScoreBadge size="large" value={value} />
                            <span el="title">{SCORE_NAMES[value]}</span>
                        </div>
                    ))}
                </div>
            </div>
        </Bem>
    );
};

/* * * * * * * * * * * * *
 * COMPETENCE OVER BLOCK *
** * * * * * * * * * * * */
const CompetenceBlocksGraph = ({ scores, currentBlock = {} }) => {

    /* * * * * * * * * * *
     * CALCULATE VALUES  *
    ** * * * * * * * * * */
    // remap scores so we can use averagevalue hook
    const totalScores = scores.reduce((acc, { scores: _scores }) => {
        _scores.forEach((score) => acc[score]++);
        return acc;
    }, { 1: 0, 2: 0, 3: 0, 4: 0 });

    const { averageOffset } = useAverageValues(totalScores);

    /* * * * * * * * * * * * * *
     * SCROLL TO CURRENT BLOCK *
    ** * * * * * * * * * * * * */
    const { id: currentBlockId } = currentBlock;
    const graphRef = useRef();
    useEffect(() => {
        const graph = graphRef.current;
        const currentBlockColumn = graph?.querySelector(`[data-block="${currentBlockId}"]`);

        if (currentBlockColumn) {
            let offset = 0;
            let sibling = currentBlockColumn.previousSibling;

            while (sibling.getAttribute('data-block')) {
                offset += sibling.offsetWidth;
                sibling = sibling.previousSibling;
            }

            graph.scrollTo({
                left: parseInt(offset, 10),
                behavior: 'smooth'
            });
        }
    }, [currentBlockId]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="blocks-graph">
                <div el="grid">
                    <div el="line" mod={{ value: 1 }}/>
                    <div el="line" mod={{ dashed: true, sub: 1 }}/>
                    <div el="line" mod={{ value: 2 }}/>
                    <div el="line" mod={{ dashed: true, sub: 2 }}/>
                    <div el="line" mod={{ value: 3 }}/>
                    <div el="line" mod={{ dashed: true, sub: 3 }}/>
                    <div el="line" mod={{ value: 4 }}/>
                    <div
                        el="line"
                        mod="average"
                        style={{ bottom: `${averageOffset}%` }}
                    />
                </div>
                <div el="graph" ref={graphRef}>
                    <div el="column legend" mod="legend">
                        {[4, 3, 2, 1].map((value) => (
                            <div
                                el="badge"
                                mod={{ value }}
                                data-value={totalScores[value]}
                                key={`legend-badge-${value}`}
                            >
                                <ScoreBadge size="large" value={value} />
                            </div>
                        ))}
                    </div>
                    {scores.map(({ scores, block }) => (
                        <CompetenceBlockGraphColumn
                            key={`graph-col-${block.id}`}
                            block={block}
                            scores={scores}
                        />
                    ))}
                </div>
            </div>
        </Bem>
    )
};

const CompetenceBlockGraphColumn = ({ block, scores: _scores }) => {
    const { id: blockId, number: blockNumber } = block;

    // remap scores for calculations
    const scores = _scores.reduce((acc, score) => {
        acc[score]++;
        return acc;
    }, { 1: 0, 2: 0, 3: 0, 4: 0 });

    const { min, max } = getMinMax(scores);
    const { averageOffset, averageColor } = useAverageValues(scores);

    const lineHidden = min === 4 || max === 1 || min === max;

    return (
        <Bem style={styles} block="blocks-graph__graph">
            <div el="column" data-block={blockId}>
                <div el="line" mod={{ min, max, hidden: lineHidden }} />
                <div el="dots">
                    {[1, 2, 3, 4].map((value) => (
                        <div
                            el="dot"
                            mod={{ value, hidden: !scores[value] }}
                            data-value={scores[value]}
                            key={`graph-dot-${value}`}
                        />
                    ))}
                </div>
                <div
                    el="average"
                    mod={{ color: averageColor }}
                    style={{ bottom: `${averageOffset}%` }}
                />
                <span el="block">Blok {blockNumber}</span>
            </div>
        </Bem>
    );
};


/* * * * * * *
 * UTILITIES *
** * * * * * */
function getMinMax (scores) {
    const { min, max } = Object.keys(scores).reduce((acc, score) => {
        if (scores[score]) {
            return { min: score, ...acc, max: score }; // 🧙‍♂️
        }
        return acc;
    }, {});

    return { min, max };
}

function useAverageValues (scores) {
    return useMemo(() => {
        const amount = Object.keys(scores).reduce((acc, score) => acc + scores[score], 0);
        const total = Object.keys(scores).reduce((acc, score) => acc + scores[score] * (score - 1), 0);
        const average = amount ? total / amount : 0;

        const offset = parseInt(average / 3 * 100, 10);

        // map back to actual score (1 - 4)
        // to define color and name
        const mappedScore = Math.max(Math.min(Math.round(average + 1), 4), 1);

        return {
            average,
            averageOffset: offset,
            averageScore: mappedScore,
            averageColor: SCORE_COLORS[mappedScore],
            averageScoreName: SCORE_NAMES[mappedScore]
        };
    }, [scores]);
}

export { CompetenceBlocksGraph, CompetenceGraph, CompetenceGraphLegend };
