import React, { useContext } from 'react';
import { useUserEnvironment } from '../hooks';


const EnvironmentContext = React.createContext();

const EnvironmentContextProvider = ({ children }) => {

    const store = useUserEnvironment();

    return (
        <EnvironmentContext.Provider value={store}>
            {children}
        </EnvironmentContext.Provider>
    );
};

const useEnvironmentContext = () => useContext(EnvironmentContext);

export { EnvironmentContextProvider };
export default useEnvironmentContext
