import React from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { useEnvironmentContext, useAuthenticationContext, useAppStateContext } from '../../context';

import { Logo, FooterNavigation, FooterLegal, Icon } from '../../components';

import styles from './Footer.module.scss';

const Footer = () => {
    const { environment } = useEnvironmentContext();
    const { footer: { ref: footerRef } } = useAppStateContext();
    const { isAuthenticated } = useAuthenticationContext();

    if (!isAuthenticated) {
        return <footer ref={footerRef} />;
    }

    return (
        <Bem style={styles}>
            <footer
                el="footer"
                mod={['stretched', { env: environment.toLowerCase() }]}
                ref={footerRef}
            >
                <div el="background-image" />
                <div el="content" mod={{ stretched: false, padded: true }}>
                    <div el="wrapper" mod="nav">
                        <h4 el="title">
                            <Link to="/" title="Flexx Homepage" className={styles.footer__content__wrapper__title__logo}>
                                <Logo color="white" size="small"/>
                            </Link>
                        </h4>
                        <FooterNavigation />
                    </div>

                    <div el="wrapper" mod="contact">
                        <h4 el="title">Vragen of opmerkingen over Flexx?</h4>
                        <address>
                            <Icon type="email" color="footer-text" inline valign="middle"/> <a href="mailto:flexxsupport@rocva.nl" title="Email ons">flexxsupport@rocva.nl</a>
                        </address>
                    </div>

                    {/*environment === 'STUDENT' && (
                        <div el="wrapper" mod="contact">
                            <h4 el="title">Lorem Ipsum</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hic quoque suus est de summoque bono dissentiens dici vere Peripateticus non potest. Hoc etsi multimodis reprehendi.
                            </p>
                        </div>
                    )*/}


                    <div el="wrapper" mod="legal">
                        <FooterLegal />
                    </div>
                </div>

            </footer>
        </Bem>
    );
}

export default Footer;
