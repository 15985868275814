import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {PageHeader, PrintButton, ProgressOverviewHeaderContent, ScoreLegend } from '../../';
import { useCoachingTraineeContext, useBreadcrumbTrailContext, useFiltersContext } from '../../../context';
import { getCopy } from '../../../utils';

import styles from './PageHeader.module.scss';

const CoachingPageHeader = ({ role }) => {
    const { student, studentId, studentLoading } = useCoachingTraineeContext();
    const { selectedYear, selectedPeriod } = useFiltersContext();
    const { full_name: studentName } = student;

    /* * * * * * * * * * *
     * BREADCRUMB TRAIL  *
    ** * * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        const traineeListTitle = getCopy(`coaching.${role}.traineeList.title`);

        if (studentName || studentLoading) {
            const rootPath = getCopy(`coaching.${role}.path`);

            put({
                name: traineeListTitle,
                href: rootPath,
            }, 0);

            put({
                name: studentName,
                loading: studentLoading,
                href: encodeURI(`${rootPath}/${studentId}`),
            }, 1);
        }

        return () => {
            remove(traineeListTitle);
            remove(studentName);
        }
    }, [put, remove, studentName, studentId, studentLoading, role]);

    // insert header content when looking to progress overview page
    const overviewPath = getCopy(`progress.${role}.overview.path`, { studentId });
    const overviewMatch = useRouteMatch(overviewPath);
    const onProgressOverviewPage = overviewMatch !== null;

    const basePath = getCopy(`coaching.${role}.path`);
    const scheduledCourseMatch = useRouteMatch(`${basePath}/:student_id/voortgang/cursus/:course_id/:block_id`);
    const onBPVPage = useRouteMatch(`${basePath}/:student_id/voortgang/mijn-bpv`) !== null;
    const onMyTeamPage = useRouteMatch(`${basePath}/:student_id/voortgang/mijn-team`) !== null;
    const onSecondCoachPage = useRouteMatch(`${basePath}/:student_id/voortgang/tweede-coach`) !== null;
    const onScheduledCoursePage = scheduledCourseMatch !== null;
    const onStudieRoutePage = useRouteMatch(`${basePath}/:student_id/voortgang/studieroute`) !== null;
    const onFeedbackPage = useRouteMatch(`${basePath}/:student_id/feedback`) !== null;

    // const onBPVPage = isMatch

    const pageTitle = onScheduledCoursePage ?
        getCopy(`progress.${role}.pageTitle.scheduledCourse`) :
        onBPVPage || onMyTeamPage || onSecondCoachPage ?
        getCopy(`progress.${role}.pageTitle.bpv`) :
        getCopy(`coaching.${role}.title`);

    const collapseHeader = studentLoading || !!studentName?.length;

    const filterUrl = selectedPeriod?.blockId !== undefined ? `block/${selectedPeriod.blockId}/` : selectedYear?.yearId !== undefined ? `year/${selectedYear.yearId}/` :  ''
    const printPath = `feedback/${studentId}/excel/${filterUrl}`;

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <PageHeader
            collapsed={collapseHeader}
            title={pageTitle}
        >
            {onStudieRoutePage ? (<ScoreLegend
                studieRoute={onStudieRoutePage}
            />) : null }
            {onProgressOverviewPage ?
                <ProgressOverviewHeaderContent studentId={studentId} /> :
                null
            }
            {onFeedbackPage && <PrintButton
                path={printPath}
                className={styles.content__printbutton}
                isExport={true}
            />}
        </PageHeader>
    );
};

export default CoachingPageHeader;
