import React from 'react';
import Bem from 'react-better-bem';

import styles from './EmptyBox.module.scss';

import graphics from '../../../assets/graphics';

const EmptyBox = ({ children }) => {
    return (
        <Bem style={styles}>
            <div el="emptybox">
                <div el="graphic">
                    <img
                        src={graphics.confusedowl}
                        el="img"
                        alt=""
                    />
                </div>
                <p el="text">
                    {children}
                </p>
            </div>
        </Bem>
    );
};

export default EmptyBox;
