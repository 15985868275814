import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Bem from 'react-better-bem';

import { useEnvironmentContext } from '../../../../../context';
import { Popover, Icon, Badge, GroupName } from '../../../../';
import { getAttendanceEduarteUrl, getCopy } from '../../../../../utils';

import styles from './EventDetailsPopover.module.scss';

function EventDetailsPopover ({ event, onClose }) {
    const { environment } = useEnvironmentContext();

    const {
        id,
        title,
        type,
        isToday,
        date,
        start,
        end,
        teacher,
        canvasUrl,
        room,
        building,
        groupCourses = [],
    } = event || {};

    const selectedEventRef = useRef();

    if (!!event) {
        const eventNode = document.querySelector(`[data-event-id="${id}"]`);
        if (eventNode) {
            selectedEventRef.current = eventNode;
        }
    }

    const eventTypeIcon = getCopy(`calendar.type.${type}.icon`);
    const eventTypeName = getCopy(`calendar.type.${type}.name`) || type;

    const enterAttendanceUrl = getAttendanceEduarteUrl('teacher', null, 'enter');

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Popover
            mounted={!!event}
            attachedRef={selectedEventRef}
            onClose={onClose}
            title={title}
            subtitle={(
                <>
                    <Icon
                        type={eventTypeIcon}
                        color="t-header-title"
                        inline
                        valign="middle"
                    />
                    {eventTypeName}
                </>
            )}
        >
            <Bem style={styles}>
                <dl el="popover-dl">
                    <dt el="term">
                        <EventDetailsIcon type="calendar" alt="Datum" />
                    </dt>
                    <dd el="def">
                        {isToday ? <strong el="today-tag">Vandaag</strong> : null}
                        {date}
                    </dd>

                    <dt el="term">
                        <EventDetailsIcon type="clock" alt="Tijd" />
                    </dt>
                    <dd el="def">
                        {start} - {end}
                    </dd>

                    {room !== null || building !== null ? (
                        <dt el="term" mod="location">
                            <EventDetailsIcon type="location-marker" alt="Locatie" collapse />
                        </dt>
                    ) : null}
                    {room !== null || building !== null ? (
                        <dd el="def" mod="location">
                            {building && (<span el="building">{building}</span>)}
                            {room && (
                                <Badge
                                    background="calendar-event-badge-background"
                                    text="calendar-event-badge-text"
                                >
                                    {room}
                                </Badge>
                            )}
                        </dd>
                    ) : null}

                    {environment === 'STUDENT' ? (
                        <Bem style={styles} block="popover-dl">
                            {teacher?.length ? (
                                <dt el="term">
                                    <EventDetailsIcon type="teacher" alt="Docent" />
                                </dt>
                            ) : null}
                            {teacher?.length ? (<dd el="def">{teacher}</dd>) : null}
                            {groupCourses.length ? (
                                <Bem style={styles} block="popover-dl">
                                    <dt el="term" mod="groups">
                                        <EventDetailsIcon type="owl" alt="Cursusinformatie" />
                                    </dt>
                                    <dd el="def" mod="groups">
                                        {groupCourses.map(({ group_code, id }) => (
                                            <Link
                                                key={`detailspopoverlink-${id}`}
                                                className={`${styles['popover-dl__def__line']} ${styles['popover-dl__def__link']}`}
                                                to={`/voortgang/cursus/${id}`}
                                            >
                                                {groupCourses.length > 1 ? (
                                                    `Voortgang (${group_code})`
                                                ) : (
                                                    'Studievoortgang'
                                                )}
                                                <span className={styles['popover-dl__def__link__icon-wrapper']}>
                                                    <Icon
                                                        type="chevron-right"
                                                        size="small"
                                                        valign="middle"
                                                        color="red-darkest"
                                                        className={styles['popover-dl__def__link__icon-wrapper__icon']}
                                                    />
                                                </span>
                                            </Link>
                                        ))}
                                    </dd>
                                </Bem>
                            ) : null}
                        </Bem>
                    ) : environment === 'TEACHER' ? (
                        groupCourses.length ? (
                            <Bem style={styles} block="popover-dl">
                                <dt el="term" mod="groups">
                                    <EventDetailsIcon type="owl" alt="Groepen" collapse />
                                </dt>
                                <dd el="def" mod="groups">
                                    {groupCourses.map(({ group_code, id }) => (
                                        <Link className={`${styles['popover-dl__def__line']} ${styles['popover-dl__def__link']}`} to={`/beoordelen/${id}`}>
                                            <GroupName code={group_code} />
                                            <span className={styles['popover-dl__def__link__icon-wrapper']}>
                                                <Icon
                                                    type="chevron-right"
                                                    size="small"
                                                    valign="middle"
                                                    color="blue-darker"
                                                    className={styles['popover-dl__def__link__icon-wrapper__icon']}
                                                />
                                            </span>
                                        </Link>
                                    ))}
                                </dd>
                            </Bem>
                        ) : null
                    ) : null}

                    {canvasUrl ? (
                        <dt el="term" mod="canvas">
                            <EventDetailsIcon type="info-circle" alt="Canvas" />
                        </dt>
                    ) : null}
                    {canvasUrl ? (
                        <dd el="def" mod="canvas">
                            <a
                                href={canvasUrl}
                                rel="noreferrer"
                                target="_blank"
                                el="link"
                            >
                                Canvas cursusinformatie
                                <Icon
                                    type="external-link"
                                    size="small"
                                    valign="middle"
                                    color={environment === 'TEACHER' ? 'blue-darker' : 'red-darkest'}
                                    className={styles['popover-dl__def__link__icon']}
                                />
                            </a>
                        </dd>
                    ) : null}

                    {environment === 'TEACHER' ? (
                        <Bem style={styles} block="popover-dl">
                            <dt el="term">
                                <EventDetailsIcon type="external-link" alt="Externe link" />
                            </dt>
                            <dd el="def">
                                <a
                                    href={enterAttendanceUrl}
                                    rel="noreferrer"
                                    target="_blank"
                                    el="link"
                                >
                                    Presentie invoeren
                                </a>
                            </dd>
                        </Bem>
                    ) : null}

                </dl>
            </Bem>
        </Popover>
    );
}

export default EventDetailsPopover;

function EventDetailsIcon ({ type, alt, collapse }) {

    let className = styles['popover-dl__icon'];
    if (collapse) {
        className += ` ${styles['popover-dl__icon--collapsed']}`;
    }

    return (
        <Icon
            type={type}
            color="calendar-event-badge-background"
            alt={alt}
            className={className}
        />
    );
}
