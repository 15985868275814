import React from 'react';
import Bem from 'react-better-bem';

import { useEnvironmentContext, useAppStateContext } from '../../context';

import { LoadingDots, BreadCrumbNav } from '../../components';

import styles from './PageHeader.module.scss';

const VALID_ALIGN_CHILDREN = ['start', 'end'];

const PageHeader = ({
    title,
    subtitle,
    children,
    bigPopover,
    trail = [],
    collapsed = false,
    alignChildren = 'start',
}) => {
    const { environment } = useEnvironmentContext();
    const { loading, pageTitle: { ref } } = useAppStateContext();

    alignChildren = VALID_ALIGN_CHILDREN.includes(alignChildren) ?
        alignChildren :
        VALID_ALIGN_CHILDREN[0];

    return (
        <Bem style={styles}>
            <header
                el="header"
                mod={{
                    env: environment.toLowerCase(),
                    collapsed,
                    "big-popover": bigPopover
                }}
                ref={ref}
            >
                <div el="space"></div>
                <div el="content">
                    <div el="title">
                        <h1 el="title">
                            {title}
                            {loading ? (<span el="loading"><LoadingDots/></span>) : null}
                        </h1>
                        {subtitle ? (<h2 el="subtitle">{subtitle}</h2>) : null}
                        <div el="crumbs">
                            <BreadCrumbNav />
                        </div>
                    </div>
                    {children ? (
                        <div el="children" mod={{ align: alignChildren }}>
                            {children}
                        </div>
                    ) : null}
                </div>
            </header>
        </Bem>
    );
};

export default PageHeader;
