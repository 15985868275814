import { useEffect, useMemo } from 'react';
import useApi from '../useApi';

import { useEnvironmentContext } from '../../context';
import { refineCourseName } from '../../utils';

import STATUS from '../../data/rating_statusses.json';

const NOT_COMPLETED_STATUSSES = [STATUS.NOT_COMPLETED];
const LEGACY_YEARS = (process.env.REACT_APP_FLEXX_LEGACY_YEARS || '').split(',');

const INIT_VALUE = { scheduled: [], planning: [] };


function useProgressRouteApi (studentId, planning) {
    const { environment } = useEnvironmentContext();
    const apiPath = environment === 'TEACHER' ? `/students/${studentId}/studyroute` : '/studyroute';

    const { get, loading, value, fetched } = useApi('studieroute', apiPath, { persist: true, initialValue: INIT_VALUE });

    // fetch initially
    const fetchWhen = !fetched && !loading;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);


    const { route, notPlanned, maxCoursesPerBlock, _bpvRequiredCourses, _bpvRequiredCourses2, _totalBpvCourses, _totalBpvOneCompletedCourses, remainingBpvOneCourses } = useMemo(() => {
        if (!planning?.blocks?.length) {
            return { route: [], notPlanned: [], maxCoursesPerBlock: 0 };
        }

        const {
            planning: _yearPlanning,     // meerjarenplanning
            scheduled: _studentSchedule,  // rooster
        } = value;

        const _bpvRequiredCourses = _studentSchedule.filter(({ scheduled_course: { bpv_required } }) => bpv_required === 1);
        const _bpvRequiredCourses2 = _yearPlanning.filter(({ bpv_required  }) => bpv_required === 1);


        const _totalBpvCourses = _bpvRequiredCourses2 && _bpvRequiredCourses2.length;
        const _totalBpvOneCompletedCourses = _bpvRequiredCourses.filter( ( { ratings } ) => ratings[0]?.status === 'Voldaan').length;

        const remainingBpvOneCourses = _bpvRequiredCourses2.filter( ( { course: { id }  } ) => !_studentSchedule.find( ( { scheduled_course: { course: { id: id2 } } } ) => id === id2 ) );

        // Make route:
        // 1a. turn yearPlanning into same format as studentSchedule
        // 1b. (temp) remove 'verbredend' route status from studentSchedule
        // 2.  turn yearPlanning and studentSchedule into list of block (ids)
        // 3.  check per block if there is a studentSchedule for this block
        // 4a. YES: _only_ use studentSchedule for this block
        // 4b. NO: use yearPlanning for this block
        //      => there should be no block with both studentSchedule and yearPlanning
        // 5.  filter only courses which have been marked as rateable

        // Step 1a
        const yearPlanning = _yearPlanning
            .map((scheduled_course) => ({
                scheduled_course,
                ratings: {},
                id: `sch_${scheduled_course.id}`,
                isScheduledCourse: true,
            }));

        // Step 1b
        const studentSchedule = _studentSchedule
            .map(({ scheduled_course: sc, ...planned_course }) => ({
                ...planned_course,
                scheduled_course: {
                    ...sc,
                    route_status: sc.route_status?.toUpperCase() === 'VERBREDEND' ?
                        undefined :
                        sc.route_status,
                },
            }));

        // Step 2
        const route = [...yearPlanning, ...studentSchedule]
            .reduce((acc, gc) => {
                const { scheduled_course: { block: { id: blockId } } } = gc;

                if (!acc.includes(blockId)) {
                    acc.push(blockId);
                }

                return acc;
            }, [])
            .map((blockId) => {
                const filterByBlock = (gc) => {
                    const { scheduled_course: { block: { id: _blockId } } } = gc;
                    return _blockId === blockId;
                };

                // Step 3 + 4a
                const studentScheduleCourses = studentSchedule.filter(filterByBlock);
                if (studentScheduleCourses.length) {
                    return { id: blockId, courses: studentScheduleCourses };
                }

                // Step 4b
                return {
                    id: blockId,
                    courses: yearPlanning.filter(filterByBlock),
                };
            })
            .reduce((acc, { id, courses }) => {
                // Step 5
                const remappedCourses = courses
                    .filter(filterByCanBeRatedOnly)
                    .map((gc) => {
                        return {
                            ...gc,
                            scheduled_course: {
                                ...gc.scheduled_course,
                                course: refineCourseName(gc.scheduled_course.course),
                            },
                        };
                    });

                remappedCourses.sort(sortGroupCourses);

                return { ...acc, [id]: remappedCourses };
            }, {});

        // Not Planned Courses list:
        // Courses in studentSchedule which
        //  - have been rated negatively last known time AND
        //  - for which course is not referenced in current block or later blocks AND
        //  - which was not last rated in a 'legacy year'
        // => make 'unique', only take last known group course

        const notPlanned = studentSchedule
            .filter((groupCourse, i, allGroupCourses) => {
                const {
                    id: gcId,
                    scheduled_course: {
                        course: { id: cId },
                        block: { number: blockNumber = 1, school_year: year },
                    },
                    ratings: [{ status } = {}] = [],
                } = groupCourse;

                // if not rated negatively => don't show in list
                if (!NOT_COMPLETED_STATUSSES.includes(status)) {
                    return false;
                }

                // if we find a group course in a _later_ block referencing
                // the same course => don't show in list
                const sameCourseInLaterGC = allGroupCourses.some((course) => {
                    const {
                        id: _gcId,
                        scheduled_course: {
                            course: { id: _cId },
                            block: { number: _blockNumber = 1 },
                        },
                    } = course;

                    return gcId !== _gcId && cId === _cId && _blockNumber >= blockNumber;
                });

                if (sameCourseInLaterGC) {
                    return false;
                }

                // if we find a scheduled course in a _later_ block referencing
                // the same course => don't show in list
                const sameCourseInLaterSC = yearPlanning.some((course) => {
                    const {
                        scheduled_course: {
                            course: { id: _cId },
                            block: { number: _blockNumber = 1 },
                        },
                    } = course;

                    return cId === _cId && _blockNumber >= blockNumber;
                });

                if (sameCourseInLaterSC) {
                    return false;
                }

                // check if course was in a 'legacy year'
                // if so => don't show in list
                const courseInLegacyYear = LEGACY_YEARS.includes(year);

                if (courseInLegacyYear) {
                    return false;
                }

                return true;
            })
            .map((sc) => ({
                ...sc,
                scheduled_course: {
                    ...sc.scheduled_course,
                    course: refineCourseName(sc.scheduled_course.course),
                },
            }))
            .sort(sortGroupCourses);

        const maxCoursesPerBlock = Object.keys(route)
            .reduce((acc, block) => Math.max(acc, route[block].length), 0);

        return { route, notPlanned, maxCoursesPerBlock, _bpvRequiredCourses, _bpvRequiredCourses2, _totalBpvCourses, _totalBpvOneCompletedCourses, remainingBpvOneCourses };
    }, [value, planning]);

    return {
        route,
        notPlanned,
        maxCoursesPerBlock,
        _bpvRequiredCourses,
        _bpvRequiredCourses2,
        _totalBpvOneCompletedCourses,
        remainingBpvOneCourses,
        _totalBpvCourses,
        loading,
        fetched,
    };
}

export default useProgressRouteApi;

function filterByCanBeRatedOnly ({ scheduled_course: { course: { can_be_rated } } }) {
    return can_be_rated;
}

function sortGroupCourses (groupCourseA, groupCourseB) {
    const { scheduled_course : { course: { name: nameA } } } = groupCourseA;
    const { scheduled_course : { course: { name: nameB } } } = groupCourseB;

    return nameA < nameB ? -1 : nameB < nameA ? 1 : 0;
}
