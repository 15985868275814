import React, { useCallback, useState, useEffect, useRef } from 'react';
import Bem from 'react-better-bem';

import { useLayout, usePageTitle, useToggleState } from '../../../hooks';
import {
    // useCoachingTraineeContext,
    useEnvironmentContext,
    useAppStateContext,
} from '../../../context';
import {
    useSecondMomentApi,
    useNonRosteredCoursesApi ,
} from '../../../hooks/api';

import {
    ChoicesPageHeader,
    Km2Roster,
    Km2AvailableCourses,
    Km2AvailableCourse,
    TileContainer,
    CourseTile,
    Popover,
} from '../../../components';

import { getCopy, refineCourseName, hideFooterElement } from '../../../utils';

import styles from './SecondMoment.module.scss';

const MIN_HEIGHT = 1400;

const SecondMoment = ({ role, block, api, student, nonRosteredApi }) => {
    const calenderRef = useRef(null);
    const [ smallHeight, setSmallHeight ] = useState(0);
    const {
        screen: { isTablet },
        modal: { open: openModal, close: closeModal, hidden: modalHidden },
    } = useAppStateContext();
    useLayout({ stretch: true, collapse: 'both', stretchContent: true, margin: false });
    const [ isModalOpen, toggleModal ] = useState(true);

    const { actualAvailable } = nonRosteredApi;

    const {
        studyLoad,
        roster,
        available,
        choose,
        deleteChoice: _deleteChoice,
        getAvailableSeats,
        getSortMethod,
        sortMethod,
        // setSortMethod,
        periods,
        loading,
    } = api;

    /* * * * * * * * * * * * * * * * * * * *
     * STATES FOR SHOWING SLOTS IN ROSTER  *
    ** * * * * * * * * * * * * * * * * * * */
    // hilight slots where expanded courses will fit
    const [hilightedSlots, setHilightedSlots] = useState([]);
    const hilightSlots = useCallback((slots = []) => {
        setHilightedSlots(slots);
    }, []);

    // store state for 'active' period:
    // the period we're currently making choices for
    const [activePeriod, setActivePeriod] = useState([]);

    /**
     * These are only related to the popover which is also only for mobile
     */
    const [availableCourses, setAvailableCourses] = useState([]);
    const [popOverAvailableCourses, setPopOverAvailableCourses ] = useState(false);
    const [popOverAllAvailableCourses, setPopOverAllAvailableCourses ] = useState(false);
    const [showCourseInfo, setShowCourseInfo] = useState(false);
    const [bigPopover, setBigPopover] = useState(false)

    const chooseCourse = useCallback((actualCourseId) => {
        closeModal();
        setActivePeriod([]);
        setHilightedSlots([]);
        choose(actualCourseId);
        setAvailableCourses([]);
        setPopOverAvailableCourses(false);
        setPopOverAllAvailableCourses(false);
        setShowCourseInfo(false);
    }, [closeModal, choose]);

    const deleteChoice = useCallback((actualCourseId) => {
        closeModal();
        setHilightedSlots([]);
        _deleteChoice(actualCourseId);
        setShowCourseInfo(false)
    }, [closeModal, _deleteChoice, setShowCourseInfo]);

    /**
     * @param {Object} course
     * Open modal for timeslot with available choices
     * Check whether we are on tablet or not
     * On mobile we open PopOver instead of modal
     */
    const showAvailableCourses = useCallback((weekday, period) => {
        setActivePeriod([weekday, period]);
        hilightSlots([]);

        const availableInPeriod = available.filter(({
            weekday: [_day],
            startPeriod: [_start],
            endPeriod: [_end],
        }) => {
            if (_day !== weekday) {
                return false;
            }

            return period >= _start && period <= _end;
        });

        setAvailableCourses(availableInPeriod);
        setPopOverAvailableCourses(true);

        const modalTitle = getCopy(`coursePlanner.secondMoment.${role}.available.modalTitle`);

        if(isTablet) {
            openModal(
                <AvailableChoicesModal
                    choices={availableInPeriod}
                    choose={chooseCourse}
                    getAvailableSeats={getAvailableSeats}
                    hilightSlots={hilightSlots}
                />,
                modalTitle,
                { small: true },
            );
        }
    }, [openModal, available, chooseCourse, role, getAvailableSeats, hilightSlots, isTablet]);

    // open modal for chosen course (so you can delete it)
    const showChoiceModal = useCallback((actualCourseId) => {
        hilightSlots([]);

        const ac = roster.find(({ id: [id] }) => id === actualCourseId);
        if(isTablet) { // come back later
            openModal(
                <Km2AvailableCourse
                    availableChoice={ac}
                    autoExpand={true}
                    delete={deleteChoice}
                    getAvailableSeats={getAvailableSeats}
                    keepOwnAvailabilityState
                    deleteButton
                />,
                null,
                { small: true },
            );
        }
    }, [openModal, roster, deleteChoice, getAvailableSeats, hilightSlots, isTablet]);

    const notScheduledCourseTitle = getCopy(`coursePlanner.secondMoment.${role}.notScheduled.title`);

    // Open modal showing the courses that are not in the roster but are available
    useEffect(() => {
        if(actualAvailable) {
            openModal(
                <NotScheduledCourses
                    actualAvailable={actualAvailable}
                />,
                notScheduledCourseTitle,
                { small: false },
                true
            );
        }

        toggleModal(false);
    }, [isModalOpen, openModal, notScheduledCourseTitle, actualAvailable]);

    /**
     * Handles showing a Popover instead of a Modal with all available courses as the content
     */
    const showAllAvailableCoursesMobile = useCallback(() => {
        setAvailableCourses([]);
        setPopOverAvailableCourses(false);
        setPopOverAllAvailableCourses(true);
    }, []);

    /**
     * Handles showing the CourseInfo Popover instead of a modal for smaller screens
     */
     const [chosenCourse, setChosenCourse] = useState(null)
    const showCourseInfoMobile = useCallback((actualCourseId) => {

        const ac = roster.find(({ id: [id] }) => id === actualCourseId);
        setChosenCourse(ac)

        setAvailableCourses([]);
        setPopOverAvailableCourses(false);
        setPopOverAllAvailableCourses(false);
        setShowCourseInfo(true);
    }, [roster]);

    /**
     * All related to the popover on mobile devices because we don't use the modal
     */
    const { number } = block
    const [_course ] = hilightedSlots;
   const { courseName } = _course || {};
    const showPopOverAvailableCourses = !isTablet && availableCourses.length && popOverAvailableCourses;
    const showPopOverAllAvailableCourses = !isTablet && available.length && popOverAllAvailableCourses;
    const showCourseInfoPopover = !isTablet && showCourseInfo;
    const showPopOver = showPopOverAvailableCourses || showPopOverAllAvailableCourses || showCourseInfoPopover;

    const popOverTitle = showPopOverAvailableCourses ?
            getCopy(`coursePlanner.secondMoment.${role}.popover.availableCourseTitle`) :
            showPopOverAllAvailableCourses && !bigPopover ?
            getCopy(`coursePlanner.secondMoment.${role}.popover.allAvailableCoursesTitle`, { blockNumber: number }) :
            bigPopover ?
            getCopy(`coursePlanner.secondMoment.${role}.popover.bigPopoverTitle`, { courseName: courseName }) :
            showCourseInfoPopover ?
            getCopy(`coursePlanner.secondMoment.${role}.popover.courseInfo`) : null;

    const onPopoverClose = useCallback(() => {
        setAvailableCourses([]);
        setPopOverAvailableCourses(false);
        setPopOverAllAvailableCourses(false);
        setShowCourseInfo(false);
        setBigPopover(false);

            setHilightedSlots([]);
            setActivePeriod([]);

    }, [])

    const handlePopoverHide = useCallback(() => {
        setBigPopover(true);
        hideFooterElement();
        // setKm2RosterPopover(true);
        // setPopOverAllAvailableCourses((c) => !c);

    }, []);


    // Focus on what is happening here - cos its may be the cause of the reset of the highlighted state for the available course
    // try to use one state to manage both opening the modal and popover
    useEffect(() => {
        if(isTablet && modalHidden) {
            setActivePeriod([]);
            setHilightedSlots([]);
        }
    }, [isTablet, modalHidden]);

    useEffect(() => {
        const { screen: { height } } = window;

        if ((height < MIN_HEIGHT) && !loading && isTablet) {
            setSmallHeight(true);
            document.documentElement.style.fontSize = '85%';
            calenderRef.current && calenderRef.current.scrollIntoView({
                behavior: 'auto',
                block: "start",
                inline: "nearest"
            });
        }
    },[isTablet, loading])

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <>
            <ChoicesPageHeader
                km2
                role={role}
                block={block}
                student={student}
                studyLoad={studyLoad}
                onIntroductieClick={toggleModal}
                bigPopover={bigPopover}
            />
            <Bem style={styles}>
                <div el="grid" mod={{ "big-popover": bigPopover }} ref={calenderRef} style={{ height: `${!smallHeight ? "calc(100vh - 19.5rem)" : ""}`}}>
                    {isTablet ? (
                        <div el="col" mod="available">
                            <Km2AvailableCourses
                                role={role}
                                available={available}
                                sortMethod={sortMethod}
                                getAvailableSeats={getAvailableSeats}
                                getSortCriteria={getSortMethod}
                                hilightSlots={modalHidden ? hilightSlots : false}
                            />
                        </div>
                    ) : null}
                    <div el="col" mod="roster">
                        <Km2Roster
                            role={role}
                            block={block}
                            roster={roster}
                            available={available}
                            periods={periods}
                            loading={loading}
                            activePeriod={activePeriod}
                            hilightedSlots={hilightedSlots}
                            chooseCourse={chooseCourse}
                            showAvailableCourses={showAvailableCourses}
                            showChoiceModal={isTablet ? showChoiceModal : showCourseInfoMobile}
                            showAllAvailableCoursesMobile={showAllAvailableCoursesMobile}
                            bigPopover={bigPopover}
                        />
                    </div>
                </div>
            </Bem>
            <Popover
                mounted={showPopOver}
                title={popOverTitle}
                choiceMoment
                onClose={onPopoverClose}
                bigPopover={bigPopover}
            >
                {showPopOverAvailableCourses ? (

                    <AvailableChoicesModal
                        choices={availableCourses}
                        choose={chooseCourse}
                        getAvailableSeats={getAvailableSeats}
                        hilightSlots={hilightSlots}
                    />) : showPopOverAllAvailableCourses ? (
                       <Km2AvailableCourses
                            role={role}
                            available={available}
                            sortMethod={sortMethod}
                            getAvailableSeats={getAvailableSeats}
                            getSortCriteria={getSortMethod}
                            popover
                            hilightSlots={modalHidden ? hilightSlots : false}
                            viewButton
                            bigPopover={bigPopover}
                            hidePopover={handlePopoverHide}
                        />
                    ) : showCourseInfoPopover && chosenCourse ? (
                        <Km2AvailableCourse
                            expanded
                            expand={() => {console.log('hello')}}
                            delete={deleteChoice}
                            availableChoice={chosenCourse}
                            getAvailableSeats={getAvailableSeats}
                            hilightSlots={hilightSlots}
                            role={role}
                            deleteButton
                        />
                    ) : null
                }
            </Popover>
        </>
    );
};


const StudentSecondMoment = () => {
    const { planning: { km2Student: block = {} } = {} } = useEnvironmentContext();

    const {
        student: { km2: { current } = {} } = {},
        number: blockNumber,
    } = block;

    const api = useSecondMomentApi();
    const nonRosteredApi = useNonRosteredCoursesApi();

    usePageTitle('coursePlanner.secondMoment.student', { blockNumber });

    if (!current) {
        return null;
    }

    return (
        <SecondMoment
            role="student"
            block={block}
            api={api}
            nonRosteredApi={nonRosteredApi}
        />
    );
}

const CoachSecondMoment = () => {
    return null;
}

export { CoachSecondMoment, StudentSecondMoment };


const AvailableChoicesModal = (({ choices, choose, getAvailableSeats, hilightSlots }) => {
    /* * * * * * * * * * *
     * EXPAND LIST ITEM  *
    ** * * * * * * * * * */
    const removeHilightedSlots = useCallback(() => {
        hilightSlots([]);
    }, [hilightSlots]);

    const {
        toggled: expandedCourse,
        toggle: expand,
    } = useToggleState({ onReset: removeHilightedSlots });

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <ul>
            {choices.map((choice) => (
                <li key={`modal-available-choice-${choice.id[0]}`}>
                    <Km2AvailableCourse
                        availableChoice={choice}
                        expanded={expandedCourse === choice.id[0]}
                        expand={expand}
                        autoExpand={choices.length === 1}
                        choose={choose}
                        hilightSlots={hilightSlots}
                        getAvailableSeats={getAvailableSeats}
                        keepOwnAvailabilityState
                        addButton
                    />
                </li>
            ))}
        </ul>
    );
});

const NotScheduledCourses = ({ actualAvailable }) => {
    const content = getCopy('coursePlanner.secondMoment.student.notScheduled.content');
    const subTitle = getCopy('coursePlanner.secondMoment.student.notScheduled.subTitle');

    /* * * * * *
	 * OUTPUT  *
	** * * * * */
    return (
        <Bem style={styles}>
            <p>{content}</p>
            <div el="not-on-roster">
                <TileContainer
                    title={subTitle}
                    foldout={true}
                    el="courses"
                    choiceMoment
                >
                    {actualAvailable.length > 0 ? actualAvailable.map(course => {
                        const { id, scheduled_course:  { course: _course, route_status: routeStatus } } = course;
                        const { cleanName } = refineCourseName(_course);

                        return (
                            <CourseTile
                                key={id}
                                altLayout
                                cleanName={cleanName}
                                routeStatus={routeStatus}
                            />
                        )
                    }) : <span><strong>Geen gekozen cursussen.</strong></span> }
                </TileContainer>
            </div>
        </Bem>
    );
}


// a new popover component that takes km2roster with the same props
