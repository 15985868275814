import { useEffect } from 'react';

function useLog (value, identifier) {
    useEffect(() => {
        if (identifier) {
            console.log({ [identifier]: value });
        }
        console.log(value);
    }, [value, identifier]);
}

export default useLog;
