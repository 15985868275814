import React from 'react';
import Bem from 'react-better-bem';

import { HomePageNavTile, HomePageCoursePlannerNavTile } from '../';

import { useEnvironmentContext } from '../../context';

import styles from './HomePageNavigation.module.scss';

const HomePageNavigation = ({ roles = [] }) => {
    const {
        navItems: { homepage },
        planning: { km1Student, km2Student },
    } = useEnvironmentContext();

    const isStudent = roles.includes('student');
    const isCoach = roles.includes('coach') || roles.includes('mentor');
    const isOnlyMentor = isCoach && !roles.includes('coach');

    const hasChoiceMoment1 = (isStudent || isCoach) && km1Student !== undefined;
    const hasChoiceMoment2 = isStudent && km2Student !== undefined;

   const {
        student: {
            km1: {
                current: isKm1,
            } = {},
        } = {},
    } = hasChoiceMoment1 && km1Student

    const {
        student: {
            km2: {
                current: isKm2,
            } = {},
        } = {}
    } = hasChoiceMoment2 && km2Student

    return (
        <Bem style={styles}>
            <nav el="nav">
                <h5 el="title">Ga direct naar:</h5>
                <ol el="list">
                    {homepage.map(({ name, nameBroken, icon, href, invertOnHomePage }) => {
                        return (
                            <li key={`homepage-nav-item-${name}`} el="item">
                                <HomePageNavTile
                                    href={href}
                                    icon={icon}
                                    name={nameBroken || name}
                                    inverted={invertOnHomePage}
                                />
                            </li>
                        );
                    })}
                    {hasChoiceMoment1 && (
                        <li el="item">
                            <HomePageCoursePlannerNavTile
                                block={km1Student}
                                isKm1={isKm1}
                                role={isOnlyMentor ? 'mentor' : isCoach ? 'coach' : 'student'}
                            />
                        </li>
                    )}
                    {hasChoiceMoment2 && (
                        <li el="item">
                            <HomePageCoursePlannerNavTile
                                block={km2Student}
                                isKm2={isKm2}
                                role="student"
                            />
                        </li>
                    )}
                </ol>
            </nav>
        </Bem>
    );
}

export default HomePageNavigation;
