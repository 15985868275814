import React, { useMemo } from 'react';
import Bem from 'react-better-bem';

import SortButton from './SortButton';

import styles from './Table.module.scss';

const Table = ({
    rows = [],
    includesHeaderRow = true,
    ...props
}) => {
    const header = includesHeaderRow && rows[0];
    const bodyRows = useMemo(() => {
        if (includesHeaderRow) {
            return rows.slice(1);
        }
        return [ ...rows ];
    }, [includesHeaderRow, rows]);

    const headerCells = header.cells || header;

    return (
        <Bem style={styles}>
            <table el="table" {...props}>
                <TableHeader cells={headerCells}/>
                <TableBody rows={bodyRows} />
            </table>
        </Bem>
    );
};

export default Table;

const TableHeader = ({ cells = [] }) => {
    if (!cells.length) {
        return null;
    }

    return (
        <Bem style={styles} block="table">
            <thead el="section" mod="header">
                <TableRow
                    cells={cells}
                    bg={true}
                    align="center"
                    header
                />
            </thead>
        </Bem>
    );
};

const TableBody = ({ rows = [] }) => {
    if (!rows.length) {
        return null;
    }

    return (
        <Bem style={styles} block="table">
            <tbody el="section" mod="body">
                {rows.map((row, index) => {
                    const cells = Array.isArray(row) ? row : (row.cells || []);

                    return (
                        <TableRow
                            key={`table-body-row-${index}`}
                            cells={cells}
                            border={true}
                            collapseRow={row.collapse || false}
                            {...(row?.className && { className: row.className })}
                        />
                    );
                })}
            </tbody>
        </Bem>
    );
};

const TableRow = ({ cells = [], collapseRow, header = false, bg = false, shaded = false, border = false, collapse = false, align = 'left', style = {}, className }) => {

    const bemMod = header ? 'header' : 'body';

    const cellsMemo = useCellsMemo(cells, { bemMod, bg, shaded, border, align, collapse });

    if (!cellsMemo.length) {
        return null;
    }

    return (
        <Bem style={styles} block="table__section" mod={[bemMod, { collapse: collapseRow }]}>
            <tr el="row" mod={{ collapse }} className={className}>
                {cellsMemo.map((cell, index) => (
                    <TableCell
                        key={`cell-${bemMod}-${index}`}
                        header={header}
                        { ...cell }
                    />
                ))}
            </tr>
        </Bem>
    );
};

const TableCell = ({ header, spacer, content, bg, shaded, border, collapse, spanning, align, style, className, bemMod, sort, colSpan }) => {
    if (spacer) {
        return (
            <Bem style={styles} block="table__section__row" mod={bemMod}>
                <td el="cell spacer" mod={{ bg, border, shaded }} colSpan={colSpan} />
            </Bem>
        );
    }

    return (
        <Bem style={styles} block="table__section__row" mod={bemMod}>
            <td el="cell" mod={{ bg, border, align, collapse, spanning, shaded }} style={style} className={className} colSpan={colSpan}>
                <div el="content" >
                    {header ? <SortButton { ...sort } /> : null}
                    {content}
                </div>
            </td>
        </Bem>
    );
};

const useCellsMemo = (
    cells,
    defaults = {
        bg: false,
        shaded: false,
        border: false,
        align: 'left',
        spaceBefore: false,
        spaceAfter: false,
        colSpan: 1,
        bemMod: 'body',
        style: {}
    }
) => useMemo(() => cells
    .map((cell) => {
        const cellIsElement = React.isValidElement(cell) || typeof cell === 'string';

        return {
            ...defaults,
            ...(cellIsElement ? { content: cell } : cell)
        };
    })
    .reduce((acc, cell, index, allCells) => {
        const thisCell = { ...cell };
        const prevCell = { ...(index && allCells[index - 1]) };
        const nextCell = { ...allCells[index + 1] };

        // check for first/last/single bg && border
        if (thisCell.bg && !['single', 'first', 'last'].includes(thisCell.bg)) {
            const isFirst = !index || (['last', 'single'].includes(prevCell?.bg) && prevCell?.spaceAfter) || !prevCell?.bg;
            const isLast = index === allCells.length - 1 || (nextCell?.bg === 'first' && nextCell?.spaceBefore) || !nextCell?.bg;
            const isSingle = isFirst && isLast;
            thisCell.bg = isSingle ? 'single' : isFirst ? 'first' : isLast ? 'last' : true;
        }

        if (thisCell.border && !['single', 'first', 'last'].includes(thisCell.border)) {
            const isFirst = !index || (['last', 'single'].includes(prevCell?.border) && prevCell?.spaceAfter) || !prevCell?.border;
            const isLast = index === allCells.length - 1 || (nextCell?.border === 'first' && nextCell?.spaceBefore) || !nextCell?.border;
            const isSingle = isFirst && isLast;
            thisCell.border = isSingle ? 'single' : isFirst ? 'first' : isLast ? 'last' : true;
        }

        const newCells = [thisCell];

        // add spacers if required
        if (thisCell.spaceBefore) {
            newCells.unshift({
                spacer: true,
                bg: [true, 'last'].includes(thisCell.bg),
                // shaded: [true, 'last'].includes(thisCell.shaded),
                border: [true, 'last'].includes(thisCell.border),
                bemMod: cell.bemMod
            });
        }

        if (thisCell.spaceAfter) {
            newCells.push({
                spacer: true,
                bg: [true, 'first'].includes(thisCell.bg),
                // shaded: [true, 'first'].includes(thisCell.shaded),
                border: [true, 'first'].includes(thisCell.border),
                bemMod: cell.bemMod
            });
        }

        return [ ...acc, ...newCells ];
    }, [])
, [cells, defaults]);
