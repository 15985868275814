import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from "react-router-dom";

import { useAppStateContext } from "../../../context";

import { CourseProgressFilter, FilterButton, Icon, LinkList, Popover, Tooltip } from '../../../components';

import globalStyles from './CoachGroup.module.scss';
import responsiveStyles from './CoachGroup.responsive.module.scss';
import { getCopy } from "../../../utils";

const styles = { ...globalStyles, ...responsiveStyles };

const CoachGroupResponsive = ({ api }) => {
    const {
        sidePanel: {
            open: openSidePanel,
            close: closeSidePanel,
            setContent: setSidePanelContent,
        },
    } = useAppStateContext();

    const {
        students,
        setCurrentStudent,
        unsetCurrentStudent,

        allYears,
        allBlocks,
        defaultYear,

        selectedYear,
        setSelectedYear,
        selectedBlock,
        setSelectedBlock,
    } = api;

    useEffect(() => {
        setSidePanelContent(
            <CourseProgressFilter
                allYears={allYears}
                allBlocks={allBlocks}
                defaultYear={selectedYear}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
            />
        );
    }, [
        allYears,
        allBlocks,
        defaultYear,
        selectedYear,
        selectedBlock,
        setSidePanelContent,
        closeSidePanel,
        setSelectedYear,
        setSelectedBlock
    ]);

    const linksList = useLinkListsMemo(students, setCurrentStudent);

    const currentStudent = students.find(({ current }) => current);

    const popoverSubTitle = useMemo(() => {
        const progressOverviewHref = getCopy(
            'progress.teacher.overview.path',
            { studentId: currentStudent?.flexx_id }
        );

        const progressOverviewTitle = getCopy(
            'progress.teacher.overview.title',
            { student: currentStudent?.full_name }
        );

        const progressOverviewLinkContent = getCopy(
            'progress.teacher.overview.linkTitle'
        );

        return (
            <Link
                to={progressOverviewHref}
                title={progressOverviewTitle}
                className={styles['progress-overview-link']}
            >
                <small className={styles['progress-overview-link__content']}>
                    {progressOverviewLinkContent}
                </small>
                <Icon
                    type="chevron-right"
                    size="small"
                    valign="middle"
                    color="blue-darker"
                />
            </Link>
        );
    }, [currentStudent]);

    let filterButtonContent = selectedYear?.yearLabel || '';
    if (selectedBlock?.period) {
        const periodStr = `Blok ${selectedBlock.number}`;
        filterButtonContent = periodStr;
    }

    return (
        <>
            <FilterButton
                onClick={openSidePanel}
                className={styles['filter-button']}
            >
                {filterButtonContent}
            </FilterButton>
            {linksList}
            <Popover
                mounted={currentStudent?.flexx_id !== undefined}
                title={currentStudent?.full_name}
                subtitle={popoverSubTitle}
                onClose={unsetCurrentStudent}
            >
                {currentStudent?.flexx_id !== undefined && (<>
                    <ul className={styles['results']}>
                        <Result topic={'completed'} value={currentStudent?.ratings?.completed} />
                        <Result topic={'notCompleted'} value={currentStudent?.ratings?.not_completed} />
                        <Result topic={'closed'} value={currentStudent?.ratings?.closed} />
                        <Result topic={'available'} value={currentStudent?.ratings?.available} />
                    </ul>
                    <ul className={styles['results']}>
                        <Result topic={'total'} value={currentStudent?.ratings?.total} />
                        <Result topic={'results'} value={currentStudent?.ratings?.results} />
                    </ul>
                </>)}
            </Popover>
        </>
    );
};

export default CoachGroupResponsive;

/* * * * * * * * * * * * * * * * *
 * MEMO'S FOR LINKLISTS (MOBILE) *
** * * * * * * * * * * * * * * * */
const useLinkListsMemo = (students, setCurrentStudent) => {
    const studentsMemo = useMemo(() => {
        return students.map(({ full_name, base_group, flexx_id }) => ({
            children: (
                <strong className={styles['student']}>
                    {full_name}
                    <span className={styles['student__class']}>{base_group}</span>
                </strong>
            ),
            onClick: () => {
                setCurrentStudent(flexx_id);
            }
        }))
    }, [students, setCurrentStudent]);

    return (
        <LinkList
            links={studentsMemo}
            collapseTop={true}
        />
    );
};

const Result = ({ topic, value }) => {
    const ref = useRef();
    const header = getCopy(`rating.tableHeaders.progress.${topic}`);

    return (
        <li className={styles['result']}>
            <abbr title={header.tooltip} ref={ref}>
                { header.title }
            </abbr>
            <Tooltip target={ref} />
            <strong>{ value }</strong>
        </li>
    );
}
