import React from 'react';

import { Button } from '../';

import { PRINT_BASE_PATH, ENABLE_PRINT_BUTTON } from '../../config/config';
const _ENABLE_PRINT_BUTTON =  ENABLE_PRINT_BUTTON === '1';

const PrintButton = ({ children, path, isExport = false, ...props }) => {
    if (!_ENABLE_PRINT_BUTTON) {
        return null;
    }

    const pdfHref = `${PRINT_BASE_PATH}${path}`;
    const buttonText = isExport ? "Exporteren" : "Afdrukken";
    return (
        <Button
            {...props}
            icon="printer"
            iconPosition="start"
            variant="small"
            href={pdfHref}
            download
        >
            {children || buttonText}
        </Button>
    );
};

export default PrintButton;
