import React, { useContext } from 'react';

import { useCoachingTrainee } from '../hooks';

const CoachingTraineeContext = React.createContext({});

const CoachingTraineeContextProvider = ({ children }) => {
    const trainee = useCoachingTrainee();

    return (
        <CoachingTraineeContext.Provider value={trainee}>
            {children}
        </CoachingTraineeContext.Provider>
    );
};

const useCoachingTraineeContext = () => useContext(CoachingTraineeContext);

export { CoachingTraineeContextProvider };
export default useCoachingTraineeContext;
