import React, { memo } from "react";
import Bem from "react-better-bem";

import { CardHeader, Card } from "../"

import { Icon, Button } from "../../../components"

import styles from "./InternshipOverview.module.scss";

function InternshipCard({ internships, uploadFile, deleteFile, summary, studentRole, bpvLinks }) {
    const summaryTitle = `Goedgekeurde uren`;
    const { learningGoalsLink, requestFormLink } = bpvLinks;

    return (
        <Bem style={styles}>
            <div el="main">
                <section el="top-section">
                    <header el="header">
                        <h4 el="title">{summaryTitle}</h4>
                    </header>
                    <article el="card-container">
                        <ul el="card-lists">
                            {summary && summary.map(({ iconName, title, hours }) => (
                                <CardHeader
                                    key={`card-header-${title}-${iconName}`}
                                    IconName={iconName}
                                    title={title}
                                    hours={hours}
                                />
                            ))}
                        </ul>
                    </article>
                </section>
                <section el="bottom-section">
                    <header el="header">
                        <h4 el="title">Stagebedrijven</h4>
                    </header>
                    <article el="card-container">
                        <ul el="card-lists">
                            {internships?.length ? internships.map((_internship, index) => (
                                <Card
                                    key={`body-card-${index}-${_internship.id}`}
                                    cardContent={_internship}
                                    uploadFile={uploadFile}
                                    deleteFile={deleteFile}
                                    studentRole={studentRole}
                                />
                            )) : null }
                        </ul>
                    </article>
                </section>
               {(studentRole && (requestFormLink || learningGoalsLink)) ? (
                    <section el="internship-request">
                    <div el="col" mod="icon">
                        <span el="circle"></span>
                        <Icon
                            color="red"
                            type="badge"
                            size="extra-large"
                            className={styles["main__internship-request--img"]}
                        />
                    </div>
                    <div el="col" mod="text-content">
                        <h1 el="title">Nog een stage lopen?</h1>
                        <div el="button-group">
                            {requestFormLink && (
                                <Button
                                    variant="inverted"
                                    href={requestFormLink}
                                    target="_blank"
                                    className={styles["main__internship-request__col__button-group--btn"]}
                                    disabled={requestFormLink && !studentRole}
                                >
                                    Stage aanvraagformulier
                                </Button>
                            )}
                            {learningGoalsLink && (
                                <Button
                                    variant="default"
                                    href={learningGoalsLink}
                                    target="_blank"
                                    disabled={learningGoalsLink && !studentRole}
                                >
                                    Leerdoelen formulier downloaden
                                </Button>
                            )}

                        </div>
                    </div>
                    </section>) : null
               }
            </div>
        </Bem>
    );
}

export default memo(InternshipCard);
