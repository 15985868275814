import React, { useCallback, useEffect } from 'react';
import { useParams, Prompt } from 'react-router-dom';

import GroupCourseDesktop from './GroupCourse.desktop';
import GroupCourseResponsive from './GroupCourse.responsive';

import { PageHeader, ScoreLegend, BlockBadge, Badge } from '../../../components';

import { useAppStateContext, useBreadcrumbTrailContext } from '../../../context';
import { useLayout, usePageTitle } from '../../../hooks';
import { useRatingGroupCourseApi } from '../../../hooks/api';
import { getCopy } from '../../../utils';

import styles from './GroupCourse.module.scss';

const UNSAVED_FEEDBACK_WARNING = getCopy('rating.unsavedFeedbackWarning');

const GroupCourse = () => {
    // layout stuff
    const { screen: { isSmallDesktop } } = useAppStateContext();
    useLayout({ stretch: false, collapse: 'top', flexContent: true, margin: false });

    // api stuff
    const { group_course_id: groupCourseId } = useParams();

    const api = useRatingGroupCourseApi(groupCourseId);
    const {
        loading,
        courseName, // <- is actually cleanName
        groupCode,
        students,
        block,
    } = api;

    usePageTitle(
        loading ? 'loading' : 'rateGroup',
        {
            course: courseName || '',
            group: groupCode || '',
            block: block?.period || '',
        }
    );

    /* * * * * * * * * * *
     * UNSAVED FEEDBACK  *
    ** * * * * * * * * * */
    const hasUnsavedFeedback = students.some(({ hasUnsavedFeedback }) => hasUnsavedFeedback);
    const warnUserBeforeClose = useCallback((e) => {
        if (hasUnsavedFeedback) {
            e.preventDefault();
            e.returnValue = UNSAVED_FEEDBACK_WARNING;
        }
        return UNSAVED_FEEDBACK_WARNING;
    }, [hasUnsavedFeedback]);

    useEffect(() => {
        window.addEventListener('beforeunload', warnUserBeforeClose);
        return () => {
            window.removeEventListener('beforeunload', warnUserBeforeClose);
        };
    }, [warnUserBeforeClose]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <>
            <Prompt when={hasUnsavedFeedback} message={UNSAVED_FEEDBACK_WARNING} />
            <GroupCoursePageHeader
                loading={loading}
                courseName={courseName}
                groupCode={groupCode}
                groupCourseId={groupCourseId}
                block={block}
            />
            {isSmallDesktop ? (
                <GroupCourseDesktop
                    groupCode={groupCode}
                    api={api}
                />
            ) : (
                <GroupCourseResponsive
                    groupCode={groupCode}
                    api={api}
                />
            )}
        </>
    );
};

const GroupCoursePageHeader = ({ loading, courseName, groupCode, groupCourseId, block }) => {

    const title = loading ? '' : (
        <>
            {courseName}
            <BlockBadge
                className={styles['block-badge']}
                block={block}
                size="large"
                period
            />
        </>
    );

    const { set } = useBreadcrumbTrailContext();
    useEffect(() => {
        set([
            {
                name: 'Beoordelen',
                href: '/beoordelen',
            },
            {
                name: (
                    <>
                        {`${courseName} `}
                        <Badge background="blue-darker" text="white" size="small">{groupCode}</Badge>
                    </>
                ),
                href: `/beoordelen/${groupCourseId}`,
                loading,
            }
        ]);
    }, [set, loading, courseName, groupCode, groupCourseId]);

    return (
        <PageHeader
            title={title}
            collapsed
        >
            <ScoreLegend includeRatingManual />
        </PageHeader>
    );
}

export default GroupCourse;
