import React, { useCallback, useEffect, useRef } from 'react';
import Bem from 'react-better-bem';
import { Prompt } from 'react-router-dom';

import {
    Button,
    TileContainer,
    CourseTile,
    ChoicesEmptyBox,
} from '../..';

import { useAppStateContext } from '../../../context';
import { getCopy } from '../../../utils';

import styles from './Basket.module.scss';


const Basket = ({
    role,
    myChoices = [],
    theirChoices = [],
    student = {},
    deleteChoice,
    submitChoices,
    toggleMode,
    openInfoModal,
}) => {
    const { screen: { isntTablet } } = useAppStateContext();

    /* * * * * * * * * * * * * * * * * *
     * WARN USER FOR UNSAVED REQUESTS  *
    ** * * * * * * * * * * * * * * * * */
    const hasNoChoices = !myChoices.length && !theirChoices.length;

    const amount = myChoices.length;

    // store original amount in ref, so we can compare it to 'live' amount
    const originalAmount = useRef(myChoices.length);
    const nChanges = Math.abs(originalAmount.current - amount);
    const unsavedChangesWarning = getCopy(`coursePlanner.firstMoment.${role}.unsavedRequestsWarning`);

    const warnUserBeforeClose = useCallback((e) => {
        if (nChanges) {
            e.preventDefault();
            e.returnValue = unsavedChangesWarning;
        }

        return unsavedChangesWarning;
    }, [nChanges, unsavedChangesWarning]);

    useEffect(() => {
        window.addEventListener('beforeunload', warnUserBeforeClose);
        return () => {
            window.removeEventListener('beforeunload', warnUserBeforeClose);
        };
    }, [warnUserBeforeClose]);

    /* * * * *
     * COPY  *
    ** * * * */
    const submitButtonText = getCopy(
        `coursePlanner.firstMoment.${role}.basket.button`
    );

    const titleText = getCopy(
        `coursePlanner.firstMoment.${role}.basket.title`,
        { student: student?.first_name }
    );

    const emptyText = getCopy(
        `coursePlanner.firstMoment.${role}.basket.empty`
    );

    const theirChoicesTitle = getCopy(
        `coursePlanner.firstMoment.${role}.basket.theirChoices`,
        { student: student?.first_name }
    );

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <>
            <Prompt when={nChanges > 0} message={unsavedChangesWarning} />
            <Bem style={styles}>
                <div el="basket" mod={{ role }}>
                    <div el="content" mod={{ collapse: !hasNoChoices }}>
                        <div el="header">
                            <h2>{titleText}</h2>
                        </div>
                        <div el="button">
                            <Button
                                block
                                variant={isntTablet || hasNoChoices ? 'default' : 'inverted'}
                                className={styles.basket__content__button__button}
                                onClick={() => {
                                    submitChoices();
                                    toggleMode();
                                }}
                            >
                                {submitButtonText}
                                {amount ? <span className={styles.button__badge}>{amount}</span> : null}
                            </Button>
                        </div>
                        <div
                            el="courses"
                            mod={{ collapse: !hasNoChoices && isntTablet }}
                        >
                            {!myChoices.length ? (
                                <ChoicesEmptyBox>
                                    {emptyText}
                                </ChoicesEmptyBox>
                            ) : myChoices.length ? (
                                <TileContainer scroll>
                                    {myChoices.map((choice) => {
                                        const {
                                            id,
                                            is_bpv,
                                            is_special: isMasterClass,
                                            scheduled_course: {
                                                course,
                                                routeStatus,
                                                _bpvPhase,
                                            },
                                        } = choice;

                                        return (
                                            <CourseTile
                                                altLayout
                                                onClick={() => { openInfoModal(choice, false); }}
                                                key={`chosen_course-${id}`}
                                                role={role}
                                                routeStatus={isMasterClass ? 'masterclass' : routeStatus}
                                                altIcon="trash"
                                                bpvPhase={_bpvPhase === 1 || _bpvPhase === 2 || is_bpv}
                                                altOnClick={() => { deleteChoice(id); }}
                                                {...course}
                                            />
                                        );
                                    })}
                                </TileContainer>
                            ) : null}
                            {theirChoices.length ? (
                                <div el="their-choices">
                                    <TileContainer
                                        title={theirChoicesTitle}
                                        scroll
                                    >
                                        {theirChoices.map((choice) => {
                                            const {
                                                id,
                                                is_bpv,
                                                is_special: isMasterClass,
                                                scheduled_course: {
                                                    course,
                                                    routeStatus,
                                                    _bpvPhase,
                                                },
                                            } = choice;

                                            return (
                                                <CourseTile
                                                    altLayout
                                                    onClick={() => { openInfoModal(choice, false); }}
                                                    key={`chosen_course-${id}`}
                                                    role={role}
                                                    bpvPhase={_bpvPhase === 1 || _bpvPhase === 2 || is_bpv}
                                                    routeStatus={isMasterClass ? 'masterclass' : routeStatus}
                                                    altIcon="info-circle"
                                                    {...course}
                                                />
                                            );
                                        })}
                                    </TileContainer>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Bem>
        </>
    );
};

export default Basket;
