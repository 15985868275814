import React from 'react';
import Bem from 'react-better-bem';

import styles from './ToggleSlider.module.scss';

const ToggleSlider = ({
    onToggle = () => void 0,
    toggled,
    disabled = false,
    className,
    spanElement,
}) => {
    const Tag = spanElement ? 'span' : 'button';
    const roleProps = spanElement ? { role: 'button', tabIndex: 0 } : {};

    return (
        <Bem style={styles}>
            <span el="wrapper" className={className}>
                <Tag
                    el="toggle"
                    mod={{ checked: toggled, disabled }}
                    disabled={disabled}
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggle();
                    }}
                    {...roleProps}
                >
                    <span el="track" />
                    <span el="knob" />
                </Tag>
            </span>
        </Bem>
    );
};

export default ToggleSlider;
