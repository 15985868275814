import React, { useMemo } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { Icon } from '../';

import { useAppStateContext } from '../../context';

import styles from './NavLink.module.scss';

const NavLink = ({ to = '/', name = '', icon = null, desktop = false }) => {

    const { nav: { close: closeNav } } = useAppStateContext();

    const linkIcon = useMemo(() => {
        return (
            <Icon
               type={icon}
               size="large"
               color={desktop ? 'nav-item-icon-desktop' : 'nav-item-icon'}
               className={`${styles.navlink__icon} ${desktop ? styles['navlink__icon--desktop'] : ''}`}
           />
        );
    }, [icon, desktop]);

    return (
        <RouterNavLink
            to={to}
            className={`${styles.navlink} ${desktop ? styles['navlink--desktop'] : ''}`}
            activeClassName={`${styles['navlink--active']} ${desktop ? styles['navlink--active--desktop'] : ''}`}
            onClick={closeNav}
        >
            {linkIcon}
            <span>{name || to}</span>
        </RouterNavLink>
    );
};

export default NavLink;
