import {useEffect, useState} from 'react';

import useApi from '../useApi';

function useMyCoachApi (autoFetch = false) {
    const [coaches, setCoaches] = useState([]);
    const { get, loading, value = [], fetched, error } = useApi('mijn coach', `/coaching/mycoach`);

    // fetch initially
    const fetchWhen = autoFetch && !fetched && !loading;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    useEffect(() => {
        if (!Array.isArray(value)) {
            setCoaches([value])
            return;
        }

        setCoaches(value)
    }, [value]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        coaches,
        error,
        loading,
        fetched
    };
}

export default useMyCoachApi;
