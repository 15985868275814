import { useState, useCallback } from 'react';

export default function useToggleState ({ defaultValue = null, onReset } = {}) {
    const [toggled, setToggled] = useState(defaultValue);
    const toggle = useCallback((id) => {
        setToggled((c) => {
            if (c === id) {
                if (typeof onReset === 'function') {
                    onReset();
                }
                return null;
            }
            return id;
        });
    }, [onReset]);

    return { toggled, toggle, };
}
