import React from 'react';

import { DatePicker, Popover } from '../../../../';

import styles from './DatePickerPopover.module.scss';

const DatePickerPopover = ({ isMounted, date, onNavigate, viewDate, setViewDate, onClose, events }) => {

    return (
        <Popover
            mounted={isMounted}
            onClose={onClose}
            buttons={[{
                icon: 'calendar-today',
                onClick: () => {
                    onNavigate(new Date());
                    onClose();
                }
            }]}
        >
            <div className={styles.container}>
                <DatePicker
                    date={date}
                    onNavigate={(date) => {
                        onNavigate(date);
                        onClose();
                    }}
                    viewDate={viewDate}
                    setViewDate={setViewDate}
                    events={events}
                    includeDots={true}
                />
            </div>
        </Popover>
    )
};

export default DatePickerPopover;
