export const customSelectStyles  = {
    container: (provided) => ({
        ...provided,
        width: '100%',
        border: 'none',
        backgroundColor: 'transparent',
        marginTop: '7px'
    }),

    singleValue: (provided) => ({
        ...provided,
        color: '#7C9AB8',
        fontSize: '14px',
        fontWeight: "600",
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorContainer: () => ({
        color: 'red',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#7C9AB8',
        fontWeight: 600,
        fontSize: '14px'
    }),
}