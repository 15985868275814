import React from 'react';
import Bem from 'react-better-bem';

import { Avatar } from '../';

import styles from './Person.module.scss';

const VALID_COLORS = ['default', 'blue', 'red'];

const Person = ({
    subtext,
    color = 'default',
    large = false,
    className,
    loading,

    ...props
}) => {

    const {
        first_name: first,
        last_name: last,
        full_name: full,
        flexx_id: id,
    } = props; // user props

    if (!id || !first || !last || !full) {
        return null;
    }

    color = VALID_COLORS.includes(color) ? color : VALID_COLORS[0];

    return (
        <Bem style={styles} strict={false}>
            <div el="person" mod={{ color, large }} className={className}>
                <Avatar
                    {...props}
                    size={large ? 'xxl' : 'xl'}
                    className="person__avatar"
                    loading={loading}
                />
                <div el="content">
                    {!loading ? (<p el="name"><strong>{full}</strong></p>) : null}
                    {!loading && subtext ? (<p el="sub">{subtext}</p>) : null}
                </div>
            </div>
        </Bem>
    );
};

export default Person;
