import React from 'react';
import Bem from 'react-better-bem';

import styles from './ProgressBar.module.scss';

const VALID_COLORS = ['default', 'red', 'red-darkest', 'green', 'blue'];

const ProgressBar = ({ progress = 0, color = 'default', round = false, ...props }) => {
    color = VALID_COLORS.includes(color) ? color : VALID_COLORS[0];

    const percentage = (progress * 100).toFixed(2) + '%';

    return (
        <Bem style={styles}>
            <div el="graph" mod={{ color, round }} {...props}>
                <div el="bar" style={{ width: percentage }} />
            </div>
        </Bem>
    );
};

export default ProgressBar;
