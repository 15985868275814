import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import Calendar from 'react-calendar';
import Bem from 'react-better-bem';

import { Button } from '../';

import './lib/styles.scss';

import styles from './DatePicker.module.scss';

const DatePicker = ({ viewDate, setViewDate, date, onNavigate, events = [], includeDots = false }) => {

    /* * * * * * * * *
     * CURRENT MONTH *
    ** * * * * * * * */
    const prevMonth = useCallback(() => {
        setViewDate(c => {
            const m = moment(c);
            m.subtract(1, 'month');
            return m.toDate();
        });
    }, [setViewDate]);

    const nextMonth = useCallback(() => {
        setViewDate(c => {
            const m = moment(c);
            m.add(1, 'month');
            return m.toDate();
        });
    }, [setViewDate]);

    // update current month when changing current date
    useEffect(() => {
        setViewDate(date);
    }, [setViewDate, date]);

    /* * * * * *
     * RENDER  *
    ** * * * * */
    const getCurrentWeekClassName = useCallback(({ date: tileDate }) => {
        const currentWeekNr = moment().isoWeek();
        const tileWeekNr = moment(tileDate).isoWeek();

        if (currentWeekNr === tileWeekNr) {
            const classNames = ['react-calendar__tile--this-week'];

            const isFirstDay = moment(tileDate).weekday() === 0;
            const isLastDay = moment(tileDate).weekday() === 6;

            if (isFirstDay) {
                classNames.push('react-calendar__tile--first-day-of-week');
            }
            if (isLastDay) {
                classNames.push('react-calendar__tile--last-day-of-week');
            }

            return classNames.join(' ');
        }

        return null;
    }, []);

    const getDots = useCallback(({ date: tileDate }) => {

        const _tileDate = moment(tileDate);

        const numberOfEventsOnDate = events.reduce((acc, { start }) => {
            if (_tileDate.isSame(start, 'day')) {
                acc++;
            }
            return acc;
        }, 0);

        if (!numberOfEventsOnDate) {
            return null;
        }

        const nDots = numberOfEventsOnDate > 4 ? 3 : numberOfEventsOnDate === 1 ? 1 : 2;

        return (
            <Bem style={styles}>
                <div el="dots">
                    {new Array(nDots).fill().map((_, index) => (
                        <span el="dot" key={`dot-${index}`}/>
                    ))}
                </div>
            </Bem>
        );
    }, [events]);

    const month = moment(viewDate).format('MMMM');
    const year = moment(viewDate).format('YYYY');

    return (
        <Bem style={styles}>
            <div el="datepicker">
                <div el="navigation">
                    <Button
                        icon="chevron-left"
                        variant="mini-circle"
                        onClick={prevMonth}
                        className={styles.datepicker__navigation__button}
                    />
                    <span el="date">
                        <span el="inner" mod="month">{month}</span>
                        <span el="inner" mod="year">{year}</span>
                    </span>
                    <Button
                        icon="chevron-right"
                        variant="mini-circle"
                        onClick={nextMonth}
                        className={styles.datepicker__navigation__button}
                    />
                </div>
                <Calendar
                    locale="nl-NL"
                    maxDetail="month"
                    minDetail="month"
                    defaultView="month"
                    returnValue="end"
                    value={date}
                    activeStartDate={viewDate}
                    onChange={onNavigate}
                    showNavigation={false}
                    tileClassName={getCurrentWeekClassName}
                    tileContent={getDots}
                />
            </div>
        </Bem>
    );
};

export default DatePicker;
