import React, { useContext } from 'react';
import { useAvatars } from '../hooks';

const AvatarsContext = React.createContext();

const AvatarsContextProvider = ({ children }) => {
    const avatars = useAvatars();

    return (
        <AvatarsContext.Provider value={avatars}>
            {children}
        </AvatarsContext.Provider>
    );
};

const useAvatarsContext = () => useContext(AvatarsContext);

export { AvatarsContextProvider };
export default useAvatarsContext;
