import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import Bem from 'react-better-bem';

import { Button, Badge, CourseTile } from '../../../components';

import { useLayout, usePageTitle } from '../../../hooks';
import { useProgressRouteApi } from '../../../hooks/api';
import {
    useAppStateContext,
    useEnvironmentContext,
    useBreadcrumbTrailContext,
    useCoachingTraineeContext,
} from '../../../context';

import { getCopy, refineCourseName } from '../../../utils';

import styles from './Route.module.scss';


const ProgressRoute = ({ role = 'student', api, student, studentId, planning, planningLoading = false }) => {
    const { screen: { isSmallDesktop, isTablet, isLargePhone } } = useAppStateContext();
    useLayout({ stretch: false, collapse: isSmallDesktop ? 'vertical' : 'both' });
    usePageTitle(`progress.route.${role}`, { student: student?.full_name || '' });

    /* * * *
     * API *
    ** * * */
    const {
        loading ,
        route,
        notPlanned,
        maxCoursesPerBlock,
        remainingBpvOneCourses,
    } = api;

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        const pageTitle = getCopy(`progress.${role}.route.title`);
        const path = getCopy(`progress.${role}.route.path`, { studentId });

        put({
            name: pageTitle,
            href: path,
            loading: (loading || planningLoading),
        }, role === 'student' ? 1 : 2);

        return () => {
            remove(pageTitle);
        };
    }, [remove, put, role, studentId, loading, planningLoading]);

    /* * * * * * * * *
     * SCROLL STUFF  *
    ** * * * * * * * */
    const { blocks, current, upcoming, latest } = planning;



    const currentBlock = current || upcoming || latest;
    const nBlocks = blocks.length;

    const [viewBlockIndex, setViewBlockIndex] = useState(-1);

    // offset: how much blocks are visible to the left and right of
    // the block we are looking at?
    const [viewBlockLeftOffset, viewBlockRightOffset] = useMemo(() => {
        if (isSmallDesktop) {
            return [1, 2]; // | 1 left | viewing | 1 right | 1 right |
        }
        if (isTablet) {
            return [1, 1]; // | 1 left | viewing | 1 left |
        }
        if (isLargePhone) {
            return [0, 1]; // | viewing | 1 right |
        }
        return [0, 0]; // | viewing |
    }, [isSmallDesktop, isTablet, isLargePhone]);

    useEffect(() => {
        setViewBlockIndex((cViewBlockIndex) => {
            if (cViewBlockIndex !== -1) {
                return cViewBlockIndex;
            }

            // which block are we looking at?
            // if there's no current block: last block is current
            const { id: currentBlockId } = currentBlock || {};

            const currentBlockIndex = blocks.findIndex(({ id }) => id === currentBlockId);

            if (currentBlockIndex === -1) {
                return blocks.length - 1;
            }

            return currentBlockIndex;
        });
    }, [blocks, currentBlock]);

    const scrollRight = useCallback(() => {
        setViewBlockIndex((c) => (c + 1) % nBlocks);
    }, [nBlocks]);
    const scrollLeft = useCallback(() => {
        setViewBlockIndex((c) => (nBlocks + c - 1) % nBlocks);
    }, [nBlocks]);

    // effect to trigger scrolling
    const headerRef = useRef();
    const bodyRef = useRef();
    useEffect(() => {
        if(!loading && bodyRef.current.firstChild){
            const bcr = bodyRef.current.firstChild.getBoundingClientRect();
            const { width } = bcr;

            const scrollDistance = parseInt((viewBlockIndex - viewBlockLeftOffset) * width, 10);

            headerRef.current.scrollTo({
                left: scrollDistance,
                behavior: 'auto',
            });

            bodyRef.current.scrollTo({
                left: scrollDistance,
                behavior: 'auto',
            });
        }
    }, [viewBlockIndex, viewBlockLeftOffset, loading]);

    const notPlannedBpvOneCourses = useMemo(() => {
        const _courses = [];

        return blocks.map(({ id: blockId }) => {
            const blockCourses = route[blockId] || [];
             blockCourses.filter(({ scheduled_course: { course: { id: _id }} }) => {
                const course = remainingBpvOneCourses.find(({ course: { id: _id2 }}) => _id === _id2);
                const isCourseAlreadyInCourses = _courses.find(({ course: { id: _id3 }}) => _id === _id3);

                if(course && !isCourseAlreadyInCourses) {
                    _courses.push(course);
                }

                return true;
            });

            return _courses
        })
    }, [blocks, route, remainingBpvOneCourses]);

    const _remainingNotPlannedBpvOneCourses = remainingBpvOneCourses && remainingBpvOneCourses.filter(({ course: { id: _id }}) => !notPlannedBpvOneCourses[0].find(({ course: { id: _id2 }}) => _id === _id2)).map((_course) => {
        return {
            ..._course,
            course: refineCourseName(_course.course),
        }
    });

    const _notPlanned =
    _remainingNotPlannedBpvOneCourses && _remainingNotPlannedBpvOneCourses.length ?
    [...notPlanned, ..._remainingNotPlannedBpvOneCourses] :
    [];

    /* * * * * *
     * RENDER  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <section el="schedule" mod={{ hidden: viewBlockIndex === -1 || loading }}>
                <header el="header box">
                    <div el="buttons">
                        <Button
                            icon="chevron-left"
                            variant="circle"
                            onClick={scrollLeft}
                            {...(viewBlockIndex - viewBlockLeftOffset <= 0 && {
                                className: `${styles['schedule__header__buttons__button']} ${styles['schedule__header__buttons__button--disabled']}`,
                                disabled: true
                            })}
                        />
                        <Button
                            icon="chevron-right"
                            variant="circle"
                            onClick={scrollRight}
                            {...(viewBlockIndex + viewBlockRightOffset >= nBlocks - 1 && {
                                className: `${styles['schedule__header__buttons__button']} ${styles['schedule__header__buttons__button--disabled']}`,
                                disabled: true
                            })}
                        />
                    </div>
                    <div el="grid" mod="header" ref={headerRef}>
                        {blocks.map(({ number, id, school_year: year, period }, i) => (
                            <div el="col" key={`header-col-${number || i}`}>
                                <h4 el="title">
                                    {(number > 0) ? `Blok ${number}` : `${year} ${period}`}
                                    {id === currentBlock.id ? (
                                        <Badge
                                            text="accent"
                                            background="white"
                                            className={styles['schedule__box__grid__col__title__badge']}
                                        >
                                            Huidig
                                        </Badge>
                                    ) : null}
                                </h4>
                            </div>
                        ))}
                    </div>
                </header>
                <main el="main box">
                    <div el="grid" mod="body" ref={bodyRef}>
                        {blocks.map(({ id: blockId }) => {
                            return (
                                <ProgressRouteBlockColumn
                                    key={`route-courses-block-${blockId}`}
                                    block={blockId}
                                    role={role}
                                    studentId={studentId}
                                    groupCourses={route[blockId]}
                                    lines={maxCoursesPerBlock}
                                />
                            );
                        })}
                    </div>
                </main>
            </section>

           {/*{ (_totalBpvCourses > 0) ? (*/}
           {/*     <StudieRouteBPV*/}
           {/*         totalBpvOneCourses={_totalBpvCourses}*/}
           {/*         completedCourses={_totalBpvOneCompletedCourses}*/}
           {/*         remainingCourses={_totalBpvCourses - _totalBpvOneCompletedCourses}*/}
           {/*         role={role}*/}
           {/*         studentId={studentId}*/}
           {/*     /> ) : null*/}
           {/* }*/}

            <NotPlannedCourses
                courses={_notPlanned}
                studentId={studentId}
                role={role}
            />
        </Bem>
    );
};

const StudentProgressRoute = () => {
    const { planning } = useEnvironmentContext();
    const api = useProgressRouteApi(false, planning);

    return <ProgressRoute planning={planning} api={api} role="student" />;
};

const CoachProgressRoute = ({ role = 'coach' }) => {
    const { student, studentId, planning, planningLoading } = useCoachingTraineeContext();
    const api = useProgressRouteApi(studentId, planning);

    return (
        <ProgressRoute
            student={student}
            studentId={studentId}
            planning={planning}
            planningLoading={planningLoading}
            api={api}
            role={role}
        />
    );
};

export {
    StudentProgressRoute,
    CoachProgressRoute,
};

const ProgressRouteBlockColumn = ({ groupCourses = [], block = 1, lines = 1, role, studentId }) => {
    const hasCourses = !!groupCourses.length;

    return (
        <Bem style={styles} block="schedule__box__grid" mod="body">
            <div
                el="col"
                mod={{ empty: !hasCourses }}
            >
                {hasCourses ? (
                    groupCourses.map(({
                        scheduled_course: scheduledCourse,
                        isScheduledCourse,
                        id,
                        ratings
                    }) => {
                        const { course, block: { id: blockId }, route_status: routeStatus } = scheduledCourse;
                        const { cleanName, id: courseId } = course;
                        const { status } = (Array.isArray(ratings) ? ratings[0] : ratings) || {};
                        const bpvPhase = scheduledCourse.bpv_required

                        const courseInfoPath = isScheduledCourse ?
                            getCopy(
                                `progress.${role}.courses.scheduledCourseLinkTemplate`,
                                { studentId, courseId, blockId }
                            ) :
                            getCopy(
                                `progress.${role}.courses.linkTemplate`,
                                { studentId, groupCourseId: id }
                            );

                        return (
                            <div el="line" key={`course-tile-${block}-${cleanName}-${id}`}>
                                <CourseTile
                                    layout="line"
                                    status={status}
                                    routeStatus={routeStatus?.toUpperCase()}
                                    to={courseInfoPath}
                                    faded={isScheduledCourse}
                                    bpvPhase={bpvPhase === true}
                                    {...course}
                                />
                            </div>
                        );
                    })
                ) : (
                    new Array(lines).fill().map((_, i) => (
                        <div el="line" key={`empty-course-tile-${block}-${i}`}>
                            <CourseTile
                                layout="line"
                                empty
                            />
                        </div>
                    ))
                )}
                {!hasCourses ? (
                    <span el="empty-message">
                        <strong>
                            Informatie voor dit blok is nog niet zichtbaar
                        </strong>
                    </span>
                ) : null}
            </div>
        </Bem>
    );
};

const NotPlannedCourses = ({ courses = [], role, studentId }) => {
    if (!courses.length) {
        return null;
    }

    const groupedByLetter = courses.reduce((acc, sc) => {
        const _courseName = sc.scheduled_course?.course.courseName || sc.course?.courseName;

        let firstChar = _courseName[0].toUpperCase();

        if (!isNaN(parseInt(firstChar, 10))) {
            firstChar = '#';
        }

        if (acc[firstChar]) {
            acc[firstChar].push(sc);
        } else {
            acc[firstChar] = [sc];
        }

        return acc;
    }, {});

    const letters = Object.keys(groupedByLetter).sort();

    return (
        <Bem style={styles}>
            <section el="not-planned">
                <div el="content">
                    <h4 el="title">Niet behaalde cursussen die nog niet opnieuw ingepland zijn</h4>
                    <ol el="list">
                        {letters.map((letter) => (
                            <li el="letter" key={`not-planned-list-${letter}`}>
                                <h5 el="title">{letter}</h5>
                                <ol el="list">
                                    {groupedByLetter[letter].map((gc) => {
                                        const id = gc.id;
                                        const course = gc.scheduled_course?.course || gc?.course;
                                        const bpvPhase = gc.scheduled_course?.course.bpv_required || gc?.bpv_required;

                                        const to = getCopy(
                                            `progress.${role}.courses.linkTemplate`,
                                            { groupCourseId: id }
                                        );

                                        return (
                                            <li el="course" key={`not-planned-list-course-${id}`}>
                                                <CourseTile
                                                    layout="line"
                                                    to={to}
                                                    {...course}
                                                    noShadow
                                                    bpvPhase={bpvPhase === true}
                                                />
                                            </li>
                                        );
                                    })}
                                </ol>
                            </li>
                        ))}
                    </ol>
                </div>
            </section>
        </Bem>
    );
}
