import React from 'react';
import Bem from 'react-better-bem';

import styles from './ProgressGraph.module.scss';

const VALID_SIZES = ['default', 'small'];
const SIZE_MAP = {
    'default': { graphSize: 86, strokeWidth: 5, dotRadius: 5 },
    'small': { graphSize: 52, strokeWidth: 4, dotRadius: 4 }
};
const VALID_COLORS = ['default', 'red'];

const ProgressGraph = ({ done = 0, total = 1, size = 'default', color = 'default', label = false }) => {
    size = VALID_SIZES.includes(size) ? size : VALID_SIZES[0];
    color = VALID_COLORS.includes(color) ? color : VALID_COLORS[0];

    const progress = Math.min(1, Math.max(0, done / total));

    const { graphSize, strokeWidth, dotRadius } = SIZE_MAP[size];

    const radius = (graphSize - 2 * strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;

    // how far should the outer stroke be drawn?
    const strokeOffset = circumference - progress * circumference;

    // define position of dot on end of stroke
    const rotation = progress * 2 * Math.PI; // rads
    const dotX = parseInt(Math.cos(rotation) * radius, 10) + graphSize / 2;
    const dotY = parseInt(Math.sin(rotation) * radius, 10) + graphSize / 2;

    return (
        <Bem style={styles}>
            <div el="container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${graphSize} ${graphSize}`} el="graph" mod={{ size, color }}>
                    <circle
                        el="circle"
                        mod="back"
                        cx={parseInt(graphSize / 2, 10)}
                        cy={parseInt(graphSize / 2, 10)}
                        r={parseInt(radius, 10)}
                        strokeWidth={`${strokeWidth}px`}
                    />
                    <circle
                        el="circle"
                        mod="front"
                        cx={parseInt(graphSize / 2, 10)}
                        cy={parseInt(graphSize / 2, 10)}
                        r={parseInt(radius, 10)}
                        strokeWidth={`${strokeWidth}px`}
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeOffset}
                    />
                    {progress < 1 ? (
                        <circle
                            el="dot"
                            cx={dotX}
                            cy={dotY}
                            r={dotRadius}
                        />
                    ) : null}
                </svg>
                {label && <div el="label">{label}</div>}
            </div>
        </Bem>
    );
};

export default ProgressGraph;
