import React from 'react';
import Bem from 'react-better-bem';

import { useAppStateContext } from '../../context';

import { Header, Navigation, Footer, Overlay, Modal, SidePanel, Notifications } from '../../components';

import styles from './Main.module.scss';

const Main = ({ children }) => {
    const {
        container: {
            stretched,
            collapsed,
            padContent,
            stretchContent,
            flexContent,
            margin,
            background,
        },
    } = useAppStateContext();

    const bpvPage = window.location.pathname.includes('bpv');
    const _margin = !bpvPage ? margin : false;

    return (
        <Bem style={styles}>
            <Header />
            <Navigation />
            <main
                el="container"
                mod={{ stretched, collapsed, margin: _margin, background }}
            >
                <SidePanel />
                <div el="content" mod={{ 'stretched-content': stretchContent, padded: padContent, flex: flexContent }}>
                    {children}
                </div>
            </main>
            <Footer />
            <Overlay />
            <Modal />
            <Notifications />
        </Bem>
    );
};

export default Main;
