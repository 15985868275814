import { useCallback, useRef } from 'react';

import { useAvatarApi } from './api';

const URL_CREATOR = window.URL || window.webkitURL;
const MAX_CONCURRENT_OPERATIONS = 3;
const RETRY_AFTER = 100;

const FAILED_AVATAR = process.env.REACT_APP_FLEXX_FAILED_AVATAR_ID;

// NICE TO HAVE:
// https://developer.mozilla.org/en-US/docs/Web/API/AbortController

export default function useAvatars () {
    const { getAvatar: getAvatarFromApi } = useAvatarApi();

    // state to store blob url's in
    // ref to not trigger updates
    // wtf is a blob? 🤔 https://javascript.info/blob
    const blobs = useRef({});
    const storeAvatar = useCallback((id, blobUrl) => {
        blobs.current = { ...blobs.current, [id]: blobUrl };
    }, []);
    const resetAvatars = useCallback(() => {
        Object.keys(blobs.current).forEach((id) => {
            if (blobs[id] !== FAILED_AVATAR) {
                URL_CREATOR.revokeObjectURL(blobs[id]);
            }
        });
        blobs.current = {};
    }, []);

    // state to keep track of how many things we're doing currently
    // ref to not trigger updates
    const running = useRef(0);
    const getAndBlobify = useCallback(async (id) => {
        // bump running
        running.current++;

        // output blob > by default FAILED_AVATAR identifier
        let _blobUrl = FAILED_AVATAR;

        // wrap everything in a promise
        const promise = new Promise((resolve, reject) => {
            // get the avatar from the api
            getAvatarFromApi(id)
                .then((response) => {
                    // check if we get a valid base64 string or thrown an error
                    if (response?.base64 !== null && response?.base64?.length) {
                        return `data:image/png;base64,${response.base64}`;
                    }

                    throw Error(`No valid base64 string for photo ${id}`);
                })
                .then((base64) => {
                    fetch(base64)
                        // turn the base64 string async into a blob
                        // wtf is a blob? 🤔 https://javascript.info/blob
                        .then((img) => img.blob())
                        // turn the blob into an object url
                        // wtf are we doing? 😨 https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
                        .then((blob) => URL_CREATOR.createObjectURL(blob))
                        .then((blobUrl) => {
                            // set output blob to url
                            _blobUrl = blobUrl;
                            resolve();
                        })
                        .catch((e) => {
                            console.warn(e);
                            reject();
                        });
                })
                .catch((e) => {
                    console.warn(e);
                    reject();
                });
        });

        // misuse of allSettled: might be a better way
        // this makes sure we're waiting until the promise
        // is either rejected or resolved
        await Promise.allSettled([promise]);

        // decrement running
        running.current--;
        return _blobUrl;
    }, [getAvatarFromApi]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    // exposed get avatar method
    const getAvatar = useCallback(async (id) => {
        // if we already got the blob (url) just return it
        if (blobs.current[id]) {
            return blobs.current[id];
        }

        // check if current work is resolved,
        // if not, check again after RETRY_AFTER ms
        while (running.current >= MAX_CONCURRENT_OPERATIONS) {
            await new Promise((resolve) => { setTimeout(resolve, RETRY_AFTER); });
        }

        // current work has been resolved: now it's this avatars turn
        const blobUrl = await getAndBlobify(id);
        storeAvatar(id, blobUrl);
        return blobUrl;
    }, [getAndBlobify, storeAvatar]);

    return {
        getAvatar,
        resetAvatars,
    };
}
