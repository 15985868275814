import React from 'react';
import { useParams } from 'react-router-dom';

import {
    TileContainer,
    CourseTile,
    LoadingDots,
    Icon,
    Button,
    BlockBadge,
} from '../../';

import { useProgressCoursesApi } from '../../../hooks/api';
import { getCopy } from '../../../utils';

import styles from './CourseList.module.scss';

const CourseList = ({ role = 'student', icon }) => {
    const { student_id: studentId } = useParams();
    const {
        currentCourses,
        previousCourses,
        currentBlock,
        loading,
    } = useProgressCoursesApi(studentId);

    const sectionTitle = getCopy(`progress.${role}.courses.title`);
    const titleIcon = icon ? (
        <Icon type={icon} color="accent" valign="middle" inline />
    ) : null;

    if (loading) {
        return (<h2>{titleIcon}{sectionTitle} <LoadingDots/></h2>);
    }

    // list copy
    const currentBlockTitle = getCopy(`progress.${role}.courses.currentBlockTitle`, { blockNumber: currentBlock?.number });
    const previousBlockTitle = getCopy(`progress.${role}.courses.previousBlockTitle`);

    const currentBlockEmptyTagLine = getCopy(`progress.${role}.courses.currentBlockEmptyTagLine`);

    const currentBlockTitleElement = (
        <>
            {currentBlockTitle}
            <BlockBadge
                block={currentBlock}
                className={styles['block-badge']}
            />
        </>
    );

    // button copy (progress overview)
    const overviewButtonTitle = getCopy(`progress.${role}.overview.linkTitle`);
    const overviewButtonTo = getCopy(`progress.${role}.overview.path`, { studentId });

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <>
            <h2>{titleIcon}{sectionTitle}</h2>
            {currentCourses.length ? (
                <section className={styles.section}>
                    <TileContainer title={currentBlockTitleElement} scroll>
                        {currentCourses.map((course, i) => (
                            <CourseTile
                                key={`current-course-${course.id}-${i}`}
                                to={getCopy(
                                    `progress.${role}.courses.linkTemplate`,
                                    { studentId, groupCourseId: course.id }
                                )}
                                status={course?.rating?.status}
                                {...course}
                            />
                        ))}
                    </TileContainer>
                </section>
            ) : (
                <section className={styles.section}>
                    <h3>{currentBlockTitleElement}</h3>
                    <p>{currentBlockEmptyTagLine}</p>
                </section>
            )}
            {previousCourses.length ? (
                <section className={styles.section}>
                    <TileContainer title={previousBlockTitle} scroll>
                        {previousCourses.map((course, i) => (
                            <CourseTile
                                key={`current-course-${course.id}-${i}`}
                                to={getCopy(
                                    `progress.${role}.courses.linkTemplate`,
                                    { studentId, groupCourseId: course.id }
                                )}
                                status={course?.rating?.status}
                                {...course}
                            />
                        ))}
                    </TileContainer>
                </section>
            ) : null}
            <section className={styles.section}>
                <Button to={overviewButtonTo} variant="small">
                    Ga naar {overviewButtonTitle}
                </Button>
            </section>
        </>
    );
};

export default CourseList;
