import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { withCoachingTrainee } from '../../hocs';
import { ENABLE_STUDYROUTE, getCopy } from '../../utils';
import { useScrollRestoration } from '../../hooks';

import { CoachingPageHeader } from '../../components';

import CoachOverview from './CoachOverview/CoachOverview';
import TraineeDetails from './TraineeDetails/TraineeDetails';
import Reports from './Reports/Reports';
import ReportDetails from './ReportDetails/ReportDetails';
import { TeacherFeedbackOverview } from './Feedback/Feedback';

import {
    CoachProgressOverview,
    CoachProgressRoute,
    CoachProgressCourse,
    CoachProgressCompetences,
    CoachProgressCompetenceDetails,
    CoachFirstMoment,
    BPVOverview,
} from '../';


const Landing = withCoachingTrainee(({ role }) => {

    const path = getCopy(`coaching.${role}.path`);
    useScrollRestoration({ path });

    const onCoursePlannerPage = useRouteMatch(`${path}/:student_id/cursusplanner`) !== null;

    return (
        <>
            {onCoursePlannerPage ? null : <CoachingPageHeader role={role} />}
            <Switch>
                <Route path={`${path}/:student_id/gespreksverslagen/:report_id`} exact={true}>
                    <ReportDetails role={role} />
                </Route>
                <Route path={`${path}/:student_id/gespreksverslagen`} exact={true}>
                    <Reports role={role} />
                </Route>
                <Route path={`${path}/:student_id/feedback`} exact={true}>
                    <TeacherFeedbackOverview role={role} />
                </Route>
                <Route path={`${path}/:student_id/voortgang/overzicht`} exact={true}>
                    <CoachProgressOverview role={role} />
                </Route>
                {ENABLE_STUDYROUTE ? (
                    <Route path={`${path}/:student_id/voortgang/studieroute`} exact={true}>
                        <CoachProgressRoute role={role} />
                    </Route>
                ) : null}
                <Route path={`${path}/:student_id/voortgang/competenties/:choice_id`} exact={true}>
                    <CoachProgressCompetenceDetails role={role} />
                </Route>
                <Route path={`${path}/:student_id/voortgang/mijn-bpv`} exact={true}>
                    <BPVOverview role={role} />
                </Route>
                <Route path={`${path}/:student_id/voortgang/competenties`} exact={true}>
                    <CoachProgressCompetences role={role} />
                </Route>
                <Route path={`${path}/:student_id/voortgang/cursus/:course_id/:block_id`} exact={true}>
                    <CoachProgressCourse role={role} scheduled />
                </Route>
                <Route path={`${path}/:student_id/voortgang/cursus/:group_course_id`} exact={true}>
                    <CoachProgressCourse role={role} />
                </Route>
                {['coach', 'secondcoach'].includes(role) ? (
                    <Route path={`${path}/:student_id/cursusplanner`} exact={true}>
                        <CoachFirstMoment role={role} />
                    </Route>
                ) : null}
                <Route path={`${path}/:student_id`} exact={true}>
                    <TraineeDetails role={role} />
                </Route>
                <Route path={path} exact={true}>
                    <CoachOverview role={role} />
                </Route>
            </Switch>
        </>
    )
});

const Coaching = () => <Landing role="coach" />;
const SecondCoach = () => {
    return (
        <Landing role="secondcoach" />
    );
}
const Mentoring = () => <Landing role="mentor" />;

export { Coaching, SecondCoach, Mentoring };
