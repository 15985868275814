import React from 'react'
import Bem from 'react-better-bem'

import styles from './Progress.module.scss'

const Progress = ({ progress }) => {
    let width =  !isNaN(progress) ? progress : 0

	if (progress) {
	  width = progress;
	}

    return (
        <Bem style={styles}>
            <div el="container">
                <div el="progress-bar-wrapper">
                    <div
                        el="progress-bar"
                        style={{ width: `${width}%` }}
                    >
                        <span
                            el="progress-bar-text"
                            mod={{ 'small-progress': width === 0 || width <= 10 }}
                        >{width}%</span>
                    </div>
                </div>
            </div>
        </Bem>
    )
}

export default Progress
