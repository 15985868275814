import React from 'react';
import Bem from 'react-better-bem';
import { Link } from 'react-router-dom';

import { IconButton } from '../';

import styles from './LinkList.module.scss';

const LinkList = ({ links = [], large = false, collapseTop = false, ...props }) => {
    if (!links.length) {
        return null;
    }

    return (
        <Bem style={styles}>
            <ol {...props} el="list" mod={{ large, collapse: collapseTop }}>
                {links.map(({ extraButton = null, ...linkProps }, i) => (
                    <li el="item" key={`linklist-${i}`}>
                        <LinkElement
                            large={large}
                            {...linkProps}
                        />
                        {extraButton ? <div el="extra-button">{extraButton}</div> : null}
                    </li>
                ))}
            </ol>
        </Bem>
    );
};

const LinkElement = ({ children, large, to, href, ...props }) => {
    let linkClassName = styles.list__item__link;
    if (large) {
        linkClassName += ` ${styles['list__item__link--large']}`;
    }

    const linkContent = (
        <Bem style={styles} block="list__item__link" mod={{ large }}>
            <div el="content">
                {children}
            </div>
            <IconButton icon="chevron-right" className={styles.list__item__link__button} />
        </Bem>
    );

    // internal link
    if (to) {
        return (
            <Link {...props} to={to} className={linkClassName}>
                {linkContent}
            </Link>
        );
    }

    // external link
    if (href) {
        return (
            <a {...props} href={href} className={linkClassName} target="_blank" rel="noreferrer">
                {linkContent}
            </a>
        );
    }

    // button
    linkClassName += ` ${styles['list__item__link--button']}`;

    return (
        <button {...props} className={linkClassName}>
            {linkContent}
        </button>
    );
};

export default LinkList;
