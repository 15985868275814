import React from 'react';
import Bem from 'react-better-bem';


import {
    TileContainer,
    CourseTile,
    Icon,
} from '../../';

import styles from './Section.module.scss';


const Section = ({
    title, subtitle, icon,
    shaded = false, showAll,
    choices,
    choose,
    allowChoosing,
    allowApproving,
    approve,
    decline,
    role,
    student,
    openInfoModal,
    sortField = "cleanName"
}) => {

    // sort by course field (default is name)
    choices.sort((
        { scheduled_course: { routeStatus: sa, course: a } },
        { scheduled_course: { routeStatus: sb, course: b } }
    ) => {
        const na = a[sortField];
        const nb = b[sortField];

        if (sa !== sb) {
            return sa === 'VERTRAGEND' ? -1 : 1;
        }
        return na < nb ? -1 : nb < na ? 1 : 0;
    });


    const subtitleComponent = icon ? (<><Icon color="red" type={icon}/> {subtitle}</>) : subtitle;

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <section el="section" mod={{ shaded }}>
                <div el="content">
                    <TileContainer title={title} subtitle={subtitleComponent} foldout={!showAll}>
                        {choices.map((choice) => {
                            const {
                                id,
                                is_bpv,
                                scheduled_course: { course, routeStatus, bpv_required: _bpvPhase },
                                request: { studentChoice, coachChoice, systemChoice, is_approved },
                            } = choice;

                            return (
                                <CourseTile
                                    key={`choice-coursetile-${id}`}
                                    empty={studentChoice || coachChoice} // show "empty" tile when user already chose course
                                    onClick={() => { openInfoModal(choice, allowChoosing); }}
                                    // in sections we only show route status for systemchoices
                                    routeStatus={systemChoice ? routeStatus : undefined}
                                    // for redo courses we set the icon color to red
                                    routeStatusIconColor={routeStatus === 'VERTRAGEND' ? 'warning-bar-error-text' : undefined}
                                    {...course}
                                    altIcon={allowChoosing ? 'plus-sign' : 'info-circle'}
                                    altOnClick={allowChoosing ? () => { choose(id); } : undefined}
                                    altLayout
                                    bpvPhase={_bpvPhase === 1 || _bpvPhase === 2 || is_bpv}
                                    toggle={allowApproving}
                                    toggled={is_approved}
                                    onToggle={is_approved ?
                                        () => { decline(id); } :
                                        () => { approve(id); }
                                    }
                                />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
        </Bem>
    );
};

export default Section;
