import { useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';

import useApi from '../useApi';

const NOW_MOMENT = moment();

/* * * * * * * * *
 * USER PLANNING *
** * * * * * * * */
function useUserPlanningApi (path = '/user/blocks') {
    const { get, loading, value: _planning, fetched, error } = useApi(
        'planning',
        '/user/blocks',
        { initialValue: [], noGlobalLoading: true }
    );

    const planning = useMemo(() => mapBlocks(_planning), [_planning]);

    useEffect(() => {
        window.user_planning = planning;
        return () => {
            delete window.user_planning;
        }
    }, [planning]);

    return {
        fetch: get,
        loading,
        fetched,
        error,
        planning,
    };
}

/* * * * * * * * * * *
 * TRAINEE PLANNING  *
** * * * * * * * * * */
function useTraineePlanningApi (studentId = false, autoFetch = false) {
    const { get, loading, value: _planning, fetched, error } = useApi(
        'studentplanning',
        `/students/${studentId}/blocks/`,
        { initialValue: [] }
    );

    const planning = useMemo(() => mapBlocks(_planning), [_planning]);

    const autoFetchWhen = studentId && autoFetch && !loading && !fetched;
    useEffect(() => {
        if (autoFetchWhen) {
            get();
        }
    }, [get, autoFetchWhen]);

    return {
        fetch: get,
        loading,
        fetched,
        error,
        planning,
    };
}

/* * * * * * * * * * * * * *
 * SINGLE PLANNING PROMISE *
** * * * * * * * * * * * * */
function useSimpleTraineePlanningApi () {
    const { get, loading, fetched, error, abort } = useApi('studentplanning', '', { initialValue: [] });

    const fetch = useCallback((studentId) => {
        return get(`/students/${studentId}/blocks`)
            .then((planning) => {
                return mapBlocks(planning)
            });
    }, [get]);

    return {
        fetch,
        loading,
        fetched,
        error,
        abort,
    };
}

export { useUserPlanningApi, useTraineePlanningApi, useSimpleTraineePlanningApi };

/* * * * * *
 * METHODS *
** * * * * */
function remainingTime (start, end) {
    // if dates aren't set we don't need any of these
    // fancy strings
    if ([start, end].includes(null) || [start, end].includes('')) {
        return {};
    }

    const sd = new Date(start);
    const ed = new Date(end);

    const sm = moment(sd);
    const em = moment(ed);

    const totalTime = em.diff(sm);
    const elapsed = NOW_MOMENT.diff(sm);

    const timeRemaining = em.diff(NOW_MOMENT);
    const fraction = elapsed / totalTime;
    let readable = '';

    if (timeRemaining <= 60 * 1000) {
        readable = 'Minder dan een minuut';
    } else if (timeRemaining <= 2 * 60 * 1000) {
        readable = '1 minuut';
    } else if (timeRemaining <= 60 * 60 * 1000) {
        readable = `${Math.floor(timeRemaining / (60 * 1000))} minuten`
    } else if (timeRemaining <= 24 * 60 * 60 * 1000) {
        readable = `${Math.floor(timeRemaining / (60 * 60 * 1000))} uur`
    } else if (timeRemaining <= 2 * 24 * 60 * 60 * 1000) {
        readable = '1 dag';
    } else {
        readable = `${Math.floor(timeRemaining / (24 * 60 * 60 * 1000))} dagen`;
    }

    return {
        time: timeRemaining,
        fraction,
        readable
    };
}

function mapBlocks (blocks) {
    const _blocks = blocks.map((block) => {
        const {
            is_km1_student = false,
            is_km1_coach = false,
            is_km2_student = false,
            km1_student_open,
            km1_student_closed,
            km1_coach_closed,
            km2_student_open,
            km2_student_closed,
        } = block;

        return {
            ...block,
            student: {
                km1: {
                    current: is_km1_student,
                    awaiting: is_km1_coach,
                    ...remainingTime(km1_student_open, km1_student_closed)
                },
                km2: {
                    current: is_km2_student,
                    ...remainingTime(km2_student_open, km2_student_closed)
                }
            },
            coach: {
                km1: {
                    current: is_km1_coach,
                    ...remainingTime(km1_student_closed, km1_coach_closed)
                }
            }
        };
    });

    _blocks.sort((
        { start_date: _da, number: na, period: pa, school_year: ya },
        { start_date: _db, number: nb, period: pb, school_year: yb }
    ) => {
        // first try sorting by date
        if (![_da, _db].includes(null)) {
            const da = new Date(_da);
            const db = new Date(_db);
            return da < db ? -1 : db < da ? 1 : 0;
        }

        // try to sort by number
        if (![na, nb].includes(null)) {
            return na - nb;
        }

        // try to sort by year
        if (ya !== yb) {
            return ya < yb ? -1 : yb < ya ? 1 : 0;
        }

        // finally: sort by period (years are the same)
        return pa - pb;
    });

    return {
        blocks: _blocks,
        get current () {
            const _current = this.blocks.find(({ is_current }) => is_current);
            return _current || this.getBlockByDate();
        },
        get latest () {
            const now = new Date();
            const _blocks = [ ...this.blocks ].reverse();
            return _blocks.find(({ end_date }) => {
                if (end_date === null) {
                    return false;
                }
                return new Date(end_date) <= now;
            });
        },
        get upcoming () {
            const now = new Date();
            return this.blocks.find(({ start_date }) => {
                if (start_date === null) {
                    return false;
                }
                return new Date(start_date) > now;
            });
        },
        get km1Student () {
            // check for both coach and student flags: we need to show an 'awaiting results' page
            return this.blocks.find(({ is_km1_student, is_km1_coach }) => is_km1_student || is_km1_coach);
        },
        get km2Student () {
            return this.blocks.find(({ is_km2_student }) => is_km2_student);
        },
        get km1Coach () {
            return this.blocks.find(({ is_km1_coach }) => is_km1_coach);
        },
        getBlockById: function (id) {
            return this.blocks.find(({ id: _id }) => _id === id);
        },
        getBlockByDate: function (date) {
            const _date = date === undefined ? new Date() : new Date(date);
            const _blocks = [ ...this.blocks ].reverse();

            return _blocks.find(({ start_date, end_date }) => {
                if ([start_date, end_date].includes(null)) {
                    return false;
                }

                const _start = new Date(start_date);
                const _end = new Date(end_date);

                return _date >= _start && _date < _end;
            });
        },
        get currentYear () {
            return this.current?.school_year;
        },
        get upcomingYear () {
            return this.upcoming?.school_year;
        },
        get latestYear () {
            return this.latest?.school_year;
        }
    };
}
