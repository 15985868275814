import { useCallback, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

import { setCssVariable } from '../utils';

function useElementHeight (elementName, initHeight = 0) {
    const onResize = useCallback(({ height }, other) => {
        if (!isNaN(height)) {
            setCssVariable(`${elementName}-height`,`${height}px`);
        }
    }, [elementName]);

    const { ref } = useResizeObserver({ onResize, box: 'border-box' });

    useEffect(() => {
        setCssVariable(`${elementName}-height`,`${initHeight}px`);
    }, [elementName, initHeight]);

    return ref;
}

export default useElementHeight;
