import { useCallback } from 'react';

const ENABLED = process.env.REACT_APP_FLEXX_ENV === 'production' && parseInt(process.env.REACT_APP_PENDO_ENABLED, 10) === 1;
const ACCOUNT_ID = process.env.REACT_APP_PENDO_ACCOUNT_ID;

const pendo = window.pendo;

export default function usePendo() {

    const initialize = useCallback(({ id, role, superuser = false }) => {
        if (ENABLED) {
            pendo.initialize({
                visitor: {
                    id, // Required if user is logged in
                    role, // Optional
                    superuser
                    // email:        // Recommended if using Pendo Feedback, or NPS Email
                    // full_name:    // Recommended if using Pendo Feedback

                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },

                account: {
                    id: ACCOUNT_ID // Required if using Pendo Feedback
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                }
            });
        }
    }, []);

    return {
        initialize
    };
}
