import React, { useMemo, useCallback, useEffect } from 'react';
import Bem  from 'react-better-bem';

import { Icon, Km2AvailableCourse, CustomSelect } from '../../';
import { useToggleState } from '../../../hooks';
import { getCopy } from '../../../utils';

import styles from './Km2AvailableCourses.module.scss';


const Km2AvailableCourses = ({
	role,
	sortMethod,
	available,
	getAvailableSeats,
	hilightSlots,
	getSortCriteria,
	viewButton,
	hidePopover,
	popover = false,
	bigPopover = false
 }) => {

	// aggregate, grouping and sorting
	const availableGroups = useMemo(() => {
		// aggragate options so we can show multiple options for 1 course in one tile
		const options = available.reduce((acc, option) => {
			const {
				id: [id],
				weekday: [weekday],
				startPeriod: [startPeriod],
				endPeriod: [endPeriod],
				actualStartPeriod: [actualStartPeriod],
				actualEndPeriod: [actualEndPeriod],
				availableSeats: [availableSeats],
				scheduled_course: {
					course: { id: courseId },
				},
			} = option;

			const sameCourseIndex = acc.findIndex(({ scheduled_course: { course: { id: _courseId } } }) => (
				courseId === _courseId
			));

			if (sameCourseIndex > -1) {
				acc[sameCourseIndex].id.push(id);
				acc[sameCourseIndex].weekday.push(weekday);
				acc[sameCourseIndex].startPeriod.push(startPeriod);
				acc[sameCourseIndex].endPeriod.push(endPeriod);
				acc[sameCourseIndex].actualStartPeriod.push(actualStartPeriod);
				acc[sameCourseIndex].actualEndPeriod.push(actualEndPeriod);
				acc[sameCourseIndex].availableSeats.push(availableSeats);

				return acc;
			}

			// re-define id, weekday, startPeriod, endPeriod and availableSeats
			// we need to do this, because a shallow copy will use references
			// to the _original_ "complex" structures (like arrays in this case)
			// to prevent this, we redefine all arrays
			const _option = {
				...option,
				id: [id],
				weekday: [weekday],
				startPeriod: [startPeriod],
				endPeriod: [endPeriod],
				actualStartPeriod: [actualStartPeriod],
				actualEndPeriod: [actualEndPeriod],
				availableSeats: [availableSeats],
			};

			return [...acc, _option];
		}, []);

		if (sortMethod === 'ALPHABET') {
			const groups = options.reduce((acc, choice) => {
				const { scheduled_course: { course: { cleanName } } } = choice;

				let letter = cleanName[0].toUpperCase();
				if (!isNaN(parseInt(letter, 10))) {
					letter = '#';
				}

				const currentGroupIndex = acc.findIndex(({ header }) => header === letter);

				if (currentGroupIndex > -1) {
					acc[currentGroupIndex].options.push(choice);
				} else {
					acc.push({ header: letter, options: [choice] });
				}

				return acc;
			}, []);

			groups.sort(({ header: a }, { header: b }) => {
				return a < b ? -1 : b < a ? 1 : 0;
			});

			return groups;

		} else if (sortMethod === 'VERSNELLEND') {
			return applyFilter(['VERSNELLEND', 'VERTRAGEND'], options);

		} else if (sortMethod === 'VERBREDEND') {
			return applyFilter(['VERBREDEND'], options);

		} else {
            const _groups = options.reduce((acc, choice) => {
                const { scheduled_course: { course: { cleanName } } } = choice;

                if (!acc[cleanName] && cleanName?.toLowerCase().includes(sortMethod?.toLowerCase())) {
                    acc.push({ options: [choice] });
                }

                return acc;
            }, []);

           return _groups;
        }
	}, [available, sortMethod]);

	function applyFilter(keys, options) {
		const groups = options.reduce((acc, choice) => {
			const { scheduled_course: { routeStatus } } = choice;

			if (!keys.includes(routeStatus))  return acc;
				acc.push({ options: [choice] });

			return acc;

		}, []);

		return groups;
	}

	/* * * * * * * * * * *
	 * EXPAND LIST ITEM  *
	** * * * * * * * * * */
	const removeHilightedSlots = useCallback(() => {
		if (hilightSlots) {
			hilightSlots([]);
		}
	}, [hilightSlots]);

	const {
		toggled: expandedCourse,
		toggle: expand,
	} = useToggleState({ onReset: removeHilightedSlots });

	const flatIds = available.map(({ id: [id] }) => id).join(';');
	useEffect(() => {
		expand(null);
	}, [flatIds, expand]);

	/* * * * *
	 * COPY  *
	** * * * */
	const columnTitle = getCopy(`coursePlanner.secondMoment.${role}.available.title`);
	const infoText = getCopy(`coursePlanner.secondMoment.${role}.available.info`);

	// take off later
	const selectOptions = [
		{value: 'Alle cursussen', label: "Alle cursussen", key: 'ALPHABET'},
		{ value: 'Binnen je diplomaprofiel', label: "Binnen je diplomaprofiel", key: 'VERSNELLEND' },
		{value: 'Buiten je diplomaprofiel', label: "Buiten je diplomaprofiel", key: 'VERBREDEND'},
	];

	const handleSelectOption = useCallback((value) => {
		getSortCriteria(value?.key ? value.key : value?.value);

	},[getSortCriteria])

	/* * * * * *
	 * OUTPUT  *
	** * * * * */
	return (
		<Bem style={styles}>
			<aside el="aside" mod={{ popover, 'big-popover': bigPopover }}>
				<header el="header element">
					{!popover ? (<h5>{ columnTitle}</h5>) : null}
					<div el="sort">
						<CustomSelect
							options={selectOptions}
							onChange={(value) => handleSelectOption(value)}
						/>
					</div>
					{!popover ? (<div el="info">
						<Icon
							type="info-bubble"
							size="large"
							color="gray-darker"
							className={styles['aside__info__icon']}
						/>
						<strong el="text">{infoText}</strong>
					</div>) : null
					}
				</header>

				<div el="courses element">
					<ol el="list">
						{availableGroups?.map(({ header, options }, i) => {
                            return (
                                <React.Fragment key={`list-options-${i}`}>
                                    {header ? (
                                        <li
                                            className={styles.aside__courses__list__header}
                                        >
                                            {header}
                                        </li>
                                    ) : null}
                                    {options.map((choice) => {
                                        return (
                                            <li key={`available-course-${choice.id[0]}`}>
                                                <Km2AvailableCourse
                                                    expanded={expandedCourse === choice.id[0]}
                                                    expand={expand}
                                                    availableChoice={choice}
                                                    getAvailableSeats={getAvailableSeats}
                                                    hilightSlots={hilightSlots}
                                                    role={role}
                                                    viewButton={viewButton}
                                                    hidePopover={hidePopover}
                                                />
                                            </li>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
					</ol>
				</div>
			</aside>
		</Bem>
	);
};

export default Km2AvailableCourses;
