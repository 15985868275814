import behaald from './studie_route/Gehaald_klein Copy 14.svg';
import niet_behaald from './studie_route/Gehaald_klein Copy 37.svg';
import lock from "./studie_route/icon_25.svg";
import redo from "./studie_route/Herhaling.svg";
import speed from "./studie_route/Verbreden Copy 4.svg";
import bpv from "./studie_route/Rectangle 196.svg";

const studieRouteIcons = {
    behaald,
    niet_behaald,
    lock,
    redo,
    speed,
    bpv,
}

export default studieRouteIcons;
