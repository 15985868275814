import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import useFetch from 'use-http';
import useCookie from 'react-use-cookie';
import querystring from 'querystring';

import { useAuthenticationContext, useAppStateContext } from '../context';

const ENDPOINT = process.env.REACT_APP_API_HOST;

const DEFAULT_CACHE_LIFE = 1000 * 60 * 60; // 1 hour

function useApi (identifier = '', path = '/', { headers, redirectToLogin = false, initialValue = {}, noWarning = false, noGlobalLoading = false, revoke = false, ...options } = {}) {
    const [csrfToken] = useCookie('csrftoken');

    const [value, setValue] = useState(initialValue);
    const initialValueRef = useRef(initialValue);

    const [fetched, setFetched] = useState(false);
    const { isAuthenticated, revoke: revokeAuth, authenticate } = useAuthenticationContext();
    const {
        notifications: {
            warning: warningNotification,
            error: errorNotification,
        },
        startLoading,
        stopLoading,
    } = useAppStateContext();

    let cachePolicy = 'no-cache';
    if (options.persist) {
        cachePolicy = 'cache-first';
    }

    /* * * * * * *
     * HANDLERS  *
    ** * * * * * */
    const { get, post, put, patch, del, abort, loading, error, cache: { clear } } = useFetch(
        `${ENDPOINT}${path}`,
        {
            credentials: 'include', // <- include session id,
            cachePolicy,
            cacheLife: DEFAULT_CACHE_LIFE,
            data: initialValue,
            ...options,
            headers: {
                'X-CSRFToken': csrfToken,
                ...headers,
            },
            interceptors: {
                response: async ({ response }) => {
                    setFetched(true);

                    if ([401, 403].includes(response.status)) {
                        if (revoke) {
                            revokeAuth(redirectToLogin);
                            return response
                        } else if (!noWarning) {
                            warningNotification(
                                `Je hebt niet de juiste rechten om "${identifier}" op te vragen.`,
                                { title: "Ho! 👮" }
                            );
                        }
                    } else if (!noWarning && [400, 404].includes(response.status)) {
                        warningNotification(
                            `We kunnen "${identifier}" niet vinden.`,
                            { title: "Hmmm... 🕵️" }
                        );
                    } else if (!noWarning && (response.status < 200 || response.status > 399)) {
                        warningNotification(
                            `Er is iets fout gegaan bij "${identifier}".`,
                            { title: "Oeps! 😲" }
                        );
                    }

                    if (![401, 403].includes(response.status)) {
                        authenticate();
                    }

                    if (response.ok) {
                        setValue(response.data);
                    } else {
                        clear();
                        setValue(initialValueRef.current);
                    }

                    return response;
                },
            },
        },
    );

    // set loading state in AppStateContext
    useEffect(() => {
        if (loading && !noGlobalLoading) {
            startLoading(identifier);
        } else {
            stopLoading(identifier);
        }
        return () => {
            stopLoading(identifier);
        };
    }, [noGlobalLoading, identifier, loading, startLoading, stopLoading]);

    // when not authenticated (anymore) => clear cache
    useEffect(() => {
        if (isAuthenticated === false) {
            clear();
            setValue(initialValueRef.current);
        }
    }, [isAuthenticated, clear]);

    // warn when backend is not available
    useEffect(() => {
        if (error instanceof Error && error.message.toLowerCase() === 'failed to fetch') {
            errorNotification(
                `De Flexx services zijn momenteel niet beschikbaar. Probeer het later opnieuw.`,
                { title: "Oh nee! ☠️" }
            );
        }
    }, [error, errorNotification, identifier]);

    /* * * * * *
     * METHODS *
    ** * * * * */
    const exposedGet = useCallback(async (pathOrParams = {}) => {
        const queryString = typeof pathOrParams === 'object' ?
            querystring.stringify(pathOrParams) :
            '';
        let requestPath = typeof pathOrParams !== 'object' ? `${pathOrParams}/` : '';

        let _path = requestPath + (queryString.length ? `?${queryString}` : '');
        _path = _path.replace(/^\//, '').replace(/\/+/g, '/');

        return get(_path);
    }, [get]);

    const makeExposedCall = useCallback((methodFunction, useQueryString = false) => {
        return async (pathOrParams = {}, actualParams = {}) => {
            let path = typeof pathOrParams === 'string' ? pathOrParams : '';
            let params = typeof pathOrParams === 'string' ? actualParams : pathOrParams;

            if (useQueryString) {
                const queryString = querystring.stringify(params);
                path += queryString.length ? `?${queryString}` : '';
                params = undefined;
            }

            return methodFunction(path, params);
        }
    }, []);

    const exposedPost = useMemo(() => makeExposedCall(post), [post, makeExposedCall]);
    const exposedPut = useMemo(() => makeExposedCall(put), [put, makeExposedCall]);
    const exposedPatch = useMemo(() => makeExposedCall(patch), [patch, makeExposedCall]);
    const exposedDel = useMemo(() => makeExposedCall(del), [del, makeExposedCall]);

    return {
        get: exposedGet,
        post: exposedPost,
        put: exposedPut,
        patch: exposedPatch,
        del: exposedDel,
        clearCache: clear,
        abort,
        error,
        loading,
        value,
        fetched,
        isAuthenticated,
    };
}

export default useApi;
