import React, { useContext } from 'react';
import { useAuthentication } from '../hooks';


const AuthenticationContext = React.createContext();

const AuthenticationContextProvider = ({ children }) => {

    const store = useAuthentication();

    return (
        <AuthenticationContext.Provider value={store}>
            {children}
        </AuthenticationContext.Provider>
    );
};

const useAuthenticationContext = () => useContext(AuthenticationContext);

export { AuthenticationContextProvider };
export default useAuthenticationContext;
