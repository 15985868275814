import l0 from './score_badges/l_0.svg';
import l1 from './score_badges/l_1.svg';
import l2 from './score_badges/l_2.svg';
import l3 from './score_badges/l_3.svg';
import l4 from './score_badges/l_4.svg';
import lUnknown from './score_badges/l_unknown.svg';
import lInvalid from './score_badges/l_invalid.svg';

import m0 from './score_badges/m_0.svg';
import m1 from './score_badges/m_1.svg';
import m2 from './score_badges/m_2.svg';
import m3 from './score_badges/m_3.svg';
import m4 from './score_badges/m_4.svg';
import mUnknown from './score_badges/m_unknown.svg';
import mInvalid from './score_badges/m_invalid.svg';

import s0 from './score_badges/s_0.svg';
import s1 from './score_badges/s_1.svg';
import s2 from './score_badges/s_2.svg';
import s3 from './score_badges/s_3.svg';
import s4 from './score_badges/s_4.svg';
import sUnknown from './score_badges/s_unknown.svg';
import sInvalid from './score_badges/s_invalid.svg';

const badges = {
    l0, l1, l2, l3, l4, lUnknown, lInvalid,
    m0, m1, m2, m3, m4, mUnknown, mInvalid,
    s0, s1, s2, s3, s4, sUnknown, sInvalid,
};

export default badges;
