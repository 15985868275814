import React from 'react';
import Bem from 'react-better-bem';

import { PageHeader, Icon } from '../../components';
import { useLayout, usePageTitle } from '../../hooks';

import styles from './Contact.module.scss';

const Contact = () => {
    usePageTitle('contact');
    useLayout({ collapse: 'top' });

    return (
        <Bem style={styles}>
            <PageHeader title="Contact" />

            <h2>Vragen over Flexx</h2>

            <address el="address">
                <span>
                    <Icon type="phone" color="footer-background" inline valign="middle"/>
                    <a href="tel:+31356892080" title="Bel ons">06 3321 0720</a>
                </span>
                <br />
                <span>
                    <Icon type="mail" color="footer-background" valign="middle" inline/>
                    <a href="mailto:flexxsupport@rocva.nl">flexxsupport@rocva.nl</a>
                </span>
            </address>

            <h2>Secretariaat opleiding Media</h2>

            <address el="address">
                <span>
                    <Icon type="phone" color="footer-background" valign="middle" inline/>
                    <a href="tel:+31356892080" title="Bel ons">035 - 689 20 80</a>
                </span>
                <br/>
                <span>
                    <Icon type="mail" color="footer-background" valign="middle" inline/>
                    <a href="mailto:secretariaatmedia@rocva.nl">secretariaatmedia@rocva.nl</a>
                </span>
            </address>

            <h2>MBO College Hilversum</h2>

            <address el="address">
                MBO College Hilversum<br/>
                Arena 301<br/>
                1213 NW Hilversum<br/>
                <br/>
                <span>
                    <Icon type="phone" color="footer-background" valign="middle" inline/>
                    <a href="tel:+31356892000" title="Bel ons">035 - 689 20 00</a>
                </span>
                <br/>
                <span>
                    <Icon type="mail" color="footer-background" valign="middle" inline/>
                    <a href="mailto:mbocollegehilversum@rocva.nl">mbocollegehilversum@rocva.nl</a>
                </span>
            </address>

        </Bem>
    );
};

export default Contact;
