// Export all environment variables once in this config file

export const {
    REACT_APP_LEERDOELEN_FORMULIER: LEERDOELEN_FORM_LINK,
    REACT_APP_GOOGLE_FORM_LINK: GOOGLE_FORM_LINK,
    REACT_APP_MEDIASTAGES_LINK: MEDIA_GROUP_LINK,
    REACT_APP_FLEXX_FAILED_AVATAR_ID: AVATAR_ID,
    REACT_APP_STAGEMARKT_LINK: STAGE_MARKT_LINK,
    REACT_APP_FLEXX_LEGACY_YEARS: LEGACY_YEARS,
    REACT_APP_PRINT_HOST: PRINT_BASE_PATH,
    REACT_APP_ENABLE_PRINT_BUTTON: ENABLE_PRINT_BUTTON,
} = process.env
