import { useEffect } from 'react';
import { useAppStateContext } from '../context';

export default function useLayout ({ stretch, collapse, stretchContent, padContent, margin, background, flexContent } = {}) {
    const { container: { setLayout } } = useAppStateContext();

    useEffect(() => {
        setLayout({ stretch, collapse, stretchContent, padContent, margin, background, flexContent });
    }, [stretch, collapse, stretchContent, padContent, setLayout, margin, background, flexContent]);
}
