import React, { useContext } from 'react';
import useRatings from "../hooks/useRatings";

const RatingsContext = React.createContext();

const RatingsContextProvider = ({ children }) => {
    const ratings = useRatings();

    return (
        <RatingsContext.Provider value={ratings}>
            {children}
        </RatingsContext.Provider>
    );
};

const useRatingsContext = () => useContext(RatingsContext);

export { RatingsContextProvider };
export default useRatingsContext;
