import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/nl';
import Bem from 'react-better-bem';

import { dateToDateString, getCopy } from '../../utils';
import { useCalendarApi } from '../../hooks/api';
import { useLayout, usePageTitle } from '../../hooks';
import { useAppStateContext, useEnvironmentContext } from '../../context';
import { Calendar, DatePicker, HomePageCoursePlannerNavTile } from '../../components';

import styles from './Calendar.module.scss';

const FlexCalendar = () => {
    const {
        planning: { km1Student, km2Student },
        environment,
    } = useEnvironmentContext();


    const { screen: { isTablet } } = useAppStateContext();

    useLayout({ stretch: true, collapse: 'both', stretchContent: true, margin: false });
    usePageTitle('calendar');

    const { date: urlDate = 'vandaag', eventId: selectedEventId } = useParams();
    const history = useHistory();
    const { get, events, loading } = useCalendarApi();

    // turn url param date string into usable request date;
    const requestDate = useMemo(() => {
        switch (urlDate) {
            case 'vandaag':
            case undefined: {
                return new Date();
            }
            default: {
                return new Date(urlDate);
            }
        }
    }, [urlDate]);

    const [datepickerDate, setDatepickerDate] = useState(requestDate);

    // navigation buttons
    const onNavigate = useCallback((newDate) => {
        history.push(`/agenda/${dateToDateString(newDate)}`);
    }, [history]);

    const onSelectEvent = useCallback(({ id }) => {
        history.push(`/agenda/${urlDate}/${id}`);
    }, [urlDate, history]);

    const onCloseDetailsPopover = useCallback(() => {
        history.push(`/agenda/${urlDate}`);
    }, [urlDate, history]);

    /* * * * * * * * * *
     * EFFECT TO FETCH *
    ** * * * * * * * * */
    const requestedStartMonthRef = useRef();
    const requestedEndMonthRef = useRef();
    useEffect(() => {
        // check if we actually have a date to work with
        if (requestDate instanceof Date) {

            const first = moment.min(moment(requestDate), moment(datepickerDate));
            const last = moment.max(moment(requestDate), moment(datepickerDate));

            const _first = first.format('MMYYYY');
            const _last = last.format('MMYYYY');

            if (_first !== requestedStartMonthRef.current || _last !== requestedEndMonthRef.current) {
                // get first of the month
                // than subtract weekdays to get first day visible in datepicker
                const _start = moment(first).date(1);
                const startWeekday = _start.weekday();
                _start.subtract(startWeekday, 'day');

                // get last of the month
                // than add weekdays to get last day visible in datepicker
                const _end = moment(last).add(1, 'month').date(1).subtract(1, 'day');
                const endWeekday = _end.weekday();
                _end.add(6 - endWeekday, 'day');

                // make strings
                const start = dateToDateString(_start);
                const end = dateToDateString(_end);

                // fetch those events
                get(start, end);
            }

            requestedStartMonthRef.current = _first;
            requestedEndMonthRef.current = _last;
        }
    }, [requestDate, datepickerDate, get]);

    // redirect to current date if requested 'vandaag'
    if (urlDate === 'vandaag') {
        return (
            <Redirect to={`/agenda/${dateToDateString()}`} />
        );
    }

    const showChoiceMoment1Tile = environment === 'STUDENT' && km1Student !== undefined;
    const showChoiceMoment2Tile = environment === 'STUDENT' && km2Student !== undefined;

    const {
        student: {
            km1: {
                current: isKm1,
            } = {}
        } = {},
    } = showChoiceMoment1Tile && km1Student

    const {
        student: {
            km2: {
                current: isKm2,
            } = {},
        } = {}
    } = showChoiceMoment2Tile && km2Student

    const choiceMoment1Title = getCopy(
        `calendar.coursePlanner.title`,
        { moment: 1, blockNumber: km1Student?.number }
    );
    const choiceMoment2Title = getCopy(
        `calendar.coursePlanner.title`,
        { moment: 2, blockNumber: km2Student?.number }
    );

    return (
        <Bem style={styles}>
            <div el="grid">
                {isTablet ? (
                    <div el="col" mod="datepicker">
                        <DatePicker
                            events={events}
                            date={requestDate}
                            onNavigate={onNavigate}
                            viewDate={datepickerDate}
                            setViewDate={setDatepickerDate}
                            includeDots={true}
                        />
                        {showChoiceMoment1Tile ? (
                            <div el="course-planner" mod="planner-1">
                                <h5 el="header">{choiceMoment1Title}</h5>
                                <HomePageCoursePlannerNavTile
                                    block={km1Student}
                                    isKm1={isKm1}
                                    role="student"
                                    stretched
                                />
                            </div>
                        ) : null}
                        {showChoiceMoment2Tile ? (
                            <div el="course-planner" mod="planner-2">
                                <h5 el="header">{choiceMoment2Title}</h5>
                                <HomePageCoursePlannerNavTile
                                    block={km2Student}
                                    isKm2={isKm2}
                                    role="student"
                                    stretched
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
                <div el="col" mod="calendar">
                    <Calendar
                        loading={loading}
                        events={events}
                        urlDate={urlDate}
                        date={requestDate}
                        onNavigate={onNavigate}
                        selectedEventId={selectedEventId}
                        onSelectEvent={onSelectEvent}
                        onCloseDetailsPopover={onCloseDetailsPopover}
                        viewDate={datepickerDate}
                        setViewDate={setDatepickerDate}
                    />
                </div>
            </div>
        </Bem>
    );
};

export default FlexCalendar;
