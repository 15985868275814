import React, { useRef } from 'react';
import Bem from 'react-better-bem';
import useOnClickOutside from 'use-onclickoutside';

import { Button } from '../';
import { useAppStateContext } from '../../context';

import styles from './SidePanel.module.scss';

function SidePanel () {

    const {
        sidePanel: {
            content,
            title,
            visible,
            hidden,
            close,
            hide,
        },
    } = useAppStateContext();

    const sidePanelRef = useRef();
    useOnClickOutside(sidePanelRef, close);

    return (
        <Bem style={styles}>
            <aside
                el="panel"
                mod={{ visible, hidden }}
                onTransitionEnd={hide}
                ref={sidePanelRef}
            >
                <div el="content">
                    <div el="header">
                        {title ? <h3 el="title">{title}</h3> : null}
                        <Button
                            variant="mini"
                            transparent
                            icon="cross"
                            color="sidepanel-text"
                            iconSize="small"
                            tabIndex={-1}
                            onClick={close}
                            className={styles['panel__content__header__close-button']}
                        />
                    </div>
                    <div el="inner">
                        {content}
                    </div>
                </div>
            </aside>
        </Bem>
    );
}

export default SidePanel;
