import React from 'react';
import { Route, Switch } from "react-router-dom";

import { withTeacherRatings } from '../../hocs';
import { useEnvironmentContext } from "../../context";

import RatingOverview from './Overview/Overview';
import RatingGroupCourse from './GroupCourse/GroupCourse';

const Landing = withTeacherRatings(() => {
    const { routeItems } = useEnvironmentContext();

    const ratingOverviewRoute = routeItems.find((route) => route.name === 'Beoordelen');
    const ratingGroupCourse = routeItems.find((route) => route.name === 'Cursus Beoordelen');

    return (
         <Switch>
            <Route path={ratingOverviewRoute.path} exact={ratingOverviewRoute.exactPath} key={`route-${ratingOverviewRoute.name}-${ratingOverviewRoute.path}`}>
                <RatingOverview />
            </Route>
            <Route path={ratingGroupCourse.path} exact={ratingGroupCourse.exactPath} key={`route-${ratingGroupCourse.name}-${ratingGroupCourse.path}`}>
                <RatingGroupCourse />
            </Route>
        </Switch>
    )
});

const Overview = () => <Landing/>;
const GroupCourse = () => <Landing/>;

export { Overview as RatingOverview, GroupCourse as RatingGroupCourse };
