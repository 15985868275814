import { useState, useCallback, useEffect, useMemo, useRef } from 'react';

import ENVIRONMENTS from '../config/user_environments.json';
import COLORS from '../config/colours.json';
import ENV_COLORS from '../config/env_colours.json';
import ROUTES from '../config/routes.json';

import { usePendo } from './';
import { setCssVariable } from '../utils';

const DEFAULT_USER_ENVIRONMENT = process.env.REACT_APP_DEFAULT_USER_ENVIRONMENT;
const NODE_ENV = process.env.NODE_ENV;

function useUserEnvironment () {
    const { initialize: initPendo } = usePendo();

    /* * * * * * * * * * * * * * *
     * ENVIRONMENT / USER PROPS  *
    ** * * * * * * * * * * * * * */
    const [flexxId, setFlexxId] = useState(null);
    const [impersonated, setImpersonated] = useState(false)
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [email, setEmail] = useState(null);
    const [baseGroup, setBaseGroup] = useState(null);
    const [cohort, setCohort] = useState(null);
    const [specialisation, setSpecialisation] = useState(null);
    const [bpvOne, setBpvOne] = useState(null);
    const [bpvTwo, setBpvTwo] = useState(null);
    const [bpvEnabled, setBpvEnabled] = useState(true);
    const [studyRouteEnabled, setStudyRouteEnabled] = useState(true);

    const [roles, setRoles] = useState([]);

    /* * * * * * * * * * * * * * * * * *
     * SET TEACHER/STUDENT ENVIRONMENT *
    ** * * * * * * * * * * * * * * * * */
    const environment = useMemo(() => {
        const _roles = roles.map((_r) => _r.toLowerCase());
        const env = _roles.includes('teacher') ? 'TEACHER' :
            _roles.includes('student') ? 'STUDENT' :
                undefined;
        return ENVIRONMENTS.includes(env) ? env : DEFAULT_USER_ENVIRONMENT;
    }, [roles]);

    /* * * * * * * * * *
     * BLOCKS PLANNING *
    ** * * * * * * * * */
    const [planning, setPlanning] = useState({});
    const clearPlanning = useCallback(() => { setPlanning({}); }, []);

    /* * * * * * * * * * * * * * * * * * * * * * * *
     * SET AND CLEAR USER (ON LOGIN AND ON LOGOUT) *
    ** * * * * * * * * * * * * * * * * * * * * * * */
    // + init pendo
    // + clear useHttp cache!
    const flexxIdRef = useRef();
    const setUserInformation = useCallback((userInfo = {}) => {
        // set user information in state
        const {
            flexx_id,
            first_name,
            last_name,
            full_name,
            bpv_1,
            bpv_2,
            bpv_active,
            study_route_active,
            email,
            base_group,
            cohort,
            specialisation,
            roles = [],
            impersonated,
        } = userInfo;

        // user changed OR logged in
        if (flexx_id !== flexxIdRef.current) {
            flexxIdRef.current = flexx_id;

            localStorage.removeItem('useHTTPcache');

            setFlexxId(flexx_id || null);
            setFirstName(first_name || null);
            setLastName(last_name || null);
            setFullName(full_name || null);
            setEmail(email || null);
            setBaseGroup(base_group || null);
            setCohort(cohort || null);
            setSpecialisation(specialisation || null);
            setImpersonated(impersonated || false);
            setBpvOne(bpv_1);
            setBpvTwo(bpv_2);
            setBpvEnabled(bpv_active);
            setStudyRouteEnabled(study_route_active);

            const _roles = roles.map((_r) => (_r.toLowerCase().includes('coach')) ? 'coach' : _r.toLowerCase());
            setRoles(_roles);

            // initialize user within pendo
            const pendoRole = _roles.includes('student') ? 'student' : 'teacher';
            const isSuperuser = _roles.includes('superuser');

            if (!impersonated) {
                initPendo({
                    id: flexx_id,
                    role: pendoRole,
                    superuser: isSuperuser,
                });
            }
        }
    }, [initPendo]);

    const clearUserInformation = useCallback(() => {
        setFlexxId(null);
        setFirstName(null);
        setLastName(null);
        setFullName(null);
        setEmail(null);
        setBaseGroup(null);
        setImpersonated(false)
        setBpvOne(null);
        setBpvTwo(null);
        setBpvEnabled(true);
        setStudyRouteEnabled(true);
        setRoles([]);
        clearPlanning();
    }, [clearPlanning]);

    const checkEnabledRoutes = useCallback((routeName) => {
        const enabledMappings = {
            'mijn-bpv': bpvEnabled,
            'studieroute': studyRouteEnabled,
        };

        if (enabledMappings.hasOwnProperty(routeName)) {
            return enabledMappings[routeName];
        }

        return true;
    }, [bpvEnabled, studyRouteEnabled]);

    /* * * * * * * * * * * * * * * *
     * STORE ROUTES AND NAV ITEMS  *
    ** * * * * * * * * * * * * * * */
    const routes = useMemo(() => {
        // get all routes and remap with default props
        const genRoutes = ROUTES.GENERAL;
        const envRoutes = ROUTES[environment];

        // unique routenames
        const routeNames = [ ...Object.keys(genRoutes), ...Object.keys(envRoutes) ]
            .filter((routeName, index, allNames) => allNames.indexOf(routeName) === index);

        const routes = routeNames
            // map to merged route
            .map((routeName, index) => {
                const _genRoute = genRoutes[routeName] || {};
                const _envRoute = envRoutes[routeName] || {};

                return {
                    valid: checkEnabledRoutes(routeName),
                    onlyInDev: false,
                    invertOnHomePage: false,
                    nav: [],
                    roles: [],
                    ..._genRoute,
                    ..._envRoute
                };
            })
            // filter out invalid routes
            .filter(({ valid, onlyInDev }) => valid && (!onlyInDev || NODE_ENV === 'development'))
            // filter out routes by role
            .filter(({ roles: routeRoles }) => (
                !routeRoles.length ||
                routeRoles.some((role) => roles.includes(role))
            ))
            .sort(({ order: a = 999 }, { order: b = 999 }) => a - b);

        // get redirects from routes
        const redirects = routes
            .filter(({ redirectFrom }) => redirectFrom !== undefined)
            .reduce((acc, { redirectFrom, path, exactPath, routeOrder }) => {
                redirectFrom = typeof redirectFrom === 'string' ? [redirectFrom] : redirectFrom;

                return [
                    ...acc,
                    ...redirectFrom.map((rPath) => ({
                        redirect: path,
                        path: rPath,
                        exactPath,
                        routeOrder
                    }))
                ];
            }, []);

        const navbar = [...routes].filter(({ nav }) => nav.includes('navbar'));
        const footer = [...routes].filter(({ nav }) => nav.includes('footer'));
        const footerLegal = [...routes].filter(({ nav }) => nav.includes('legal'));
        const homepage = [...routes].filter(({ nav }) => nav.includes('homepage'));

        const routeItems = [...routes, ...redirects]
            .sort(({ routeOrder: a = 0 }, { routeOrder: b = 0 }) => a - b);

        return {
            routeItems,
            navItems: {
                navbar,
                footer,
                footerLegal,
                homepage,
            }
        };
    }, [checkEnabledRoutes, environment, roles]);

    const { routeItems } = routes;
    const getRouteByName = useCallback((name) => {
        const route = routeItems.find(({ name: _name }) => _name?.toLowerCase() === name?.toLowerCase());
        return route?.href;
    }, [routeItems]);

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * UPDATE CSS COLOR VARIABLES DEPENDING ON ENVIRONMENT *
    ** * * * * * * * * * * * * * * * * * * * * * * * * * * */
    useEffect(() => {
        const envColors = {
            ...ENV_COLORS._DEFAULT_,
            ...ENV_COLORS[environment]
        };

        Object.keys(envColors).forEach((colorName) => {
            const color = COLORS[envColors[colorName]];
            setCssVariable(`c-${colorName}`, color);
        });
    }, [environment]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return {
        environment,

        user: {
            flexxId,
            firstName,
            lastName,
            fullName,
            email,
            baseGroup,
            roles,
            cohort,
            specialisation,
            impersonated,
            bpvOne,
            bpvTwo,
            bpvEnabled,
            studyRouteEnabled,
        },
        setUserInformation,
        clearUserInformation,

        planning,
        setUserPlanning: setPlanning,
        clearUserPlanning: clearPlanning,

        ...routes,
        getRouteByName
    };
}

export default useUserEnvironment;
