import React, { useMemo } from 'react';

import { GroupName, LinkList, Avatar, CoachingChoicesButton, Button } from '../../';
import { getCopy } from '../../../utils';

import styles from './TraineesLinkList.module.scss';

const TraineesLinkList = ({ groupName, collapseTop, students, role, showAvatars = true }) => {
    const links = useMemo(() => {
        const parentPagePath = getCopy(`coaching.${role}.path`);
        const urlPrefix = parentPagePath + '/';

        return students.map((student) => {
            const coursePlannerPath = getCopy(
                `coursePlanner.firstMoment.coach.path`,
                { rootPath: parentPagePath, studentId: student?.flexx_id }
            );

            const { km1_status: km1Status } = student;

            return {
                children: (
                    <div className={styles.link}>
                        <Avatar
                            {...student}
                            noRemote={!showAvatars}
                            className={styles.link__avatar}
                            size="xl"
                        />
                        <strong>{student.full_name}</strong>
                    </div>
                ),
                extraButton: ['coach', 'secondcoach'].includes(role) ? (
                    <CoachingChoicesButton
                        to={coursePlannerPath}
                        km1Status={km1Status}
                    />
                ) : null,
                to: `${urlPrefix}${student.flexx_id}`
            };
        });
    }, [students, role, showAvatars]);

    const progressGroupTitle = getCopy('progress.coachGroup.title');
    const progressGroupLink = getCopy('progress.coachGroup.path', { groupCode: groupName });

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <>
            <div className={styles.header}>
                <h4 className={styles.title}>
                    <GroupName code={groupName} noHilight={groupName === 'Overige studenten'}/>
                </h4>
                <Button className={styles.link} variant="extra-small" to={progressGroupLink}>{progressGroupTitle}</Button>
            </div>
            <LinkList
                links={links}
                collapseTop={collapseTop}
            />
        </>
    );
};

export default TraineesLinkList;
