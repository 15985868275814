import clipartclock from './graphics/clipartclock.png';
import confusedowl from './graphics/confusedowl.svg';
import deadlineclock from './graphics/deadlineclock.svg';
import dots from './graphics/dots.gif';
import readingowl from './graphics/readingowl.svg';
import statusBL from './graphics/status_BL.svg';
import internOwl from './graphics/intern-owl.svg';
import party from './graphics/party.svg';

const graphics = {
    clipartclock,
    confusedowl,
    deadlineclock,
    dots,
    readingowl,
    statusBL,
    internOwl,
    party,
};

export default graphics;
