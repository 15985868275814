import { memo, useState } from 'react';
import Bem from 'react-better-bem';

import { DragDropFile } from "../"
import { Icon, Button } from "../../../components";

import File from "./File"

import { useEnvironmentContext, useAppStateContext } from '../../../context';

import badge from "../../../assets/graphics/back-badge.png";
import pin from "../../../assets/graphics/back-pin.png";

import styles from './Card.module.scss';

const Card = ({ cardContent, uploadFile, deleteFile, studentRole = true }) => {
    const [active, setIsActive] = useState('stage');
    const {
        // screen: { isTablet },
        modal: { open: openModal, close: closeModal },
    } = useAppStateContext();

    const { environment } = useEnvironmentContext();
    const teacher = environment === 'TEACHER';
    const student = environment === 'STUDENT';

    const {
        id,
        address,
        postalCode,
        city,
        companyName,
        enteredHours,
        completedHours,
        startDate,
        endDate,
        contactPersonName,
        contactPersonEmail,
        contactPersonPhone,
        filesLength,
        files,
    } = cardContent;

    const companyAddress = [address, postalCode, city].filter(item => item).join(', ');

    const backgroundImage = active === "stage" ? badge : pin;
    const filesPage = active === "bestanden";

    const toggle = (value) => {
        setIsActive( value === "stage" ? "bestanden" : "stage" );
    };

    const handleUpload = () => {
        openModal(
            <DragDropFile
                internshipId={id}
                uploadFile={uploadFile}
                closeModal={closeModal}
                deleteFile={deleteFile}
            />,
            "Bestanden uploaden",
            { small: false },
            null,
            null,
            true,
        );
    };

    return (
        <Bem style={styles} >
            <li el="body-card" mod={{ "files-page": filesPage }} >
                <div el="back-image" mod={{ stage: filesPage }} >
                    <img
						src={backgroundImage}
						alt=""
						el="img"
					/>
                </div>
                <header el="header">
                    <nav el="nav">
                        <ul el="nav-list" mod={{ teacher, student }}>
                            <button
                                el="nav-button"
                                mod={{
                                    'active-student': active === "stage" && student,
                                    'active-teacher': active === "stage" && teacher
                                }}
                                className={`${styles[student && active ? "student" : "teacher"]}`}
                                onClick={() => toggle(active)}
                                type="button"
                            >
                                Stage
                            </button>
                            <button
                                el="nav-button"
                                mod={{
                                    'active-student-files': active === "bestanden" && student,
                                    'active-teacher-files': active === "bestanden" && teacher
                                }}
                                onClick={() => toggle(active)}
                                type="button"
                            >
                                Bestanden
                                {filesPage && filesLength > 0 ? (<span el="pill">{Number(filesLength)}</span>): null }
                            </button>
                        </ul>
                    </nav>
                </header>
                <div el="body" mod={{ "files-page": filesPage }}>
                    {active === "stage" ? (
                        <header el="body-header">
                            <h5 el="header-content">{active === "stage" ? "Basisstage" : ""}</h5>
                        </header>
                    ) : null}
                    {active === "stage" ? (
                        <ul
                            el="lists"
                            mod={{
                                fixedHeight: active === 'stage'
                            }}
                        >
                                                        <li el="list-item">
                                <Icon type="building" color="blue" />
                                <div el="content">
                                    <span el="text">{companyName}</span>
                                    <span el="address">{companyAddress}</span>
                                </div>
                            </li>
                            <li el="list-item">
                                <Icon type="calendar-filled" color="blue" />
                                <span el="text"><span el="date">{startDate}</span> - <span el="date">{endDate}</span></span>
                            </li>
                            <li el="list-item">
                                <Icon type="user-circle" color="blue" />
                                <div el="content">
                                    <span el="text">{contactPersonName}</span>
                                    <span el="mail">{contactPersonEmail}</span>
                                    <span el="phone">{contactPersonPhone}</span>
                                </div>
                            </li>
                            <li el="list-item">
                                <Icon type="clock" color="blue" />
                                <span el="text-light"><strong>{`${enteredHours}`}</strong><span el="inner"> door student geregistreerde uren</span></span>
                            </li>
                            <li el="list-item">
                                <Icon type="clock" color="blue" />
                                <span el="text-light"><strong>{`${completedHours}`}</strong><span el="inner"> door stagebedrijf geaccordeerde uren</span></span>
                            </li>
                        </ul>
                    ) : (
                        <ul el="lists"  mod={{
                            teacher: (active === 'bestanden') && teacher,
                        }}>
                            {files.map((file) => (
                                <File
                                    key={file.id}
                                    file={file}
                                    deleteFile={deleteFile}
                                />
                            ))}
                        </ul>
                    )}
                    {active === "bestanden" && studentRole ? (
                        <Button
							variant="default"
							className={styles["body-card__body--button"]}
							onClick={handleUpload}
						>
                            Upload bestanden
                        </Button>
                    ) : null}
                </div>
            </li>
        </Bem>
    );
};

export default memo(Card);
