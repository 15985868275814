import React from 'react';

import { Button, Icon } from '../';

import styles from './FilterButton.module.scss';

const FilterButton = ({ children = '', className = '', onClick = () => void 0}) => {

    const btnClassNames = [styles['filter-button'], className].filter(Boolean);

    if (!children) {
        btnClassNames.push(styles['filter-button--empty']);
    }

    const variant = children ? 'rounded' : 'rounded-empty';

    return (
        <Button
            variant={variant}
            className={btnClassNames.join(' ')}
            onClick={onClick}
        >
            {children}
            <span className={styles['filter-button__icon']}>
                <Icon
                    type="filter"
                    color="white"
                />
            </span>
        </Button>
    );
};

export default FilterButton;
