import React from 'react';

import statusIcons from '../../assets/statusIcons';
import STATUS from '../../data/rating_statusses.json';

const images = {
    [STATUS.CLOSED]: statusIcons.afgesloten,
    [STATUS.COMPLETED]: statusIcons.behaald,
    [STATUS.NOT_COMPLETED]: statusIcons.niet_behaald,
    // [STATUS.BLANK]: gfx.statusBL, // <- disabled, not showing 'blank' status icon
};

const ProgressImage = ({ status, alt = '', title = '', ...props }) => {
    status = Object.keys(images).includes(status) ? status : undefined;
    alt = alt || status;

    return status ? (
        <img
            {...props}
            alt={alt}
            title={title || alt}
            src={images[status]}
        />
    ) : null;
};

export default ProgressImage;
