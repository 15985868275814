import { useState, useEffect, useCallback } from 'react';

function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return defaultValue;
    }
    return JSON.parse(stored);
}

function useSessionStorage(key, defaultValue, reset = false) {
    const [value, setValue] = useState(
        reset ? defaultValue : getSessionStorageOrDefault(key, defaultValue)
    );

    useEffect(() => {
        if(key === undefined) return;

        sessionStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    const clearValue = useCallback(() => {
        sessionStorage.removeItem(key);
    }, [key]);

    return [value, setValue, clearValue];
}

export default useSessionStorage;
