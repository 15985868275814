import { useEffect, useMemo } from 'react';
import useApi from '../useApi';

import { useEnvironmentContext } from '../../context';

const INIT_VALUE = { criteria: [], criteria_ratings: [] };

function useProgressCompetencesApi (studentId) {
    const { environment } = useEnvironmentContext();
    const apiPath = environment === 'TEACHER' ?
        `/students/${studentId}/progress/criteria` :
        '/progress/criteria';

    const { get, loading, value, fetched } = useApi('competenties', apiPath, { initialValue: INIT_VALUE });

    // fetch initially
    const fetchWhen = !fetched && !loading;
    useEffect(() => {
        if (fetchWhen) {
            get();
        }
    }, [get, fetchWhen]);

    // Remap criteria + ratings:
    // filter only choice_based
    // Combine all scores per criterium type
    const competences = useMemo(() => {
        const { criteria: crits, criteria_ratings: ratings } = value;

        return crits
            .reduce((acc, crit) => {
                const { id, choice_id: choiceId } = crit;
                const cCritIndex = acc.findIndex(({ choice_id: _choiceId }) => _choiceId === choiceId);

                if (cCritIndex > -1) {
                    acc[cCritIndex].criteria.push(id);
                } else {
                    acc.push({ ...crit, criteria: [id] });
                }

                return acc;
            }, [])
            .map(({ id, choice_id: choiceId, criteria, description }) => {
                // filter out score 0 and score -1
                const cRatings = ratings.filter(({ criterium_id: _cId, score }) =>
                    criteria.includes(_cId) && score && score !== -1
                );

                // remove all duplicate criterium_id from cRatings
                const uniqueRatings = cRatings.reduce((acc, rating) => {
                    const { criterium_id: cId } = rating;
                    const cRatingIndex = acc.findIndex(({ criterium_id: _cId }) => _cId === cId);

                    if (cRatingIndex > -1) {
                        acc[cRatingIndex] = rating;
                    } else {
                        acc.push(rating);
                    }

                    return acc;
                }, []);

                const scores = cRatings.reduce((acc, { score }) => {
                    if (acc[score]) {
                        acc[score]++;
                    } else {
                        acc[score] = 1;
                    }

                    return acc;
                }, {});

                return {
                    id,
                    choiceId,
                    description,
                    scores,
                    total: uniqueRatings.length,
                };
            })
            // filter out competences which have no scores
            .filter(({ scores }) => !!Object.keys(scores).length)
            .sort(({ description: a }, { description: b }) => (
                a < b ? -1 : b < a ? 1 : 0
            ));
    }, [value]);

    return {
        get,
        loading,
        competences,
        fetched,
    };

}

export default useProgressCompetencesApi;
