import React, { useEffect, useState, useMemo } from 'react';
import Bem from 'react-better-bem';
import { useParams } from 'react-router-dom';

import { getCopy } from '../../../utils';
import { useLayout, usePageTitle } from '../../../hooks';
import { useProgressCompetenceDetailsApi } from '../../../hooks/api';
import {
    useAppStateContext,
    useEnvironmentContext,
    useCoachingTraineeContext,
    useBreadcrumbTrailContext,
} from '../../../context';

import {
    CompetenceBlocksGraph,
    Table,
    Button,
    LinkList,
    BlockBadge,
} from '../../../components';

import styles from './CompetenceDetails.module.scss';


const ProgressCompetenceDetails = ({ planning, studentId, student, role }) => {
    useLayout({ stretch: false, collapse: 'top', flexContent: false, margin: false });
    const { screen: { isSmallDesktop } } = useAppStateContext();

    /* * * *
     * API *
    ** * * */
    const { choice_id: choiceId } = useParams();
    const { criterium, loading, groups, scores, _pastGroups} = useProgressCompetenceDetailsApi(choiceId, studentId);

    usePageTitle(
        loading ? 'loading' : `progress.competenceDetails.${role}`,
        { criterium: criterium || '', student: student?.full_name || '' },
    );

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        const overviewPageTitle = getCopy(`progress.${role}.competences.title`);
        const overviewPath = getCopy(`progress.${role}.competences.path`, { studentId });

        put({
            name: overviewPageTitle,
            href: overviewPath,
        }, role === 'student' ? 1 : 2)

        put({
            name: criterium,
            // href: path,
            loading,
        }, role === 'student' ? 2 : 3);

        return () => {
            remove(overviewPageTitle);
            remove(criterium);
        };
    }, [remove, put, criterium, role, studentId, loading]);

    /* * * * * * * * *
     * SPLIT COURSES *
    ** * * * * * * * */
    const currentBlock = planning.current || planning.upcoming;
    const futureGroups = groups.filter((group) => {
        if (!currentBlock) {
            return false;
        }

        const { scheduled_course: { block: { number } } } = group;

        return number >= currentBlock.number;
    });

    const pastGroups = _pastGroups.filter((group) => {
        if (!currentBlock) {
            return true;
        }

        const { scheduled_course: { block: { number } } } = group;

        return number < currentBlock.number;
    });

    /* * * * * *
     * OUTPUT  *
    ** * * * * */


    const futureCoursesTitle = getCopy(`progress.${role}.competenceDetails.futureCoursesTitle`);
    const pastCoursesTitle = getCopy(`progress.${role}.competenceDetails.pastCoursesTitle`);

    return !loading && (
        <Bem style={styles}>
            <section el="container graph">
                <h2>{criterium} - verloop over tijd</h2>
                <CompetenceBlocksGraph
                    scores={scores}
                    currentBlock={planning.current || planning.latest}
                />
            </section>
            {futureGroups.length ? (
                <section el="container table" mod="shaded">
                    <div el="content">
                        <h3>{futureCoursesTitle}</h3>
                        {isSmallDesktop ? (
                            <CompetenceDetailsTable
                                groups={futureGroups}
                                studentId={studentId}
                                role={role}
                            />
                        ) : (
                            <CompetenceDetailsLinksList
                                groups={futureGroups}
                                studentId={studentId}
                                role={role}
                            />
                        )}
                    </div>
                </section>
            ) : null}
            {pastGroups.length ? (
                <section el="container table">
                    <div el="content">
                        <h3>{pastCoursesTitle}</h3>
                        {isSmallDesktop ? (
                            <CompetenceDetailsTable
                                groups={pastGroups}
                                studentId={studentId}
                                role={role}
                            />
                        ) : (
                            <CompetenceDetailsLinksList
                                groups={pastGroups}
                                studentId={studentId}
                                role={role}
                            />
                        )}
                    </div>
                </section>
            ) : null}
        </Bem>
    );
};

/* * * * * *
 * STUDENT *
** * * * * */
const StudentProgressCompetenceDetails = () => {
    const { planning } = useEnvironmentContext();

    return (
        <ProgressCompetenceDetails
            planning={planning}
            role="student"
        />
    );
};

/* * * * * * * * *
 * COACH/MENTOR  *
** * * * * * * * */
const CoachProgressCompetenceDetails = ({ role = 'coach' }) => {
    const { student, studentId, planning } = useCoachingTraineeContext();

    return (
        <ProgressCompetenceDetails
            studentId={studentId}
            student={student}
            planning={planning}
            role={role}
        />
    );
};

export {
    StudentProgressCompetenceDetails,
    CoachProgressCompetenceDetails,
};

/* * * * *
 * TABLE *
** * * * */
const CompetenceDetailsTable = ({ groups, studentId, role }) => {
    const [currentSortProp, setCurrentSortProp] = useState('name');
    const [courseSortDirection, setCourseSortDirection] = useState('ASC');
    const [blockSortDirection, setBlockSortDirection] = useState('ASC');

    const courseRows = useMemo(() => {
        const direction = currentSortProp === 'name' ? courseSortDirection : blockSortDirection;
        const sorted = [...groups].sort((groupA, groupB) => {
            const {
                scheduled_course: {
                    block: { number: blockA },
                    course: { cleanName: cNameA },
                },
            } = groupA;

            const {
                scheduled_course: {
                    block: { number: blockB },
                    course: { cleanName: cNameB },
                },
            } = groupB;

            const propA = currentSortProp === 'name' ? cNameA : blockA;
            const propB = currentSortProp === 'name' ? cNameB : blockB;

            if (direction === 'ASC') {
                return propA < propB ? -1 : propB < propA ? 1 : 0;
            }
            return propB < propA ? -1 : propA < propB ? 1 : 0;
        });

        return sorted.map(({
            id: groupCourseId,
            teachers,
            scheduled_course: {
                block: { number: blockNr },
                course: { cleanName }
            }
        }) => ({
            cells: [
                {
                    align: 'left',
                    spaceAfter: true,
                    content: cleanName
                },
                {
                    align: 'center',
                    spaceAfter: true,
                    content: blockNr
                },
                {
                    align: 'left',
                    spaceAfter: true,
                    content: teachers.length ? teachers[0].full_name : "Nog niet bekend",
                },
                {
                    align: 'center',
                    content: (
                        <Button
                            to={getCopy(
                                `progress.${role}.courses.linkTemplate`,
                                { groupCourseId, studentId }
                            )}
                            icon="external-link"
                            variant="small"
                            transparent
                        />
                    )
                }
            ]
        }));
    }, [groups, studentId, role, currentSortProp, courseSortDirection, blockSortDirection]);

    /* * * * * * * *
     * TABLE SETUP *
    ** * * * * * * */
    const copyRole = role === 'student' ? 'student' : 'teacher';
    const headerRow = {
        cells: [
            {
                align: 'left',
                spaceAfter: true,
                bg: 'single',
                style: { width: '50%' },
                content: getCopy(`rating.tableHeaders.${copyRole}.courses.title`),
                sort: {
                    sortable: true,
                    direction: courseSortDirection,
                    callback: (dir) => {
                        setCurrentSortProp('name');
                        setCourseSortDirection(dir);
                    }
                }
            },
            {
                align: 'center',
                spaceAfter: true,
                bg: 'single',
                style: { width: '7rem' },
                content: getCopy(`rating.tableHeaders.${copyRole}.block.title`),
                sort: {
                    sortable: true,
                    direction: blockSortDirection,
                    callback: (dir) => {
                        setCurrentSortProp('block');
                        setBlockSortDirection(dir);
                    }
                }
            },
            {
                align: 'left',
                bg: 'single',
                spaceAfter: true,
                content: getCopy(`rating.tableHeaders.${copyRole}.teacher.title`)
            },
            {
                align: 'center',
                bg: 'single',
                style: { width: '5rem' },
                content: getCopy(`rating.tableHeaders.${copyRole}.courseInfo.title`),
            }
        ]
    };

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Table
            className={styles.table__table}
            rows={[ headerRow, ...courseRows ]}
        />
    );
};

/* * * * * * * * * * * * * * *
 * LINKSLIST (SMALL SCREENS) *
** * * * * * * * * * * * * * */
const CompetenceDetailsLinksList = ({ groups, role, studentId }) => {
    const courseLinks = useMemo(() => {
        return groups.map(({ id: groupCourseId, scheduled_course: { block, course: { courseName } }, teachers: [{ full_name = '' }] = [{}] }) => ({
            children: (
                <>
                    <strong className={styles['list-item__title']}>{courseName}</strong>
                    <small className={styles['list-item__subtitle']}><BlockBadge size="small" block={block} /> {full_name}</small>
                </>
            ),
            to: getCopy(
                `progress.${role}.courses.linkTemplate`,
                { groupCourseId, studentId }
            )
        }))
    }, [groups, role, studentId]);

    return (
        <LinkList
            collapseTop
            links={courseLinks}
        />
    );
};



