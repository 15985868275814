import moment from 'moment';
import 'moment/locale/nl';
import resolvePath from 'object-resolve-path';

import strings from './data/strings.json';

import COLORS from './config/colours.json';
import ENV_COLORS from './config/env_colours.json';

import STATUS from './data/rating_statusses.json';
import RESULT_TYPE from './data/result_types.json';

/* * * * * * * *
 * ENVIRONMENT *
** * * * * * * */
export function getEnvironment () {
    if (window.location.href.includes('talnet.nl')) {
        return 'production';
    }

    if (window.location.href.includes('talneta.nl')) {
        return 'staging';
    }

    if (window.location.href.includes('localhost')) {
        return 'local';
    }

    return process.env.REACT_APP_FLEXX_ENV;
}

const ENABLE_STUDYROUTE_DEFAULT = !!parseInt(process.env.REACT_APP_FLEXX_ENABLE_STUDYROUTE, 10);
const ENABLE_STUDYROUTE_STAGING = !!parseInt(process.env.REACT_APP_FLEXX_ENABLE_STUDYROUTE_STAGING, 10);
const ENABLE_STUDYROUTE_LOCAL = !!parseInt(process.env.REACT_APP_FLEXX_ENABLE_STUDYROUTE_LOCAL, 10);
const ENVIRONMENT = getEnvironment();

export const ENABLE_STUDYROUTE = (ENVIRONMENT === 'staging' && ENABLE_STUDYROUTE_STAGING) ||
    (ENVIRONMENT === 'local' && ENABLE_STUDYROUTE_LOCAL) || ENABLE_STUDYROUTE_DEFAULT;

const FULL_CHOICES_DEFAULT = !!parseInt(process.env.REACT_APP_FLEXX_FULL_CHOICES, 10);
const FULL_CHOICES_STAGING = !!parseInt(process.env.REACT_APP_FLEXX_FULL_CHOICES_STAGING, 10);
const FULL_CHOICES_LOCAL = !!parseInt(process.env.REACT_APP_FLEXX_FULL_CHOICES_LOCAL, 10);

export const FULL_CHOICES = (ENVIRONMENT === 'staging' && FULL_CHOICES_STAGING) ||
    (ENVIRONMENT === 'local' && FULL_CHOICES_LOCAL) || FULL_CHOICES_DEFAULT;

/* * * * * * * * * * * * * * *
 * DATE STRINGS TRANSFORMERS *
** * * * * * * * * * * * * * */
export function dateToDateString (date = new Date()) {
    date = !moment.isMoment(date) ? moment(date) : date;
    return date.format('YYYY-MM-DD');
}

export function dateToRelativeDateString (date = new Date()) {
    date = !moment.isMoment(date) ? moment(date) : date;

    const today = moment();

    if (date.isSame(today, 'day')) {
        return date.fromNow();
    }

    const yesterday = moment().subtract(1, 'day');

    if (date.isSame(yesterday, 'day')) {
        return date.format('[gisteren om] HH:mm [uur]');
    }

    return date.format('D MMMM YYYY [om] HH:mm [uur]')
}

/* * * * * * * * * * * * * * * * * *
 * CSS VARIABLES ON DOCUMENT LEVEL *
** * * * * * * * * * * * * * * * * */
const root = document.documentElement;
export function setCssVariable (name, value) {
    root.style.setProperty(`--${name}`, value);
}

export function getCssVariable (name) {
    return getComputedStyle(root).getPropertyValue(`--${name}`);
}

/* * * * * * * * * * *
 * CAMALIZE STRINGS  *
** * * * * * * * * * */
export function camalize (str) {
    return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (_, char) => char.toUpperCase());
}

/* * * * * * * * * * * * * * * * * * * *
 * GET COURSE NAME FROM COURSE OBJECT  *
** * * * * * * * * * * * * * * * * * * */
// TODO: Replace/remove when updates are done
export function cleanupCourseName(course = {}) {
    const {
        course_name: name,
        course_subject: subject,
        course_index: index,
        course_category: category,
        course_profile: profile
    } = course;

    let courseName = name || subject;
    let courseIndex = index;
    let cleanName = name;

    const regex = /-*0*(\d+[A-Z]?)$/g; // regex to get index from courseName
    const matches = courseName?.matchAll(regex) || [];
    const indexMatch = [ ...matches ][0];

    // try to extract index and coursename from name field
    if (indexMatch !== undefined) {
        const strIndex = indexMatch.index;
        courseName = name.substring(0, strIndex);
        courseIndex = indexMatch[1];
        cleanName = `${courseName} ${courseIndex}`;
    }

    return {
        ...course,
        name,
        category,
        profile,
        courseName,
        courseIndex,
        cleanName,
    };
}

export function refineCourseName (course = {}) {
    const { index, subject = '', name = '' } = course;

    let courseName = name || subject;
    let courseIndex = index;
    let cleanName = name;

    const regex = /-*0*(\d+[A-Z]?)$/g; // regex to get index from courseName
    const matches = name.matchAll(regex);
    const indexMatch = [ ...matches ][0];

    // try to extract index and coursename from name field
    if (indexMatch !== undefined) {
        const strIndex = indexMatch.index;
        courseName = name.substring(0, strIndex);
        courseIndex = indexMatch[1];
        cleanName = `${courseName} ${courseIndex}`;
    }

    return {
        ...course,
        courseName,
        courseIndex,
        cleanName,
    };
}

/* * * * * * *
 * GET COPY  *
** * * * * * */
// get strings by key from data/strings.json
// including string replacements
export function getCopy (path, replacements = {}, source = strings) {
    path = path.replace(/\s+/g, '');

    let string = resolvePath(source, path) || '';

    const keys = Object.keys(replacements);
    keys.forEach((key) => {
        const re = new RegExp(`{${key}}`, 'g');
        const value = replacements[key];
        string = string.replace(re, value);
    });

    return string;
}

export function getAttendanceEduarteUrl (role, studentId, mode = 'view') {
    return getCopy(`eduarteAttendance.${role}.${mode}`, { studentId });
}

export function getCalendarEduarteUrl (studentId) {
    return getCopy(`eduarteCalendar.url`, { studentId });
}

/* * * * * * * * * * * * * * * * *
 * GET CORRECT ENVIRONMENT COLOR *
** * * * * * * * * * * * * * * * */
export function getColor (key, environment = 'STUDENT', fallback = '#000000') {
    if (COLORS[key]) {
        return COLORS[key];
    }

    const envColors = {
        ...ENV_COLORS._DEFAULT_,
        ...ENV_COLORS[environment]
    };

    const validKeys = Object.keys(envColors);

    if (!validKeys.includes(key)) {
        return fallback;
    }

    return COLORS[envColors[key]];
};

/**
 * A function to look at the dom and find the footer element and return it.
 */
export function hideFooterElement () {
   const footer =  document.querySelector('footer');

   if (footer) footer.style.display = 'none';
}

/**
 * Check if a string is a valid result value.
 * i.e. A 2 decimal, floating point number.
 * @param {string} result - The result string to be validated.
 * @return {boolean} Whether the string is a valid result or not.
 */
export function validateResult(result) {
    const regex = new RegExp(/^\d*([.|,]\d{0,2})?$/, 'g');
    return regex.test(result);
};

/**
 * Parse an input field result and restrict the input.
 * Note: Must be a valid result and value cannot be larger than 10.
 * @param {HTMLInputElement} target - The target input element.
 */
export function parseInput(target) {
    const value = target.value;
    if (!validateResult(value)) {
        target.value = value.substring(0, value.length-1);
    } else if (parseFloat(value) >= 10) {
        target.value = `10`;
    }
};

/**
 * Calculate the status value based on the result value.
 * @param { float | string } result - The result value
 * @param { "GRADE"|"OVG" } resultType - The type of result
 * @return { string } The computed result.
 */
export function getResultBasedStatus(result, resultType) {
    if (!result) {
        return STATUS.BLANK;
    }

    if (resultType === RESULT_TYPE.OVG) {
        return ['V', 'G'].includes(result) ? STATUS.COMPLETED : STATUS.NOT_COMPLETED;
    }

    return (result >= 5.5) ? STATUS.COMPLETED : STATUS.NOT_COMPLETED;
}

/**
 * Extract the domain from a given URL string.
 * @param {string} url - The URL to process.
 * @return {string} The URL domain.
 */
export function extractUrlName(url) {
    return url.replace(/.+\/\/|www./g, '')
}
