import { memo } from "react";
import Bem from 'react-better-bem';

import statusIcons from "../../../assets/statusIcons";

import styles from "./CardHeader.module.scss";

const TAG = 'Totaal';

const CardHeader = ({ IconName, title, hours }) => {

    return (
        <Bem style={styles}>
            <li el="header-card">
                <div el="icon">
                    <img src={statusIcons[`${IconName}`]} alt="test" />
                </div>
                <div el="content">
                    <h4 el="title" mod={{ last: title && title.includes(TAG) }}>
                        {title}
                    </h4>
                    <h2 el="hours" mod={{ last: title.includes(TAG) }}>
                        {hours ? hours : 0} uur
                    </h2>
                </div>
            </li>
        </Bem>
    );
};

export default memo(CardHeader);