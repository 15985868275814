import React from 'react';
import { Link } from 'react-router-dom';

import { useAppStateContext } from '../../../context';
import { getCopy } from '../../../utils';
import { Button, Icon } from '../../';

const InfoModal = ({ choice, allowChoosing, choose, student = {}, role }) => {
    const { modal: { close: closeModal } } = useAppStateContext();

    const {
        scheduled_course: {
            course: {
                id: courseId,
                description = '',
            },
            block: {
                id: blockId,
            },
        },
    } = choice;

    const { flexx_id: studentId } = student;

    const courseInfoLink = getCopy(
        `progress.${role}.courses.scheduledCourseLinkTemplate`,
        { courseId, blockId, studentId }
    );

    const noInfoWarning = getCopy(
        `progress.${role}.courses.noCourseInfoWarning`
    );

    const chooseButtonText = getCopy(
        `coursePlanner.firstMoment.${role}.actions.choose`
    );

    return (
        <>
            {description.length ? (
                <p>{description}</p>
            ) : (
                <p>{noInfoWarning}</p>
            )}
            <p>
                <Icon type="info" />
                <Link target="_blank" to={courseInfoLink}>Bekijk volledige beschrijving</Link>
            </p>
            {allowChoosing ? (
                <>
                    <br/>
                    <Button
                        onClick={() => {
                            choose();
                            closeModal();
                        }}
                        icon="plus-sign"
                        iconPosition="start"
                    >
                        {chooseButtonText}
                    </Button>
                </>
            ) : null}
        </>
    );
};

export default InfoModal;
