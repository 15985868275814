import React, { useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import withHocs from './hocs';
import { useEnvironmentContext } from './context';
import { usePageHistory, useScrollRestoration } from './hooks';
import { useUserApi } from './hooks/api';

/* * * * *
 * PAGES *
** * * * */
import { Main } from './containers';
import * as Pages from './pages';

function App() {
    // first check if user is logged in
    const { loadingUser, fetchedUser } = useUserApi();
    useScrollRestoration({ path: window.location.pathname });

    return (
        <Main>
            {loadingUser && !fetchedUser ? (
                <Pages.Loading/>
            ) : (
                <EnvRoutes />
            )}
        </Main>
    );
}

export default withHocs(App);

function EnvRoutes() {
    const { routeItems } = useEnvironmentContext();

    usePageHistory(routeItems);

    const routesMemo = useMemo(() => (
        <Switch>
            {routeItems.map(({ name, path, redirect, exactPath = false, container }) => {
                if (redirect !== undefined) {
                    return (
                        <Route path={path} exact={exactPath} key={`redirect-${path}-${redirect}`}>
                            <Redirect to={redirect} />
                        </Route>
                    );
                }

                const PageContainer = Pages[container];

                if (!PageContainer) {
                    return null;
                }

                return (
                    <Route path={path} exact={exactPath} key={`route-${name}-${path}`}>
                        <PageContainer />
                    </Route>
                );
            })}
            <Route>
                <Pages.ERROR_404 />
            </Route>
        </Switch>
    ), [routeItems]);

    return routesMemo;
}
