import React, { useEffect } from "react";

import { getCopy } from "../../../utils";
import { useEnvironmentContext, useBreadcrumbTrailContext } from "../../../context";
import { useInternshipApi } from '../../../hooks/api'
import { useLayout } from '../../../hooks';

import { OverviewPage } from "../../../components";

const StudentProgressBPV = () => {
    useLayout({ collapse: 'both' });

    const {
        loading: internshipsLoading,
    } = useInternshipApi();

    const api = useInternshipApi();

    const {
        user: { roles },
    } = useEnvironmentContext();

      /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
      const { put, remove } = useBreadcrumbTrailContext();

      useEffect(() => {
          const pageTitle = getCopy(`bpv.${roles[0]}.pageTitle`);
          const path = getCopy(`bpv.${roles[0]}.path`);

          put({
              name: pageTitle,
              href: path,
              internshipsLoading,
          }, roles[0] === 'student' ? 1 : 2);

          return () => {
              remove(pageTitle);
          };
      }, [remove, put, roles, internshipsLoading]);

    return (
        <OverviewPage
            showHeader={false}
            api={api}
        />
    );
};

export default StudentProgressBPV;
