import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';
import Bem from 'react-better-bem';

import { Button } from '../';
import { useAppStateContext } from '../../context';

import styles from './TileContainer.module.scss';

const TileContainer = ({ title, subtitle, children, scroll = false, foldout = false, choiceMoment = false }) => {
    const { screen: { isTablet } } = useAppStateContext();

    const containerRef = useRef();
    const nChildrenRef = useRef();

    const scrollCallback = useCallback((direction) => {
        const allTiles = containerRef.current.children;
        const scrollLeft = containerRef.current.scrollLeft;
        const tileWidth = containerRef.current.firstChild.offsetWidth;
        const tilesShifted = Math.round(scrollLeft / tileWidth);

        // 0 < shiftTo < nTiles
        const shiftTo = Math.max(0, Math.min(allTiles.length - 1, tilesShifted + (direction === 'LEFT' ? -1 : 1)));

        if (allTiles[shiftTo]) {
            containerRef.current.scrollTo({
                left: parseInt(shiftTo * tileWidth, 10),
                behavior: 'smooth'
            });
        }
    }, []);

    /* * * * * * * * *
     * SHOW BUTTONS? *
    ** * * * * * * * */
    const [showButtons, setShowButtons] = useState(false);
    const [maxVisibleOnRow, setMaxVisibleOnRow] = useState(-1);
    const [expanded, setExpanded] = useState(false);

    const checkContainerSize = useCallback(() => {
        const container = containerRef.current;

        if (container && container.firstChild) {
            const { width: cWidth } = container.getBoundingClientRect();

            const tiles = container.children;
            const tileWidth = container.firstChild.offsetWidth;
            const totalTilesWidth = tiles.length * tileWidth;

            setShowButtons(totalTilesWidth > cWidth);
            setMaxVisibleOnRow(Math.floor((cWidth - 1) / tileWidth));
        }
    }, []);

    useEffect(() => {
        if (nChildrenRef.current !== children?.length) {
            nChildrenRef.current = children?.length || 0;
            checkContainerSize();
        }
    }, [checkContainerSize, children]);
    useResizeObserver({ ref: containerRef, onResize: checkContainerSize });

    /* * * * * * * * * *
     * BUTTON ELEMENT  *
    ** * * * * * * * * */
    const buttons = useMemo(() => {
        if (!showButtons || (!scroll && !foldout)) {
            return null;
        }

        if (scroll) {
            return (
                <Bem style={styles}>
                    <div el="buttons">
                        <Button
                            variant="mini"
                            icon="arrow-left"
                            transparent
                            className={styles.buttons__button}
                            onClick={() => { scrollCallback('LEFT'); }}
                        />
                        <Button
                            variant="mini"
                            icon="arrow-right"
                            transparent
                            className={styles.buttons__button}
                            onClick={() => { scrollCallback('RIGHT'); }}
                        />
                    </div>
                </Bem>
            );
        }

        if (foldout) {
            const nChildren = children?.length ? ` (${children.length})` : '';

            return (
                <Bem style={styles}>
                    <div el="buttons">
                        <Button
                            icon={expanded ? 'chevron-down' : 'chevron-right'}
                            className={styles.buttons__button}
                            variant={isTablet ? 'small' : 'default'}
                            onClick={() => { setExpanded((cExpanded) => !cExpanded); }}
                            transparent={isTablet}
                            block={!isTablet}
                        >
                            {expanded ? 'Toon minder' : `Toon alle${nChildren}`}
                        </Button>
                    </div>
                </Bem>
            );
        }

        return null;
    }, [showButtons, scroll, foldout, expanded, scrollCallback, isTablet, children]);

    /* * * * *
     * TILES *
    ** * * * */
    const tiles = useMemo(() => {
        const limit = (foldout && !expanded && maxVisibleOnRow > -1 ? maxVisibleOnRow : (children?.length || 0)) - 1;

        return React.Children.map(children, (child, i) => (
            <Bem style={styles} block="container">
                <div el="tile" mod={{ hidden: i > limit, choice: choiceMoment }} key={`tile-${i}`}>
                    {child}
                </div>
            </Bem>
        ));
    }, [foldout, maxVisibleOnRow, expanded, children, choiceMoment]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    return (
        <Bem style={styles}>
            <div el="title">
                <h3 el="title">{title}</h3>
                {scroll || isTablet ? buttons : null}
            </div>
            {subtitle && (
                <div el="subtitle">
                    <h4 el="title">{subtitle}</h4>
                </div>
            )}
            <div el="container" mod={{ scroll, foldout }} ref={containerRef}>
                {tiles}
            </div>
            {!isTablet && foldout ? buttons : null}
        </Bem>
    );
};

export default TileContainer;
