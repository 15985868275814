import React, { useCallback, useMemo } from 'react';
import Bem from 'react-better-bem';

import { Icon } from '../';
import { useEnvironmentContext } from '../../context/'
import { getColor } from '../../utils';

import styles from './Toggle.module.scss';

import checkmarkIcon from './checkmark.svg';
import dashIcon from './dash.svg';

const VALID_TYPES = ['checkbox', 'radio'];
const VALID_COLORS = ['default', 'green', 'red', 'gray', 'gray-darker', 'red-lighter', 'blue-lighter', 'purple-dark', 'orange', 'green'];
const VALID_SIZES = ['default', 'small'];

const ICON_COLOR_MAP = {
    default: 'toggle-border',
    green: 'green',
    red: 'red',
};

const Toggle = ({
    checked = false,
    change = () => void 0,
    disabled = false,
    label = '',
    labelColor,
    color = 'default',
    borderColor,
    className = '',
    type = 'checkbox',
    size = 'default',
    icons = false,
    ...props
}) => {
    const { environment } = useEnvironmentContext();

    const onChange = useCallback(({ target }) => {
        change(target.checked);
    }, [change]);

    const toggleType = VALID_TYPES.includes(type) ? type : VALID_TYPES[0];
    const toggleColor = VALID_COLORS.includes(color) ? color : VALID_COLORS[0];
    const toggleSize = VALID_SIZES.includes(size) ? size : VALID_SIZES[0];

    const controlIcon = useMemo(() => {
        if (!icons) {
            return null;
        }

        return (
            <Icon
                src={checked ? checkmarkIcon : dashIcon}
                color={disabled ? 'toggle-border-disabled' : checked ? ICON_COLOR_MAP[toggleColor] : 'toggle-border'}
                className={`${styles.wrapper__control__icon} ${size === 'small' ? styles['wrapper__control__icon--small'] : ''}`}
            />
        );
    }, [icons, checked, disabled, size, toggleColor]);

    const labelCssProps = useMemo(() => {
        if (label && labelColor) {
            return {
                color: getColor(labelColor, environment)
            };
        }
        return {};
    }, [labelColor, label, environment]);

    return (
        <Bem style={styles}>
            <label el="wrapper" mod={{ disabled, checked, color: toggleColor, type: toggleType, icons, size: toggleSize }} className={className}>
                <input
                    {...props}
                    type={toggleType}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    el="input"
                />
                <span el="control" mod={{ borderColor }}>
                    {controlIcon}
                </span>
                {label && (<span el="label" style={labelCssProps}>{label}</span>)}
            </label>
        </Bem>
    );
};

export default Toggle;
