import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Bem from 'react-better-bem';

import { useBreadcrumbTrailContext } from '../../context';

import { Icon, LoadingDots } from '../';

import styles from './BreadCrumbNav.module.scss';

const BreadCrumbNav = () => {
    const { pathname } = useLocation();

    const { trail } = useBreadcrumbTrailContext();

    if (!trail.length) {
        return null;
    }

    const _trail = trail.filter(({ name, loading, current, href }) => {
        return (name || loading) && (current || href);
    });

    return (
        <Bem style={styles}>
            <nav el="breadcrumbs">
                {_trail.map(({ name, href, current, loading }, i) => (
                    <React.Fragment key={`breadcrumb-${name}-${i}`}>
                        {i ? (
                            <Icon
                                type="chevron-right"
                                size="extra-small"
                                valign="middle"
                                className={styles.breadcrumbs__chevron}
                            />
                        ) : null}
                        <Link
                            to={current ? pathname : href}
                            className={styles.breadcrumbs__link}
                        >
                            {loading ? <LoadingDots/> : name}
                        </Link>
                    </React.Fragment>
                ))}
            </nav>
        </Bem>
    );
};

export default BreadCrumbNav;
