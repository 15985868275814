import React from "react";
import Bem from "react-better-bem";

import { BPVFooter, BPVBody } from "../";

import styles from "./NoInternship.module.scss";

const NoInternship = ({ completed, studentName, isStudent, bpvLinks }) => (
    <Bem style={styles}>
        <section el="container">
            <BPVBody
                bpvCoursesCompleted={completed}
                studentName={studentName}
                isStudent={isStudent}
                bpvLinks={bpvLinks}
            />
            <BPVFooter isStudent={isStudent} bpvLinks={bpvLinks} />
        </section>
    </Bem>
);
export default NoInternship;
