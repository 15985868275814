import { useReducer, useCallback } from 'react';

function useBreadcrumbTrail () {
    const [trail, dispatchTrail] = useReducer(trailReducer, []);

    const reset = useCallback(() => {
        dispatchTrail({ type: 'RESET' });
    }, []);

    const set = useCallback((trail = []) => {
        dispatchTrail({ type: 'SET', trail });
    }, []);

    const push = useCallback((entry = {}) => {
        dispatchTrail({ type: 'PUSH', entry });
    }, []);

    const pop = useCallback(() => {
        dispatchTrail({ type: 'POP' });
    }, []);

    const remove = useCallback((search) => {
        dispatchTrail({ type: 'REMOVE', search });
    }, []);

    const put = useCallback((entry = {}, index = 0) => {
        dispatchTrail({ type: 'PUT', entry, index });
    }, []);

    const update = useCallback((entry = {}, index = 0) => {
        dispatchTrail({ type: 'update', entry, index });
    }, []);

    return {
        trail: trail.filter(Boolean),
        set,
        push,
        pop,
        remove,
        put,
        update,
        reset,
    };
}

export default useBreadcrumbTrail;

function trailReducer (state, action) {
    const { type } = action;

    switch (type) {
        case 'RESET': {
            return [];
        }
        case 'SET': {
            const { trail } = action;
            return sanitize(trail);
        }
        case 'PUSH': {
            const { entry } = action;
            return sanitize([ ...state, entry ]);
        }
        case 'POP': {
            const newState = [ ...state ];
            newState.pop();
            return sanitize(newState);
        }
        case 'REMOVE': {
            const { search } = action;
            const index = state.findIndex(({ name, href } = {}) =>
                name === search || href === search
            );

            const newState = state.filter((_, i) => i !== index);
            return sanitize(newState);
        }
        case 'PUT': {
            const { entry, index } = action;
            const newState = [ ...state ];
            newState[index] = entry;
            return sanitize(newState);
        }
        case 'UPDATE': {
            const { entry, index } = action;
            const newState = [ ...state ];
            newState[index] = {
                ...newState[index],
                ...entry
            };
            return sanitize(newState);
        }
        default: {
            return state;
        }
    }
}

function sanitize (trail = []) {
    return trail
        // .filter(({ name = '' }, i, allCrumbs) => name && name.length)
        // .filter(({ href }, i, allCrumbs) =>
        //     allCrumbs.findIndex(({ href: _href }) => href === _href) === i
        // )
        .map((entry, i, allCrumbs) => ({
            ...entry,
            current: i === allCrumbs.length - 1,
        }))
}
